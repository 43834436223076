import React from "react";
import { AxiosResponse, AxiosError } from "axios";
import DataGrid, { Pager, Paging, FilterRow, Column, SearchPanel, StateStoring} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import gridUtils, { currencyTypeToSymbolMatrix, currencyTypes } from "../grid/GridUtilities";
import PaymentService, { PaymentCyclesGridServiceRowItem } from "../../services/PaymentService";
import ActionCell from "./ActionCellComponent";
import UserService, { RoleGroupNames } from "../../services/UserService";
import PaymentViewActionCell from "../PaymentsV2/PaymentViewActionCell";
import PaymentEditActionCell from "../PaymentsV2/PaymentEditActionCell";

//props
interface PaymentCycleGridProps {
    refreshSignal: boolean;
    location: any;
    history: any;
}

// State
interface PaymentCycleGridState {
    gridDataSource: PaymentCyclesGridServiceRowItem[];
    showProgressIndicator: boolean;
}

// Component - displays the simple Grid for all users
//Grid_Utilities component can be reused here to format the date and Decimal fields.
class PaymentCycleGrid extends React.Component<PaymentCycleGridProps> {
    //Initialize the component's state.
    state: PaymentCycleGridState;
    //Initialize the service to fetch the data.
    Service: PaymentService;
    //Reuse the Grid utils component to format the cells.
    gridUtils: gridUtils;
    userHasAccess: boolean;
    constructor(props: PaymentCycleGridProps) {
        super(props);
        // Initialize state and services/utils
        this.Service = new PaymentService();
        this.gridUtils = new gridUtils();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        this.state = {
            gridDataSource: [],
            showProgressIndicator: false,
        };
        // Functions
        this.updateGridDataSource = this.updateGridDataSource.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    componentDidMount() {
        this.updateGridDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: PaymentCycleGridProps) {
        if (this.props.refreshSignal != prevProps.refreshSignal) {
            // refresh the grid
            this.updateGridDataSource();
        }
    }

    updateGridDataSource() {
        if (this.userHasAccess) {
            this.setState({
                showProgressIndicator: true,
            });
            this.Service.getPaymentCycleGridRows().then(this.handleSuccess).catch(this.handleError);
        }
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            gridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    }

    handleError(error: AxiosError<any>) {
        // Redirect back to home page on a 401 unauth error
        if (error.response != null && error.response.status === 401) {
            this.props.history.push({
                pathname: "/",
            });
        } else {
            this.setState({
                showProgressIndicator: false,
            });
            var respMessage: string = "getPaymentCycleGridRows failed with response: " + JSON.stringify(error);

            if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                // AppInsights is not available
                console.error(respMessage);
            }
        }
    }

    render() {
        var EventSM = UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        var EventUK = UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) || UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <Paging defaultPageSize={10} />
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <StateStoring
                                enabled={true}
                                type="localStorage" storageKey="payment_cycles_grid"
                                savingTimeout='0'
                            />
                        {EventUK?<Column caption="VIEW" cellComponent={PaymentViewActionCell} />:null}
                        {EventSM ? <Column caption="EDIT" cellComponent={PaymentEditActionCell} /> : null }
                        <Column
                            dataField="id"
                            caption="ID"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.id);
                            }}
                        />
                        <Column
                            dataField="payDate"
                            caption="CUT OFF DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.payDate);
                            }}
                        />
                        <Column
                            dataField="paymentCycleType"
                            caption="CYCLE TYPE"
                        />
                          <Column
                            dataField="subTotal"
                            caption="PROVIDER NET"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.subTotal,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.subTotal);
                            }}
                        />
                        
                      
                        <Column
                            dataField="tax"
                            caption="PROVIDER VAT"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.tax,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.tax);
                            }}
                        />
                        
                        <Column
                            dataField="total"
                            caption="PROVIDER GROSS"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.total,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.total);
                            }}
                        />
                        <Column dataField="payCycleReference" caption="OUR REF" />
                        <Column dataField="status" caption="STATUS" />
                        <Column
                            dataField="paySentDate"
                            caption="PAYMENT SENT DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.paySentDate);
                            }}
                        />
                        <Column dataField="description" caption="COMMENTS" />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default PaymentCycleGrid;
