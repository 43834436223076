import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import { SelectBox, DateBox, LoadPanel, TextBox, CheckBox, NumberBox, TextArea, Popup, ScrollView, LoadIndicator } from "devextreme-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import ResidencyFormUtil, { AddEditResidencyData } from "../Residencies/ResidencyFormUtil";
import EntertainmentResidencyService, { ResidencyGridRowItem } from "../../services/EntertainmentResidencyService";
import BillingFormUtils from "../Billing/BillingFormUtils";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import {
    DateBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
    NumberBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import DataGrid, {  Pager, Paging, FilterRow, Column, Sorting, SearchPanel} from "devextreme-react/data-grid";
import gridUtils, { currencyTypeToSymbolMatrix, currencyTypes } from "../grid/GridUtilities";
import SimpleShiftsByService from "../SimpleShiftsByService/SimpleShiftsByService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import AddEditPopUpService, { CalculationResponse } from "../../services/AddEditPopUpService";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;
//#region props
interface ResidencyFormProps extends WithTranslation {
    location: any;
    history: any;
    onApplySuccess: (applySignal: string) => void;
    isEditForm: any;
}

// State
interface ResidencyFormState {
    //TODO : Define the form state here.

    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    venueLookUp: LookupTypeItem[];
    providerLookUp: LookupTypeItem[];
    frequencyLookUp: LookupTypeItem[];
    editResidencyFormData: AddEditResidencyData;
    venueResidencyFormData: ResidencyGridRowItem[];
    loadPanelVisible: boolean;
    disableDayTextbox: boolean;
    filterId: string;
    errorMessage: [];
    showError: boolean;
    isServiceSubTypeSelected: boolean;
    isVenueGridVisible: boolean;
    isShiftGridVisible: boolean;
    serviceId: string;
    residencyId: string;
    showDuplicateItem: boolean;
    loadIndicatorVisible: boolean;
    rateChangeTimer: any;
    //weekLookUp: LookupTypeItem[];
}

class ResidencyForm extends React.Component<ResidencyFormProps> {
    lookupService: LookupService;
    state: ResidencyFormState;
    utils: ResidencyFormUtil;
    addEditutils: addEditPopupUtils;
    gridUtils: gridUtils;
    entService: EntertainmentResidencyService;
    periodWeekUtil: BillingFormUtils;
    sharedUtils: sharedUtils;
    editViewService: AddEditPopUpService;
    constructor(props: ResidencyFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.utils = new ResidencyFormUtil(props);
        this.sharedUtils = new sharedUtils();
        this.addEditutils = new addEditPopupUtils();
        this.gridUtils = new gridUtils();
        this.entService = new EntertainmentResidencyService();
        this.periodWeekUtil = new BillingFormUtils();
        this.editViewService = new AddEditPopUpService();
        var convertedEditPeriodData = this.utils.initializeResidencyFormItem(
            props.location.state && props.location.state.ResidenciesId ? props.location.state.ResidenciesId : ""
        );
        this.state = {
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            venueLookUp: [],
            providerLookUp: [],
            frequencyLookUp: [],
            editResidencyFormData: convertedEditPeriodData,
            venueResidencyFormData: [],
            loadPanelVisible: false,
            disableDayTextbox: false,
            filterId: "30",
            errorMessage: [],
            showError: false,
            isServiceSubTypeSelected: true,
            isVenueGridVisible: false,
            isShiftGridVisible: false,
            serviceId: "",
            residencyId: "",
            showDuplicateItem: false,
            loadIndicatorVisible: false,
            rateChangeTimer: null,
        };
        this.lookUpCreation = this.lookUpCreation.bind(this);
        this.handleServiceLUSuccess = this.handleServiceLUSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.getEditViewItemDataSource = this.getEditViewItemDataSource.bind(this);
        this.handleSuccessViewDataSource = this.handleSuccessViewDataSource.bind(this);
        this.handleChangeClient = this.handleChangeClient.bind(this);
        this.handleChangeVenue = this.handleChangeVenue.bind(this);
        this.handleChangeService = this.handleChangeService.bind(this);
        this.handleChangeArtist = this.handleChangeArtist.bind(this);
        this.handleChangeServiceType = this.handleChangeServiceType.bind(this);
        this.handleChangeActive = this.handleChangeActive.bind(this);
        this.handleChangeRate = this.handleChangeRate.bind(this);
        this.handleChangeFrequency = this.handleChangeFrequency.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeOverrideFlag = this.handleChangeOverrideFlag.bind(this);
        this.onApplyClick = this.onApplyClick.bind(this);
        this.onViewResidencyClick = this.onViewResidencyClick.bind(this);
        this.onViewShiftClick = this.onViewShiftClick.bind(this);
        this.handleSuccessDataSubmission = this.handleSuccessDataSubmission.bind(this);
        this.handleSuccessVenueData = this.handleSuccessVenueData.bind(this);
        this.hideInfoVenueGrid = this.hideInfoVenueGrid.bind(this);
        this.handleSuccessDataValidate = this.handleSuccessDataValidate.bind(this);
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        this.dropDownDataSource();
        this.getEditViewItemDataSource();
    }

    dropDownDataSource() {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
            .then(this.handleServiceLUSuccess)
            .catch(this.handleError);
    }
    handleServiceLUSuccess(response: AxiosResponse<any>) {
        var serviceDropDown = this.lookUpCreation(response);
        this.setState({
            serviceLookUp: serviceDropDown,
        });
    }
    lookUpCreation(response: AxiosResponse<any>): LookupTypeItem[] {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    }

    getEditViewItemDataSource() {
        if (this.props.location.state && this.props.location.state.id) {
            this.entService
                .getResidencyDataItem(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleError);
        }
    }

    handleSuccessViewDataSource(response: AxiosResponse<any>) {
        var viewDataSource = this.utils.convertResidencyItemResponeToFormData(response.data.data);
        this.setState({
            editResidencyFormData: viewDataSource,
        },
        () => {
            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        })
    }

    handleError(err: any) {
        this.setState({
            errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response?.data?.error)) : null, // For capturing response at the time of exception and showing error message
            showError: true,
            loadPanelVisible: false,
        });
    }

    financialCalculations = () => {
        if(this.state.editResidencyFormData.feeOverrideFlag == "True"){
            let overrideFee = this.state.editResidencyFormData.OverrideFee ? parseFloat(this.state.editResidencyFormData.OverrideFee.toString()) : 0;
            let rate = this.state.editResidencyFormData.Rate ? this.state.editResidencyFormData.Rate : 0;
            let clientNet = overrideFee + rate;
            this.setState({
                editResidencyFormData: {
                    ...this.state.editResidencyFormData,
                    clientNet: clientNet
                },
            });
        }
        else {
            this.showLoadIndicator();
            let calCulationObject = this.addEditutils.convertCalcDataToResidencyCalcRequest(
                this.state.editResidencyFormData,
                null,
                undefined,
                0
            );

            this.editViewService
                .uploadFormData(calCulationObject)
                .then(this.handleCalculationSuccess)
                .catch((err:any) => {
                    this.setState({
                        errorMessage: err.response ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                        IsShowSuccess: false,
                    });
                    this.hideLoadIndicator();
                })
                .finally(() => {
                    this.hideLoadIndicator();
                });
        }
    };

    hideLoadIndicator = () => {
        this.setState({
            loadIndicatorVisible: false,
        });
    };

    showLoadIndicator = () => {
        this.setState({ loadIndicatorVisible: true});
    };

    handleCalculationSuccess = (response: AxiosResponse<any>) => {
        let serverResponse: CalculationResponse = response.data.data;
        this.hideLoadIndicator();
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                OverrideFee: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitAmountLocal),
                clientNet: this.sharedUtils.convertStringToFloat(serverResponse.clientBillableAmountLocal),
            },
        });
    };

    startCallingFinancialAdjustment = () => {
        if (this.state.rateChangeTimer) {
            clearTimeout(this.state.rateChangeTimer);
            this.state.rateChangeTimer = null;
            this.financialCalculations();
        }
    };

    handleChangeClient(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                ClientId: dxValueChange.value,
            },
        });
    }
    handleChangeVenue(dxValueChange: LookUpOnValueChangedEvent) {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                VenueId: dxValueChange.value,
            },
        });
    }
    handleChangeServiceType(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                ServiceSubTypeId: dxValueChange.value,
            },
        },()=>{this.setFlag()});
    }

    setFlag(){
        this.setState({
            isServiceSubTypeSelected: false
        });
    }

    handleChangeType(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                typeLookUpId: dxValueChange.value,
            },
        });
    }
    handleChangeService(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                ServiceTypeId: dxValueChange.value,
            },
        });
    }
    handleChangeArtist(dxValueChange: LookUpOnValueChangedEvent) {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                ProviderId: dxValueChange.value,
            },
        });
    }
    handleChangeActive(dxValueChange: CheckBoxOnValueChanged) {
        var activeFlag = dxValueChange.value;
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                IsActive: activeFlag ? "True" : "False",
            },
        });
    }
    handleChangeOverrideFlag(dxValueChange: CheckBoxOnValueChanged) {
        var overRideFlag = dxValueChange.value;
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                feeOverrideFlag: overRideFlag ? "True" : "False",
            },
        },
        () => {
            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        })
    }
    handleChangeDateFrom = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                DateFrom: dxValueChange.value,
            },
        });
    };
    handleChangeDateTo = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                DateTo: dxValueChange.value,
            },
        });
    };

    handleChangeDay = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                DayId: dxValueChange.value,
            },
        });
    };
    handleChangeStartTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                StartTime: dxValueChange.value,
            },
        });
    };
    handleChangeEndTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                EndTime: dxValueChange.value,
            },
        });
    };
    handleChangeRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                Rate: dxValueChange.value,
            },
        } ,
        () => {
            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        })
    };

    handleChangeFee = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                OverrideFee: dxValueChange.value,
            },
        },
        () => {
            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        })
    };
    handleChangeClientNet = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                clientNet: dxValueChange.value,
            },
        });
    };
    handleChangeInternalComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                internalComments: dxValueChange.value,
            },
        });
    };
    handleChangeFrequency = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editResidencyFormData: {
                ...this.state.editResidencyFormData,
                FrequencyId: dxValueChange.value,
            },
        });
    };
    onApplyClick() {
        this.setState({
            loadPanelVisible: true,
        });
        let residencySubmitRequest = this.utils.convertFormDataToResidencyItemRequest(this.state.editResidencyFormData);
        
        this.entService
            .validateResidencyDataItem(residencySubmitRequest)
            .then(this.handleSuccessDataValidate)
            .catch(this.handleError);
    }

    onViewResidencyClick() {
        this.setState({
            loadPanelVisible: true,
        });
        let residencyVenueId = this.state.editResidencyFormData.VenueId;
        this.entService.getResidencyDataItemByVenue(residencyVenueId).then(this.handleSuccessVenueData).catch(this.handleError);
    }

    onViewShiftClick() {
        this.setState({
            isShiftGridVisible: true,
            residencyId: this.state.editResidencyFormData.ResidenciesId,
            serviceId: this.state.editResidencyFormData.ServiceTypeId,
        });
    }

    handleSuccessVenueData(response: AxiosResponse<any>) {
        this.setState({
            venueResidencyFormData: response.data.data,
            loadPanelVisible: false,
            isVenueGridVisible: true
        });
    }

    handleSuccessDataValidate(response: AxiosResponse<any>) {
        this.setState({
            loadPanelVisible: false,
        });

        let serverResponse: any = response.data.data; 
        if(serverResponse.length > 0){
            this.setState({
                showDuplicateItem: true,
            });  
        }
        else {
            this.setState({
                loadPanelVisible: true,
            });
            let residencySubmitRequest = this.utils.convertFormDataToResidencyItemRequest(this.state.editResidencyFormData);
            this.entService
                .saveResidencyDataItem(residencySubmitRequest)
                .then(this.handleSuccessDataSubmission)
                .catch(this.handleError);            
        }
    }

    handleSuccessDataSubmission() {
        this.setState({
            loadPanelVisible: false,
        });

        if (this.props.location.state && this.props.location.state.id) {
            this.props.onApplySuccess("EDIT");
        } else if (this.state.editResidencyFormData.ResidenciesId == "0") {
            this.props.onApplySuccess("ADD");
        }
    }

    onProceed() {
        this.setState({
            loadPanelVisible: true,
        });
        let residencySubmitRequest = this.utils.convertFormDataToResidencyItemRequest(this.state.editResidencyFormData);
         //passing object data to the server
         this.entService
         .saveResidencyDataItem(residencySubmitRequest)
         .then(this.handleSuccessDataSubmission)
         .catch(this.handleError);    
    };

    hideInfoVenueGrid() {
        this.setState({
            isVenueGridVisible: false,
            isShiftGridVisible: false,
            showDuplicateItem: false
        });
    }

    filterDataSource = (data: LookupTypeItem[], filterId: string): LookupTypeItem[] => {
        return !this.isBlank(filterId)
            ? data.filter((item: LookupTypeItem) => {
                return item.id === filterId;
            })
            : data;
    };
    isBlank(filterId: string) {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    }
    setCalculations = () => {};
    //#region render Component
    render() {
        return (
            <>
                <form data-testid="addEditShift-form" className="edit-form my-5 form_border">
                    <div className="container mb-6 edit-form__column">
                        <div className="row pt-3">
                            <h2 className="billingForm-heading">Residency Form</h2>
                        </div>
                        <br></br>
                        <div className="row justify-content-left pt-3" hidden={!this.state.showError}>
                            <span className="text-danger font-weight-bold text-left">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                                ID
                            </div>
                            <div className="mt-3 col-10 col-lg-2" hidden={!this.props.isEditForm}>
                                <TextBox value={this.state.editResidencyFormData.ResidenciesId} readOnly={true} />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Service</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <SelectBox
                                    dataSource={this.filterDataSource(this.state.serviceLookUp, this.state.filterId)}
                                    displayExpr={"value"}
                                    valueExpr={"id"}
                                    onValueChanged={this.handleChangeService}
                                    value={this.state.editResidencyFormData.ServiceTypeId}
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Sub Type</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <DropDownFromLookupTableDynamic
                                    lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                    isRoot={true}
                                    parentMappingId={this.state.editResidencyFormData.ServiceTypeId}
                                    onValueChanged={this.handleChangeServiceType}
                                    value={this.state.editResidencyFormData.ServiceSubTypeId}
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Type</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <DropDownFromLookupTableDynamic
                                    lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                    isRoot={true}
                                    parentMappingId={this.state.editResidencyFormData.ServiceSubTypeId}
                                    onValueChanged={this.handleChangeType}
                                    value={this.state.editResidencyFormData.typeLookUpId}
                                    isDisabled={this.state.isServiceSubTypeSelected}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Client</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                    onValueChanged={this.handleChangeClient}
                                    value={this.state.editResidencyFormData.ClientId}
                                    recordType={
                                        this.props.location.state && this.props.location.state.id ? "all" : "active"
                                    }
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Venue Name</div>
                            <div className="mt-3 col-10 col-lg-2 venue-width">
                                    <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.venueType}
                                        onValueChanged={this.handleChangeVenue}
                                        isRoot={false}
                                        parentMappingId={this.state.editResidencyFormData.ClientId}
                                        value={this.state.editResidencyFormData.VenueId}
                                        displayExpression={"value"}
                                        recordType={
                                            this.props.location.state && this.props.location.state.id ? "all" : "active"
                                        }
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Artist</div>
                            <div className="mt-3 col-10 col-lg-2 text-left">
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.providerType}
                                    onValueChanged={this.handleChangeArtist}
                                    value={this.state.editResidencyFormData.ProviderId}
                                    isRoot={false}
                                    parentMappingId={""}
                                    displayExpression={"value"}
                                />
                                {/* <input data-testid={LookupTypeIndexes.providerType} type='hidden' name={LookupTypeIndexes.providerType} value={(this.state.editViewDataItem.providerId) ? this.state.editViewDataItem.providerId : 0}  ></input> */}
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Active?</div>
                            <div className="mt-3 col-10 col-lg-2 residency_form_active_checkbox">
                                <CheckBox
                                    value={this.state.editResidencyFormData.IsActive != "False" ? true : false}
                                    onValueChanged={this.handleChangeActive}
                                // width={130}
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Date From</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <DateBox
                                    // disabled={this.state.createAdjustment ? this.state.createAdjustment : this.props.location.state.isAmend}
                                    onValueChanged={this.handleChangeDateFrom}
                                    value={
                                        this.state.editResidencyFormData.DateFrom
                                            ? this.state.editResidencyFormData.DateFrom
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                />
                            </div>

                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Date To</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <DateBox
                                    // disabled={this.state.createAdjustment ? this.state.createAdjustment : this.props.location.state.isAmend}
                                    onValueChanged={this.handleChangeDateTo}
                                    value={
                                        this.state.editResidencyFormData.DateTo
                                            ? this.state.editResidencyFormData.DateTo
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Start Time</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <DateBox
                                    type="time"
                                    interval={15}
                                    placeholder="00:00"
                                    useMaskBehavior={true}
                                    displayFormat={"HH:mm"}
                                    onValueChanged={this.handleChangeStartTime}
                                    value={
                                        this.state.editResidencyFormData.StartTime
                                            ? this.state.editResidencyFormData.StartTime
                                            : undefined
                                    }
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">End Time</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <DateBox
                                    type="time"
                                    interval={15}
                                    placeholder="00:00"
                                    useMaskBehavior={true}
                                    displayFormat={"HH:mm"}
                                    onValueChanged={this.handleChangeEndTime}
                                    value={
                                        this.state.editResidencyFormData.EndTime
                                            ? this.state.editResidencyFormData.EndTime
                                            : undefined
                                    }
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Day</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <DropDownFromLookupTableDynamic
                                    lookupTypeIndex={LookupTypeIndexes.dayType}
                                    isRoot={true}
                                    parentMappingId={"0"}
                                    onValueChanged={this.handleChangeDay}
                                    value={this.state.editResidencyFormData.DayId}
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Frequency</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <DropDownFromLookupTableDynamic
                                    lookupTypeIndex={LookupTypeIndexes.frequencyType}
                                    isRoot={true}
                                    parentMappingId={"0"}
                                    onValueChanged={this.handleChangeFrequency}
                                    value={this.state.editResidencyFormData.FrequencyId}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Rate</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeRate}
                                    valueChangeEvent="keyup"
                                    value={this.state.editResidencyFormData.Rate}
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Override Fee?</div>
                            <div className="mt-3 col-10 col-lg-2 residency_form_active_checkbox">
                                <CheckBox
                                    value={this.state.editResidencyFormData.feeOverrideFlag != "False" && this.state.editResidencyFormData.feeOverrideFlag != "" ? true : false}
                                    onValueChanged={this.handleChangeOverrideFlag}
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">
                                <div className="residency_form_fee-margin">Calculated/Override Fee</div>
                            </div>
                            <div className="mt-3 col-10 col-lg-2">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeFee}
                                    valueChangeEvent="keyup"
                                    value={this.state.editResidencyFormData.OverrideFee}
                                />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Client Net</div>
                            <div className="mt-3 col-10 col-lg-2">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeClientNet}
                                    valueChangeEvent="keyup"
                                    value={this.state.editResidencyFormData.clientNet}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <LoadIndicator
                                    id="load-indicator"
                                    className="load-indicator--centered"
                                    height={40}
                                    width={40}
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Internal comments</div>
                            <div className="mt-3 col-10 col-lg-11">
                                <TextArea
                                    height={50}
                                    value={this.state.editResidencyFormData.internalComments}
                                    onValueChanged={this.handleChangeInternalComments}
                                    valueChangeEvent="keyup"
                                />
                            </div>
                        </div>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <br></br>
                        <br></br>
                        <div className="row">
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                                Last Updated On
                            </div>
                            <div className="mt-3 col-10 col-lg-2" hidden={!this.props.isEditForm}>
                                <TextBox value={this.state.editResidencyFormData.LastUpdatedOn} readOnly={true} />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                                Last Updated By
                            </div>
                            <div className="mt-3 col-10 col-lg-3" hidden={!this.props.isEditForm}>
                                <TextBox value={this.state.editResidencyFormData.LastUpdatedBy} readOnly={true} />
                            </div>
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size"></div>
                            <div
                                className="mt-3 col-2 col-lg-7 font-weight-bold font_size"
                                hidden={this.props.isEditForm}
                            ></div>                        
                        </div>
                        <div className="row pt-5">
                            <div className="col-12 col-lg-2 mt-3">
                                <Link
                                    to={{
                                        pathname: "/matrixView",
                                        state: {
                                            isCancel: true,
                                        },
                                    }}
                                    className="btn btn--ghost btn--large"
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faTimes} />
                                    </span>
                                    {this.props.t("addEditShiftRow.financeSection.cancelButton.text")}
                                </Link>
                            </div>
                            <div className="col-12 col-lg-2 mt-3">
                                <button
                                    className="btn btn-primary btn--large"
                                    type="button"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onApplyClick();
                                    }}
                                >
                                    <span className="btn__icon"></span>
                                    {this.props.t("addEditShiftRow.financeSection.applyButton.text")}
                                </button>
                            </div>
                            <div className="col-12 col-lg-2 mt-3">
                                <button
                                    className="btn btn-primary btn--large"
                                    type="button"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onViewShiftClick();
                                    }}
                                >
                                    <span className="btn__icon"></span>
                                    View Shifts
                                </button>
                            </div>
                            <div className="col-12 col-lg-3 mt-3">
                                <button
                                    className="btn btn-primary btn--large"
                                    type="button"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onViewResidencyClick();
                                    }}
                                >
                                    <span className="btn__icon"></span>
                                    View Venue Residencies
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <Popup
                    visible={this.state.isVenueGridVisible}
                    onHiding={this.hideInfoVenueGrid}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Residencies belonging to same Venue"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <DataGrid
                                dataSource={this.state.venueResidencyFormData}
                                showBorders={false}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                columnAutoWidth={true}
                                allowColumnReordering={true}
                                selection={{ mode: "single" }}
                                allowColumnResizing={true}
                                columnResizingMode="widget"
                                className="residencyVenue-grid"
                            >
                                <SearchPanel visible={true} placeholder={"Search"} />
                                <Sorting mode="single" />
                                <Paging defaultPageSize={5} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                <Column dataField="residenciesId" allowSorting={true} caption="ID" calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertStringToInt(rowData.residenciesId);
                                }}></Column>
                                <Column dataField="clientName" allowSorting={true} caption="CLIENT" width={165}></Column>
                                <Column dataField="serviceTypeName" allowSorting={true} caption="SERVICE"></Column>
                                <Column dataField="serviceSubTypeName" allowSorting={true} caption="SUB TYPE"></Column>
                                <Column dataField="typeLookUp" allowSorting={true} caption="TYPE"></Column>
                                <Column dataField="providerName" allowSorting={true} caption="ARTIST"></Column>

                                <Column dataField="venueName" allowSorting={true} caption="VENUE NAME"></Column>
                                <Column dataField="isActive" allowSorting={true} caption="IS ACTIVE"></Column>
                                <Column
                                    dataField="dateFrom"
                                    allowSorting={true}
                                    caption="DATE FROM"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateFrom);
                                    }}
                                ></Column>
                                <Column
                                    dataField="dateTo"
                                    allowSorting={true}
                                    caption="DATE TO"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                                    }}
                                ></Column>
                                <Column dataField="frequencyName" allowSorting={true} caption="FREQUENCY" />
                                <Column dataField="day" allowSorting={true} caption="DAY" />

                                <Column
                                    dataField="rate"
                                    allowSorting={true}
                                    caption="RATE"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate);
                                    }}
                                    calculateDisplayValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToCurrency(
                                            rowData.rate,
                                            currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                        );
                                    }}
                                ></Column>

                                <Column
                                    dataField="feeOverride"
                                    allowSorting={true}
                                    caption="OVERRIDE FEE"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.feeOverride);
                                    }}
                                    calculateDisplayValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToCurrency(
                                            rowData.feeOverride,
                                            currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                        );
                                    }}
                                ></Column>
                                <Column
                                    dataField="startTime"
                                    allowSorting={true}
                                    caption="START TIME"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.converthhmmStringToTime(rowData.startTime);
                                    }}
                                ></Column>
                                <Column
                                    dataField="endTime"
                                    allowSorting={true}
                                    caption="END TIME"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.converthhmmStringToTime(rowData.endTime);
                                    }}
                                ></Column>
                                <Column
                                    dataField="lastUpdatedon"
                                    allowSorting={true}
                                    caption="LAST UPDATED TIME"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedon);
                                    }}
                                ></Column>
                                <Column dataField="lastUpdatedBy" allowSorting={true} caption="LAST UPDATED BY" />

                                <FilterRow visible={true} applyFilter="auto" />
                            </DataGrid>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.isShiftGridVisible}
                    onHiding={this.hideInfoVenueGrid}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Shifts belonging to same Residency"
                    width={"90%"}
                    height={600}
                >
                    <ScrollView width="100%" height="100%">
                        <SimpleShiftsByService
                            clientId=""
                            venueId=""
                            serviceTypeId={this.state.serviceId}
                            startDate=""
                            endDate=""
                            setCalculations={this.setCalculations}
                            navigatedFrom="residencies"
                            residencyId={this.state.residencyId}
                            getBuild={() => { return null }}
                        ></SimpleShiftsByService>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.showDuplicateItem}
                    onHiding={this.hideInfoVenueGrid}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Possible Duplicate Residency"
                    width={"30%"}
                    height={"auto"}
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <p className="text-center">Please confirm to proceed with adding this residency as another one exists for the same venue and same day of the week</p>
                            <div className="row px-3">
                                <div className="col-12 col-xl-6 pl-0">
                                    <button
                                        className="btn btn-primary btn--large mt-3"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onProceed();
                                        }}
                                    >
                                        CONFIRM?
                                    </button>
                                </div>
                                <div className="col-12 col-xl-6 pl-0">
                                    <button
                                        className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.hideInfoVenueGrid();
                                        }}
                                    >
                                        CANCEL
                                    </button>
                                </div>
                            </div> 
                        </div>
                    </ScrollView>
                </Popup>
            </>
        );
    }
}
export default withTranslation()(ResidencyForm);
