import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, { Pager, Paging, FilterRow, Column, Export, SearchPanel } from "devextreme-react/data-grid";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import LicenseCheckService, { LicenseCheckServiceRowItem } from "../../services/LicenseChecksService";
import { LoadIndicator, Popup, ScrollView } from "devextreme-react";
import SimpleShiftsByService from "../SimpleShiftsByService/SimpleShiftsByService";
import gridUtils from "../grid/GridUtilities";
import { ServiceType } from "../../common/ServiceUtilities";

//props
interface LicenseChecksComponentProps {}

// State
interface LicenseChecksComponentState {
    licenseGridDataSource: LicenseCheckServiceRowItem[];
    loadIndicator: boolean;
    isVisible: boolean;
    name: string;
    licenseNumber: string;
    serviceId: string;
}

// Component - displays the simple Grid for all users
class LicenseChecksComponent extends React.Component<LicenseChecksComponentProps> {
    state: LicenseChecksComponentState;
    service: LicenseCheckService;
    gridUtils: gridUtils;
    constructor(props: LicenseChecksComponentProps) {
        super(props);
        this.service = new LicenseCheckService();
        this.gridUtils = new gridUtils();
        // Initialize state and services/utils
        this.state = {
            licenseGridDataSource: [],
            loadIndicator: false,
            isVisible: false,
            name: "",
            licenseNumber: "",
            serviceId: "",
        };
        this.idGridDataSource = this.idGridDataSource.bind(this);
        this.handleSuccessGridDataSource = this.handleSuccessGridDataSource.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    componentDidMount() {
        this.idGridDataSource();
    }

    idGridDataSource() {
        this.setState({
            loadIndicator: true,
        });
        this.service.getLicenseCheckGridRows().then(this.handleSuccessGridDataSource).catch(this.handleError);
    }

    handleSuccessGridDataSource(response: AxiosResponse<any>) {
        this.setState({
            licenseGridDataSource: response.data.data,
        });
        this.setState({
            loadIndicator: false,
        });
    }

    handleError(error: AxiosResponse<any>) {
        this.setState({
            loadIndicator: false,
        });
        var respMessage: string = "License Check Grid data load failed with response: " + JSON.stringify(error);
        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }
    // Update state from server
    componentDidUpdate(prevProps: LicenseChecksComponentProps) {}

    onCustomGridLoad() {
        return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid);
    }

    onCustomGridSave(gridState: Object) {
        CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid, gridState);
    }
    renderCustomData = (data: any) => {
        var name = data.data.name;
        var licenseNumber = data.data.licenseNumber;
        var service = data.data.service;
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    <span
                        className="px-2 py-0 btn btn-primary"
                        onClick={() => this.showChildGridPopUp(name, licenseNumber, service)}
                    >
                        View
                    </span>
                </div>
            </div>
        );
    };
    showChildGridPopUp = (name: string, licenseNumber: string, service: string) => {
        this.setState({
            isVisible: true,
            name: name,
            licenseNumber: licenseNumber,
            serviceId: service.toLowerCase() == "security" ? ServiceType.Security : "",
        });
    };
    hideInfo = () => {
        this.setState({
            isVisible: false,
        });
    };
    setCalculations = () => {};
    render() {
        return (
            <div>
                {this.state.isVisible ? (
                    <Popup
                        visible={this.state.isVisible}
                        onHiding={this.hideInfo}
                        dragEnabled={false}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        showCloseButton={true}
                        title={"Shifts For" + " " + this.state.name.toUpperCase()}
                        width={"90%"}
                        height={600}
                    >
                        <ScrollView width="100%" height="100%">
                            <SimpleShiftsByService
                                clientId=""
                                venueId=""
                                serviceTypeId={this.state.serviceId}
                                startDate=""
                                endDate=""
                                setCalculations={this.setCalculations}
                                navigatedFrom="licensecheck"
                                licenseNumber={this.state.licenseNumber}
                                getBuild={() => { return null }}
                            ></SimpleShiftsByService>
                        </ScrollView>
                    </Popup>
                ) : null}
                {this.state.loadIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.loadIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.licenseGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Export enabled={true} allowExportSelectedData={false} fileName="LicenseCheckFile" />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[1, 5, 10, 20]} showInfo={true} />
                        <Column dataField="id" allowSorting={true} caption="ID"    calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.id);
                            }}/>
                        <Column dataField="service" allowSorting={true} caption="SERVICE" />
                        <Column dataField="subType" allowSorting={true} caption="SUB TYPE" />
                        <Column dataField="licenseType" allowSorting={true} caption="LICENSE" />
                        <Column dataField="name" allowSorting={true} caption="NAME" />
                        <Column dataField="licenseNumber" allowSorting={true} caption="LICENSE ID" />
                        <Column dataField="status" allowSorting={true} caption="STATUS" />
                        <Column
                            dataField="expiryDate"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertPredefinedStringToddmmyyyyFormat(rowData.expiryDate);
                            }}
                            allowSorting={true}
                            caption="EXPIRY DATE"
                        />
                        <Column
                            dataField="asAtDate"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertPredefinedStringToddmmyyyyFormat(rowData.asAtDate);
                            }}
                            allowSorting={true}
                            caption="AS AT DATE"
                        />
                        <Column
                            dataField="requestedDate"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertPredefinedStringToddmmyyyyFormat(rowData.requestedDate);
                            }}
                            allowSorting={true}
                            caption="REQUESTED"
                        />
                        <Column
                            dataField="lastworkedDate"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertPredefinedStringToddmmyyyyFormat(rowData.lastworkedDate);
                            }}
                            allowSorting={true}
                            caption="LAST WORKED"
                        />
                        <Column
                            dataField="dueToWorkDate"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertPredefinedStringToddmmyyyyFormat(rowData.dueToWorkDate);
                            }}
                            allowSorting={true}
                            caption="DUE TO WORK"
                        />
                        <Column
                            dataField="lastUpdateDate"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertPredefinedStringToddmmyyyyFormat(rowData.lastUpdateDate);
                            }}
                            allowSorting={true}
                            caption="LAST UPDATE"
                        />
                        <Column dataField="lastUpdatedBy" allowSorting={true} caption="LAST UPDATE BY" />
                        <Column caption="SHIFTS" cellRender={this.renderCustomData} />
                        <FilterRow visible={true} applyFilter="auto" />
                        {/* <Scrolling mode="standard" useNative={true} scrollByThumb={true} /> */}
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default LicenseChecksComponent;
