import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    NumberBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import UserService, { RoleGroupNames } from "../../services/UserService";
import PaymentRequestUtils, { PaymentRequestMatchedPopUpFormData } from "./PaymentRequestUtils";
import PaymentService, { paymentRequestStatus } from "../../services/PaymentService";
import sharedUtils from "../grid/sharedUtilities";
import AddEditPopUpService, { BillableItemSubmitRequest, CalculationResponse, Status } from "../../services/AddEditPopUpService";
import { AddEditFormData,BusinessEntityTaxApplicableStatus,BusinessEntityTypes} from "../grid/AddEditPopUpUtilities";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import { LoadIndicator, LoadPanel, NumberBox, Popup, ScrollView, TextArea, TextBox } from "devextreme-react";
import GlobalService from "../../services/GlobalService";
import adjustUtils from "../Utility/billableItemUtils";

interface MatchedPopUpShiftDetailsProps extends WithTranslation {
    location: any;
    history: any;
    billableDataItem: AddEditFormData;
    loadPanelVisible: boolean;
    buttonState: boolean;
    removeButtonState: boolean;
    setUnMatchSuccessful: (isUnmatched: boolean) => void;
    paymentReqId: string;
    paymentDataItem: PaymentRequestMatchedPopUpFormData;
    disable:boolean;
}
// State
interface MatchedPopUpShiftDetailsState {
    billableData: AddEditFormData;
    showLoadIndicator: boolean;
    errorMessage: [];
    IsShowSuccess: boolean;
    IsShowError: boolean;
    validationMessage: string;
    isViewMode: boolean;
    configuarationValue: [];
    configurationIdIndex: string[];
    configuarationValueIndex: string[];
    loadPanelVisible: boolean;
    isDisableMatchButton: boolean;
    venueLabel: string;
    venueClassName: string;
    providerLabel: string;
    providerClassName: string;
    dateLabel: string;
    dateClassName: string;
    feesLabel: string;
    feesClassName: string;
    isMatchingShiftQueryShow: boolean;
    providerLabelForConf: string;
    providerClassNameForConf: string;
    feeLabelForConf: string;
    feeClassNameForConf: string;
    artistFeeLabel: string;
    feeToVenueClass: string;
    bookingFeeAmount: number;
    requestFeeAmount: number;
    invoiceId: string;
    accural: boolean;
    valueChangeTimer: any;
    loadIndicatorVisible: boolean;
    isDisableRemoveButton: boolean;
    unMatchPopupVisible: boolean;
    itemTypeLookUp: LookupTypeItem[];
    businessEntityTaxApplicableStatuses: BusinessEntityTaxApplicableStatus[];
}

class MatchedPopUpShiftDetails extends React.Component<MatchedPopUpShiftDetailsProps> {
    utils: PaymentRequestUtils;
    sharedUtils: sharedUtils;
    state: MatchedPopUpShiftDetailsState;
    paymentService: PaymentService;
    billableItemUtils: addEditPopupUtils;
    adjustUtils : adjustUtils;
    editViewService: AddEditPopUpService;
    lookupService: LookupService;
    addEditPopupUtils: addEditPopupUtils;

    constructor(props: MatchedPopUpShiftDetailsProps) {
        super(props);
        this.utils = new PaymentRequestUtils();
        this.sharedUtils = new sharedUtils();
        this.paymentService = new PaymentService();
        this.billableItemUtils = new addEditPopupUtils();
        this.adjustUtils = new adjustUtils();
        this.editViewService = new AddEditPopUpService();
        this.addEditPopupUtils = new addEditPopupUtils();
        var convertedEditViewDataItem = this.utils.initializePaymentRequestMatchedPopUpItems();
        var convertedBillableViewItem = this.billableItemUtils.initializeBillableItem("");
        this.lookupService = new LookupService();
        this.state = {
            loadPanelVisible: this.props.loadPanelVisible,
            configuarationValue: [],
            configuarationValueIndex: [],
            configurationIdIndex: [],
            billableData: convertedBillableViewItem,
            showLoadIndicator: false,
            errorMessage: [],
            IsShowSuccess: false,
            IsShowError: false,
            validationMessage: "",
            isDisableMatchButton: true,
            isViewMode: this.props.location.state && this.props.location.state.isReadOnly ? this.props.location.state.isReadOnly : false,
            venueLabel: "",
            providerLabel: "",
            dateLabel: "",
            feesLabel: "",
            isMatchingShiftQueryShow: false,
            venueClassName: "",
            providerClassName: "",
            dateClassName: "",
            feesClassName: "",
            providerLabelForConf: "",
            providerClassNameForConf: "",
            feeLabelForConf: "",
            feeClassNameForConf: "",
            artistFeeLabel: "",
            feeToVenueClass: "",
            bookingFeeAmount: 0,
            requestFeeAmount: 0,
            invoiceId: "",
            accural: false,
            valueChangeTimer: null,
            loadIndicatorVisible: false,
            isDisableRemoveButton: true,
            unMatchPopupVisible: false,
            itemTypeLookUp: [],
            businessEntityTaxApplicableStatuses:[]
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.configuration)
            .then(this.handleConfigurationLookupSuccess)
            .catch(this.handleError);
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.itemType)
            .then(this.handleSuccessItemTypeLookUp)
            .catch(this.handleError);
        // this.setState({
        //     editViewDataItem: this.props.paymentDataItem
        // });

        // Retrieve data required to get provider tax status
        this.editViewService
            .getTaxApplicableStatus()
            .then(this.handleTaxApplicableSuccess)
            .catch(this.handleError);
    }

    componentDidUpdate(prevprops: MatchedPopUpShiftDetailsProps) {
        if (prevprops.buttonState != this.props.buttonState || prevprops.loadPanelVisible != this.props.loadPanelVisible ||
            JSON.stringify(prevprops.billableDataItem) != JSON.stringify(this.props.billableDataItem)) {
            if (this.props.billableDataItem.billableItemId) {
                this.setState({
                    billableData: this.props.billableDataItem
                }, () => {
                    if (this.props.paymentDataItem && this.props.paymentDataItem.paymentRequestStatusLookUp && this.props.paymentDataItem.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched) {
                        this.setMatchedRecordButtonState();
                    }
                    else {
                        this.setBillableItemObj();

                    }
                });                
            }

        }
    }

    handleSuccessItemTypeLookUp = (response: AxiosResponse<any>) => {
            var itemLUItem: LookupTypeItem;
            var itemLUItemArray: LookupTypeItem[];
            itemLUItemArray = [];
            response.data.data.map((item: any, uniqueKey: number) => {
                itemLUItem = {
                    id: item.id,
                    value: item.value,
                    parentMappingId: "0",
                };
                itemLUItemArray.push(itemLUItem);
            });
            this.setState({
                itemTypeLookUp: itemLUItemArray,
            });
        };

    handleTaxApplicableSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                businessEntityTaxApplicableStatuses: response.data.data,
            }
        );
    };

    setMatchedRecordButtonState = () => {
        this.setState({
            isMatchingShiftQueryShow: true
        }, () => { this.showMatchOrNotMatch() });
        if (this.state.billableData.pay_Date) {
            var payDate = this.state.billableData.pay_Date;
            var date = new Date();
            payDate.setDate(payDate.getDate() - 2);
            if (payDate > date) {
                this.setState({
                    isDisableRemoveButton: false
                });
            }
            else {
                this.setState({
                    isDisableRemoveButton: true
                });
            }
        } else {
            this.setState({
                isDisableRemoveButton: false
            });
        }
    }


    handleConfigurationLookupSuccess = (response: AxiosResponse<any>) => {
        var configData = this.sharedUtils.getConfigurationDataFromLookupResponse(response.data.data);
        this.setState({
            configuarationValue: response.data.data,
            configurationIdIndex: configData.id,
            configuarationValueIndex: configData.value,
        });
    };

    getPaymentRequestFormDataById = () => {
        // let paymentRequestId: string = "";
        // if (this.props.paymentReqId) {
        //     paymentRequestId = this.props.paymentReqId;
        // }
        // else if (this.props.location.state.id) {
        //     paymentRequestId = this.props.location.state.id;
        // }
        // if (paymentRequestId) {
        //     this.paymentService.getPaymentRequestFormData(paymentRequestId)
        //         .then(this.handleSuccess)
        //         .catch(this.handleError)
        // }

        //}
    }

    handleSuccess = (response: AxiosResponse<any>) => {
        var convertedFormData = this.utils.convertMatchedPopUpResponseToFormData(response.data.data);
        this.setState({
            editViewDataItem: convertedFormData
        });
    }

    handleError = (error: AxiosResponse<any>) => {
        var respMessage: string = "Matched Popup form load failed with response: " + JSON.stringify(error);

        if (!this.paymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }

    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                billableData: {
                    ...this.state.billableData,
                    comments: dxValueChange.value
                }
            });
        }
    }
    handleChangeInternalComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                billableData: {
                    ...this.state.billableData,
                    internalComments: dxValueChange.value
                }
            })
        }
    }

    handleChangeRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                billableData: {
                    ...this.state.billableData,
                    rate_Decimal: dxValueChange.value
                }
            })
            this.state.valueChangeTimer = setTimeout(this.startCallingFinancialCalculation, 1000);
        }

    }
    showLoadIndicator = () => {
        this.setState({ loadIndicatorVisible: true, isDisableMatchButton: true });
    };
    hideLoadIndicator = () => {
        this.setState({
            loadIndicatorVisible: false,
            isDisableMatchButton: false
        });
    };
    startCallingFinancialCalculation = () => {
        if (this.state.valueChangeTimer) {
            clearTimeout(this.state.valueChangeTimer);
            this.state.valueChangeTimer = null;
            if (this.state.venueLabel == "Matched" && this.state.dateLabel == "Matched") {
                this.showLoadIndicator();
                let calCulationObject = this.addEditPopupUtils.convertCalcDataToCalcRequest(
                    this.state.billableData,
                    null,
                    0,
                    parseInt(this.state.billableData.billableItemId)
                );
                this.editViewService
                    .uploadFormData(calCulationObject)
                    .then(this.handleCalculationSuccess)
                    .catch((err) => {
                        this.setState({
                            errorMessage: err.response ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                            IsShowSuccess: false,
                        });
                        this.failureMessageVisible();
                        this.hideLoadIndicator();
                    })
                    .finally(() => {
                        this.hideLoadIndicator();
                    });
            }
        }
    }

    handleCalculationSuccess = (response: AxiosResponse<any>) => {
        var serverResponse: CalculationResponse = response.data.data;
        this.hideLoadIndicator();

        this.setState({
            IsShowError: false,
        });
        // Convert server response to proper data types
        this.setState({
            billableData: {
                ...this.state.billableData,
                billable_Minutes: this.sharedUtils.convertStringToFloat(serverResponse.billableMinutes),
                provider_Pay_Amount_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmount),
                provider_Pay_Currency_Local_Ccy: serverResponse.providerPayAmountLocalCurrency, // Ex: "GBP",
                provider_Pay_Tax_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.providerPayTaxLocalCcy),
                provider_Pay_Total_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.providerPayTotalLocalCcy),
                provider_Tax_Applicable: serverResponse.providerPayTaxApplicable?'True':'False',
                client_Billable_Amount_Local_Ccy: this.sharedUtils.convertStringToFloat(
                    serverResponse.clientBillableAmount
                ),
                client_Billable_Currency_Local_Ccy: serverResponse.clientBillableAmountLocalCurrency,
                client_Billable_Tax_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.clientBillableTaxLocalCcy),
                client_Billable_Total_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.clientBillableTotalLocalCcy),
                client_Tax_Applicable: serverResponse.clientTaxApplicable,
                fee_Amount_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitAmountLocal),
                fee_Currency_Local_Ccy: serverResponse.grossProfitAmountLocalCurrency,
                fee_Percentage_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitPercentage),
                fee_Tax_Applicable: serverResponse.grossProfitTaxApplicable,
                fee_Tax_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitTaxLocalCcy),
                fee_Total_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitTotalLocalCcy),
                equipmentBillableQuantity: this.sharedUtils.convertStringToFloat(serverResponse.billableQuantity),
                billableRate: this.sharedUtils.convertStringToFloat(serverResponse.billableRate),
                accrual: serverResponse.accrual
                    ? {
                        id: serverResponse.accrual.id,
                        accrualTypeId: serverResponse.accrual.accrualTypeId,
                        accrualStatusId: serverResponse.accrual.accrualStatusId,
                        accrualBillableItemId: serverResponse.accrual.accrualBillableItemId,
                        accrualInvoiceId: serverResponse.accrual.accrualInvoiceId,
                        providerPayAmountAccrual: serverResponse.accrual.providerPayAmountAccrual,
                        providerPayAmountTaxAccrual: serverResponse.accrual.providerPayAmountTaxAccrual,
                        providerPayAmountTotalAccrual: serverResponse.accrual.providerPayAmountTotalAccrual,
                        grossProfitAmountAccrual: serverResponse.accrual.grossProfitAmountAccrual,
                        grossProfitAmountTaxAccrual: serverResponse.accrual.grossProfitAmountTaxAccrual,
                        grossProfitAmountTotalAccrual: serverResponse.accrual.grossProfitAmountTotalAccrual,
                        clientBillableAmountAccrual: serverResponse.accrual.clientBillableAmountAccrual,
                        clientBillableAmountTaxAccrual: serverResponse.accrual.clientBillableAmountTaxAccrual,
                        clientBillableAmountTotalAccrual: serverResponse.accrual.clientBillableAmountTotalAccrual,
                        lastUpdatedOn: serverResponse.accrual.lastUpdatedOn,
                        lastUpdatedBy: serverResponse.accrual.lastUpdatedBy,
                    }
                    : null,
            },
            expectedFee: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitAmountLocal),
        });
    }

    providerPayTaxCalculation = (_billableData:any,navigateFrom:string) => {
        let providerCalculationVal: number[] = [];
        providerCalculationVal = this.addEditPopupUtils.providerPayTaxCal(
            _billableData,
            this.state.configuarationValue
        );

        let providerPayTaxLocalCcy: number = 0;
        let providerPayAmountTotalCcy: number = 0;
        if (providerCalculationVal.length > 0) {
            providerPayTaxLocalCcy = providerCalculationVal[0];
            providerPayAmountTotalCcy = providerCalculationVal[1];
        }
        _billableData.provider_Pay_Tax_Local_Ccy = providerPayTaxLocalCcy;
        _billableData.provider_Pay_Total_Local_Ccy = providerPayAmountTotalCcy;
        this.setState({
            billableData: _billableData
        },()=>this.onSubmit(navigateFrom));
    };

    providerTaxStatus_onSubmit = (navigateFrom: string) => {
        let taxApplicable: string = "False";
        // let _billableData = JSON.parse(JSON.stringify(this.state.billableData));
        let _billableData = { ...this.state.billableData };
        let providerIsMatch = this.state.providerLabel === "Matched";
        if (navigateFrom == "match" && !providerIsMatch) {
            _billableData.provider = this.props.paymentDataItem?.providerBusinessEntity?.shortName;

            let businessEntityId = this.props.paymentDataItem?.providerBusinessEntity?.id;
            let taxStatus = this.state.businessEntityTaxApplicableStatuses.find(
                (item) => item.businessEntityID === businessEntityId
            );

            // A client/provider is tax applicable if they have a tax registration number
            if (taxStatus) {
                if (taxStatus.taxApplicable) {
                    taxApplicable = "True";
                }
                if (taxStatus.businessEntityType === BusinessEntityTypes.Provider) {
                    _billableData.provider_Tax_Applicable = taxApplicable;
                }
            }

        }
        this.providerPayTaxCalculation(_billableData, navigateFrom);
    }

    onSubmit = (navigateFrom: string) => {
        var isEventUKUser = (UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager));
        var errMsg = this.validationCheckFunction();
        var serviceFeeMatrixVal = this.adjustUtils.serviceFeeMatrixDerivation(
            this.state.billableData.clientId,
            this.state.billableData.serviceTypeId,
            this.state.billableData.serviceSubTypeId
        )
        let itemType = "";
        let statusId = "";
        let approveDate = null;
        let acceptDate = null;
        let billableItemIdValueForAdjustment = "";
        let paretRecordIdForAdjustment = "";
        this.setState({
            loadPanelVisible: true,
        });
        var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
        var paymentRequestDate: string = "";
        if (this.props.paymentDataItem && this.props.paymentDataItem.requestDate) {
            paymentRequestDate = this.props.paymentDataItem.requestDate;
        }
        if (this.state.invoiceId !== "" && this.state.accural == false && this.state.bookingFeeAmount !== this.state.requestFeeAmount) {
            if (this.state.billableData.provider_Pay_Amount_Local_Ccy == 0) {
                statusId = Status.Pending;
                itemType = this.state.itemTypeLookUp[1].id; // Adjustment
            } else if (Math.sign(this.state.billableData.provider_Pay_Amount_Local_Ccy) == 1) {
                if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
                    statusId = Status.Approved;
                    approveDate = new Date();
                } else {
                    statusId = Status.Pending;
                }
                itemType = this.state.itemTypeLookUp[3].id; // AdjustmentClientDebit
            } else if (Math.sign(this.state.billableData.provider_Pay_Amount_Local_Ccy) == -1) {
                if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
                    statusId = Status.Accepted;
                    acceptDate = new Date();
                } else {
                    statusId = this.adjustUtils.billableItemStatusSetBasedOnItemType
                        (this.state.billableData.itemTypeId, serviceFeeMatrixVal.valueOfProviderAcceptance);
                }
                itemType = this.state.itemTypeLookUp[4].id; // adjustmentProviderDebit
            }
            billableItemIdValueForAdjustment = "0";
            paretRecordIdForAdjustment = this.state.billableData.billableItemId;

            var billableItemSubmitRequest = this.billableItemUtils.convertFormDataToBillableItemRequestMatchUnmatch(
                this.state.billableData,
                statusId,
                itemType,
                false,
                billableItemIdValueForAdjustment,
                paretRecordIdForAdjustment,
                "",
                acceptDate,
                approveDate,
                navigateFrom == "match" ? true : false,
                navigateFrom == "match" ? "paymentMatch" : "paymentRemoveMatch",
                this.state.invoiceId !== "" && this.state.accural == false && this.state.bookingFeeAmount !== this.state.requestFeeAmount ? true : false,
                this.props.paymentDataItem.id,
                navigateFrom == "match" ? paymentRequestDate : "",
                navigateFrom == "match" ? this.props.paymentDataItem.providerBusinessEntity && this.props.paymentDataItem.providerBusinessEntity.id : ""
            );
            billableItemSubmitRequestArr.push(billableItemSubmitRequest);
        }
        else {
            var billableItemSubmitRequest = this.billableItemUtils.convertFormDataToBillableItemRequestMatchUnmatch(
                this.state.billableData,
                "",
                "",
                false,
                "",
                "",
                "",
                undefined,
                undefined,
                navigateFrom == "match" ? true : false,
                navigateFrom == "match" ? "paymentMatch" : "paymentRemoveMatch",
                false,
                this.props.paymentDataItem.id,
                navigateFrom == "match" ? paymentRequestDate : "",
                navigateFrom == "match" ? this.props.paymentDataItem.providerBusinessEntity && this.props.paymentDataItem.providerBusinessEntity.id : ""
            );
            billableItemSubmitRequestArr.push(billableItemSubmitRequest);
        }        
        
        if (errMsg && isEventUKUser) {
            this.setState({
                validationMessage: errMsg,
                loadPanelVisible: false
            })
        }
        else {
            //passing object data to the server
            // console.log(billableItemSubmitRequestArr,'billableItemSubmitRequestArr');
            // this.setState({
            //     loadPanelVisible: false,
            // });
            // return;
            this.editViewService.uploadEditAddDataV3(billableItemSubmitRequestArr)
                .then(() => this.handleFormDataSubmissionSuccess(navigateFrom))
                .catch((err) => {
                    this.setState({
                        errorMessage:
                            err.response && err.response.data && err.response.data.error
                                ? JSON.parse(JSON.stringify(err.response.data.error))
                                : null,
                        IsShowSuccess: false,
                    });
                    this.failureMessageVisible();
                    this.hideLoadpanel();
                })
                .finally(() => {
                    this.hideLoadpanel();
                });
        }
    };

    handleMatchPopUpDataSubmissionSuccess = () => {
        this.hideLoadpanel();
        this.successMessageVisible();
        this.setState({
            IsShowError: false,
        });
        this.renderRedirect();
    }

    handleRemoveMatchSuccess = () => {
        this.setState({
            unMatchPopupVisible: true
        }, () => { window.location.reload() });
    }

    handleFormDataSubmissionSuccess = (navigatedFrom: string) => {
        if (navigatedFrom == "match") {
            this.paymentService.postMatchPopUpData(parseInt(this.props.paymentDataItem.id), true, this.props.paymentDataItem.paymentRequestType.id)
                .then(this.handleMatchPopUpDataSubmissionSuccess).catch(this.handleError)
        }
        else {
            this.paymentService.postMatchPopUpData(parseInt(this.props.paymentDataItem.id), false)
                .then(this.handleRemoveMatchSuccess).catch(this.handleError)
        }

    };

    failureMessageVisible = () => {
        this.setState({
            IsShowError: true,
        });
    };

    hideLoadpanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    successMessageVisible = () => {
        this.setState({
            IsShowSuccess: true,
        });
    };

    renderRedirect = () => {
        this.props.history.push({
            pathname: "/paymentRequests",
            state: {
                pointOfNavigation: "popUp"
            },
        });
    };

    onCancel = () => {
        if (window.location.href && window.location.href.includes("filterBy")) {
            window.close();
        }
        else {
            this.props.history.push({
                pathname: "/paymentRequests",
                state: {
                    pointOfNavigation: "popUp"
                },
            });
        }
    }

    navigateBack = () => {
        this.props.history.goBack(); // Return back to the page on a form submission success.
    };

    validationCheckFunction = () => {
        let errorMessage: string = "";
        let commentsField: string = this.state.billableData.internalComments.trim();
        if (commentsField.length === 0) {
            errorMessage = "Internal Comments: Please provide the Internal comments before submitting the Form .";
        }
        return errorMessage;
    };

    venueNameExtraction = (venue: string) => {
        var billableItemVenue: string = "";
        var parts = venue.split("-");
        billableItemVenue = parts[1];
        return billableItemVenue;
    }
    showMatchOrNotMatch = () => {
        var billableItemVenue = this.state.billableData.venueId;
        let artistFee: string = "";
        let bookingFee: string = "";
        let selectedInvoiceId = "";
        let selectedAccural = null;
        let feeAdjustment = undefined;
        if (this.props.paymentDataItem.providerPayAmountLocal || this.props.paymentDataItem.providerPayAmountLocalExtras || this.props.paymentDataItem.providerFasterPayFee) {
            if(this.props.paymentDataItem.providerPayAmountLocal && this.props.paymentDataItem.providerPayAmountLocalExtras && this.props.paymentDataItem.providerFasterPayFee){
                let totalArtistFee = this.props.paymentDataItem.providerPayAmountLocal + this.props.paymentDataItem.providerPayAmountLocalExtras + this.props.paymentDataItem.providerFasterPayFee;
                artistFee = totalArtistFee.toString();
            }  
            else if (this.props.paymentDataItem.providerPayAmountLocal && this.props.paymentDataItem.providerPayAmountLocalExtras) {
                let totalArtistFee = this.props.paymentDataItem.providerPayAmountLocal + this.props.paymentDataItem.providerPayAmountLocalExtras;
                artistFee = totalArtistFee.toString();
            } 
            else if (this.props.paymentDataItem.providerPayAmountLocal && this.props.paymentDataItem.providerFasterPayFee) {
                let totalArtistFee = this.props.paymentDataItem.providerPayAmountLocal + this.props.paymentDataItem.providerFasterPayFee;
                artistFee = totalArtistFee.toString();
            }    
            else if (this.props.paymentDataItem.providerPayAmountLocal) {
                artistFee = this.props.paymentDataItem.providerPayAmountLocal.toString();
            }  
            else{
                artistFee = "0.00";
            }    
        }     
        if(this.state.billableData.providerPayAmountLocal || this.state.billableData.providerFasterPayFee){
            selectedInvoiceId = this.state.billableData.invoiceId;
            selectedAccural = this.state.billableData.accrual == null ? false : this.state.billableData.accrual;
            if(this.state.billableData.providerPayAmountLocal && this.state.billableData.providerFasterPayFee){
                let totalBookingFee = this.state.billableData.providerPayAmountLocal + this.state.billableData.providerFasterPayFee;
                bookingFee = totalBookingFee.toString();
            }
            else if(this.state.billableData.providerPayAmountLocal){
                bookingFee = this.state.billableData.providerPayAmountLocal.toString();
            }
            else {
                bookingFee = "0.00";
            }
        }  
        if(selectedInvoiceId !== "" && selectedAccural == false && parseFloat(bookingFee) !== parseFloat(artistFee)){ 
            feeAdjustment = parseFloat(artistFee) - parseFloat(bookingFee);  
        }
        if(this.props.paymentDataItem && this.props.paymentDataItem.paymentRequestStatusLookUp && this.props.paymentDataItem.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched){
            feeAdjustment = this.state.billableData.rate_Decimal;
        }
        if (this.props.paymentDataItem && this.props.paymentDataItem.venue.id == billableItemVenue.trim() &&
            this.sharedUtils.convertDateToString(this.props.paymentDataItem.dateFrom) == this.sharedUtils.convertDateToString(this.state.billableData.date) &&
            this.props.paymentDataItem.providerBusinessEntity.id == this.state.billableData.providerId &&
            parseFloat(artistFee) == this.state.billableData.provider_Pay_Amount_Local_Ccy) {
            this.setState({
                isDisableMatchButton: false
            });
        }
        if (this.props.paymentDataItem && this.props.paymentDataItem.venue.id == billableItemVenue.trim()) {
            this.setState({
                venueLabel: "Matched",
                venueClassName: "tile bg-success"
            });
        }
        else {
            this.setState({
                venueLabel: "Not Matched",
                venueClassName: "tile bg-danger",
                isDisableMatchButton: true
            });
        }

        if (this.props.paymentDataItem && this.sharedUtils.convertDateToString(this.props.paymentDataItem.dateFrom) == this.sharedUtils.convertDateToString(this.state.billableData.date)) {
            this.setState({
                dateLabel: "Matched",
                dateClassName: "tile bg-success"
            });
        }
        else {
            this.setState({
                dateLabel: "Not Matched",
                dateClassName: "tile bg-danger",
                isDisableMatchButton: true
            });
        }
        if (this.props.paymentDataItem && this.props.paymentDataItem.providerBusinessEntity.id == this.state.billableData.providerId) {
            this.setState({
                providerLabel: "Matched",
                providerClassName: "tile bg-success",
                providerLabelForConf: "Provider",
                providerClassNameForConf: "tile bg-success"
            });
        }
        else {
            this.setState({
                providerLabel: "Not Matched",
                providerClassName: "tile bg-danger",
                providerLabelForConf: "Use Provider from Request?",
                providerClassNameForConf: "tile bg-warning"
            });
        }
        if (this.props.paymentDataItem && parseFloat(artistFee) == parseFloat(bookingFee)) {
            this.setState({
                feesLabel: "Matched",
                feesClassName: "tile bg-success",
                feeLabelForConf: "Fee to Artist",
                feeClassNameForConf: "tile bg-success",
                artistFeeLabel: "Request",
                feeToVenueClass: "tile bg-cleaner",
                bookingFeeAmount: parseFloat(bookingFee),
                requestFeeAmount: parseFloat(artistFee),
                invoiceId: selectedInvoiceId,
                accural: selectedAccural
            });
        }
        else {
            this.setState({
                isDisableMatchButton: true,
                feesLabel: "Not Matched",
                feesClassName: "tile bg-danger",
                feeLabelForConf: "Enter Correct Fee to Artist?",
                feeClassNameForConf: "tile bg-warning",
                artistFeeLabel: "Request",
                feeToVenueClass: "tile bg-warning",
                bookingFeeAmount: parseFloat(bookingFee),
                requestFeeAmount: parseFloat(artistFee),
                invoiceId: selectedInvoiceId,
                accural: selectedAccural,
                billableData: {
                    ...this.state.billableData,
                    rate_Decimal: feeAdjustment,
                    client_Billable_Amount_Local_Ccy: this.props.paymentDataItem && this.props.paymentDataItem.paymentRequestStatusLookUp && this.props.paymentDataItem.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched ? this.state.billableData.client_Billable_Amount_Local_Ccy : undefined
                }
            });
        }
    }
    setBillableItemObj = () => {
        this.setState({
            isMatchingShiftQueryShow: true
        }, () => { this.showMatchOrNotMatch(); });
        this.setMatchButtonState();
    }

    setMatchButtonState = () => {
        var isHideDetails: boolean = false;
        var deletedItemId = GlobalService.getDeletedItemId();
        if (deletedItemId && deletedItemId == this.state.billableData.billableItemId) {
            isHideDetails = true;
        }
        this.setState({
            isDisableMatchButton: isHideDetails ? true : this.props.buttonState,
            //isMatchingShiftQueryShow: true,
            billableData: {
                ...this.state.billableData,
                internalComments: isHideDetails ? "" : this.state.billableData.internalComments,
                comments: isHideDetails ? "" : this.state.billableData.comments
                // provider_Pay_Amount_Local_Ccy: isHideDetails ? 0 : this.state.billableData.provider_Pay_Amount_Local_Ccy

            }
        });
    }

    onContentReadyNumberBox = (e: any) => {
        this.onClickElementSelection(e);
    }

    onClickElementSelection = (e: any) => {
        e.element.addEventListener('click', function () {
            let inputElement = e.element.querySelector(".dx-texteditor-input");
            inputElement.selectionStart = 0;
            inputElement.selectionEnd = inputElement.value.length;
        });
    }


    returnByCondition = (isLoadPanelVisibal: boolean, isMatchingShiftQueryShow: boolean) => {
        let requestVenue: string = "";
        let requestDate: string = "";
        let requestProvider: string = "";
        let artistFee: string = "";
        let isReadOnlyRate: boolean = false;
        if (this.props.paymentDataItem) {
            if (this.props.paymentDataItem.venue && this.props.paymentDataItem.venue.venueName) {
                requestVenue = this.props.paymentDataItem.venue.venueName;
            }
            if (this.props.paymentDataItem.dateFrom) {
                requestDate = this.sharedUtils.convertDateToString(this.props.paymentDataItem.dateFrom);
            }
            if (this.props.paymentDataItem.providerBusinessEntity && this.props.paymentDataItem.providerBusinessEntity.shortName) {
                requestProvider = this.props.paymentDataItem.providerBusinessEntity.shortName;
            }
            if (this.props.paymentDataItem.providerPayAmountLocal || this.props.paymentDataItem.providerPayAmountLocalExtras || this.props.paymentDataItem.providerFasterPayFee) {
                if(this.props.paymentDataItem.providerPayAmountLocal && this.props.paymentDataItem.providerPayAmountLocalExtras && this.props.paymentDataItem.providerFasterPayFee){
                    let totalArtistFee = this.props.paymentDataItem.providerPayAmountLocal + this.props.paymentDataItem.providerFasterPayFee + this.props.paymentDataItem.providerPayAmountLocalExtras;
                    artistFee = "£" + totalArtistFee.toFixed(2);
                }
                else if(this.props.paymentDataItem.providerPayAmountLocal && this.props.paymentDataItem.providerPayAmountLocalExtras){
                    let totalArtistFee = this.props.paymentDataItem.providerPayAmountLocal + this.props.paymentDataItem.providerPayAmountLocalExtras;
                    artistFee = "£" + totalArtistFee.toFixed(2);
                }
                else if(this.props.paymentDataItem.providerPayAmountLocal && this.props.paymentDataItem.providerFasterPayFee){
                    let totalArtistFee = this.props.paymentDataItem.providerPayAmountLocal + this.props.paymentDataItem.providerFasterPayFee;
                    artistFee = "£" + totalArtistFee.toFixed(2);
                }
                else if (this.props.paymentDataItem.providerPayAmountLocal) {
                    artistFee = "£" + this.props.paymentDataItem.providerPayAmountLocal.toFixed(2);
                }
                
            }            
            if (this.props.paymentDataItem.paymentRequestStatusLookUp.value.toLowerCase() == "matched") {
                isReadOnlyRate = true;
            }

        }
        if (isLoadPanelVisibal) {
            return (
                <div className="starter-template">
                    <LoadIndicator
                        id="simple-grid-indicator"
                        height={50}
                        width={50}
                        visible={this.props.loadPanelVisible}
                    />
                </div>
            );
        }
        else if (isMatchingShiftQueryShow) {
            // as "Matched" values are already calculated above, this reuses them
            // however, a broader refactor may be able to combine these two into one piece of code and avoid string equality check
            // pull out matching values from the array so they can be more easily accessed
            const venueIsMatch = this.state.venueLabel === "Matched";
            const dateIsMatch = this.state.dateLabel === "Matched";
            const providerIsMatch = this.state.providerLabel === "Matched";
            const feeIsMatch = this.state.feesLabel === "Matched";
            const needsConfirmation = !providerIsMatch || !feeIsMatch;

            const panelData = [
                {
                    key: 1,
                    title: "Venue",
                    bookingLabel: "Booking Venue",
                    bookingVal: this.venueNameExtraction(this.state.billableData.venue),
                    requestLabel: "Request Venue",
                    requestVal: requestVenue,
                    isMatch: venueIsMatch,
                    importance: "danger"
                },
                {
                    key: 2,
                    title: "Date",
                    bookingLabel: "Booking Date",
                    bookingVal: this.sharedUtils.convertDateToString(this.state.billableData.date),
                    requestLabel: "Request Date",
                    requestVal: requestDate,
                    isMatch: dateIsMatch,
                    importance: "danger"
                },
                {
                    key: 3,
                    title: "Provider",
                    bookingLabel: "Booking Provider",
                    bookingVal: this.state.billableData.provider,
                    requestLabel: "Request Provider",
                    requestVal: requestProvider,
                    isMatch: providerIsMatch,
                    importance: "warning",
                },
                {
                    key: 4,
                    title: "Payment",
                    bookingLabel: "Booking Fee",
                    bookingVal: "£" + this.state.bookingFeeAmount.toFixed(2),
                    requestLabel: this.state.artistFeeLabel + " Fee",
                    requestVal: artistFee,
                    isMatch: feeIsMatch,
                    importance: "warning",
                }
            ];
            return (
                <>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="row mx-0">
                        <div className="col-12 row mx-0 pr-0 mt-3 align-items-center">
                            <label className="card-form__label col-12 col-xl-1">
                                External Comments (Public)
                            </label>
                            <div className="col-12 col-xl-11 pl-0 align-items-center">
                                <TextArea
                                    height={100}
                                    value={this.state.billableData.comments}
                                    onValueChanged={this.handleChangeComments}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-1">
                                Internal Comments (Private)
                            </label>
                            <div className="col-12 col-xl-11 pl-0">
                                <TextArea
                                    height={100}
                                    value={this.state.billableData.internalComments}
                                    onValueChanged={this.handleChangeInternalComments}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="match-shift-panel my-5">
                        {panelData.map(item =>
                            <div className={`row mb-2 match-shift-panel__row match-shift-panel__row--${item.importance} ${item.isMatch ? "match-shift-panel__row--matching" : "match-shift-panel__row--not-matching"}`} key={item.key}>
                                <div className="col-6 col-lg-2 match-shift-panel__col">
                                    {item.title}
                                </div>
                                <div className="col-6 col-lg-2 match-shift-panel__col match-shift-panel__col--divided">
                                    {
                                        item.isMatch ?
                                            <>
                                                <span className="match-shift-panel__icon">
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </span>
                                                <span className="match-shift-panel__match-text ml-3">Matching</span>
                                            </>
                                            :
                                            <>
                                                <span className="match-shift-panel__icon">
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </span>
                                                <span className="match-shift-panel__match-text ml-3">Not Matching</span>
                                            </>
                                    }
                                </div>
                                <div className="col-12 col-lg-4 match-shift-panel__col match-shift-panel__col--divided d-flex">
                                    <span className="col-6">
                                        {item.bookingLabel}
                                    </span>
                                    <TextBox
                                        className="col-6"
                                        value={item.bookingVal}
                                        disabled={true} />
                                </div>
                                <div className="col-12 col-lg-4 match-shift-panel__col match-shift-panel__col--divided d-flex">
                                    <span className="col-6">
                                        {item.requestLabel}
                                    </span>
                                    <TextBox
                                        className="col-6"
                                        value={item.requestVal}
                                        disabled={true} />
                                </div>
                            </div>
                        )
                        }
                    </div>
                    {needsConfirmation ?
                        <>
                            <hr className="horizontal_border_style" />
                            <div className="match-shift-panel mt-4 mb-5">
                                <h4>
                                    Items to confirm:
                                </h4>
                                {/* This section handles any overrides people can make if they didn't match above */}
                                <div className="row match-shift-panel__dynamically-divided-section">
                                    {!providerIsMatch ?
                                        <div className="col-12 col-lg-4 match-shift-panel__col d-flex">
                                            <span className="col-6">
                                                Provider (from request)
                                            </span>
                                            <TextBox
                                                className="col-6"
                                                value={requestProvider}
                                                disabled={true}
                                            ></TextBox>
                                        </div>
                                        : null
                                    }
                                    {!feeIsMatch ?
                                        <>
                                            <div className="col-12 col-lg-4 match-shift-panel__col d-flex">
                                                <span className="col-6">
                                                    Artist fee
                                                </span>
                                                <NumberBox
                                                    className="col-6"
                                                    format={{
                                                        type: "currency",
                                                        precision: 2,
                                                        currency: "GBP",
                                                    }}
                                                    onValueChanged={this.handleChangeRate}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.billableData.rate_Decimal}
                                                    step={0}
                                                    onContentReady={this.onContentReadyNumberBox}
                                                    readOnly={isReadOnlyRate}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-4 match-shift-panel__col d-flex">
                                                <span className="col-6">
                                                    Fee to Venue
                                                </span>
                                                <NumberBox
                                                    className="col-6"
                                                    format={{
                                                        type: "currency",
                                                        precision: 2,
                                                        currency: "GBP",
                                                    }}
                                                    value={this.state.billableData.client_Billable_Amount_Local_Ccy}
                                                    step={0}
                                                    disabled={true}
                                                />
                                            </div>
                                        </>
                                        : null
                                    }                                    
                                </div>
                                {  this.state.invoiceId !== "" && this.state.accural == false && this.state.bookingFeeAmount !== this.state.requestFeeAmount ?                               
                                    <div className="matching-error">
                                        The Shift being matched has already been billed - an adjustment will be created if the payment request amount does not equal the billed artist fee for the amount shown.
                                    </div> : null
                                }
                                {  this.state.billableData.rate_Decimal == undefined ?                               
                                    <div className="matching-error">
                                        If booking fee differs from artist fee - please enter artist fee
                                    </div> : null
                                }
                            </div>
                        </>
                        : null
                    }
                </>
            );
        }
        else {
            return (
                <div className="matchedPopUpSection">Please select an item from the above grid to see the details here </div>
            );
        }
    }

    render() {
        let disableMatchButton: boolean = false;
        let disableUnMatchButton: boolean = false;
        if (this.state.isDisableMatchButton || this.state.billableData.rate_Decimal == undefined) {
            disableMatchButton = true;
        }
        else if (this.state.isDisableRemoveButton) {
            disableUnMatchButton = true;
        }
        return (
            <>
                <section className="card card-form my-5" hidden={this.props.disable}>
                    <div className="card-body">
                        <h3 className="card-form__section-heading">Matching Shifts / Query Details</h3>
                        <hr className="horizontal_border_style"></hr>
                        {this.returnByCondition(this.props.loadPanelVisible, this.state.isMatchingShiftQueryShow)}
                    </div>
                </section>
                <br></br>
                <Popup
                    visible={this.state.unMatchPopupVisible}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"UNMATCHED SUCCESSFUL"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>Payment Request has been successfully unmatched</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <>
                    {this.state.validationMessage != "" ? (
                        <>
                            {" "}
                            <br />{" "}
                            <span className="text-danger font-weight-bold justify-content-center">
                                {this.state.validationMessage}
                            </span>{" "}
                        </>
                    ) : null}
                    <section className="card-form__button-container" hidden={this.props.disable}>
                        <div className="col-12 col-lg-4 col-xl-2">
                            <button
                                className="btn btn-primary btn--large"
                                disabled={disableMatchButton}
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    //this.onSubmit("match");
                                    this.providerTaxStatus_onSubmit('match')
                                }}
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faCheck} />
                                </span>
                                Match?
                            </button>
                        </div>

                        <div className="col-12 col-lg-4 col-xl-2">
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onCancel();
                                }}
                            >
                                Close
                            </button>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-2">
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onSubmit("unmatch");
                                }}
                                disabled={disableUnMatchButton}
                            >
                                <span className="btn__icon">
                                    X
                                </span>
                                Remove Match
                            </button>
                        </div>
                    </section>
                </>
            </>
        );
    }
}

export default withTranslation()(MatchedPopUpShiftDetails);
