import React from "react";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Lookup,
    SearchPanel,
    Export,
} from "devextreme-react/data-grid";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import { OnRowUpdatingEvent, OnRowInsertingEvent, onEditCanceledEvent } from "../../types/DevExtremeTypes";
import lookupService, { LookupTypeIndexes } from "../../services/LookupService";
import MatrixService, { ServiceTypeRowItem } from "../../services/MatrixService";
import { AxiosResponse } from "axios";

interface ServiceTypesProps {}

export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}
interface serviceTypeAddEditObjectItem {
    typeId: number;
    typeLookUpId: number;
}
interface serviceTypeState {
    roleLookUp: LookupTypeItemGrid[];
    serviceTypeGridDataSource:ServiceTypeRowItem[]
    serviceTypeAddEditObject: serviceTypeAddEditObjectItem;
    showProgressIndicator: boolean;
    errorMessage: any[];
    loadPanelVisible: boolean;
}
export class ServiceTypes extends React.Component<ServiceTypesProps> {
    state: serviceTypeState;
    dropdownService: lookupService;
    typeService: MatrixService;
    constructor(props: ServiceTypesProps) {
        super(props);
        this.dropdownService = new lookupService();
        this.typeService = new MatrixService();
        this.state = {
            serviceTypeGridDataSource:[],
            roleLookUp: [],
            errorMessage: [],
            loadPanelVisible: false,
            showProgressIndicator: false,
            serviceTypeAddEditObject: {
                typeId: 0,
                typeLookUpId: 0,
            },
        };
    }

    componentDidMount(){
        this.dropDownDataSource();
        this.serviceTypeDataSource();
    }

    dropDownDataSource = () => {
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.offeringsType)
            .then(this.handleRoleLUSuccess)
            .catch(this.handleError);
    };

    serviceTypeDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.typeService.getServiceTypeDataItem().then(this.handleSuccess).catch(this.handleError);
    };
    
    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            serviceTypeGridDataSource: response.data.data,
            showProgressIndicator: false,
            loadPanelVisible: false,
        });
    };

    lookUpCreation = (response: AxiosResponse<any>): LookupTypeItemGrid[] => {
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: parseInt(item.id),
                value: item.value,
                parentMappingId: item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };
    handleRoleLUSuccess = (response: AxiosResponse<any>) => {
        var roleDropDown = this.lookUpCreation(response);
        this.setState({
            roleLookUp: roleDropDown,
        });
    };
    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
            loadPanelVisible: false,
        });
        var respMessage: string = "type service failed with response: " + JSON.stringify(error);

        if (!this.typeService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    onRowUpadating = (e: OnRowUpdatingEvent) => {
        this.setState({
            loadPanelVisible: true,
            serviceTypeAddEditObject: {
                ...this.state.serviceTypeAddEditObject,
                typeId: e.oldData.typeId,
                typeLookUpId:
                    e.newData.typeLookUpId || e.newData.typeLookUpId === null || e.newData.typeLookUpId === ""
                        ? e.newData.typeLookUpId
                        : e.oldData.typeLookUpId,
            },
        });
        this.typeService
            .saveServiceTypeDataItem(this.state.serviceTypeAddEditObject)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch((err) => {
                this.setState({
                    loadPanelVisible: false,
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                });
            });
    };
    onRowInserting = (e: OnRowInsertingEvent) => {
        this.setState({
            loadPanelVisible: true,
            ...this.state.serviceTypeAddEditObject,
            serviceTypeAddEditObject: {
                typeId: 0,
                typeLookUpId: e.data.typeLookUpId ? e.data.typeLookUpId : null,
            },
        });
        this.typeService
            .saveServiceTypeDataItem(this.state.serviceTypeAddEditObject)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch((err) => {
                this.setState({
                    loadPanelVisible: false,
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                });
            });
    };
    onEditCancelled = (e: onEditCanceledEvent) => {
        this.setState({
            errorMessage: [],
        });
    };
    onRowUpdatedInsertedDeleted = () => {
        this.setState({
            loadPanelVisible: false,
            errorMessage: [],
        });
    };
    renderErrorMessage = (errorMessage: any[]) => {
        let errorData: React.ReactNode = <></>;
        if (errorMessage) {
            errorData = (
                <span className="unscheduled-shift">
                    <ul>
                        {errorMessage.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    {item.columnName}: {item.errorMessage}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            );
        }
        return errorData;
    };
    render() {
        var { errorMessage, loadPanelVisible } = this.state;
        return (
            <div>
                {this.renderErrorMessage(errorMessage)}
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={loadPanelVisible} />
                        <DataGrid
                            dataSource={this.state.serviceTypeGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            onRowUpdating={this.onRowUpadating}
                            onRowInserting={this.onRowInserting}
                            // onRowRemoving={this.onRowRemoving}
                            onEditCanceling={this.onEditCancelled}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Sorting mode="single" />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <Editing
                                mode="row"
                                useIcons={true}
                                allowAdding={true}
                                allowUpdating={true}
                                allowDeleting={true}
                            />
                            <Column width="200" type="buttons" buttons={["edit"]} />
                            <Column
                                width="200"
                                dataField="typeId"
                                allowSorting={true}
                                caption="ID"
                                allowEditing={false}
                            />
                            <Column width="300" dataField="typeLookUpId" allowSorting={true} caption="TYPE">
                                <Lookup dataSource={this.state.roleLookUp} displayExpr="value" valueExpr="id" />
                            </Column>
                            <FilterRow visible={true} applyFilter="auto" />
                        </DataGrid>
                    </>
                )}
            </div>
        );
    }
}
