import React, { MouseEvent, useState, useEffect } from "react";
import { Link, useHistory,useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUserCircle, faQuestionCircle, faArrowCircleLeft, faInfoCircle, faPhone, faBell, faDotCircle } from "@fortawesome/pro-regular-svg-icons";
import "../../../assets/images/logo-icon-white-landscape.svg";
import HeaderService, { HeaderResponse } from "../../services/HeaderService";
import { AxiosResponse } from "axios";
import Navigation from "./Navigation";
import { msalInstance } from "../../services/AuthService";
import { Popup, ScrollView } from "devextreme-react";
import moment from "moment";
import packageJSON from '../../../package.json';
//console.log(packageJSON,'menu');

interface HeaderComponentProps {
    title: string;
    getBuild: (flag: boolean) => void;
    isNewBuild: boolean;
    initialState: boolean;
}

// useState function Hook cannot be used within a class: define as FC instead
const Header: React.FC<HeaderComponentProps> = (props: HeaderComponentProps) => {
    const [state, setHeader] = useState<HeaderResponse>({
        title: "",
        description: "",
        businessEntityId: "",
    });
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertPopup, setShowAlertPopup] = useState(false);
    const history = useHistory();
    const service = new HeaderService();
    const location = useLocation();

  useEffect(() => {
    // alert(`Path changed to:${location.pathname}`);
    buildDateGreaterThanLocalStorage();

    // fetch('../package.json')
    // .then(res => res.json())
    // .then((menu:any) => {
    //     console.log(menu,'menu api');
    // })
  }, [location]);

    //Trigger the UseEffect Hook only when the component loads initially.
    useEffect(() => {
        service.getMainHeaderByRole().then(handleSuccess).catch(handleError);
        if(props.isNewBuild == true){
            setShowAlert(true);
        }
    }, [props.isNewBuild]);

    const writeBuildDateToLocalStorage = (currDate: any) => {
        //localStorage.clear();
        localStorage.setItem("latest_build_date",currDate);
      }

    const buildDateGreaterThanLocalStorage = () => {
        let reload = false;
        let currBuildDate1 = process.env.REACT_APP_BUILD_TIME;
        let currBuildDate = packageJSON?.builddate;
        console.log(packageJSON?.builddate,'latest_build_date package');

        console.log(currBuildDate1,'latest_build_date_env');
        let localDate = localStorage.getItem("latest_build_date");
        if(currBuildDate){
            const momCurrDateTime = moment(currBuildDate);
            const momLocalDate = moment(localDate);
        
            if (localDate && momLocalDate.isValid()) {
              if (momCurrDateTime.isAfter(momLocalDate)) {
                //writeDateToLocalStorage(currBuildDate);
                reload = true;
              } else {
                reload = false;
              }
            } else {
              //first time load
              //writeDateToLocalStorage(currBuildDate);
              reload = true;
            }
        }
        if(reload){
            console.log('Build date is latest,reloading the app....');
            writeBuildDateToLocalStorage(currBuildDate);
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then(function (names) {
                  for (let name of names) caches.delete(name);
                });
              }
      
              // delete browser cache and hard reload
              window.location.reload();
        }
      };

    // Set data source from server
    const handleSuccess = (response: AxiosResponse<any>) => {        
        setHeader({
            title: response.data.data.title,
            description: response.data.data.description,
            businessEntityId: response.data.data.businessEntityId,
        });
    };

    // Capture error from server
    const handleError = (error: any) => {
        setHeader({
            title: "",
            description: "",
            businessEntityId: "",
        });
    };

    const onSignOutClick = (e: MouseEvent) => {
        msalInstance.logoutRedirect({
            account: msalInstance.getActiveAccount(),
            authority: 'login.microsoftonline.com'
        })
    };

    const onCookieClick = () => {        
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
          });
    }

    const onBellClick = () => {   
        if(showAlert == true){
            setShowAlertPopup(true);
        }          
    }

    const openDrawer = () => {
        let drawer = document.getElementById("navbarNavDrawer");

        if (drawer) {
            drawer.classList.add("nav-drawer--open");
            drawer.setAttribute("aria-expanded", "true");
            // TODO once sidebar content is added, need to fix TabIndex on internal content
        }
    };

    const handleNotificationPopUpClose = () => {
        setShowAlertPopup(false);
    };

    const onProceed = () => {        
        setShowAlertPopup(false);
        setShowAlert(false);
        props.getBuild(false);
        //localStorage.setItem("BuildVersion", "");
        history.push("/");
        window.location.reload();
    };
    
    return (
        <div className="main-header__container">
            <div className="main-header__foreground"></div>
            <div className="main-header__foreground__content">
                <nav className="navbar">
                    <button
                        className="navbar-toggler"
                        onClick={openDrawer}
                        type="button"
                        data-target="#navbarNavDrawer"
                        aria-controls="navbarNavDrawer"
                        aria-expanded="false"
                        aria-label="Open navigation drawer"
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <a className="navbar-brand main-header__title text-truncate" href="#">
                        <h2 className="mr-2 main-header__title--bold">{state.title}</h2>
                        {state.description}
                    </a>
                    <Navigation />
                </nav>
            </div>

            <div className="main-header__background">
                <div className="main-header__buttons d-inline-block mr-3">
                    <div className="dropdown main-header__dropdown d-inline-block">
                        <button
                            className="btn icon-btn dropdown-toggle"
                            type="button"
                            id="dropdownMenu"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <FontAwesomeIcon icon={faUserCircle} />
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu">
                            {/* <button className="dropdown-item" type="button">
                                Settings <FontAwesomeIcon icon={faCog} />
                            </button> */}
                            <button className="dropdown-item" type="button" onClick={onSignOutClick}>
                                Sign Out <FontAwesomeIcon icon={faArrowCircleLeft} />
                            </button>
                            <button className="dropdown-item" type="button" onClick={onCookieClick}>
                                Clear Cookies
                            </button>
                        </div>
                    </div>
                    <div className="dropdown main-header__dropdown d-inline-block">
                        <button
                            className="btn icon-btn dropdown-toggle"
                            type="button"
                            id="dropdownMenu"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu">
                            <Link className="dropdown-item" to="/FAQs">
                                FAQs <FontAwesomeIcon icon={faQuestionCircle} />
                            </Link>
                            <Link className="dropdown-item" to="/raiseQuery">
                                Contact <FontAwesomeIcon icon={faPhone} />
                            </Link>
                        </div>
                    </div>
                    <div className="dropdown main-header__dropdown d-inline-block">
                        <button
                            className="btn icon-btn dropdown-toggle"
                            type="button"
                            id="dropdownMenu"
                            onClick={onBellClick}
                        >
                            <FontAwesomeIcon icon={faBell} /> <span className="notiBadge" hidden={!showAlert}></span>
                        </button>
                    </div>
                </div>
                <h1 className="sr-only">Event UK</h1>
                <img
                    className="main-header__icon"
                    src="/assets/images/logo-icon-white-landscape.svg"
                    alt="Event UK Logo"
                    width="120"
                    height="40"
                ></img>
            </div>
            <Popup
                visible={showAlertPopup}
                onHiding={handleNotificationPopUpClose}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                showCloseButton={true}
                title={"New Application Version Available"}
                resizeEnabled={true}
                width="550px"
                height="auto"
            >
                <ScrollView width="100%" height="100%">
                    <div>
                        <div className="card">
                            <div className="card-body">
                                <p>There is a newer application version - please refresh your browser</p>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3 w-50">
                        <div className="col-12 col-xl-6 pl-0">
                        <button
                            className="btn btn-primary btn--large mt-3"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                onProceed();
                            }}
                        >
                            Yes
                        </button>
                        </div>
                        <div className="col-12 col-xl-6 pl-0">
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    handleNotificationPopUpClose();
                                }}
                            >
                                No
                            </button>
                        </div>
                    </div> 
                </ScrollView>
            </Popup>
        </div>
    );
};

export default Header;
