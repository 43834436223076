import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Editing,
    Selection,
    Texts
} from "devextreme-react/data-grid";
import { DateBox, LoadIndicator, TextArea } from "devextreme-react";
import { DataGridOnSelectionChanged, DateBoxOnValueChangedEvent, onExportingEvent, TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import ArtistService from "../../services/ArtistService";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import FindAnArtistUtils from "../FindAnArtist/FindAnArtistUtils";
import FindAnArtistService, { AppliedArtistObj, ShowInterestOptionsSentRequest } from "../../services/FindAnArtistService";
import sharedUtils from "../grid/sharedUtilities";
import { faFaceThinking, faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import { feedbackScoreType } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup, ScrollView } from "devextreme-react";

//props
interface AppliedArtistGridProps {
    requestId: string;
    internalComments: string;
    onConfirm: (
        submitRequest: ShowInterestOptionsSentRequest
    ) => void;
    errorMessage: [];
    serviceTypeId: string;
    serviceSubTypeId: string;
    clientBusinessEntityId: string;
    requestStatusId: string;
}

// State
interface AppliedArtistGridState {
    dataSource: [];
    showProgressIndicator: boolean;
    internalComments: string;
    isInternalCommentsChanged: boolean;
    optionsSentConfirmDate: NullableDate;
    optionsConfirmVal: boolean;
    showInterestArr: AppliedArtistObj[];
    loadPanelVisible: boolean;
    linkPopupVisible: boolean;
    linkURL: string;
}

// Component - displays both the header and child grids
class AppliedArtistGrid extends React.Component<AppliedArtistGridProps> {
    state: AppliedArtistGridState;
    service: ArtistService;
    findAnArtistUtils: FindAnArtistUtils;
    sharedUtils: sharedUtils;
    constructor(props: AppliedArtistGridProps) {
        super(props);
        // Initialize state and services/utils
        this.state = {
            dataSource: [],
            showProgressIndicator: false,
            internalComments: "",
            isInternalCommentsChanged: false,
            optionsSentConfirmDate: undefined,
            optionsConfirmVal: false,
            showInterestArr: [],
            loadPanelVisible: false,
            linkPopupVisible: false,
            linkURL: "",
        };
        this.service = new ArtistService();
        this.findAnArtistUtils = new FindAnArtistUtils();
        this.sharedUtils = new sharedUtils();
    }

    // Initialize state from server
    componentDidMount() {
        this.setState({
            showProgressIndicator: true,
        });
    }

    costReportGridDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.service
            .getAppliedArtist(
                this.props.requestId
            )
            .then(this.handleSuccess)
            .catch(this.handleError);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        this.setState({
            dataSource: response.data.data
        });
    };

    handleError = () => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    componentDidUpdate = (prevprops: AppliedArtistGridProps) => {        
        if (
            this.props.requestId != prevprops.requestId
        ) {
            this.costReportGridDataSource();
        }
    };

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell && gridCell.rowType === "data") {
                    if (gridCell.column && gridCell.column.dataField === "link") {
                        excelCell.value = {
                            text: gridCell.value,
                            hyperlink: gridCell.value
                        }
                    }
                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    };

    handleLink = (link: any) => {
        const data = link;
        let updatedUrl: string = "";
        if (data) {
            var substr2 = "";
            if (data.toLowerCase().includes("list") && data.toLowerCase().includes("playlist")) {
                substr2 = data.substring(data.indexOf("?"));
                updatedUrl = "https://www.youtube.com/embed/videoseries" + substr2;
            } else if (data.toLowerCase().includes("list")) {
                substr2 = "?" + data.substring(data.indexOf("list"));
                updatedUrl = "https://www.youtube.com/embed/videoseries" + substr2;
            } else {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = data.match(regExp);
                updatedUrl = match !== null ? "https://www.youtube.com/embed/" + match[2] : "Not match";
            }
        }
        return updatedUrl;
    }

    openLink = (linkURL: any) => {
        if(linkURL !== "" && (linkURL.toLowerCase().includes("facebook") || linkURL.toLowerCase().includes("twitter"))){
            window.open(linkURL, '_blank', 'noopener,noreferrer');
        } 
        else{
            let url = this.handleLink(linkURL);
            this.setState({
                linkPopupVisible: true,
                linkURL: url == "Not match" ? linkURL : url
            })
        }
    }

    hidelinkPopup = () => {
        this.setState({
            linkPopupVisible: false,
            linkURL: ""
        })
    }

    renderLinkColumn = (cellInfo: any) => {
        return (
            <span>
                <ul className="mb-0">
                    {cellInfo.data.link && cellInfo.data.link.length
                        ? cellInfo.data.link.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    <a 
                                        href="#media-popup" 
                                        onClick={(e: React.MouseEvent) => {
                                            e.preventDefault();
                                            this.openLink(item);
                                        }} 
                                        data-media={item}>{item}
                                    </a>
                                </li>
                            );
                        })
                        : null}
                </ul>
            </span>
        );
    }

    renderFeedbackColumn = (cellInfo: any) => {
        let iconToShow: string = "";
        if (cellInfo.data.feedbackScore == feedbackScoreType.thumbsUp) {
            iconToShow = "faThumbsUp";
        }
        else if (cellInfo.data.feedbackScore == feedbackScoreType.thumbsDown) {
            iconToShow = "faThumbsDown";
        }
        else if (cellInfo.data.feedbackScore == feedbackScoreType.faceThinking) {
            iconToShow = "faFaceThinking";
        }
        return (
            <div className="artist-card__feedback">
                <a
                    className={`faThumbsUp-btn-artist`}
                >
                    {iconToShow == "faThumbsUp" ? <FontAwesomeIcon icon={faThumbsUp} className="text-success" /> :
                        iconToShow == "faThumbsDown" ? <FontAwesomeIcon icon={faThumbsDown} className="text-danger" /> :
                            iconToShow == "faFaceThinking" ? <FontAwesomeIcon icon={faFaceThinking} className="text-warning" /> : null}
                </a>
            </div>
        );
    }

    handleChangeInternalComment = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            isInternalCommentsChanged: true,
            internalComments: dxValueChange.value
        });
    };

    handleChangeOptionsConfirmDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState(
            {
                optionsSentConfirmDate: dxValueChange.value,
                optionsConfirmVal: true
            }
        );
    };

    onConfirmOptionsSent = () => {
        let internalComments: string = "";
        let requestStatusId = "23";
        let previousRequestStatusId = "";
        if(this.props.requestStatusId !== requestStatusId){
            previousRequestStatusId = this.props.requestStatusId;
        }
        if (this.state.isInternalCommentsChanged) {
            internalComments = this.state.internalComments;
        }
        else {
            internalComments = this.props.internalComments;
        }
        let submitRequest = this.findAnArtistUtils.mapShowInterestOptionsSentRequest(this.props.requestId, true, this.state.optionsSentConfirmDate, internalComments, this.state.showInterestArr, this.props.serviceTypeId, this.props.serviceSubTypeId, this.props.clientBusinessEntityId, requestStatusId, previousRequestStatusId);
        this.props.onConfirm(submitRequest);
    }

    onSelectionChanged = (value: DataGridOnSelectionChanged) => {
        let showInterestArr: AppliedArtistObj[] = [];
        if (value.selectedRowsData && value.selectedRowsData.length > 0) {
            value.selectedRowsData.forEach((items) => {
                let showInterestObj = {
                    id: items.id,
                    providerBusinessEntityId: items.providerBusinessEntityId,
                    requestId: this.props.requestId,
                    makeOfferAmount: items.makeOfferAmount,
                    optionsSent: this.sharedUtils.convertDateToString(this.state.optionsSentConfirmDate)
                }
                showInterestArr.push(showInterestObj);
            });
            this.setState({
                showInterestArr: showInterestArr
            });
        }
    };

    headerOfIncludeCell = () => {
        return <div>INCLUDE</div>;
    };


    render() {
        let internalCommentsVal: string;
        let modeOfSelection = "multiple";
        if (this.state.isInternalCommentsChanged) {
            internalCommentsVal = this.state.internalComments;
        }
        else {
            internalCommentsVal = this.props.internalComments;
        }
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <>
                        <DataGrid
                            dataSource={this.state.dataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            onSelectionChanged={this.onSelectionChanged}
                            columnResizingMode={"widget"}
                            onExporting={this.onExporting}
                            noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                        >
                            {/* <SearchPanel visible={true} placeholder={"Search"} />
                        <GroupPanel visible={true} /> */}
                            <Editing>
                                <Texts confirmDeleteMessage=""></Texts>
                            </Editing>
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <Column
                                headerCellRender={this.headerOfIncludeCell}
                                type="selection"
                            />
                            <Column dataField="date" caption="DATE" />
                            <Column dataField="houseName" caption="HOUSE NAME" />
                            <Column dataField="artistName" caption="ARTIST'S NAME" />
                            <Column dataField="professionalName" caption="PROFESSIONAL NAME" />
                            <Column dataField="showInterestStatus" caption="SHOW INTEREST STATUS" />
                            <Column dataField="feedbackScore" caption="FEEDBACK" cellRender={this.renderFeedbackColumn}/>
                            <Column dataField="appliedDate" caption="Applied Date" />
                            <Column dataField="makeOfferAmount" caption="MAX OFFER FEE" />
                            <Column dataField="budget" caption="GIG LIST FEE" />
                            <Column dataField="maxBudget" caption="VENUE FEE" />
                            <Column dataField="optionsSent" caption="OPTIONS SENT" />
                            <Column dataField="link" caption="LINK(S)" cellRender={this.renderLinkColumn} />
                            <Selection mode={modeOfSelection}></Selection>
                            <FilterRow visible={true} applyFilter="auto" />
                        </DataGrid>

                        <div className="card-form__subheading mt-6">Internal Comments
                            <sup className="card-form__mandatory-symbol">*</sup></div>
                        <TextArea
                            onValueChanged={this.handleChangeInternalComment}
                            value={internalCommentsVal}
                            height="50%"
                        />
                        <div className="row align-items-center mt-4">
                            <div className="col-5">
                                <button
                                    className="btn btn-primary btn--large"
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onConfirmOptionsSent();
                                    }}
                                    disabled={this.props.requestStatusId == "30"}
                                >
                                    CONFIRM OPTIONS SENT
                                </button>
                            </div>
                            <div className="col-2 text-right">Options Sent Date</div>
                            <div className="col-5">
                                <DateBox
                                    displayFormat="dd/MM/yyyy"
                                    onValueChanged={this.handleChangeOptionsConfirmDate}
                                    value={
                                        this.state.optionsSentConfirmDate
                                            ? this.state.optionsSentConfirmDate
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                />
                            </div>
                            <div className="row align-items-center mt-4">
                                {this.props.errorMessage && this.props.errorMessage.length > 0 && this.props.errorMessage != null ? (
                                    <span className="unscheduled-shift">
                                        <ul>
                                            {this.props.errorMessage.map((item: any, uniqueKey: number) => {
                                                return (
                                                    <li key={uniqueKey}>
                                                        {item.columnName}: {item.errorMessage}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </>
                )}

                <Popup
                    visible={this.state.linkPopupVisible}
                    onHiding={this.hidelinkPopup}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title=""
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <section>
                            <iframe
                                className="artist-profile__latest-videos"
                                src={this.state.linkURL}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </section>
                    </ScrollView>
                </Popup>
            </div>
        );
    }
}

export default AppliedArtistGrid;
