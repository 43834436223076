import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import VenueHierarchyService, { VenueHierarchyStatus } from "../../../services/VenueHierarachyService";
import { LoadPanel } from "devextreme-react";
import { DataGridOnSelectionChanged, TextBoxOnValueChangedEvent, onExportingEvent } from "../../../types/DevExtremeTypes";
import DataGrid, { Pager, Paging, FilterRow, Column, Sorting, Editing, SearchPanel, FilterPanel, GroupPanel, Selection } from "devextreme-react/data-grid";

interface MatchingVenueGridProps extends WithTranslation {
    findMatchesClicked: boolean,
    pastedVenueDetails: any[],
    clientId: string,
    updateFoundRecords: (matchedGridSource: any[], pastedGridSource: any[]) => void;
    setSelectedRows: (selectedRows: any) => void;
}

interface MatchingVenueGridState {
    matchingDataSource: [],
    loadPanelVisible: boolean,
    contactDetails: []
}

class MatchingVenueGrid extends Component<MatchingVenueGridProps> {
    state: MatchingVenueGridState;
    venueHierarchyService: VenueHierarchyService;
    dataGridRef:any;
    constructor(props: MatchingVenueGridProps) {
        super(props);
        this.state = {
            matchingDataSource: [],
            loadPanelVisible: false,
            contactDetails: []
        }
        this.venueHierarchyService = new VenueHierarchyService();
        this.dataGridRef = null;
    }

    componentDidMount() {
        this.fetchContactDetailsUsingEmailIDs();
    }

    componentDidUpdate(prevProps: MatchingVenueGridProps, prevState: MatchingVenueGridState) {
        if ((this.props.findMatchesClicked !== prevProps.findMatchesClicked && this.props.findMatchesClicked == true)) {
            this.fetchContactDetailsUsingEmailIDs();
        }
    }

    getHouseNumberList = () => {
        let houseList = '';
        houseList = this.props.pastedVenueDetails.map(a => a.houseNumber).join(',');
        return houseList;
    }

    fetchContactDetailsUsingEmailIDs = () => {
        this.setState({
            loadPanelVisible: true,
        });
        let ams = this.props.pastedVenueDetails.map(a => a.amEmail);
        let manager = this.props.pastedVenueDetails.map(a => a.opsManagerEmail);
        let director = this.props.pastedVenueDetails.map(a => a.opsDirectorEmail);
        let all = [...ams, ...manager, ...director];
        let filteredAll = all.filter(a => a);
        this.venueHierarchyService
            .fetchContactDetails(filteredAll)
            .then(this.handleContactSuccess)
            .catch(this.handleContactError);
    }

    handleContactSuccess = (response: any) => {
        this.setState({ contactDetails: response?.data?.data }, () => {
            this.fetchMatchingVenues();
        })

    }

    handleContactError = (err: any) => {
        this.setState({
            loadPanelVisible: false,
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null, // For capturing response at the time of exception and showing error message
        }, () => {
            // let matchedRecords: any[] = [];
            // let updatedVenueSource = this.props.pastedVenueDetails;
            // this.props.updateFoundRecords(matchedRecords, updatedVenueSource);
        });
    };

    fetchMatchingVenues = () => {
        this.setState({
            loadPanelVisible: true,
        });
        let houseList = this.getHouseNumberList();
        let clientId = this.props.clientId;
        this.venueHierarchyService
            .findMatchingVenues(clientId, houseList)
            .then(this.handleFetchVenueSuccess)
            .catch(this.handleVenueError);
    };

    getNewContactDetails = (pastedRecord: any, existingRecord: any) => { //amEmail,opsManagerEmail,opsDirectorEmail
        let finalObj: {} = { amStatus: VenueHierarchyStatus.EMPTY, opsManagerStatus: VenueHierarchyStatus.EMPTY, opsDirectorStatus: VenueHierarchyStatus.EMPTY };
        if (pastedRecord && pastedRecord.amEmail) {
            let pastedEmail = pastedRecord.amEmail?.trim();
            let filteredContact = this.state.contactDetails.filter((contact: any) => contact.contactEmail?.toLowerCase() == pastedEmail?.toLowerCase());
            if (filteredContact && filteredContact.length && filteredContact.length > 0) {
                let filtered: any = filteredContact[0];
                let obj = {
                    areaManagerContactId_New: filtered.contactId,
                    areaManagerContactTypeId_New: filtered.contactTypeId,
                    areaManagerContactName_New: filtered.contactName,
                    areaManagerContactEmail_New: filtered.contactEmail,
                    areaManagerUserId_New:filtered.userId,
                    amStatus: pastedEmail.toLowerCase() === existingRecord.areaManagerEmail?.toLowerCase() ? VenueHierarchyStatus.EXISTING : VenueHierarchyStatus.NEW
                }
                finalObj = { ...finalObj, ...obj };
            } else {
                finalObj = { ...finalObj, amStatus: VenueHierarchyStatus.NOTFOUND,areaManagerContactEmail_New: pastedEmail}
            }
        }
        // else {
        //     finalObj = {...finalObj ,amStatus : VenueHierarchyStatus.EMPTY}
        // }

        if (pastedRecord && pastedRecord.opsManagerEmail) {
            let pastedEmail = pastedRecord.opsManagerEmail?.trim();
            let filteredContact = this.state.contactDetails.filter((contact: any) => contact.contactEmail?.toLowerCase() == pastedEmail?.toLowerCase());
            if (filteredContact && filteredContact.length && filteredContact.length > 0) {
                let filtered: any = filteredContact[0];
                let obj = {
                    opsManagerContactId_New: filtered.contactId,
                    opsManagerContactTypeId_New: filtered.contactTypeId,
                    opsManagerContactName_New: filtered.contactName,
                    opsManagerContactEmail_New: filtered.contactEmail,
                    opsManagerUserId_New:filtered.userId,
                    opsManagerStatus: pastedEmail.toLowerCase() === existingRecord.opsManagerEmail?.toLowerCase() ? VenueHierarchyStatus.EXISTING : VenueHierarchyStatus.NEW
                }
                finalObj = { ...finalObj, ...obj };
            } else {
                finalObj = { ...finalObj, opsManagerStatus: VenueHierarchyStatus.NOTFOUND,opsManagerContactEmail_New: pastedEmail}
            }
        }
        // else {
        //     finalObj = {...finalObj ,opsManagerStatus : VenueHierarchyStatus.EMPTY}
        // }

        if (pastedRecord && pastedRecord.opsDirectorEmail) {
            let pastedEmail = pastedRecord.opsDirectorEmail?.trim();
            let filteredContact = this.state.contactDetails.filter((contact: any) => contact.contactEmail?.toLowerCase() == pastedEmail?.toLowerCase());
            if (filteredContact && filteredContact.length && filteredContact.length > 0) {
                let filtered: any = filteredContact[0];
                let obj = {
                    opsDirectorContactId_New: filtered.contactId,
                    opsDirectorContactTypeId_New: filtered.contactTypeId,
                    opsDirectorContatcName_New: filtered.contactName,
                    opsDirectorContactEmail_New: filtered.contactEmail,
                    opsDirectorUserId_New:filtered.userId,
                    opsDirectorStatus: pastedEmail?.toLowerCase() === existingRecord.opsDirectorEmail?.toLowerCase() ? VenueHierarchyStatus.EXISTING : VenueHierarchyStatus.NEW
                }
                finalObj = { ...finalObj, ...obj };
            } else {
                finalObj = { ...finalObj, opsDirectorStatus: VenueHierarchyStatus.NOTFOUND,opsDirectorContactEmail_New: pastedEmail}
            }
        }
        // else {
        //     finalObj = {...finalObj ,opsDirectorStatus : VenueHierarchyStatus.EMPTY}
        // }

        return finalObj;

    }

    handleFetchVenueSuccess = (response: any) => {
        let venuesData = response?.data?.data || []
        let matchedRecords: any[] = [];
        let updatedPastedSource = this.props.pastedVenueDetails;
        if (venuesData && venuesData.length && venuesData.length > 0) {
            const newPastedSource = this.props.pastedVenueDetails.map((record) => {
                let found = venuesData.filter((resData: any) => {
                    let isFound = false;
                    if (record.houseNumber == resData.venueHouseNumber) {
                        isFound = true;
                        let filtered = matchedRecords.filter(a => a.venueHouseNumber == resData.venueHouseNumber) //avoid duplicate records
                        if (filtered && filtered.length < 1) {
                            let modifiedObj = this.getNewContactDetails(record, resData);
                            let finalObj = { ...resData, ...modifiedObj };
                            matchedRecords.push(finalObj);
                            //matchedRecords.push(resData);
                        }

                    }

                    return isFound;
                })
                if (found && found.length && found.length > 0) {
                    return { ...record, itemMatched: true,venueId:found[0].venueId};
                    //return { ...record, itemMatched: false };
                }
                else {
                    return { ...record, itemMatched: false };
                }
            });
            updatedPastedSource = newPastedSource;
        } else {
            
        }
        this.setState({
            matchingDataSource: matchedRecords, //set in state for grid updates and also send to parent for further updates
            loadPanelVisible: false
        }, () => {
            this.dataGridRef?.instance?.clearSelection();
            this.props.updateFoundRecords(matchedRecords, updatedPastedSource)
        })
    }

    handleVenueError = (err: any) => {
        this.setState({
            loadPanelVisible: false,
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null, // For capturing response at the time of exception and showing error message
        }, () => {
            // let matchedRecords: any[] = [];
            // let updatedVenueSource = this.props.pastedVenueDetails;
            // this.props.updateFoundRecords(matchedRecords, updatedVenueSource);
        });
    };

    onSelectionChanged = (value: DataGridOnSelectionChanged) => {
        let selectedRows = value.selectedRowsData;
        this.props.setSelectedRows ? this.props.setSelectedRows(selectedRows) : null;
    };

    colorStatusForEmails = (cellInfo: any, entityName: string) => {
        let email = '';
        let status;
        if (entityName) {
            if (entityName == 'am') {
                status = cellInfo?.data?.data?.amStatus;
                email = cellInfo?.data?.data?.areaManagerContactEmail_New || '';
            } else if (entityName == 'manager') {
                status = cellInfo?.data?.data?.opsManagerStatus;
                email = cellInfo?.data?.data?.opsManagerContactEmail_New || '';
            } else if (entityName == 'director') {
                status = cellInfo?.data?.data?.opsDirectorStatus;
                email = cellInfo?.data?.data?.opsDirectorContactEmail_New || ''
            }
        }

        if (status) {
            if (status == VenueHierarchyStatus.EMPTY) {
                return (<div style={{fontWeight:'bold',color: 'black',padding:'0.5rem'}}>No Email Provided</div>)
            } else if (status == VenueHierarchyStatus.NOTFOUND) {
                return (
                    <>
                        <div style={{fontWeight:'bold',color: 'red',padding:'0.5rem'}}>
                            <div>Unknown Email - Not Found</div>
                            <div>{`( ${email} )`}</div>
                        </div>
                        
                    </>
                )
            } else if (status == VenueHierarchyStatus.EXISTING) {
                return (
                    <>
                        <div style={{fontWeight:'bold',color: 'black',padding:'0.5rem'}}>
                            <div>Same Email already assigned to venue</div>
                            <div>{`( ${email} )`}</div>
                        </div>
                        
                    </>
                )
            } else if (status == VenueHierarchyStatus.NEW) {
                return (
                    <>
                        <div className="new-email" style={{fontWeight:'bold',color: '#32c5ff',padding:'0.5rem'}}>
                            <div>Valid Email for update</div>
                            <div>{`( ${email} )`}</div>
                        </div>
                        
                    </>
                )
            }
        }
        return (
            <div style={{padding:'0.5rem'}}></div>
        )
    }

    render() {
        return (
            <div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                <div className="row">
                    <div className="col-md">
                        <DataGrid dataSource={this.state.matchingDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            allowColumnReordering={true}
                            columnMinWidth={100}
                            onSelectionChanged={this.onSelectionChanged}
                            className="matching-venue-grid"
                            ref={(ref) => { this.dataGridRef = ref; }}
                        >
                            <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always"></Selection>
                            {/* <SearchPanel visible={true} placeholder={"Search"} /> */}
                            {/* <GroupPanel visible={true} /> */}
                            <FilterPanel visible={true} />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            {/* <Column
                                dataField=""
                                caption="CLIENT" /> */}
                            <Column
                                dataField="venueName"
                                caption="VENUE" />
                            <Column
                                dataField="venueHouseNumber"
                                caption="HOUSE NUMBER" />
                            <Column
                                dataField="areaManagerEmail"
                                caption="CURRENT AM EMAIL" />
                            <Column
                                dataField="areaManagerContactEmail_New"
                                caption="NEW AM EMAIL"
                                cellComponent={(cellInfo: any) => { return this.colorStatusForEmails(cellInfo, 'am') }} />
                            <Column
                                dataField="opsManagerEmail"
                                caption="CURRENT OPS MANAGER EMAIL" />
                            <Column
                                dataField="opsManagerContactEmail_New"
                                caption="NEW OPS MANAGER EMAIL"
                                cellComponent={(cellInfo: any) => { return this.colorStatusForEmails(cellInfo, 'manager') }} />
                            <Column
                                dataField="opsDirectorEmail"
                                caption="CURRENT OPS DIRECTOR EMAIL" />
                            <Column
                                dataField="opsDirectorContactEmail_New"
                                caption="NEW OPS DIRECTOR EMAIL"
                                cellComponent={(cellInfo: any) => { return this.colorStatusForEmails(cellInfo, 'director') }} />
                        </DataGrid>
                    </div>
                </div>

            </div>
        );
    }
}

export default withTranslation()(MatchingVenueGrid);