import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { LoadPanel, Popup, TextArea } from "devextreme-react";
import { Link } from "react-router-dom";
import BaseActionCell, { BaseActionCellProps } from "../GridCells/BaseActionCell";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { alert } from "devextreme/ui/dialog";
import { AxiosResponse } from "axios";
import { paymentRequestStatus } from "../../services/PaymentService";
import { queryTypes } from "../../services/QueryService";
import { PaymentTypes } from "../../services/FindAnArtistService";

class PaymentQueryActionCell extends BaseActionCell {
    constructor(props: BaseActionCellProps) {
        super(props);
    }

    componentDidMount() {
        var statusColumnIndex: number = 0;
        if (this.props.data.data.paymentRequestStatusLookUp.value) {
            statusColumnIndex = this.props.data.component.getVisibleColumnIndex("paymentRequestStatusLookUp.value");
        }
        let payReqStatus = this.props.data.data.paymentRequestStatusLookUp.id;
        let payReqStatusId = this.props.data.data.paymentRequestStatusLookUp.id;
        let queryDesc: string = "";
        let queryId: string = "";
        let queryDateCreated: string = "";
        if (this.props.data.data && this.props.data.data.hasOwnProperty('queries')) {
            queryDesc = this.props.data.data.queries.queryDescription;
            queryId = this.props.data.data.queries.id;
            queryDateCreated = this.props.data.data.queries.queryDateCreated;
        }
        var isQueried: boolean = this.state.isItemQueried;
        let isQueriedDisable: boolean = true;
        if (payReqStatus && payReqStatus.toString() == paymentRequestStatus.Queried || payReqStatus && payReqStatus.toString() == paymentRequestStatus.AddJobQueried || this.props.data.data.paymentRequestStatusLookUp.value == "Queried" || this.props.data.data.paymentRequestStatusLookUp.value == "Add Job Queried") {
            isQueried = true;
        }
        if (payReqStatusId && (payReqStatusId == paymentRequestStatus.Draft || payReqStatusId == paymentRequestStatus.Pending || payReqStatusId == paymentRequestStatus.NotMatched || payReqStatusId == paymentRequestStatus.AddJobDraft || payReqStatusId == paymentRequestStatus.AddJobPending || this.props.data.data.paymentRequestStatusLookUp.value == "Not Matched" || this.props.data.data.paymentRequestStatusLookUp.value == "Add Job Pending")) {
            isQueriedDisable = false;
        }
        this.setState({
            isItemQueried: isQueried,
            isQueriedDisable: isQueriedDisable,
            queryItem: {
                ...this.state.queryItem,
                queryDescription: queryDesc
            },
            queryId: queryId,
            queryDateCreated: queryDateCreated,
            queryStatusIndexVal: statusColumnIndex
        });
    }

    handleChangeQueryComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        let queryTypeId: string = "";
        if (this.props.data.data.paymentRequestType.id.toString() == PaymentTypes.AddJob) {
            queryTypeId = queryTypes.AddJob;
        }
        else if (this.props.data.data.paymentRequestType.id.toString() == PaymentTypes.PaymentRequest) {
            queryTypeId = queryTypes.PaymentRequest;
        }
        let clientId: string = this.props.data.data.clientBusinessEntity.id;
        let serviceTypeId: string = this.props.data.data.serviceType.id;
        let venueId: string = this.props.data.data.venue.id;
        let linkedId: string = this.props.data.data.id;
        let queryId: string = this.state.queryId.toString();
        let queryDateCreated: string = this.state.queryDateCreated;
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
                queryTypeLookupID: queryTypeId,
                clientId: clientId,
                serviceTypeId: serviceTypeId,
                venueId: venueId,
                linkedId: linkedId,
                id: queryId,
                queryDateCreated: queryDateCreated
            },
        });
    };

    handleChangeResolutionReason = (dxValueChange: any) => {
        let queryId: string = this.state.queryId.toString();
        let linkedId: string = this.props.data.data.id;
        let queryTypeId: string = "";
        if (this.props.data.data.paymentRequestType.id.toString() == PaymentTypes.AddJob) {
            queryTypeId = queryTypes.AddJob;
        }
        else if (this.props.data.data.paymentRequestType.id.toString() == PaymentTypes.PaymentRequest) {
            queryTypeId = queryTypes.PaymentRequest;
        }
        this.setState({
            queryFormData: {
                ...this.state.queryFormData,
                resolutionReason: dxValueChange.value,
                id: queryId,
                queryTypeLookupID: queryTypeId,
                linkedId: linkedId
            }
        })
    }


    onQuerySubmission = () => {
        let queryDescriptionAdded: boolean = false;

        queryDescriptionAdded = this.sharedUtils.validationCheckFunction(this.state.queryItem.queryDescription);
        if (!queryDescriptionAdded) {
            alert("Please enter the query before pushing the submit button", "Raise Query");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            this.queryService
                .uploadQueryData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    onQueryResolved = () => {
        let queryDescriptionAdded: boolean = false;
        queryDescriptionAdded = this.sharedUtils.validationCheckFunction(this.state.queryFormData.resolutionReason);

        if (!queryDescriptionAdded) {
            alert("Please enter the query resolution reason before pushing the Resolve button", "Resolve Query");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            let convertedresolveQueryItem = this.queryUtils.convertFormDataToQueryRequest(this.state.queryFormData, "");
            this.resolvedQueryService
                .postResolveQueryRequestFormData(convertedresolveQueryItem)
                .then(this.handleQueryResolved)
                .catch(this.handleQuerySubmissionFailure);
        }
    }

    handleQueryResolved = (response: AxiosResponse<any>) => {
        this.closeResolveQueryBox();
        this.setState({
            loadPanelVisible: false,
            isItemQueried: false,
            isQueriedDisable: false
        },() => { this.updateStatusColumn(response.data.data.value)});
        alert("The Query has been resolved successfully", "Query Resolved");
    }

    handleQuerySubmissionSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            onQueryRaising: false,
            disableQueryIcon: true,
            queryId: parseInt(response.data.data.id),
            isItemQueried: true,
        }, () => { this.updateStatusColumn(response.data.data.value)});
        this.closeResolveQueryBox();
        if (this.state.queryItem.id) {
            alert("The Query has been updated, an Event Uk staff will be in touch soon. Thanks!", "Query Submitted");
        }
        else {
            alert("The Query has been submitted, an Event Uk staff will be in touch soon. Thanks!", "Query Submitted");
        }
    };

    updateStatusColumn = (value: string) => {
        this.props.data.component.cellValue(
            this.props.data.rowIndex,
            this.state.queryStatusIndexVal,
            value
        );
    }

    handleQuerySubmissionFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false
        });
    };

    //Helper function to close the Query popup.
    closeQueryBox = () => {
        this.setState({
            onQueryRaising: false,
        });
    };

    handleQueryOnClick = () => {
        this.setState({
            onQueryRaising: true,
        });
    };

    handleQueriedOnClick = () => {
        this.setState({
            afterQueryRaised: true
        });
    }

    closeResolveQueryBox = () => {
        this.setState({
            afterQueryRaised: false
        });
    }


    render() {
        return (
            <>
                <>
                    <div className="d-flex justify-content-between align-items-center">
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            message="Please wait..."
                            visible={this.state.loadPanelVisible}
                        />
                        <div>
                            {" "}
                            {this.state.isItemQueried ? (
                                <Link
                                    className="approval-query-column"
                                    to="#"
                                    onClick={this.handleQueriedOnClick}
                                >
                                    Queried
                                </Link>
                            ) : (
                                <button className={this.state.isQueriedDisable ? "disabled btn icon-btn" : "btn icon-btn"} onClick={this.handleQueryOnClick} disabled={this.state.isQueriedDisable}>
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                </button>
                            )}
                        </div>
                    </div>
                    <Popup
                        wrapperAttr={{
                            class: "pending-request-panel__raise-query-pop-up",
                        }}
                        visible={this.state.onQueryRaising}
                        onHiding={this.closeQueryBox}
                        dragEnabled={false}
                        closeOnOutsideClick={false}
                        showTitle={true}
                        showCloseButton={true}
                        title="Raise Query"
                        maxWidth="500px"
                    >
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <div className="pending-request-panel__raise-query">
                            <div className="pending-request-panel__query-box">
                                <TextArea
                                    height="100%"
                                    placeholder="Please enter your query here and select Submit Query button - a member of the Event UK staff will be in touch"
                                    onValueChanged={this.handleChangeQueryComments}
                                />

                                <button className="btn btn-primary btn--full-width" onClick={this.onQuerySubmission}>
                                    Submit Query
                                </button>
                            </div>
                        </div>
                    </Popup>

                    <Popup
                        wrapperAttr={{
                            class: "pending-request-panel__raise-query-pop-up",
                        }}
                        visible={this.state.afterQueryRaised}
                        onHiding={this.closeResolveQueryBox}
                        dragEnabled={false}
                        closeOnOutsideClick={false}
                        showTitle={true}
                        showCloseButton={true}
                        title="Resolve Or Update Query"
                        maxWidth="600px"
                        height="auto"
                    >
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <div className="pending-request-panel__raise-query">
                            <div className="pending-request-panel__query-box">
                                <div className="card-form__subheading ">Query

                                </div>
                                <TextArea
                                    onValueChanged={this.handleChangeQueryComments}
                                    value={this.state.queryItem.queryDescription}
                                    height="40%"
                                />
                                <div className="card-form__subheading mt-4">
                                    Resolution
                                </div>
                                <TextArea
                                    onValueChanged={this.handleChangeResolutionReason}
                                    value={this.state.queryFormData.resolutionReason}
                                    placeholder="Please enter a resolution reason..."
                                    height="40%"
                                />
                                <div className="row">
                                    <div className={"col-12 col-lg-6"} >
                                        <button className="btn btn-primary btn--large" onClick={this.onQueryResolved} >
                                            Resolve
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <button className="btn btn--ghost btn--ghost--teal btn--large" onClick={this.onQuerySubmission} >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popup>
                </>
            </>
        );
    }
}

export default PaymentQueryActionCell;
