import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import DataGrid, { Pager, Paging, FilterRow, Column, Sorting, Export, SearchPanel, Editing, Texts } from "devextreme-react/data-grid";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import VenueProviderService, { VenueProviderGridRowItem } from "../../services/VenueProviderService";
import lookupService, { LookupTypeItem } from "../../services/LookupService";
import gridUtils from "../grid/GridUtilities";
import ProviderServiceCellComponent from "./ProviderServiceCellComponent";
import MaskedStatusForActive from "../ServiceFeeMatrix/MaskedStatusForActive";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { confirm } from "devextreme/ui/dialog";

interface ProviderServiceGridProps {
    refreshSignal: boolean;
}

// State
interface ProviderServiceGridState {
    venueProviderGridDataSource: VenueProviderGridRowItem[];
    showProgressIndicator: boolean;
    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    serviceFeeLookUp: LookupTypeItem[];
    loadPanelVisible: boolean;
}

// Component - displays the Rate Matrix Grid
class ProviderServiceGrid extends React.Component<ProviderServiceGridProps> {
    //Initialize the component's state.
    state: ProviderServiceGridState;
    venueProviderService: VenueProviderService;
    dropdownService: lookupService;
    gridUtils: gridUtils;
    constructor(props: ProviderServiceGridProps) {
        super(props);
        this.venueProviderService = new VenueProviderService();
        this.dropdownService = new lookupService();
        this.gridUtils = new gridUtils();
        // Initialize state and services/utils
        this.state = {
            venueProviderGridDataSource: [],
            showProgressIndicator: false,
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            serviceFeeLookUp: [],
            loadPanelVisible: false,
        };
    }

    componentDidMount() {
        this.fetchVenueProviderDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: ProviderServiceGridProps) {
        if (this.props.refreshSignal !== prevProps.refreshSignal) {
            // refresh the grid
            this.fetchVenueProviderDataSource();
        }
    }

    fetchVenueProviderDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.venueProviderService.getVenueProviderDataItem().then(this.handleSuccess).catch(this.handleError);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            venueProviderGridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    };

    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    onRowUpdatedInserted = () => {
        this.fetchVenueProviderDataSource();
    };

    handleDeleteOnClick = (id: number) => {
        confirm("<div class='text-center'>Delete this row?</div>", "Delete").then((hasConfirmed) => {
            if (hasConfirmed) {
                this.setState({
                    loadPanelVisible: true,
                });
                //call the API for posting Delete provider Id's and handle the response
                this.venueProviderService
                    .deleteVenueProviderDataItem(id)
                    .then(this.handleSuccessDelete)
                    .catch((error) => {
                        var respMessage: string = "venue service provider failed with response: " + JSON.stringify(error);

                        if (!this.venueProviderService.traceAsErrorToAppInsights(respMessage)) {
                            // AppInsights is not available
                            console.error(respMessage);
                        }
                    });
            }
        });
    };

    handleSuccessDelete = (response: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        this.fetchVenueProviderDataSource();
        
    };

    editDeleteCellRender = (cellInfo: any) => {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">

                        <Link
                            title="Delete"
                            className="icon-btn"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.handleDeleteOnClick(cellInfo.data.id);
                            }}
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    isDelete: true,
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Link>

                        <Link
                            title="Edit"
                            className="icon-btn"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    id: cellInfo.data.id ? cellInfo.data.id.toString() : "",
                                    isEdit: true,
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <> 
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <DataGrid
                            dataSource={this.state.venueProviderGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                        >
                            <Editing>
                                <Texts confirmDeleteMessage=""></Texts>
                            </Editing>
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Sorting mode="single" />
                            <Export enabled={true} allowExportSelectedData={false} fileName="Venue Service Provider" />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <Column
                                dataField="id"
                                allowSorting={true}
                                caption="ID"
                                calculateSortValue={(rowData: VenueProviderGridRowItem) => {
                                    return this.gridUtils.convertNumberAsStringToInteger(rowData.id);
                                }}
                            ></Column>
                            <Column dataField="providerBusinessEntity" allowSorting={true} caption="PROVIDER"></Column>
                            <Column dataField="clientBusinessEntity" allowSorting={true} caption="CLIENT"></Column>
                            <Column dataField="venueName" allowSorting={true} caption="VENUE"></Column>
                            <Column dataField="houseNo" allowSorting={true} caption="HOUSE NO"></Column>
                            <Column dataField="serviceType" allowSorting={true} caption="SERVICE"></Column>
                            <Column dataField="serviceSubType" allowSorting={true} caption="SUB TYPE"></Column>
                            <Column dataField="city" allowSorting={true} caption="TOWN/CITY"></Column>
                            <Column dataField="county" allowSorting={true} caption="COUNTY"></Column>
                            <Column dataField="postCode" allowSorting={true} caption="POST CODE"></Column>
                            <Column
                                dataField="isActive"
                                allowSorting={true}
                                caption="ACTIVE"
                                cellComponent={MaskedStatusForActive}
                            ></Column>
                            <Column
                                dataField="lastUpdatedOn"
                                allowSorting={true}
                                caption="LAST UPDATED"
                                calculateSortValue={(rowData: VenueProviderGridRowItem) => {
                                    return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                                }}
                            ></Column>
                            <Column dataField="lastUpdatedBy" allowSorting={true} caption="LAST UPDATED BY"></Column>
                            <Column cellRender={this.editDeleteCellRender}></Column>
                            <FilterRow visible={true} applyFilter="auto" />
                        </DataGrid>                    
                    </>
                )}
            </div>
        );
    }
}

export default ProviderServiceGrid;
