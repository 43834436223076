import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faDownload } from "@fortawesome/pro-regular-svg-icons";
import ManageDocumentsGridService from "../../../services/ManageDocumentsGridService";
import { AxiosResponse } from "axios";
import { LoadPanel } from "devextreme-react";
import { confirm } from "devextreme/ui/dialog";

interface DocumentActionCellProps {
    data: any;
}

interface DocumentActionCellState {
    filetype: string;
    loadPanelVisible: boolean;
}

class DocumentActionCell extends React.Component<DocumentActionCellProps> {
    state: DocumentActionCellState;
    service: ManageDocumentsGridService;
    constructor(props: DocumentActionCellProps) {
        super(props);
        this.state = {
            filetype: this.props.data.data.formatType,
            loadPanelVisible: false,
        };
        this.service = new ManageDocumentsGridService();
    }

    onDownloadClick = () => {
        this.setState({
            loadPanelVisible: true,
        });

        this.service
            .getDocumentById(this.props.data.data.id)
            .then(this.handleDownloadSuccess)
            .catch(this.handleDownloadError);
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        let link = this.props.data.data.link.split("/");
        let filename = link[link.length - 1];
        var FileSaver = require("file-saver");
        FileSaver.saveAs(response.data, filename);
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleDownloadError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "getDocumentById failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    onDeleteClick = () => {
        confirm("<div class='text-center'>Are you Sure you want to Delete the file</div>", "Delete").then(
            (hasConfirmed) => {
                if (hasConfirmed) {
                    this.onDeleteConfirm();
                }
            }
        );
    };

    onDeleteConfirm = () => {
        this.setState({
            loadPanelVisible: true,
        });

        this.service
            .deleteDocumentById(this.props.data.data.id)
            .then(this.handleDeleteSuccess)
            .catch(this.handleDeleteError);
    };

    handleDeleteSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
        window.location.reload(false);
    };

    handleDeleteError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "deleteDocumentById failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        window.location.reload(false);
    };

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex btn">
                    <FontAwesomeIcon icon={faDownload} onClick={this.onDownloadClick} />
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                </div>
                <div className="mr-2 d-flex btn">
                    <FontAwesomeIcon icon={faTrashAlt} onClick={this.onDeleteClick} />
                </div>
            </div>
        );
    }
}

export default DocumentActionCell;
