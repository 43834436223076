import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, { Pager, Export, Paging, FilterRow, Column, Sorting, SearchPanel, Scrolling } from "devextreme-react/data-grid";
import dxDataGrid from "devextreme/ui/data_grid";
import { LoadIndicator } from "devextreme-react";
import { onGridExportedEvent, onGridExportingEvent } from "../../../types/DevExtremeTypes";
import gridUtils from "../../grid/GridUtilities";
import PaymentService, { PaymentBACSGridRowItem } from "../../../services/PaymentService";
import ActionCell from "./ActionCell";
import UserService, { RoleGroupNames } from "../../../services/UserService";

//props
interface PaymentBACSGridProps {
    refreshSignal: boolean;
    id: string;
    gridType?: string;
    location: any;
    history: any;
}

// State
interface PaymentBACSGridState {
    gridDataSource: PaymentBACSGridRowItem[];
    showProgressIndicator: boolean;
}

// Component - displays the simple Grid for all users
//Grid_Utilities component can be reused here to format the date and Decimal fields.
class PaymentBACSGrid extends React.Component<PaymentBACSGridProps> {
    //Initialize the component's state.
    state: PaymentBACSGridState;
    //Initialize the service to fetch the data.
    Service: PaymentService;
    //Reuse the Grid utils component to format the cells.
    gridUtils: gridUtils;
    userHasAccess: boolean;

    constructor(props: PaymentBACSGridProps) {
        super(props);
        // Initialize state and services/utils
        this.Service = new PaymentService();
        this.gridUtils = new gridUtils();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        this.state = {
            gridDataSource: [],
            showProgressIndicator: false,
        };
        // Functions
        this.updateGridDataSource = this.updateGridDataSource.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    componentDidMount() {
        this.updateGridDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: PaymentBACSGridProps) {
        if (this.props.refreshSignal != prevProps.refreshSignal) {
            // refresh the grid
            this.updateGridDataSource();
        }
    }

    updateGridDataSource() {
        if (this.userHasAccess) {
            const PaymentCycleId = parseInt(this.props.id);
            this.setState({
                showProgressIndicator: true,
            });

            this.Service.getBACSGridRows(PaymentCycleId).then(this.handleSuccess).catch(this.handleError);
        }
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            gridDataSource: response.data.data,
            showProgressIndicator: false,
        },
        () => {
            for(let i=0; i<this.state.gridDataSource.length; i++){
                this.state.gridDataSource[i].paymentCode = "99";
            }            
        });        
    }

    handleError(error: AxiosResponse<any>) {
        // Redirect back to home page on a 401 unauth error
        if (error.request.status === 401) {
            this.props.history.push({
                pathname: "/",
            });
        } else {
            this.setState({
                showProgressIndicator: false,
            });
            var respMessage: string = "getBACSGridRows failed with response: " + JSON.stringify(error);

            if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                // AppInsights is not available
                console.error(respMessage);
            }
        }
    }

    handleExportingEvent(component: dxDataGrid | undefined, onExporting: boolean) {
        if (component) {
            if (onExporting) {
                component.beginUpdate();
                component.option("showColumnHeaders", false);
            } else {
                component.option("showColumnHeaders", true);
                component.endUpdate();
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : this.props.gridType == "30" ? (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        onExporting={(e: onGridExportingEvent) => {
                            this.handleExportingEvent(e.component, false);
                        }}
                        onExported={(e: onGridExportedEvent) => {
                            this.handleExportingEvent(e.component, false);
                        }}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Sorting mode="single" />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="sortCode" allowSorting={true} caption="SORT CODE" />
                        <Column dataField="name" allowSorting={true} caption="BENEFICIARY NAME" />                        
                        <Column dataField="accountNumber" allowSorting={true} caption="ACCOUNT NUMBER" />
                        <Column
                            dataField="amount"
                            allowSorting={true}
                            caption="AMOUNT"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.amount);
                            }}
                        />
                        <Column dataField="reference" allowSorting={true} caption="REFERENCE" />  
                        <Column dataField="paymentCode" allowSorting={true} caption="PAYMENT CODE" />                        
                        <Column caption="ACTION" cellComponent={ActionCell} allowExporting={false} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                ) :
                (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        onExporting={(e: onGridExportingEvent) => {
                            this.handleExportingEvent(e.component, true);
                        }}
                        onExported={(e: onGridExportedEvent) => {
                            this.handleExportingEvent(e.component, false);
                        }}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Sorting mode="single" />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="name" allowSorting={true} caption="NAME" />
                        <Column dataField="sortCode" allowSorting={true} caption="SORT CODE" />
                        <Column dataField="accountNumber" allowSorting={true} caption="ACCOUNT NUMBER" />
                        <Column dataField="reference" allowSorting={true} caption="REFERENCE" />
                        <Column
                            dataField="amount"
                            allowSorting={true}
                            caption="AMOUNT"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.amount);
                            }}
                        />
                        <Column caption="ACTION" cellComponent={ActionCell} allowExporting={false} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default PaymentBACSGrid;
