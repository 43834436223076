import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { alert } from "devextreme/ui/dialog";
import notify from 'devextreme/ui/notify';
import { LoadPanel, TextArea } from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { AxiosResponse } from "axios";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { Link } from "react-router-dom";
import BaseActionCell, { BaseActionCellProps } from "./BaseActionCell";
import { queryTypes } from "../../services/QueryService";

class QueryActionCell extends BaseActionCell {
    constructor(props: BaseActionCellProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.data && this.props.data.data) {
            var disableQueryIcon: boolean = false;
            var queryId: number = 0;
            var statusColumnIndex: number = 0;
            if (this.props.data.data.statusValue) {
                statusColumnIndex = this.props.data.component.getVisibleColumnIndex("statusValue");
            }
            else if (this.props.data.data.status) {
                statusColumnIndex = this.props.data.component.getVisibleColumnIndex("status");
            }
            else if (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value) {
                statusColumnIndex = this.props.data.component.getVisibleColumnIndex("billableStatusLookUp.value");
            }
            if (this.props.data && this.props.data.data.queries && this.props.data.data.queries.length > 0) {
                disableQueryIcon = this.costReportUtils.disableRaiseQueryIcon(this.props.data.data.queries);
                queryId = this.props.data.data.queries[this.props.data.data.queries.length - 1].case_ID;
            }
            else if (this.state.status == "Queried" || (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value) == "Queried") {
                disableQueryIcon = true;
                queryId = this.state.queryId;
            }
            this.setState({
                disableQueryIcon: disableQueryIcon,
                queryId: queryId,
            });

            if (
                this.props.data &&
                (this.props.data.data.itemTypeId == itemTypes.ClientCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderDebit ||
                    this.props.data.data.itemTypeId == itemTypes.Receivable ||
                    this.props.data.data.itemTypeId == itemTypes.Payable)
            ) {
                this.setState({
                    isFinancialAdjustmentView: true,
                });
            }
            if (this.props.data && this.props.data.data && this.props.data.data.editAllowed && this.props.data.data.editAllowed.toLowerCase() == "false") {
                this.setState({
                    isEditIconDisable: true,
                });
            }
            if (
                ((this.props.data.data.itemTypeId == itemTypes.Original ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebit) &&
                    ((this.props.data.data.approvalDate ||
                        this.props.data.data.acceptDate ||
                        this.props.data.data.billDate) ||
                        (this.props.data.data.approvalDate
                            || this.props.data.data.acceptDate
                            || this.props.data.data.billDate))) ||
                this.props.data.data.statusValue == "Approved" || this.props.data.data.statusValue == "Approved" ||
                this.props.data.data.statusValue == "Accepted" || this.props.data.data.statusValue == "Accepted"
            ) {
                this.setState({
                    isAdjustmentChange: true,
                });
            }
            this.setState({
                statusColumnIndexVal: statusColumnIndex,
                queryStatusIndexVal: statusColumnIndex
            });
        }
    }

    handleChangeQueryComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        let billableItemId: string = "";
        let serviceTypeId: string = "";
        let clientId: string = "";
        if (this.props.data.data && this.props.data.data.billableItemId) {
            billableItemId = this.props.data.data.billableItemId.toString();
        }
        else if (this.props.data.data && this.props.data.data.id) {
            billableItemId = this.props.data.data.id.toString();
        }
        if (this.props.data.data && this.props.data.data.clientId) {
            clientId = this.props.data.data.clientId;
        }
        else if (this.props.data.data && this.props.data.data.clientBusinessEntity && this.props.data.data.clientBusinessEntity.id) {
            clientId = this.props.data.data.clientBusinessEntity.id;
        }

        if (this.props.data.data && this.props.data.data.serviceTypeId) {
            serviceTypeId = this.props.data.data.serviceTypeId;
        }
        else if (this.props.data.data && this.props.data.data.serviceTypeLookUpId) {
            serviceTypeId = this.props.data.data.serviceTypeLookUpId;
        }
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
                clientId: clientId,
                venueId: this.props.data.data.venueId,
                serviceTypeId: serviceTypeId,
                billableItemId: billableItemId,
                navigateFrom: "webApp",
                queryTypeLookupID: queryTypes.BillableItem
            },
        });
    };

    handleQueryOnClick = () => {
        this.setState({
            onQueryRaising: true,
        });
    };

    updateStatusColumn = (value: string) => {
        this.props.data.component.cellValue(
            this.props.data.rowIndex,
            this.state.queryStatusIndexVal,
            value
        );
    }

    onQuerySubmission = () => {
        let queryDescriptionAdded: boolean = false;
        queryDescriptionAdded = this.sharedUtils.validationCheckFunction(this.state.queryItem.queryDescription);
        if (!queryDescriptionAdded) {
            alert("Please enter the query before pushing the submit button", "Raise Query");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            this.queryService
                .uploadQueryData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    handleQuerySubmissionSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            onQueryRaising: false,
            disableQueryIcon: true,
            queryId: parseInt(response.data)
        },()=>{this.updateStatusColumn(response.data.data.value)});
        notify({ message: "The Query has been submitted, an Event Uk staff will be in touch soon. Thanks!", width: 500, shading: true }, 'success', 600);
    };

    handleQuerySubmissionFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
        notify({ message: "The Query has been failed", width: 300, shading: true }, 'error', 600);
    };

    //Helper function to close the Query popup.
    closeQueryBox = () => {
        this.setState({
            onQueryRaising: false,
        });
    };
    render() {
        var { disableQueryIcon } = this.state;
        return (
            <>
                <div className="d-flex justify-content-between align-items-center datagrid__queried-item-row ">
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        message="Please wait..."
                        visible={this.state.loadPanelVisible}
                    />

                    <div>
                        {" "}
                        {disableQueryIcon ? (
                            <Link
                                className="approval-query-column"
                                to={{
                                    pathname: "/queryGrid",
                                    state: {
                                        queryId:this.state.queryId,
                                        navigateFrom : "shiftMatrix",
                                    },
                                }}
                            >
                                Queried
                            </Link>
                        ) : (
                            <button className={"btn icon-btn"} onClick={this.handleQueryOnClick}>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </button>
                        )}
                    </div>
                </div>
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.onQueryRaising}
                    onHiding={this.closeQueryBox}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Raise Query"
                    maxWidth="500px"
                >
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="pending-request-panel__raise-query">
                        <div className="pending-request-panel__query-box">
                            <TextArea
                                height="100%"
                                placeholder="Please enter your query here and select Submit Query button - a member of the Event UK staff will be in touch"
                                onValueChanged={this.handleChangeQueryComments}
                            />

                            <button className="btn btn-primary btn--full-width" onClick={this.onQuerySubmission}>
                                Submit Query
                            </button>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }
}

export default QueryActionCell;
