import React, { MouseEvent, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import FavouriteButton from "./favouriteButton";
import FindAnArtistService, { showCasePanel } from "../../services/FindAnArtistService";
import { AxiosResponse } from "axios";
import { LoadIndicator, Popup, ScrollView } from "devextreme-react";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import { faHeart as faHeartOutline } from "@fortawesome/pro-regular-svg-icons";

interface ShowcaseItemProps {
    url: string;
    artistName: string;
    businessEntityId: string;
    setCloseButtonState: (businessEntityID: string) => void;
    venueFavourite: string;
}

interface ShowcaseItemState {
    venueFavourite: string;
    showProgressIndicator: boolean;
    popupVisible: boolean;
    listOfBusinessEntity: string[];
}

class ShowcaseItem extends React.Component<ShowcaseItemProps> {
    state: ShowcaseItemState;
    service: FindAnArtistService;
    constructor(props: ShowcaseItemProps) {
        super(props);
        this.service = new FindAnArtistService();
        this.state = {
            venueFavourite: "",
            showProgressIndicator: false,
            popupVisible: false,
            listOfBusinessEntity: [],
        };
    }

    selectFavorite = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.service.toggleFavorite(this.props.businessEntityId).then(this.handleSuccess).catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        this.setState({
            venueFavourite: response.data.toString(),
        });
        setTimeout(function () {}, 1000);
    };

    handleFailure = (error: any) => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    showInfo = (e: any) => {
        e.preventDefault();
        this.setState({
            popupVisible: true,
        });
    };
    hideInfo = () => {
        this.setState({
            popupVisible: false,
        });
    };

    onClickCloseButton = () => {
        this.props.setCloseButtonState(this.props.businessEntityId);
    };

    renderLinkError = () => {
        if(this.props.url == "Not match"){
            return(
                <div className="configurationText_Color text-center">
                    <b>Sorry! Link to Video appears to be incorrect - please check.</b>
                </div>
            )
        }        
    }
    render() {
        return (
            <article className="showcase-item">
                <header className="showcase-item__header">
                    <div className="showcase-item__name">
                        <a className="showcase-item__hyperlink" href="#" onClick={this.showInfo}>
                            {this.props.artistName}
                        </a>
                    </div>
                    <div className="showcase-item__controls">
                        <a href="#" className="icon-btn">
                            <span className="sr-only">View details for {this.props.artistName}</span>
                            {this.props.artistName ? (
                                <button className="btn icon-btn" onClick={this.showInfo}>
                                    <FontAwesomeIcon icon={faUser} />
                                </button>
                            ) : null}
                        </a>
                        {this.props.artistName ? (
                            this.state.showProgressIndicator ? (
                                <LoadIndicator
                                    id="simple-grid-indicator"
                                    height={20}
                                    width={25}
                                    visible={this.state.showProgressIndicator}
                                />
                            ) : (
                                <button
                                    className="artist-card__favourite-button btn icon-btn"
                                    onClick={this.selectFavorite}
                                >
                                    {this.state.venueFavourite.toLowerCase() == "true" ||
                                    (this.props.venueFavourite.toLowerCase() == "true" &&
                                        this.state.venueFavourite.toLowerCase() == "") ? (
                                        <FontAwesomeIcon icon={faHeart} />
                                    ) : (
                                        <FontAwesomeIcon icon={faHeartOutline} />
                                    )}
                                </button>
                            )
                        ) : null}
                        <button className="btn icon-btn" onClick={this.onClickCloseButton}>
                            {this.props.artistName ? <FontAwesomeIcon icon={faTimes} /> : null}
                        </button>
                    </div>
                </header>
                {this.props.url && this.props.url !== "Not match" ? (
                    <section>
                        <iframe
                            width="100%"
                            height="auto"
                            src={this.props.url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </section>
                ) : (
                    this.renderLinkError()
                )} 
                {this.state.popupVisible ? (
                    <Popup
                        visible={this.state.popupVisible}
                        onHiding={this.hideInfo}
                        dragEnabled={false}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        showCloseButton={true}
                        title="Artist Details"
                        width={"90%"}
                        height={600}
                    >
                        <ScrollView width="100%" height="100%">
                            <ArtistProfileComponent
                                mode="view"
                                id={this.props.businessEntityId}
                                handlePopUpClose = {()=>{return null}}
                                handleFeedbackUpdate = {()=>{return null}}
                                handleAddArtist = {()=>{return null}}
                                serviceType={"Entertainment"}
                            ></ArtistProfileComponent>
                        </ScrollView>
                    </Popup>
                ) : null}
            </article>
        );
    }
}

export default ShowcaseItem;
