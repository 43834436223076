import React, { Component, MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import DataGrid, { Paging, HeaderFilter, FilterRow, Column, Export, FilterPanel, GroupPanel, Pager, SearchPanel, Scrolling } from "devextreme-react/data-grid";
import { LoadPanel, TextArea, ScrollView, Popup } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import sharedUtils from "../grid/sharedUtilities";
import QueryService from "../../services/QueryService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons"
import queryUtils, { QueryFormObject } from "../Query/QueryUtils";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { alert,confirm } from "devextreme/ui/dialog";
import { faCheckDouble, faEye, faPen, faSquare } from "@fortawesome/pro-regular-svg-icons";
import ArtistProfileComponent  from "../Artist-Profile/ArtistProfileComponent";
import TagCell from "../GigRequest/TagCell";


interface SignUpProfileUpdateGridProps extends WithTranslation {
    statusId: string;
}

interface SignUpProfileUpdateGridState {
    dataSource: [],
    titlePopupVisible: boolean;
    title: string;
    internalCommentsPopupVisible: boolean;
    internalComments: string;
    resolutionReasonPopUpVisible: boolean;
    resolutionReason: string;
    onQueryRaising: boolean;
    loadPanelVisible: boolean;
    resolveQueryItem: QueryFormObject;
    loadIndicatorForGrid: boolean;
    popupVisible: boolean;
    artistCardId:string;
    descriptionPopupVisible: boolean;
    description: string;
}

class SignUpProfileUpdateGrid extends Component<SignUpProfileUpdateGridProps> {
    state: SignUpProfileUpdateGridState;
    queryService: QueryService;
    sharedUtils: sharedUtils;
    queryUtils: queryUtils;
    remoteOperations: {
        sorting: boolean;
        paging: boolean;
        filtering: boolean;
        grouping: boolean;
        summary: boolean;
    }
    isEditMode: string;
    constructor(props: SignUpProfileUpdateGridProps) {
        super(props);
        this.isEditMode = "";
        this.queryService = new QueryService();
        this.sharedUtils = new sharedUtils();
        this.queryUtils = new queryUtils();
        this.remoteOperations = {
            sorting: true,
            filtering: true,
            grouping: true,
            paging: true,
            summary: false,
        }
        var convertedResolveQueryFormData = this.queryUtils.initializeQueryItem(
            ""
        );
        this.state = {
            dataSource: [],
            titlePopupVisible: false,
            title: "",
            internalCommentsPopupVisible: false,
            internalComments: "",
            resolutionReasonPopUpVisible: false,
            resolutionReason: "",
            onQueryRaising: false,
            loadPanelVisible: false,
            resolveQueryItem: convertedResolveQueryFormData,
            loadIndicatorForGrid: false,
            popupVisible: false,
            artistCardId:"",
            descriptionPopupVisible: false,
            description: "",
        }

    }

    getResults = (loadOptions: any) => {
        let params = buildRequestParameters(loadOptions);
        if(params){
            params += '&'
        } else {
            params += '?'
        }
        params += `serviceTypeId=${""}&status=${this.props.statusId}&clientId=${""}&venueId=${""}&navigateFrom=${"manageSignUpGrid"}`;
        // if (params) {
        //     if(params !== "") params = params + `&serviceTypeId=${""}&status=${this.props.statusId}&clientId=${""}&venueId=${""}&navigateFrom=${"manageSignUpGrid"}`;
        //     else params = params + `?serviceTypeId=${""}&status=${this.props.statusId}&clientId=${""}&venueId=${""}&navigateFrom=${"manageSignUpGrid"}`;
        // }
        // else {
        //     params = `?serviceTypeId=${""}&status=${this.props.statusId}&clientId=${""}&venueId=${""}&navigateFrom=${"manageSignUpGrid"}`;
        // }
        return this.queryService.getQueryGridRowsWithinRemoteOperations(params)
            .then((response) => {
                return this.handleSuccessDataSource(response)
            })
            .catch((ex) => { throw ex });

    }

    handleSuccessDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });
        return {
            data: response.data.data ? response.data.data : [],
            totalCount: response.data.totalCount
        };
    }
    componentDidMount() {
        this.setState({
            loadIndicatorForGrid: true
        });
    }

    componentDidUpdate(prevpros: SignUpProfileUpdateGridProps) {
        if (prevpros.statusId != this.props.statusId) {
            this.loadDataSource();
        }
        else {
            return false;
        }
    }


    loadDataSource = () => {
        let dataSource = new CustomStore({
            key: "case_ID",
            load: this.getResults,
        });
        this.setState({
            dataSource: dataSource
        });

    }
    hideInfo = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        popupVisible: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                popupVisible: false,
            });
        }
    };

    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            popupVisible: flag,
        });
    }

    setBusinessEntityId = (id: string) => {
        if (id) {
            this.setState({
                artistCardId: id, 
                popupVisible: true
            });
        }
    }

    internalCommentsCellDisplayContent = (cellInfo: any) => {
        var internalComments: string = "";
        if (cellInfo.data.internal_Comments && cellInfo.data.internal_Comments.length > 16) {
            internalComments = `${cellInfo.data.internal_Comments.substring(0, 16)}...`;
        }
        else if (cellInfo.data.internal_Comments) {
            internalComments = cellInfo.data.internal_Comments;
        }
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoInternalComments(cellInfo.data.internal_Comments);
                    }}
                    className="approval-query-column"
                    to="#"

                >
                    {internalComments}
                </Link>
            </div>
        );
    };
    resolutionReasonCellDisplayContent = (cellInfo: any) => {
        var resolutionReason: string = "";
        if (cellInfo.data.resolution_Reason && cellInfo.data.resolution_Reason.length > 16) {
            resolutionReason = `${cellInfo.data.resolution_Reason.substring(0, 16)}...`;
        }
        else if (cellInfo.data.resolution_Reason) {
            resolutionReason = cellInfo.data.resolution_Reason;
        }
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoResolutionReason(cellInfo.data.resolution_Reason);
                    }}
                    className="approval-query-column"
                    to="#"

                >
                    {resolutionReason}
                </Link>
            </div>
        );
    };
    showInfoTitle = (title: string) => {
        this.setState({
            titlePopupVisible: true,
            title: title,
        });
    };

    hideInfoTitle = () => {
        this.setState({
            titlePopupVisible: false
        });
    };
    showInfoInternalComments = (comments: string) => {
        this.setState({
            internalCommentsPopupVisible: true,
            internalComments: comments
        });
    };

    hideInfoInternalComments = () => {
        this.setState({
            internalCommentsPopupVisible: false
        });
    };
    showInfoResolutionReason = (reason: string) => {
        this.setState({
            resolutionReasonPopUpVisible: true,
            resolutionReason: reason
        });
    };

    hideInfoResolutionReason = () => {
        this.setState({
            resolutionReasonPopUpVisible: false
        });
    };

    //Helper function to close the Query popup.
    closeQueryBox = () => {
        this.setState({
            onQueryRaising: false
        });
    };
    handleQueryOnClick = (rowInfo: any) => {
        if (rowInfo.data.caseStatusLU !== null && rowInfo.data.caseStatusLU?.case_Status_Value && rowInfo.data.caseStatusLU?.case_Status_Value.toLowerCase() != "resolved") {
            this.setState({
                resolveQueryItem: {
                    ...this.state.resolveQueryItem,
                    id: rowInfo.data.case_ID,
                    resolutionReason: rowInfo.data.resolution_Reason
                }
            }, () => { this.showQueryPopUp() });
        }
    };

    showQueryPopUp = () => {
        this.setState({
            onQueryRaising: true
        });
    }
    onQuerySubmission = () => {
        if (!this.state.resolveQueryItem.resolutionReason) {
            alert("Please enter the resolution reason before pushing the submit button", "Resolve Query");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            var convertedresolveQueryItem = this.queryUtils.convertFormDataToQueryRequest(this.state.resolveQueryItem, "");
            this.queryService.postResolveQueryRequestFormData(convertedresolveQueryItem)
                .then(() => this.handleQuerySubmissionSuccess())
                .catch(this.handleQuerySubmissionFailure);
        }
    }

    handleQuerySubmissionSuccess = () => {
        this.setState({
            loadPanelVisible: false
        });
        this.closeQueryBox();
        alert(
            "Thank you - Query Resolved",
            "Query Resolved"
        );
        setTimeout(this.loadDataSource, 2000);

    };

    handleQuerySubmissionFailure = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleChangeQueryComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.value != undefined) {
            this.setState({
                resolveQueryItem: {
                    ...this.state.resolveQueryItem,
                    resolutionReason: dxValueChange.value,
                }
            });
        }
    }
    queryDescriptionCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoQueryDescription(cellInfo.data.query_Description);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.query_Description
                        ? cellInfo.data.query_Description.length > 16
                            ? `${cellInfo.data.query_Description.substring(0, 16)}...`
                            : cellInfo.data.query_Description
                        : ""}
                </Link>
            </div>
        );
    };
    profileDisplay = (cellInfo: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <button
                    className={"btn icon-btn"}
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.setBusinessEntityId(cellInfo.data.businessEntity?.id);
                    }}
                >
                    <FontAwesomeIcon icon={faUser} />
                </button>
            </div>
        );
    }
    resolveDisplay = (cellInfo: any) => {
        let iconToShow: any;
        if (cellInfo.data.caseStatusLU !== null && cellInfo.data.caseStatusLU?.case_Status_Value && cellInfo.data.caseStatusLU?.case_Status_Value.toLowerCase() == "resolved") {
            iconToShow = faCheckDouble;
        }
        else {
            iconToShow = faSquare;
        }
        return (
            <div className="d-flex justify-content-between align-items-center">
                <button
                    className={"btn icon-btn"}
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.handleQueryOnClick(cellInfo);
                    }}
                >
                    <FontAwesomeIcon icon={iconToShow} />
                </button>
            </div>
        );
    }
    editDisplayContent = (cellInfo: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <Link title="Edit"
                    className={"icon-btn"}
                    to={{
                        pathname: "/QueryManageForm",
                        state: {
                            id: cellInfo.data.case_ID,
                            isView: false,
                            pointOfNavigation: "signupProfileGrid"
                        },
                    }}
                >
                    <FontAwesomeIcon icon={faPen} /></Link>
            </div>
        );
    }
    viewDisplayContent = (cellInfo: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <Link title="View"
                    className={"icon-btn"}
                    to={{
                        pathname: "/QueryManageForm",
                        state: {
                            id: cellInfo.data.case_ID,
                            isView: true,
                            pointOfNavigation: "signupProfileGrid"
                        },
                    }}
                >
                    <FontAwesomeIcon icon={faEye} /></Link>
            </div>
        );
    }
    createdDateTimeDisplay = (cellInfo: any) => {
        let termsAgreedDateTimeLocal = new Date(cellInfo.data?.date_Created);
        let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(termsAgreedDateTimeLocal.toString());
        let formattedDate = this.sharedUtils.convertDateToString(termsAgreedDateTimeLocal);
        let finalFormattedDate = `${formattedDate} ${formattedTime}`;
        return (
            <div>
                {cellInfo.data?.date_Created !== null && cellInfo.data?.date_Created !== ""
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };
    lastUpdatedDateTimeDisplay = (cellInfo: any) => {
        let termsAgreedDateTimeLocal = new Date(cellInfo.data?.lastUpdated_DateTime);
        let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(termsAgreedDateTimeLocal.toString());
        let formattedDate = this.sharedUtils.convertDateToString(termsAgreedDateTimeLocal);
        let finalFormattedDate = `${formattedDate} ${formattedTime}`;
        return (
            <div>
                {cellInfo.data?.lastUpdated_DateTime !== null && cellInfo.data?.lastUpdated_DateTime !== ""
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };
    showInfoQueryDescription = (description: string) => {
        this.setState({
            descriptionPopupVisible: true,
            description: description,
        });
    };
    hideInfoQueryDescription = () => {
        this.setState({
            descriptionPopupVisible: false,
        });
    };    
    render() {
        return (
            <>
                <LightModePageTemplate>
                    <DataGrid
                        elementAttr={{
                            id: "gridContainer"
                        }}
                        dataSource={this.state.dataSource}
                        showBorders={false}
                        showColumnLines={false}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        columnResizingMode={"widget"}
                        hoverStateEnabled={true}
                        remoteOperations={this.remoteOperations}
                        noDataText={"No queries found for the filters used"}
                        columnMinWidth={120}
                    >
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                        <Export enabled={true} />
                        <FilterRow visible={true} applyFilter="auto" />
                        <GroupPanel visible={true} />
                        <FilterPanel visible={true} />
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Column
                            caption="PROFILE"
                            allowExporting={false}
                            cellRender={this.profileDisplay}
                        />
                        <Column
                            caption="RESOLVE?"
                            allowExporting={false}
                            cellRender={this.resolveDisplay}
                        />
                        <Column
                            caption="TAG ME"
                            allowExporting={false}                            
                            cellComponent={TagCell}
                        ></Column>
                        <Column
                            dataField="taggedUser.name"
                            caption="TAGGED"
                        />
                        <Column
                            dataField="caseStatusLU.case_Status_Value"
                            caption="STATUS"
                        />
                        <Column
                            dataField="queryTypeLookUp.value"
                            caption="TYPE"
                        />
                        <Column
                            dataField="date_Created"
                            caption="TIME RAISED"
                            dataType="date"
                            cellRender={this.createdDateTimeDisplay}
                        />
                        <Column
                            dataField="businessEntity.shortName"
                            caption="ARTIST"
                        />
                        <Column
                            dataField="businessEntity.professionalName"
                            caption="ACT NAME"
                        /> 
                        <Column
                            dataField="query_Description"
                            caption="QUERY DESCRIPTION"
                            cellRender={this.queryDescriptionCellDisplayContent}                            
                        />
                        <Column
                            dataField="resolution_Reason"
                            caption="RESOLUTION REASON"
                            cellRender={this.resolutionReasonCellDisplayContent}
                        />
                        <Column
                            dataField="internal_Comments"
                            caption="INTERNAL COMMENTS"
                            cellRender={this.internalCommentsCellDisplayContent}
                        />
                        <Column
                            dataField="raisedByContactName"
                            caption="RAISED BY"
                        />
                        <Column
                            dataField="raisedByContactTelNumber"
                            caption="CONTACT NO"
                        />
                        <Column
                            dataField="lastUpdated_DateTime"
                            caption="LAST UPDATED" 
                            dataType="date"
                            cellRender={this.lastUpdatedDateTimeDisplay}
                        />
                        <Column
                            dataField="user.name"
                            caption="UPDATED BY"
                        />
                        <Column
                            dataField="case_ID"
                            caption="QUERY ID"
                        />
                        <Column
                            caption="CHANGE"
                            allowExporting={false}
                            cellRender={this.editDisplayContent}
                        />
                        <Column
                            caption="VIEW"
                            allowExporting={false}
                            cellRender={this.viewDisplayContent}
                        />
                    </DataGrid>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadIndicatorForGrid} />
                </LightModePageTemplate>
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.onQueryRaising}
                    onHiding={this.closeQueryBox}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Resolve Query?"
                    maxWidth="500px"
                >
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="pending-request-panel__raise-query">
                        <div className="pending-request-panel__query-box">
                            <TextArea
                                height="100%"
                                placeholder="Please enter a resolution reason......"
                                onValueChanged={this.handleChangeQueryComments}
                                value={this.state.resolveQueryItem.resolutionReason}
                            />

                            <button className="btn btn-primary btn--full-width"
                                onClick={this.onQuerySubmission}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </Popup>
                <Popup
                    visible={this.state.internalCommentsPopupVisible}
                    onHiding={this.hideInfoInternalComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"INTERNAL COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.internalComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.resolutionReasonPopUpVisible}
                    onHiding={this.hideInfoResolutionReason}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"RESOLUTION REASON"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.resolutionReason}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.descriptionPopupVisible}
                    onHiding={this.hideInfoQueryDescription}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"QUERY DESCRIPTION"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.description}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                {this.state.popupVisible ? (
                    <Popup
                        visible={this.state.popupVisible}
                        onHiding={this.hideInfo}
                        dragEnabled={false}
                        closeOnOutsideClick={false}
                        showTitle={true}
                        showCloseButton={true}
                        title="Artist Details"
                        resizeEnabled={true}
                    >
                        <ScrollView width="100%" height="100%">
                            <ArtistProfileComponent
                                mode="view"
                                id={this.state.artistCardId.toString()}
                                handlePopUpClose={this.handlePopUpClose}
                                businessEntityId={this.state.artistCardId}
                                handleFeedbackUpdate = {()=>{return null}}
                                handleAddArtist = {()=>{return null}}
                                serviceType={"Entertainment"}
                            ></ArtistProfileComponent>
                        </ScrollView>
                    </Popup>
                ) : null}
            </>

        );
    }
}

export default withTranslation()(SignUpProfileUpdateGrid);