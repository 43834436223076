import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, { Pager, Export, Paging, FilterRow, Column, Sorting, SearchPanel, Scrolling } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import gridUtils from "../../grid/GridUtilities";
import { PaymentBACSGridRowItem } from "../../../services/PaymentService";
import ManageDocumentsGridService from "../../../services/ManageDocumentsGridService";
import DocumentActionCell from "./DocumentActionCellComponent";

//props
interface ManageDocumentsGridProps {
    refreshSignal: boolean;
    invoiceId: any;
    paymentId: any;
}

// State
interface ManageDocumentsGridState {
    gridDataSource: PaymentBACSGridRowItem[];
    showProgressIndicator: boolean;
}

// Component - displays the simple Grid for all users
//Grid_Utilities component can be reused here to format the date and Decimal fields.
class ManageDocumentsGrid extends React.Component<ManageDocumentsGridProps> {
    //Initialize the component's state.
    state: ManageDocumentsGridState;
    //Initialize the service to fetch the data.
    Service: ManageDocumentsGridService; // Replace this service initialization.
    //Reuse the Grid utils component to format the cells.
    gridUtils: gridUtils;

    constructor(props: ManageDocumentsGridProps) {
        super(props);
        // Initialize state and services/utils
        this.Service = new ManageDocumentsGridService();
        this.gridUtils = new gridUtils();
        this.state = {
            gridDataSource: [],
            showProgressIndicator: false,
        };
        // Functions
        this.updateGridDataSource = this.updateGridDataSource.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    componentDidMount() {
        this.updateGridDataSource();
    }

    updateGridDataSource() {
        this.setState({
            showProgressIndicator: true,
        });
        if (!this.isBlank(this.props.invoiceId)) {
            this.Service.getDocumentByInvoiceId(this.props.invoiceId).then(this.handleSuccess).catch(this.handleError);
        }
        if (!this.isBlank(this.props.paymentId)) {
            this.Service.getDocumentByPaymentId(this.props.paymentId).then(this.handleSuccess).catch(this.handleError);
        }
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            gridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    }

    //Use this function as the standard error point for all the non resolved promises.
    handleError(error: AxiosResponse<any>) {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "getDocument service failed with response: " + JSON.stringify(error);

        if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }
    isBlank(filterparam: string) {
        return !filterparam || /^\s*$/.test(filterparam) || 0 === filterparam.length;
    }
    render() {
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Sorting mode="single" />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="formatType" allowSorting={true} caption="FORMAT TYPE" />
                        <Column dataField="documentType" allowSorting={true} caption="DOC TYPE" />
                        <Column
                            dataField="dateFrom"
                            allowSorting={true}
                            caption="DATE FROM"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateFrom);
                            }}
                        />
                        <Column
                            dataField="dateTo"
                            allowSorting={true}
                            caption="DATE TO"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                            }}
                        />
                        <Column
                            dataField="updatedOn"
                            allowSorting={true}
                            caption="UPDATED DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.updatedOn);
                            }}
                        />
                        <Column dataField="updatedBy" allowSorting={true} caption="UPDATED BY" />
                        <Column dataField="link" caption="LINK" visible={false} />
                        <Column caption="EDIT" cellComponent={DocumentActionCell} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default ManageDocumentsGrid;
