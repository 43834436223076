import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LightModePageTemplate from "./Templates/LightModeTemplate";

class VersionPage extends Component<RouteComponentProps> {
    render() {
        return (
            <LightModePageTemplate>
                <dl className="ml-5">
                    <dt>Version: </dt>
                    <dd>[AIV]{`{version}`}[/AIV]</dd>
                </dl>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(VersionPage);
