import React, { MouseEvent } from "react";
import gridUtils from "../../grid/GridUtilities";
import { AxiosResponse } from "axios";
import { alert, confirm } from "devextreme/ui/dialog";
import { TextArea, LoadPanel, ScrollView, SelectBox } from "devextreme-react";
import { Link } from "react-router-dom";
import { Popup } from "devextreme-react/popup";
import pendingRequestUtilities from "./PendingRequestUtilities";
import PendingRequestService, {
    PendingRequestItemResponse,
    requestTypes,
    RaiseQueryObject,
    updateRequest,
    statusTypes,
} from "../../../services/PendingRequestService";
import { SelectBoxOnValueChangedEvent, TextBoxOnValueChangedEvent } from "../../../types/DevExtremeTypes";
import UserService, { RoleGroupNames } from "../../../services/UserService";
import LargeLightCardWrapper from "../../Cards/LargeLightCardWrapper";
import { queryTypeLookup } from "../../../services/AddEditPopUpService";
import sharedUtils from "../../grid/sharedUtilities";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../../services/LookupService";
import FindAnArtistUtils from "../FindAnArtistUtils";

interface PendingRequestCardProps {
    cardData: PendingRequestItemResponse;
    renderCards: (requestId: string, dateFrom: string) => void;
}

interface PendingRequestCardState {
    alterButtonState: boolean;
    showProgressIndicator: boolean;
    raisingQuery: boolean;
    queryItem: RaiseQueryObject;
    pendingRequestCardData: PendingRequestItemResponse;
    loadPanelVisible: boolean;
    isCancelRequestPopupvisible: boolean;
    cancellationReasonLookUp: LookupTypeItem[];
    cancellationRequestData: updateRequest;
}

class PendingRequestCard extends React.Component<PendingRequestCardProps> {
    utils: pendingRequestUtilities;
    findAnArtistutils: FindAnArtistUtils;
    sharedUtils: sharedUtils;
    gridUtils: gridUtils;
    service: PendingRequestService;
    state: PendingRequestCardState;
    lookUpService: LookupService;
    constructor(props: PendingRequestCardProps) {
        super(props);
        this.utils = new pendingRequestUtilities();
        this.sharedUtils = new sharedUtils();
        this.lookUpService = new LookupService();
        this.findAnArtistutils = new FindAnArtistUtils();
        let convertedQueryFormData = this.utils.initializeQueryFormData(
            this.props.cardData,
            queryTypeLookup.PendingRequest
        );
        let convertedCancellationFormData = this.findAnArtistutils.initializeCancellationData();
        this.state = {
            alterButtonState: false,
            showProgressIndicator: false,
            raisingQuery: false,
            queryItem: convertedQueryFormData,
            pendingRequestCardData: this.props.cardData,
            loadPanelVisible: false,
            isCancelRequestPopupvisible: false,
            cancellationReasonLookUp: [],
            cancellationRequestData: convertedCancellationFormData,
        };
        this.gridUtils = new gridUtils();
        this.service = new PendingRequestService();
    }

    componentDidMount() {
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.cancellationReason)
            .then(this.handleCancellationLookUpSuccess)
            .catch(this.handleError);
    }

    handleError = (error: any) => {
        let respMessage: string = "loading of cancellation lookup failed " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    handleCancellationLookUpSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data && response.data.data && response.data.data.length > 0) {
            let cancellationDropDown = this.findAnArtistutils.lookUpCreation(response);
            this.setState({
                cancellationReasonLookUp: cancellationDropDown
            });
        }
    }

    closeQuery = () => {
        this.setState({ raisingQuery: false });
    };

    raiseQuery = () => {
        this.setState({ raisingQuery: true });
    };

    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
            },
        });
    };


    onCancelRequestConfirmation = () => {
        this.setState({
            loadPanelVisible: true,
        });
        let updatedRequestObject: updateRequest = {
            requestStatusValue: statusTypes.Cancelled,
            cancellationreasonId: this.state.cancellationRequestData.cancellationreasonId,
            rePublishGigRequest: this.state.cancellationRequestData.rePublishGigRequest
        };
        this.service
            .updateRequestData(this.state.pendingRequestCardData.id, updatedRequestObject)
            .then(this.handleCancelRequestSuccess)
            .catch(this.handleFailure);
    };
    handleFailure = (error: any) => {
        let respMessage: string = "updateRequestData service failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    handleCancelRequestSuccess = () => {
        this.setState({
            loadPanelVisible: false,
        });
        this.hideCancelRequestPopup();
        this.props.renderCards(this.state.pendingRequestCardData.id, this.state.pendingRequestCardData.dateFrom);
        alert("The Card has been removed successfully from the view", "Request Cancelled");
    };

    handleRequestSuccess = (response: AxiosResponse<any>) => {
        this.props.renderCards(this.state.pendingRequestCardData.id, this.state.pendingRequestCardData.dateFrom);
        alert("The Card has been removed successfully from the view ✔", "Request Booked");
    };

    onBookRequest = () => {
        confirm(
            "<div class='text-center'>Please confirm you have created a separate booking for this request</div>",
            "Book Request"
        ).then((hasConfirmed) => {
            if (hasConfirmed) {
                //call the API for posting the object and handle the response.
                this.onBookRequestConfirmation();
            }
        });
    };

    onBookRequestConfirmation = () => {
        let updatedRequestObject: updateRequest = {
            requestStatusValue: statusTypes.Booked,
            cancellationreasonId: "",
            rePublishGigRequest: null
        };
        this.service
            .updateRequestData(this.state.pendingRequestCardData.id, updatedRequestObject)
            .then(this.handleRequestSuccess)
            .catch(this.handleFailure);
    };

    onSubmitQuery = () => {
        let queryDescriptionAdded: boolean = false;
        queryDescriptionAdded = this.sharedUtils.validationCheckFunction(this.state.queryItem.queryDescription);
        if (!queryDescriptionAdded) {
            alert("Please enter the query before pushing the submit button", "Raise Query");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            this.service
                .uploadQueryData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    handleQuerySubmissionSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            raisingQuery: false,
        });
        alert("The Query has been submitted, an Event Uk staff will be in touch soon. Thanks!", "Query Submitted");
    };

    handleQuerySubmissionFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    clearErrorMessage = () => {
        this.setState({
            errorMessage: "",
        });
    };

    showCancelRequestPopup = () => {
        this.setState({
            isCancelRequestPopupvisible: true
        });
    }

    hideCancelRequestPopup = () => {
        this.setState({
            isCancelRequestPopupvisible: false
        });
    }

    handleChangeCancellationRequest = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState(
            {
                cancellationRequestData: {
                    ...this.state.cancellationRequestData,
                    cancellationreasonId: dxValueChange.value,
                },
            });
    }

    render() {
        let isEventUk =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        let buttonText: string = isEventUk ? "Manage / Book" : "View Request";
        const separator: string = ", ";
        let setsInformation: string = " min sets";
        const delimeter: string = "-";
        const {
            townCity,
            venueName,
            venuePostCode,
            dateFrom,
            serviceSubType,
            budget,
            typeLookUp,
            startTime,
            finishTime,
            performanceInformationSetsNumber,
            performanceInformationSetsDuration,
            genre,
            additionalInformation,
            equipmentRequired,
            workerName,
            workerEmail,
            workerPhone,
            requestDate,
            requestType,
            requestStatusValue,
            lastUpdatedOn,
        } = this.state.pendingRequestCardData;
        const venue: string = `${venueName}${separator}${townCity}`;
        const requestBudget: string = `${budget}`;
        let requestTimings: string = finishTime ? `${startTime}${delimeter}${finishTime}` : `${startTime}`;
        const performanceInformation: string =
            performanceInformationSetsNumber && performanceInformationSetsDuration
                ? `${performanceInformationSetsNumber} ⨯ ${performanceInformationSetsDuration}${setsInformation}`
                : "";
        let workerInformation: string =
            requestType.trim().toLowerCase() == requestTypes.FindAnArtist
                ? ""
                : `${workerName}${separator}${workerEmail}${separator}${workerPhone}`;
        let hideButtonAsPerType: boolean = requestType.trim().toLowerCase() == requestTypes.FindAnArtist ? true : false;
        return (
            <section>
                <div className="mt-5">
                    <LargeLightCardWrapper
                        hasHeader={false}
                        type="security"
                        typeTitle="Security - Door supervision Equipment"
                        enumeration={false}
                        leftSection={
                            <>
                                <div className="row pending-request-panel__details">
                                    <div className="col-12 col-xl-5">
                                        <span className="pending-request-panel__title">{venue}</span>
                                        {venuePostCode}
                                    </div>
                                    <div className="col-12 col-xl-3 mt-3 mt-lg-0">
                                        <span className="pending-request-panel__title">{dateFrom}</span>
                                        {requestTimings}
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-2 mt-3 mt-lg-0">
                                        <span className="pending-request-panel__title">{serviceSubType}</span>
                                        {typeLookUp ? typeLookUp : ""}
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-2 mt-3 mt-lg-0">
                                        <span className="pending-request-panel__title">
                                            {this.sharedUtils.thousandsSeparator(requestBudget)}
                                        </span>
                                        Budget
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="pending-request-panel__description">
                                            {performanceInformation ? <div>{performanceInformation}</div> : ""}
                                            {genre ? <div>{genre}</div> : ""}
                                            {additionalInformation ? <div>{additionalInformation}</div> : ""}
                                            {equipmentRequired ? <div>{equipmentRequired}</div> : ""}
                                            {workerInformation ? <div>{workerInformation}</div> : ""}
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="pending-request-panel__requested col-12 col-xl-5">
                                        Requested:{" "}
                                        <span className="pending-request-panel__request-date"> {requestDate} </span>
                                    </div>
                                    <div className="pending-request-panel__requested">
                                        <b>{requestStatusValue}</b>:{" "}
                                        <span className="pending-request-panel__request-date"> {lastUpdatedOn} </span>
                                    </div>
                                </div>
                            </>
                        }
                        rightSection={
                            <>
                                <div className="pending-request-panel__controls">
                                    {hideButtonAsPerType == true ? (
                                        <Link
                                            className="btn btn-primary btn--full-width"
                                            to={{
                                                pathname: "/findAnArtistLandingPage",
                                                state: {
                                                    id: this.state.pendingRequestCardData.id.toString(),
                                                    reqStatus: this.state.pendingRequestCardData.requestStatusValue,
                                                    isPendingRequest: true,
                                                },
                                            }}
                                        >
                                            {" "}
                                            {buttonText}{" "}
                                        </Link>
                                    ) : null}
                                    {isEventUk && hideButtonAsPerType == false ? (
                                        <button
                                            className="btn btn-primary btn--full-width"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.onBookRequest();
                                            }}
                                        >
                                            {" "}
                                            Book{" "}
                                        </button>
                                    ) : null}
                                    <button
                                        className="btn btn--full-width btn--ghost--teal mt-3 pending-request-panel__raise-query-button"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.showCancelRequestPopup();
                                        }}
                                    >
                                        Cancel Request
                                    </button>
                                    <button
                                        className="btn btn--full-width btn--ghost--teal mt-3"
                                        onClick={this.raiseQuery}
                                    >
                                        Raise Query
                                    </button>
                                    <Popup
                                        wrapperAttr={{
                                            class: "pending-request-panel__raise-query-pop-up",
                                        }}
                                        visible={this.state.raisingQuery}
                                        onHiding={this.closeQuery}
                                        dragEnabled={false}
                                        closeOnOutsideClick={false}
                                        showTitle={true}
                                        showCloseButton={true}
                                        title="Raise Query"
                                        maxWidth="500px"
                                    >
                                        <LoadPanel
                                            shadingColor="rgba(0,0,0,0.4)"
                                            visible={this.state.loadPanelVisible}
                                        />
                                        <div className="pending-request-panel__raise-query">
                                            <div className="pending-request-panel__query-box">
                                                <TextArea
                                                    height="100%"
                                                    placeholder="Please enter your query here and select Submit Query button - a member of the Event UK staff will be in touch"
                                                    onValueChanged={this.handleChangeComments}
                                                />

                                                <button
                                                    className="btn btn-primary btn--full-width"
                                                    onClick={this.onSubmitQuery}
                                                >
                                                    Submit Query
                                                </button>
                                            </div>
                                        </div>
                                    </Popup>
                                    <Popup
                                        visible={this.state.isCancelRequestPopupvisible}
                                        onHiding={this.hideCancelRequestPopup}
                                        dragEnabled={false}
                                        closeOnOutsideClick={true}
                                        showTitle={true}
                                        showCloseButton={true}
                                        title={"CANCELLATION REASON"}
                                        resizeEnabled={true}
                                        width="550px"
                                        height="auto"
                                    >
                                        <ScrollView width="100%" height="100%">
                                            <div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <p>Please Select a reason for cancellation</p>
                                                        <br></br>
                                                        <SelectBox
                                                            dataSource={this.state.cancellationReasonLookUp}
                                                            displayExpr={"value"}
                                                            valueExpr={"id"}
                                                            onValueChanged={this.handleChangeCancellationRequest}
                                                            value={this.state.cancellationRequestData.cancellationreasonId}
                                                        />
                                                        <button
                                                            className="btn btn-primary btn--large mt-3"
                                                            onClick={(e: MouseEvent) => {
                                                                e.preventDefault();
                                                                this.onCancelRequestConfirmation();
                                                            }}
                                                        >
                                                            SUBMIT CANCELLATION
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollView>
                                    </Popup>
                                </div>
                            </>
                        }
                    />
                </div>
            </section>
        );
    }
}
export default PendingRequestCard;
