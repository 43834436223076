import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    SearchPanel,
    Lookup,
    Selection,
    Scrolling
} from "devextreme-react/data-grid";
import {LoadIndicator} from "devextreme-react";
import SupplierInformationService, { clientVenueAddEditObject } from "../../services/SupplierInformationService";
import lookupService from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import {
    OnRowUpdatingEvent,
    DataGridOnSelectionChanged,
    onEditingStartEvent,
    onEditCanceledEvent,
} from "../../types/DevExtremeTypes";
import { Export } from "devextreme-react/bar-gauge";

//props
interface ClientVenuesGridProps {
    clientId: string;
    setClientVenueId: (clientId: string, venueId: string) => void;
}

export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}
// State
interface ClientVenuesGridState {
    clientVenueGridDataSource: [];
    showProgressIndicator: boolean;
    clientVenueAddEditObject: clientVenueAddEditObject;
    operationalcontactNameLookUp: LookupTypeItemGrid[];
    operationalcontactMobileLookUp: LookupTypeItemGrid[];
    operationalcontactEmailLookUp: LookupTypeItemGrid[];
    isEditClick: boolean;
    clientId: string;
    venueId: string;
    hierarchy: string;
    hierarchyNameHeader: string;
    hierarchyEmailHeader: string;
    hierarchyPhoneHeader: string;
}

// Component - displays the Rate Matrix Grid
class ClientVenuesGrid extends React.Component<ClientVenuesGridProps> {
    //Initialize the component's state.
    globalOperationalContact: string = "";
    state: ClientVenuesGridState;
    service: SupplierInformationService;
    sharedUtils: sharedUtils;
    dropdownService: lookupService;
    constructor(props: ClientVenuesGridProps) {
        super(props);

        this.sharedUtils = new sharedUtils();
        this.service = new SupplierInformationService();
        this.dropdownService = new lookupService();
        // Initialize state and services/utils
        this.state = {
            clientVenueGridDataSource: [],
            showProgressIndicator: false,
            clientVenueAddEditObject: {
                contactId: "",
                primarySecurityContactId: "",
            },
            operationalcontactNameLookUp: [],
            operationalcontactMobileLookUp: [],
            operationalcontactEmailLookUp: [],
            isEditClick: false,
            clientId: "",
            venueId: "",
            hierarchy: "",
            hierarchyNameHeader: "",
            hierarchyEmailHeader:"",
            hierarchyPhoneHeader: ""
        };
    }

    componentDidMount() {
        this.clientVenueDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: ClientVenuesGridProps) {
        if (this.props.clientId != prevProps.clientId) {
            this.clientVenueDataSource();
        }
    }

    dropDownDataSource = (venueId: string) => {
        this.service
            .getOperationalContactLookup(venueId)
            .then(this.handleOperationalContactLUSuccess)
            .catch(this.handleError);
    };

    onRowEditClick = (event: DataGridOnSelectionChanged) => {
        if (event.selectedRowsData) {
            this.props.setClientVenueId(this.props.clientId, event.selectedRowsData[0].id);
        }
    };
    clientVenueDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.service.getAllVenueInformation(this.props.clientId).then(this.handleSuccess).catch(this.handleError);
    };

    lookUpCreationForName = (response: AxiosResponse<any>) => {
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: parseInt(item.id),
                value: item.name,
                parentMappingId: item.id,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };

    lookUpCreationForMobileNumber = (response: AxiosResponse<any>) => {
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: parseInt(item.id),
                value: item.mobileTelephone,
                parentMappingId: item.id,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };
    lookUpCreationForEmail = (response: AxiosResponse<any>) => {
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: parseInt(item.id),
                value: item.email,
                parentMappingId: item.id,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };
    handleOperationalContactLUSuccess = (response: AxiosResponse<any>) => {
        var operationalContactNameDropDown = this.lookUpCreationForName(response);
        var operationalContactMobileDropDown = this.lookUpCreationForMobileNumber(response);
        var operationalContactEmailDropDown = this.lookUpCreationForEmail(response);
        this.setState({
            operationalcontactNameLookUp: this.constructOperationalContactDataSource(operationalContactNameDropDown),
            operationalcontactMobileLookUp: this.constructOperationalContactDataSource(
                operationalContactMobileDropDown
            ),
            operationalcontactEmailLookUp: this.constructOperationalContactDataSource(operationalContactEmailDropDown),
            isEditClick: true,
        });
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            clientVenueGridDataSource: response.data.data.venues,
            hierarchy: response.data.data.hierarchy,
            showProgressIndicator: false,
            clientId: "",
            venueId: "",
        } , () => {this.renderHierarchyHeader(response.data.data.hierarchy)});
    };

    renderHierarchyHeader = (hierarchy : string ) => {
        if(hierarchy) {
            this.setState ({
                hierarchyNameHeader: hierarchy + " " + "NAME",
                hierarchyEmailHeader:hierarchy + " " + "EMAIL",
                hierarchyPhoneHeader: hierarchy + " " + "PHONE"
            })
        }
    }

    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "Primary Security Contact service failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    onEditingStart = (event: onEditingStartEvent) => {
        this.setState({
            isEditClick: true,
        });
        if (event.data) {
            if (event.data.id) {
                this.dropDownDataSource(event.data.id);
            }
        }
        if (event.data) {
            this.setOperationalContactValue(
                event.data.operationalContactSecurityName + " - " + event.data.operationalContactSecurityPhone
            );
        }
    };

    onEditCancelled = (event: onEditCanceledEvent) => {
        this.setState({
            isEditClick: false,
        });
    };
    onRowUpadating = (e: OnRowUpdatingEvent) => {
        var primarySecurityContactId: string;
        if (e.newData.primarySecurityContactId) {
            primarySecurityContactId = e.newData.primarySecurityContactId.toString();
        } else {
            primarySecurityContactId = "";
        }
        this.setState({
            clientVenueAddEditObject: {
                ...this.state.clientVenueAddEditObject,
                contactId: e.oldData.contactId,
                primarySecurityContactId: primarySecurityContactId,
            },
            isEditClick: false,
        });

        this.service
            .savePrimarySecurityContactRowData(this.state.clientVenueAddEditObject)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch(this.handleError);
    };

    onRowUpdatedInsertedDeleted = () => {
        this.clientVenueDataSource();
    };

    addressCellDisplayContent = (cellInfo: any) => {
        var address: string = "";
        if (cellInfo.data && cellInfo.data.address) {
            address = cellInfo.data.address;
        }
        if (cellInfo.data && cellInfo.data.city) {
            address = address + " , " + cellInfo.data.city;
        }
        if (cellInfo.data && cellInfo.data.county) {
            address = address + " , " + cellInfo.data.county;
        }
        if (cellInfo.data && cellInfo.data.postCode) {
            address = address + " , " + cellInfo.data.postCode;
        }
        return (
            <div className="container-fluid">
                <a
                    href={`https://maps.google.com/maps?q=${address}`}
                    target="_blank"
                    title="This will open Google Maps in a new browser tab"
                >
                    {address}
                </a>
            </div>
        );
    };
    websiteCellDisplayContent = (cellInfo: any) => {
        var website: string = "";
        if (cellInfo.data && cellInfo.data.website) {
            website = cellInfo.data.website;
        }
        return (
            <div className="container-fluid">
                <a href={website}>{website}</a>
            </div>
        );
    };

    emailCellDisplayContent = (cellInfo: any) => {
        var email: string = "";
        if (cellInfo.data && cellInfo.data.email) {
            email = cellInfo.data.email;
        }
        return (
            <div className="container-fluid">
                <a href={`mailto:${email}`}>{email}</a>
            </div>
        );
    };

    phoneCellDisplayContent = (cellInfo: any) => {
        var phone: string = "";
        if (cellInfo.data && cellInfo.data.phone) {
            phone = cellInfo.data.phone;
        }
        return (
            <div className="container-fluid">
                <a href={`mobile:${phone}`}>{phone}</a>
            </div>
        );
    };

    operationalContactNameDisplayContent = (cellInfo: any) => {
        var operationalContactName: string = "";
        if (cellInfo.data && cellInfo.data.operationalContactSecurityName) {
            operationalContactName = cellInfo.data.operationalContactSecurityName;
        }
        return <div className="container-fluid">{operationalContactName}</div>;
    };
    operationalContactMobileDisplayContent = (cellInfo: any) => {
        var operationalContactPhone: string = "";
        if (cellInfo.data && cellInfo.data.operationalContactSecurityPhone) {
            operationalContactPhone = cellInfo.data.operationalContactSecurityPhone;
        }
        return <div className="container-fluid">{operationalContactPhone}</div>;
    };
    operationalContactEmailDisplayContent = (cellInfo: any) => {
        var operationalContactEmail: string = "";
        if (cellInfo.data && cellInfo.data.operationalContactSecurityEmail) {
            operationalContactEmail = cellInfo.data.operationalContactSecurityEmail;
        }
        return <div className="container-fluid">{operationalContactEmail}</div>;
    };

    setOperationalContactValue = (operationalContactValue: string) => {
        this.globalOperationalContact = operationalContactValue;
    };

    getOperationalContactValue = () => {
        return this.globalOperationalContact;
    };

    constructOperationalContactDataSource = (dataSource: LookupTypeItemGrid[]): LookupTypeItemGrid[] => {
        var lookupType: LookupTypeItemGrid = {
            id: 0,
            value: "Select..",
            parentMappingId: "0",
        };
        dataSource.unshift(lookupType);
        return dataSource;
    };

    exceptionaRateDisplayContent = (cellInfo: any) => {
        if (cellInfo.data && cellInfo.data.exceptionalRates) {
            return (
                <ul>
                    {cellInfo.data.exceptionalRates.map((item: any, uniqueKey: number) => {
                        return (
                            <li key={uniqueKey}>
                                {item.name} ( {item.typeLookValue} ) - {item.rateText}
                            </li>
                        );
                    })}
                </ul>
            );
        }
    };

    setContactsCellValue = (rowData: any, value: any) => {
        var mobileTelephone : string = "";
        var email : string = "";
        var phoneNoArr = this.state.operationalcontactMobileLookUp.filter((item) => {
            return item.id == value;
        });
        if(phoneNoArr.length > 0) {
          mobileTelephone = phoneNoArr[0].value;
        }
        var emailArr = this.state.operationalcontactEmailLookUp.filter((item) => {
            return item.id == value;
        });
        if(emailArr.length > 0) {
            email = emailArr[0].value;
        }       
        rowData.operationalContactSecurityPhone = mobileTelephone;
        rowData.operationalContactSecurityEmail = email;
        rowData.primarySecurityContactId = value;
    }

    render() {
        return (
            <>
                <div>
                    {this.state.showProgressIndicator ? (
                        <div className="starter-template">
                            <LoadIndicator
                                id="simple-grid-indicator"
                                height={100}
                                width={100}
                                visible={this.state.showProgressIndicator}
                            />
                        </div>
                    ) : (
                        <DataGrid
                            dataSource={this.state.clientVenueGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            onEditingStart={this.onEditingStart}
                            allowColumnReordering={true}
                            onEditCanceled={this.onEditCancelled}
                            onRowUpdating={this.onRowUpadating}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            onSelectionChanged={this.onRowEditClick}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Scrolling useNative={true} showScrollbar={top} />
                            <Export enabled={true} />
                            <Sorting mode="single" />
                            <Selection mode="single" />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <Editing
                                mode="row"
                                useIcons={true}
                                allowAdding={false}
                                allowUpdating={true}
                                allowDeleting={false}
                            />
                            <Column type="buttons" buttons={["edit"]} />
                            <Column dataField="clientName" allowSorting={true} caption="CLIENT" allowEditing={false} />
                            <Column dataField="venueName" allowSorting={true} caption="VENUE" allowEditing={false} />
                            <Column
                                dataField="houseNumber"
                                allowSorting={true}
                                allowEditing={false}
                                caption="VENUE NO."
                            />
                            <Column
                                dataField="address"
                                allowSorting={true}
                                allowEditing={false}
                                caption="ADDRESS"
                                cellRender={this.addressCellDisplayContent}
                            />
                            <Column
                                dataField="generalManager"
                                allowSorting={true}
                                allowEditing={false}
                                caption="General Manager"
                            />
                            <Column
                                dataField="website"
                                allowSorting={true}
                                allowEditing={false}
                                caption="VENUE WEBSITE"
                                cellRender={this.websiteCellDisplayContent}
                            />
                            <Column
                                dataField="phone"
                                allowSorting={true}
                                allowEditing={false}
                                caption="VENUE PHONE"
                                cellRender={this.phoneCellDisplayContent}
                            />
                            <Column
                                dataField="email"
                                allowSorting={true}
                                allowEditing={false}
                                caption="VENUE EMAIL"
                                cellRender={this.emailCellDisplayContent}
                            />
                            {this.state.hierarchy ? (
                                <Column
                                    dataField="hierarchyContactName"
                                    allowSorting={true}
                                    allowEditing={false}
                                    caption= {this.state.hierarchyNameHeader}
                                />
                            ) : null}
                            {this.state.hierarchy ? (
                                <Column
                                    dataField="hierarchyContactPhone"
                                    allowSorting={true}
                                    allowEditing={false}
                                    caption={this.state.hierarchyPhoneHeader}
                                />
                            ) : null}
                            {this.state.hierarchy ? (
                                <Column
                                    dataField="hierarchyContactEmail"
                                    allowSorting={true}
                                    allowEditing={false}
                                    caption={this.state.hierarchyEmailHeader}
                                />
                            ) : null}

                            <Column
                                dataField="licenseCondition"
                                allowSorting={true}
                                caption="VENUE LICENCE CONDITIONS"
                                allowEditing={false}
                            />
                            <Column
                                dataField="uniformPolicy"
                                allowSorting={true}
                                caption="VENUE UNIFORM POLICY"
                                allowEditing={false}
                            />
                            <Column
                                dataField="primarySecurityContactId"
                                allowSorting={true}
                                caption="DS OPERATIONAL CONTACT NAME"
                                visible={this.state.isEditClick}
                                setCellValue = {this.setContactsCellValue}
                            >
                                <Lookup
                                    dataSource={this.state.operationalcontactNameLookUp}
                                    displayExpr="value"
                                    valueExpr="id"
                                />
                            </Column>

                            <Column
                                allowSorting={true}
                                caption="DS OPERATIONAL CONTACT NAME"
                                cellRender={this.operationalContactNameDisplayContent}
                                visible={!this.state.isEditClick}
                            />

                            <Column
                                dataField="operationalContactSecurityPhone"
                                allowSorting={true}
                                caption="DS OPERATIONAL CONTACT MOBILE"
                            />

                            <Column
                                dataField="operationalContactSecurityEmail"
                                allowSorting={true}
                                caption="DS OPERATIONAL CONTACT EMAIL"
                            />

                            <Column
                                dataField="headDoorRateText"
                                allowSorting={true}
                                caption="HEAD DOOR RATE"
                                allowEditing={false}
                            />
                            <Column
                                dataField="supervisorRateText"
                                allowSorting={true}
                                caption="SUPERVISOR RATE"
                                allowEditing={false}
                            />
                            <Column
                                dataField="sicRateText"
                                allowSorting={true}
                                caption="SIC RATE"
                                allowEditing={false}
                            />
                            <Column
                                allowSorting={true}
                                caption="EXCEPTIONAL RATES"
                                allowEditing={false}
                                cellRender={this.exceptionaRateDisplayContent}
                            />
                            <FilterRow visible={true} applyFilter="auto" />
                        </DataGrid>
                    )}
                </div>
            </>
        );
    }
}

export default ClientVenuesGrid;
