import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";
import { Popup } from "devextreme-react/popup";
import ManageShiftsDesign from "./ManageShiftsDesign";
import { ScrollView } from "devextreme-react";

interface ManageShiftsDesignAsPopUpProps extends RouteComponentProps {}

interface ManageShiftsDesignAsPopUpState {
    displayPopUp: boolean;
}

class ManageShiftsDesignAsPopUp extends Component<ManageShiftsDesignAsPopUpProps> {
    state: ManageShiftsDesignAsPopUpState;

    constructor(props: ManageShiftsDesignAsPopUpProps) {
        super(props);
        this.state = { displayPopUp: false };
    }
    handleChange(e: { value: any }) {
        this.setState({
            type: e.value,
        });
    }

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <div className="col-12">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({
                                    displayPopUp: true,
                                });
                            }}
                        >
                            Launch Pop-Up
                        </button>
                    </div>
                </div>
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.displayPopUp}
                    onHiding={() => {
                        this.setState({
                            displayPopUp: false,
                        });
                    }}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Manage Shifts"
                    width="90%"
                    height="90%"
                >
                    <ScrollView>
                        <ManageShiftsDesign />
                    </ScrollView>
                </Popup>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ManageShiftsDesignAsPopUp);
