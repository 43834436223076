import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Export,
    SearchPanel,
    StateStoring
} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import ServiceFeeService, { ServiceFeeGridRowItem } from "../../services/ServiceFeesService";
import lookupService, { LookupTypeItem } from "../../services/LookupService";
import ServiceFeeCellComponent from "./ServiceFeeCellComponent";
import gridUtils from "../grid/GridUtilities";
import ServiceFeeFormUtil from "./ServiceFeeFormUtil";
import MaskedStatusForActive from "./MaskedStatusForActive";
import MaskedStatusForRefund from "./MaskedStatusForRefund";
import MaskedStatusForEarlyPay from "./MaskedStatusForEarlyPay";
import MaskedStatusForFasterPay from "./MaskedStatusForFasterPay";
import MaskedStatusForOverride from "./MaskedStatusForOverride";
import sharedUtils from "../grid/sharedUtilities";
import MaskedStatusForAccruals from "./MaskedStatusForAcctuals";
import MaskedStatusForProviderAcceptance from "./MaskedStatusForProviderAcceptance";

//props
interface ServiceFeeGridProps {
    refreshSignal: boolean;
}

// State
interface ServiceFeeGridState {
    serviceFeeGridDataSource: ServiceFeeGridRowItem[];
    showProgressIndicator: boolean;
    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    serviceFeeLookUp: LookupTypeItem[];
}

// Component - displays the Rate Matrix Grid
class ServiceFeeGrid extends React.Component<ServiceFeeGridProps> {
    //Initialize the component's state.
    state: ServiceFeeGridState;
    serviceFeeService: ServiceFeeService;
    dropdownService: lookupService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    serviceFeeUtils: ServiceFeeFormUtil;
    constructor(props: ServiceFeeGridProps) {
        super(props);
        this.serviceFeeService = new ServiceFeeService();
        this.dropdownService = new lookupService();
        this.sharedUtils = new sharedUtils();
        // Initialize state and services/utils
        this.state = {
            serviceFeeGridDataSource: [],
            showProgressIndicator: false,
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            serviceFeeLookUp: [],
        };
        this.gridUtils = new gridUtils();
        this.serviceFeeUtils = new ServiceFeeFormUtil(props);
    }

    componentDidMount() {
        this.fetchServiceFeeDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: ServiceFeeGridProps) {
        if (this.props.refreshSignal !== prevProps.refreshSignal) {
            // refresh the grid
            this.fetchServiceFeeDataSource();
        }
    }

    fetchServiceFeeDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.serviceFeeService.getServiceFeeMatrixData().then(this.handleSuccess).catch(this.handleError);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            serviceFeeGridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    };

    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    onRowUpdatedInserted = () => {
        this.fetchServiceFeeDataSource();
    };

    render() {
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.serviceFeeGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Sorting mode="single" />
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <StateStoring
                                enabled={true}
                                type="localStorage" storageKey="service_fee_grid"
                                savingTimeout='0'
                            />
                        <Column
                            dataField="id"
                            allowSorting={true}
                            caption="ID"
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertNumberAsStringToInteger(rowData.id);
                            }}
                        ></Column>
                        <Column dataField="clientBusinessEntity" allowSorting={true} caption="CLIENT"></Column>
                        <Column dataField="serviceType" allowSorting={true} caption="SERVICE"></Column>
                        <Column dataField="serviceSubType" allowSorting={true} caption="SUB TYPE"></Column>
                        <Column dataField="serviceFeeCalculation" allowSorting={true} caption="FEE BASIS"></Column>
                        <Column
                            dataField="multiplier"
                            allowSorting={true}
                            caption="MULTIPLIER"
                            format={{ type: "float", precision: 4 }}
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.multiplier);
                            }}
                        ></Column>
                        <Column
                            dataField="minGrossProfitValue"
                            allowSorting={true}
                            caption="MIN FEE"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.minGrossProfitValue);
                            }}
                            calculateDisplayValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.sharedUtils.thousandsSeparator(rowData.minGrossProfitValue);
                            }}
                        ></Column>
                        <Column
                            dataField="maxGrossProfitValue"
                            allowSorting={true}
                            caption="MAX FEE"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.maxGrossProfitValue);
                            }}
                            calculateDisplayValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.sharedUtils.thousandsSeparator(rowData.maxGrossProfitValue);
                            }}
                        ></Column>
                        <Column dataField="budgetType" allowSorting={true}
                            caption="BUDGET"></Column>
                        <Column
                            dataField="isAccruals"
                            allowSorting={true}
                            caption="ACCRUALS"
                            cellComponent={MaskedStatusForAccruals}
                        />
                        <Column
                            dataField="refund"
                            allowSorting={true}
                            caption="REFUND"
                            cellComponent={MaskedStatusForRefund}
                        />
                        <Column
                            dataField="fasterPayAvailable"
                            allowSorting={true}
                            caption="FASTER PAY"
                            cellComponent={MaskedStatusForFasterPay}
                        />
                        <Column
                            dataField="fasterPayMultiplier"
                            allowSorting={true}
                            caption="FASTER PAY MULTIPLIER"
                            format={{ type: "float", precision: 4 }}
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.fasterPayMultiplier);
                            }}
                        ></Column>
                        <Column
                            dataField="earlyPay"
                            allowSorting={true}
                            caption="EARLY PAY"
                            cellComponent={MaskedStatusForEarlyPay}
                        />
                        <Column
                            dataField="approvalVisibilityOffset"
                            allowSorting={true}
                            caption="VISIBILITY OFFSET"
                        />
                        <Column
                            dataField="visibilityDateOverride"
                            allowSorting={true}
                            caption="Override"
                            cellComponent={MaskedStatusForOverride}
                        />
                        <Column
                            dataField="approvalVisibilityDate"
                            allowSorting={true}
                            caption="VISIBILITY DATE"
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.approvalVisibilityDate);
                            }}
                        ></Column>
                        <Column
                            dataField="approvalVisibilityOffsetBatch"
                            allowSorting={true}
                            caption="BATCH OFFSET"
                        />
                        <Column
                            dataField="approvalVisibilityOffsetImport"
                            allowSorting={true}
                            caption="IMPORT OFFSET"
                        />
                        <Column
                            dataField="paymentOffset"
                            allowSorting={true}
                            caption="PAYMENT OFFSET"
                        />
                        <Column
                            dataField="providerAcceptances"
                            allowSorting={true}
                            caption="PROVIDER ACCEPTANCE"
                            cellComponent={MaskedStatusForProviderAcceptance}
                        />
                        <Column
                            dataField="isActive"
                            allowSorting={true}
                            caption="ACTIVE"
                            cellComponent={MaskedStatusForActive}
                        />
                        <Column
                            dataField="makeOfferLowerAdjustment"
                            allowSorting={true}
                            caption="MIN OFFER"
                            format={{ type: "float", precision: 4 }}
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.makeOfferLowerAdjustment);
                            }}
                        ></Column>
                        <Column
                            dataField="makeOfferUpperAdjustment"
                            allowSorting={true}
                            caption="MAX OFFER"
                            format={{ type: "float", precision: 4 }}
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.makeOfferUpperAdjustment);
                            }}
                        ></Column>
                        <Column
                            dataField="marketPlaceReduction"
                            allowSorting={true}
                            caption="MARKETPLACE REDUCTION"
                            format={{ type: "float", precision: 4 }}
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.marketPlaceReduction);
                            }}
                        ></Column>
                        <Column
                            dataField="lastUpdatedOn"
                            allowSorting={true}
                            caption="LAST UPDATED"
                            calculateSortValue={(rowData: ServiceFeeGridRowItem) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                            }}
                        ></Column>
                        <Column dataField="lastUpdatedBy" allowSorting={true} caption="LAST UPDATED BY"></Column>
                        <Column cellComponent={ServiceFeeCellComponent} width={50}></Column>
                        <Editing mode="row" useIcons={true} allowDeleting={false} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default ServiceFeeGrid;
