import React from "react";
import { Chart, LoadIndicator } from "devextreme-react";
import {
    CommonSeriesSettings,
    Size,
    Series,
    Tooltip,
    Legend,
    ValueAxis,
    ArgumentAxis,
    Title,
    Font,
    Label,
    Format,
    Border,
    ConstantLine,
    Tick,
    MinorTick,
} from "devextreme-react/chart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown, faSmile } from "@fortawesome/pro-regular-svg-icons";
import DataSource, { DataSourceOptions } from "devextreme/data/data_source";
import sharedUtils from "../grid/sharedUtilities";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { ChartData } from "../../services/ScheduleService";
import VenueFinancePeriodPopUp from "./VenueFinancePeriodPopUp";
import GlobalService from "../../services/GlobalService";
interface VenueFinancesCardProps {
    serviceColor: string;
    serviceName: string;
    periodGraphData: Array<any> | DataSource | DataSourceOptions | string;
    budgetSpendBarChartData: Array<any> | DataSource | DataSourceOptions | string;
    className?: string;
    yearToDateSpend: number;
    fullYearBudget: number;
    currentPeriod?: string;
    currentWeek?: string;
    itemToDate?: string;
    chartData?: ChartData;
    chartDataPending: boolean;
    clientOrVenueFound: boolean;
}

interface VenueFinancesCardState {
    popOverVisible: boolean;
    financePoint: any;
}

class VenueFinancesCard extends React.Component<VenueFinancesCardProps> {
    state: VenueFinancesCardState;
    className: string;
    spendGradientId: string; //used to provide SVG with unique ID for this instance
    budgetGradientId: string; //used to provide SVG with unique ID for this instance
    utils: sharedUtils;
    constructor(props: VenueFinancesCardProps) {
        super(props);
        this.state = { popOverVisible: false, financePoint: {} };
        this.className = this.props.className
            ? "home-page__service-panel " + this.props.className
            : "home-page__service-panel";
        this.spendGradientId = "SpendGradient-" + this.props.serviceName.replace(" ", "-"); // create unique ID for gradient SVG
        this.budgetGradientId = "BudgetGradient-" + this.props.serviceName.replace(" ", "-"); // create unique ID for gradient SVG
        this.utils = new sharedUtils();
    }

    customizeBarChartFill = (series: { argument: string }) => {
        return series.argument.toLowerCase() === "budget"
            ? { color: "url(#" + this.budgetGradientId + ")" }
            : { color: "url(#" + this.spendGradientId + ")" };
    };

    renderCustomPointsForSpline = () => {
        return {
            size: 6,
        };
    };

    getSpendLabelLoadingCssClass = () => {
        if (!this.props.chartDataPending) {
            return " home-page__spend-label--loaded";
        }
        return "";
    };

    renderSpendLabel = () => {
        if(this.props.yearToDateSpend > this.props.fullYearBudget) {
            return (
                <label className={"home-page__spend-label--over-spend" + this.getSpendLabelLoadingCssClass()}>
                    <FontAwesomeIcon className="home-page__spend-icon" icon={faFrown} />
                    YTD Over Budget:{" "}
                    {this.utils.thousandsSeparatorLandingPage(
                        (this.props.yearToDateSpend - this.props.fullYearBudget).toFixed()
                    )}
                </label>
            )
        } else {
            return (
                <label className={"home-page__spend-label--under-spend" + this.getSpendLabelLoadingCssClass()}>
                    <FontAwesomeIcon className="home-page__spend-icon" icon={faSmile} />
                    YTD Within Budget:{" "}
                    {this.utils.thousandsSeparatorLandingPage(
                        (this.props.fullYearBudget - this.props.yearToDateSpend).toFixed()
                    )}
                </label>
            )
        }
    };

    render() {
        let dateToString = "";
        GlobalService.setCurrentPeriod(this.props.currentPeriod ? this.props.currentPeriod : "");
        GlobalService.setCurrentWeek(this.props.currentWeek ? this.props.currentWeek : "");
        if (this.props.itemToDate) {
            var itemToDateValue = this.utils.convertStringToDate(this.props.itemToDate);
            if (itemToDateValue) {
                dateToString = itemToDateValue.toString();
                dateToString = new Date(dateToString).toString();
                var parts2 = dateToString.split(" ").slice(0, 4);
                dateToString = parts2[2] + " " + parts2[1] + " " + parts2[3];
            }
        }
        return (
            <div className={this.className}>
                <div className="row mb-4">
                    <div className="col-12 col-lg-8">
                        <h3 className="home-page-card__header">Spend vs Budget</h3>
                        <h4
                            style={{
                                color: this.props.serviceColor,
                            }}
                        >
                            {this.props.serviceName}
                        </h4>
                    </div>
                    <div className="col-12 col-lg-4 text-lg-right">
                        <h5>
                            Period {this.props.currentPeriod} Week {this.props.currentWeek}
                        </h5>
                        <h5>up to {dateToString}</h5>
                    </div>
                </div>

                <div className="row home-page__visualisations">
                    <LoadIndicator
                        id="button-indicator"
                        height={80}
                        width={80}
                        className="home-page__loader"
                        visible={
                            this.props.clientOrVenueFound ? this.props.chartDataPending : this.props.clientOrVenueFound
                        }
                    />
                    <div className="col-12 col-xl-8 home-page__graph">
                        <Chart
                            id="PeriodGraph"
                            dataSource={this.props.periodGraphData}
                            customizePoint={this.renderCustomPointsForSpline}
                        >
                            <Size height={350} />
                            <CommonSeriesSettings argumentField="period" type="area"></CommonSeriesSettings>
                            <Series
                                valueField="budget"
                                name="Budget"
                                opacity="0.35"
                                type="area"
                                color={this.props.serviceColor}
                            ></Series>

                            <Series
                                valueField="spend"
                                name="Spend"
                                opacity="0.5"
                                type="spline"
                                color={this.props.serviceColor}
                            ></Series>
                            <ArgumentAxis>
                                <Title text="PERIODS">
                                    <Font size={"1rem"} />
                                </Title>
                                <Tick visible={false} />
                                <MinorTick visible={false} />
                                {UserService.isUserInGroup(RoleGroupNames.VenueManager) ? null : (
                                    <ConstantLine
                                        value={this.props.currentPeriod}
                                        dashStyle={"solid"}
                                        color={this.props.serviceColor}
                                    >
                                        <Label
                                            text="Actual Spend<br />(up to last billed period)<br />vs. Forecast Spend"
                                            position="outside"
                                            verticalAlignment="top"
                                        />
                                    </ConstantLine>
                                )}
                            </ArgumentAxis>
                            <ValueAxis>
                                <Title text="£" />
                            </ValueAxis>
                            <Legend visible={false} />
                            <Tooltip
                                enabled={true}
                                zIndex="1"
                                contentRender={VenueFinancePeriodPopUp}
                                interactive={true}
                            />
                        </Chart>
                    </div>
                    <div className="col-12 col-xl-4 mt-4 mt-xl-0 home-page__bar-chart-container">
                        {this.renderSpendLabel()}
                        <Chart
                            id="BudgetSpendBarChart"
                            dataSource={this.props.budgetSpendBarChartData}
                            className={"home-page__bar-chart"}
                            customizePoint={this.customizeBarChartFill}
                        >
                            <Size height={250} />
                            <Series
                                valueField="value"
                                argumentField="label"
                                type="bar"
                                color={this.props.serviceColor}
                                barPadding={0.4}
                            >
                                <Label visible={true} position={"inside"} backgroundColor={"#EEEEEE"}>
                                    <Format type="currency" currency="GBP" />
                                    <Border color={this.props.serviceColor} visible={true} />
                                    <Font color={this.props.serviceColor} weight={900} size={"1rem"} />
                                </Label>
                            </Series>
                            <ArgumentAxis>
                                <Label>
                                    <Font size={"1rem"} />
                                </Label>
                            </ArgumentAxis>
                            <Legend visible={false} />
                        </Chart>
                    </div>
                </div>
                <svg
                    style={{
                        position: "absolute",
                        width: "1px",
                        height: "1px",
                    }}
                >
                    <defs>
                        <linearGradient id={this.spendGradientId} x1="0" x2="1" y1="0" y2="0">
                            <stop offset="100%" stopColor={this.props.serviceColor} />
                        </linearGradient>
                        <linearGradient id={this.budgetGradientId} x1="0" x2="1" y1="0" y2="0">
                            <stop offset="100%" stopColor={this.props.serviceColor} stopOpacity="0.4" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        );
    }
}
export default VenueFinancesCard;
