import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { alert } from "devextreme/ui/dialog";
import { WithTranslation } from "react-i18next";
import { RaiseQueryFormData } from "../../services/AddEditPopUpService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import CostReportService from "../../services/CostReportService";
import { LoadPanel, TextArea, Popup } from "devextreme-react";
import CostReportUtils from "./CostReportUtils";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";

interface RaiseQueryActionCellProps extends WithTranslation {
    data: any;
}

interface RaiseQueryActionCellState {
    queryItem: RaiseQueryFormData;
    loadPanelVisible: boolean;
    iconDisabled: boolean;
    onQueryRaising: boolean;
}

class RaiseQueryActionCell extends React.Component<RaiseQueryActionCellProps> {
    state: RaiseQueryActionCellState;
    Service: CostReportService;
    utils: addEditPopupUtils;
    sharedUtils: sharedUtils;
    costReportUtils: CostReportUtils;
    constructor(props: RaiseQueryActionCellProps) {
        super(props);
        this.Service = new CostReportService();
        this.utils = new addEditPopupUtils();
        this.costReportUtils = new CostReportUtils();
        this.sharedUtils = new sharedUtils();
        var convertedQueryFormData = this.utils.initializeQueryFormData(
            props.data.data && props.data.data.billableItemId ? props.data.data.billableItemId.toString() : "",
            "",
            "",
            "",
            "costreport"
        );
        this.state = {
            queryItem: convertedQueryFormData,
            loadPanelVisible: false,
            iconDisabled: false,
            onQueryRaising: false,
        };
    }

    //This is needed here to check for some conditions when the component mounts.
    componentDidMount() {
        var disableIcon: boolean = false;
        if (this.props.data && this.props.data.data.queries && this.props.data.data.queries.length > 0) {
            disableIcon = this.costReportUtils.disableRaiseQueryIcon(this.props.data.data.queries);
        } else {
            disableIcon = false;
        }
        this.setState({
            iconDisabled: disableIcon,
        });
    }

    handleRaiseQueryOnClick = () => {
        this.setState({
            onQueryRaising: true,
        });
    };

    showLoadPanel = () => {
        this.setState({
            loadPanelVisible: true,
        });
    };

    hideLoadPanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleChangeQueryComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
                clientId: this.props.data.data.clientId,
                venueId: this.props.data.data.venueId,
                serviceTypeId: this.props.data.data.serviceTypeId
            },
        });
    };

    onQuerySubmission = () => {
        let queryDescriptionAdded: boolean = false;
        queryDescriptionAdded = this.sharedUtils.validationCheckFunction(this.state.queryItem.queryDescription);
        if (!queryDescriptionAdded) {
            alert("Please enter the query before pushing the submit button", "Raise Query");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            this.Service.uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    handleQuerySubmissionSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            iconDisabled: true,
            onQueryRaising: false,
        });
        alert(
            "Thank you - your query has been raised - a member of the Event UK Team will be in touch",
            "Query Submitted"
        );
    };

    handleQuerySubmissionFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    //Helper function to close the Query popup.
    closeQueryBox = () => {
        this.setState({
            onQueryRaising: false,
        });
    };

    render() {
        const { iconDisabled } = this.state;
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        message="Raising the query, Please wait..."
                        visible={this.state.loadPanelVisible}
                    />
                    <div className="d-flex">
                        {" "}
                        {iconDisabled ? (
                            <div className="approval-query-column">Queried</div>
                        ) : (
                            <button className={"btn icon-btn"} onClick={this.handleRaiseQueryOnClick}>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </button>
                        )}
                    </div>
                </div>
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.onQueryRaising}
                    onHiding={this.closeQueryBox}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Raise Query"
                    maxWidth="500px"
                >
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="pending-request-panel__raise-query">
                        <div className="pending-request-panel__query-box">
                            <TextArea
                                height="100%"
                                placeholder="Please enter your query here and select Submit Query button - a member of the Event UK staff will be in touch"
                                onValueChanged={this.handleChangeQueryComments}
                            />

                            <button className="btn btn-primary btn--full-width" onClick={this.onQuerySubmission}>
                                Submit Query
                            </button>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }
}

export default RaiseQueryActionCell;
