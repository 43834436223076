import React, { Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import ManageShiftsDatePanel from "../side-bar/ManageShiftsDatePanel";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LockShiftMatrixGrid from "../BillingV2/LockShiftMatrixGrid";
import { Button,ScrollView} from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { GridTypes } from "../../services/SimpleGridService";


interface AddShiftContainerForBillingProps extends WithTranslation {
    filters: any[];
    isAddShiftClicked: boolean;
    onAddToInvoice: (selected: any) => void;
}

interface AddShiftContainerForBillingState {
    dateFrom: string;
    dateTo: string;
    isShowUnbilled: boolean;
    isUnPaid: boolean;
    isUnconfirmedItems: boolean;
    isUnapprovedItems:boolean;
    selectedRows: any;
}

class AddShiftContainerForBilling extends Component<AddShiftContainerForBillingProps> {
    state: AddShiftContainerForBillingState;
    constructor(props: AddShiftContainerForBillingProps) {
        super(props);
        this.state = {
            dateFrom: '',
            dateTo: '',
            isShowUnbilled: true,
            isUnPaid: false,
            isUnconfirmedItems: false,
            isUnapprovedItems:false,
            selectedRows: []
        }
    }

    //A helper function that would refrsh the EventUk User Grid.
    refreshMatrixGrid = (dateFrom: string, dateTo: string, isShowUnbilledItems: boolean,isUnPaidItems: boolean,isUnconfirmedItems: boolean,isUnapprovedItems :boolean, onUpdate?: boolean) => {
        this.setState((prevState: AddShiftContainerForBillingState) => ({
            //refreshSignal: onUpdate ? !prevState.refreshSignal : prevState.refreshSignal,
            //filterText: "",
            dateFrom: dateFrom,
            dateTo: dateTo,
            isShowUnbilled: isShowUnbilledItems,
            isUnPaid: isUnPaidItems,
            isUnconfirmedItems: isUnconfirmedItems,
            isUnapprovedItems:isUnapprovedItems
        }));
    };

    isAddToInvoiceDisabled = () => {
        let isDisabled = true;
        if (this.state.selectedRows && this.state.selectedRows.length && this.state.selectedRows.length > 0) {
            isDisabled = false;
        }
        return isDisabled;
    }

    addToInvoice = () => {
        this.props.onAddToInvoice ? this.props.onAddToInvoice(this.state.selectedRows) : null;
    }

    getSelectedRows = (selectedRows: any) => {
        this.setState({ selectedRows: selectedRows });
    }

    render() {
        const { dateFrom, dateTo } = this.state;
        let header: string = "Shift Matrix";
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <ManageShiftsDatePanel
                        onApplyButtonClick={this.refreshMatrixGrid}
                        clientId={""}
                        startDate={dateFrom}
                        endDate={dateTo}
                        pointOfNavigation={GridTypes.addShiftBillingGrid}
                        filterText={''}
                        isAddShiftClicked={this.props.isAddShiftClicked}
                    />
                    <BulletinPanel />
                </SideBarTemplate>
                <ScrollView width='100%' height='100%'>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mt-3 mb-3">
                        <h2 className="page-title__black">{header}</h2>
                        {/* <h3 className="grid-info__title">
                            {<b>{dateDisplayText ? dateDisplayText : ""}</b>}
                        </h3> */}
                    </header>
                    <div className="grid-info__row mt-2 mb-3">
                        <div className="grid-info__button-container">
                            <Button
                                disabled={this.isAddToInvoiceDisabled()}
                                className={"btn btn-primary btn--large btn--with-icon"}
                                onClick={this.addToInvoice}>
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                                Add To Invoice
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <LockShiftMatrixGrid
                                filters={this.props.filters}
                                getSelectedRows={this.getSelectedRows}
                                isAddShiftClicked={this.props.isAddShiftClicked}
                                dateRangeFrom={this.state.dateFrom}
                                dateRangeTo={this.state.dateTo}
                                isShowUnbilledItems={this.state.isShowUnbilled}
                                isUnPaidItems={this.state.isUnPaid}
                                isUnconfirmedItems={this.state.isUnconfirmedItems}
                                isUnapprovedItems={this.state.isUnapprovedItems}
                            />
                            
                        </div>
                        <div></div>
                    </div>
                </div>
                </ScrollView>
            </LightModePageTemplate>
        )
    }
}

export default withTranslation()(AddShiftContainerForBilling);