import React from "react";
import gridUtils from "../../grid/GridUtilities";
import ReconciliationGridService, { WeekOnWeekGridRowItem } from "../../../services/ReconciliationGridService";
import { AxiosResponse } from "axios";
import DataGrid, { Pager, Paging, Export, FilterRow, Column, SearchPanel, Scrolling } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react/load-indicator";
import GridToggleTab from "../../BillingV2/GridToggleTab";
import sharedUtils from "../../grid/sharedUtilities";
interface WeekOnWeekReconciliationGridProps {
    id: string;
    refreshGridData: boolean;
    tabVisible: boolean;
}

interface WeekOnWeekReconciliationGridState {
    showProgressIndicator: boolean;
    gridDataSource: WeekOnWeekGridRowItem[];
    primaryDataSource: WeekOnWeekGridRowItem[];
    secondaryDataSource: WeekOnWeekGridRowItem[];
    primaryDataSourceCount: number;
    secondaryDataSourceCount: number;
}

class WeekOnWeekReconciliationGrid extends React.Component<WeekOnWeekReconciliationGridProps> {
    state: WeekOnWeekReconciliationGridState;
    service: ReconciliationGridService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;

    constructor(props: WeekOnWeekReconciliationGridProps) {
        super(props);
        // Initialize state and services/utils
        this.service = new ReconciliationGridService();
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            showProgressIndicator: false,
            gridDataSource: [],
            primaryDataSource: [],
            secondaryDataSource: [],
            primaryDataSourceCount: 0,
            secondaryDataSourceCount: 0,
        };
    }

    componentDidMount() {
        // Function to call API
        this.updateGridDataSource();
    }

    updateGridDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.service.getWeekOnWeekGridRows(this.props.id).then(this.handleSuccess).catch(this.handleError);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        var primaryDataSource: WeekOnWeekGridRowItem[] = this.gridUtils.splitVarianceReportData(response.data.data);
        this.setState({
            primaryDataSource: primaryDataSource,
            secondaryDataSource: response.data.data,
            gridDataSource: this.props.tabVisible ? primaryDataSource : response.data.data,
            showProgressIndicator: false,
            primaryDataSourceCount: primaryDataSource && primaryDataSource.length > 0 ? primaryDataSource.length : 0,
            secondaryDataSourceCount: response.data.data.length,
        });
    };

    //This function will change the contents of the Grid, depending on the Tab button that is clicked.
    swapGridDataSource = (isToggle: boolean) => {
        var { primaryDataSource, secondaryDataSource } = this.state;
        if (isToggle) {
            this.setState({
                gridDataSource: primaryDataSource,
            });
        } else {
            this.setState({
                gridDataSource: secondaryDataSource,
            });
        }
    };

    handleError = (error: any) => {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string =
            "Get WeekonWeek Grid rows service load failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    setVarianceColour = (cellElement: any, cellInfo: any) => {
        var cellVarianceWithCurrencySymbol = this.sharedUtils.thousandsSeparator(cellInfo.data.varience);
        if (cellInfo.data.rag == "Green" && cellInfo.data.varience) {
            cellElement.classList.add("text-success");
        } else if (cellInfo.data.rag == "Amber" && cellInfo.data.varience) {
            cellElement.classList.add("text-warning");
        }
        if (cellInfo.data.varience) {
            cellElement.append(cellVarianceWithCurrencySymbol);
        }
    };

    renderApprovedColumnDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <span className="text-success">{this.sharedUtils.thousandsSeparator(cellInfo.data.approvedAmount)}</span>
            </div>
        );
    };
    renderUnapprovedColumnDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <span className="errorstatus">{this.sharedUtils.thousandsSeparator(cellInfo.data.unApprovedAmount)}</span>
            </div>
        );
    };

    componentDidUpdate = (prevProps: WeekOnWeekReconciliationGridProps) => {
        if (this.props.refreshGridData != prevProps.refreshGridData) {
            this.setState({
                primaryDataSourceCount: 0,
                secondaryDataSourceCount: 0,
            });
            this.updateGridDataSource();
        }
    };

    render() {
        var { primaryDataSourceCount, secondaryDataSourceCount } = this.state;
        return (
            <div className="my-3">
                <h4 className="font-weight-bold">Weekly Variance</h4>
                {this.props.tabVisible && (
                    <GridToggleTab
                        primaryTabText={`Different (${primaryDataSourceCount.toString()})`}
                        secondaryTabText={`View All (${secondaryDataSourceCount.toString()})`}
                        className={"Yellow"}
                        onToggle={this.swapGridDataSource}
                    />
                )}
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={50}
                            width={50}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="client" caption="CLIENT" />
                        <Column dataField="venue" caption="VENUE" />
                        <Column dataField="house" caption="HOUSE" />
                        <Column dataField="service" caption="Service" />
                        <Column dataField="serviceSubType" caption="SERVICE SUB TYPE" />
                        <Column dataField="period" caption="PERIOD" />
                        <Column dataField="week" caption="WEEK" />
                        <Column
                            dataField="approvedAmount"
                            caption="APPROVED"
                            cellRender={this.renderApprovedColumnDisplayContent}
                        />
                        <Column
                            dataField="unApprovedAmount"
                            caption="UNAPPROVED"
                            cellRender={this.renderUnapprovedColumnDisplayContent}
                        />
                        <Column
                            dataField="pay_Sum"
                            caption="TOTAL PAY"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.pay_Sum);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.pay_Sum);
                            }}
                        />
                        <Column
                            dataField="previousPay_Sum"
                            caption="PREVIOUS PAY"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.previousPay_Sum);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.previousPay_Sum);
                            }}
                        />
                        <Column
                            dataField="varience"
                            caption="Variance Amount"
                            cellTemplate={this.setVarianceColour}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.varience);
                            }}
                        />
                        <Column dataField="currency" caption="Currency" visible={false} />
                        <Column dataField="rag" caption="VarianceColor" visible={false} />
                        <Column dataField="rateDiffColor" caption="RateDiffColor" visible={false} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default WeekOnWeekReconciliationGrid;
