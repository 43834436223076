import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Scrolling,
    Editing,
    Texts,
    SearchPanel,
} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import gridUtils from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";
import CostReportService, { IncidentListGridRowItem } from "../../services/CostReportService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

//props
interface IncidentsListGridProps {
    dateFrom: string;
    dateTo: string;
}

// State
interface IncidentsListGridState {
    simpleShiftsGridDataSource: IncidentListGridRowItem[];
    showProgressIndicator: boolean;
    startDateId: string;
    endDateId: string;
}

class IncidentsListGrid extends React.Component<IncidentsListGridProps> {
    state: IncidentsListGridState;
    service: CostReportService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;

    constructor(props: IncidentsListGridProps) {
        super(props);
        // Initialize state and services/utils
        this.state = {
            simpleShiftsGridDataSource: [],
            showProgressIndicator: false,
            startDateId: this.props.dateFrom,
            endDateId: this.props.dateTo,
        };
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        // Functions
        this.service = new CostReportService();
    }

    // Initialize state from server
    componentDidMount() {
        this.IncidentsListGridDataSource();
    }

    IncidentsListGridDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.service
            .getIncidentGridRows(this.props.dateFrom, this.props.dateTo)
            .then(this.handleSuccess)
            .catch(this.handleError);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        if (response.status == 204) {
            this.setState({
                simpleShiftsGridDataSource: response.data, // data will be an empty string ("")
                showProgressIndicator: false,
            });
        } else {
            this.setState({
                simpleShiftsGridDataSource: response.data.data,
                showProgressIndicator: false,
            });
        }
    };

    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    componentDidUpdate = (prevprops: IncidentsListGridProps, prevState: IncidentsListGridState) => {
        if (this.props.dateFrom != prevprops.dateFrom || this.props.dateTo != prevprops.dateTo) {
            this.IncidentsListGridDataSource();
        }
    };

    //Custom cell rendering function to color code the severity column.
    severityCellDisplayContent = (cellInfo: any) => {
        var classList: string = "";
        var severity: string = cellInfo.data.severity;
        if (severity) {
            if (severity.trim().toLowerCase() == "high") {
                classList = "text-danger";
            } else if (severity.trim().toLowerCase() == "low") {
                classList = "text-success";
            } else {
                classList = "text-warning";
            }
        }
        return <div className={classList}>{severity}</div>;
    };

    //Helper function to render the custom Grid column with a Font Awesome icon.
    updateRowItem = (cellInfo: any) => {
        var incidentId = cellInfo.data.incidentId;
        return (
            <div className="d-flex">
                <Link
                    className={"icon-btn"}
                    to={{
                        pathname: "/incident",
                        state: {
                            id: incidentId ? incidentId : "",
                        },
                    }}
                >
                    <FontAwesomeIcon icon={faFileAlt} />
                </Link>
            </div>
        );
    };

    render() {
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.simpleShiftsGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                    >
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Scrolling useNative={true} showScrollbar={top} />
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />

                        <Column caption="UPDATE" cellRender={this.updateRowItem} />
                        <Column dataField="clientBusinessEntityName" caption="CLIENT" />
                        <Column dataField="venueNamePostCode" caption="VENUE" />
                        <Column dataField="houseNo" caption="HOUSE NO." />
                        <Column dataField="providerBusinessEntityName" caption="PROVIDER" />
                        <Column dataField="title" caption="INCIDENT TITLE" />
                        <Column dataField="description" caption="INCIDENT DESCRIPTION" />
                        <Column
                            dataField="date"
                            caption="DATE OF INCIDENT"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                        />
                        <Column dataField="time" caption="TIME OF INCIDENT" />
                        <Column dataField="reportedByName" caption="REPORTED BY" />
                        <Column dataField="severity" caption="SEVERITY" cellRender={this.severityCellDisplayContent} />
                        <Column dataField="customEmergencyServices" caption="EMERGENCY SERVICES?" />
                        <Column dataField="injuries" caption="INJURIES" />

                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default IncidentsListGrid;
