import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Export,
    SearchPanel,
} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import VenueHierarchyService, { VenueHierarchyGridRowItem } from "../../services/VenueHierarachyService";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { OnRowRemovingEvent, SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import gridUtils from "../grid/GridUtilities";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import VenueHierarachyForm from "./VenueHierarachyForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlusCircle } from "@fortawesome/pro-regular-svg-icons";

//props
interface VenueHierarchyGridProps {}

export interface VenueHierarchyAddEditObjectItem {
    contractId: number;
    providerBusinessEntityId: number;
    clientBusinessEntityId: number;
    serviceTypeLookUpId: number;
    serviceSubTypeLookUpId: number;
    venueId: string;
    isActive: boolean;
    isVariableHours: boolean;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    sunday: number;
    rate: number;
    total: number;
    lastUpdatedDate: Date;
    lastUpdatedBy: string;
}

// State
interface VenueHierarchyGridState {
    venueGridDataSource: VenueHierarchyGridRowItem[];
    showProgressIndicator: boolean;
    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    venueLookUp: LookupTypeItem[];
    providerLookUp: LookupTypeItem[];
    frequencyLookUp: LookupTypeItem[];
    clientId: string;
    refreshSignal: boolean;
    openForm: boolean;
    isAddClick: boolean;
    applySuccessful: string;
    rowId: string;
}

// Component - displays the Rate Matrix Grid
class VenueHierarchyGrid extends React.Component<VenueHierarchyGridProps> {
    //Initialize the component's state.
    state: VenueHierarchyGridState;
    venueHierarchyService: VenueHierarchyService;
    dropdownService: lookupService;

    gridUtils: gridUtils;
    constructor(props: VenueHierarchyGridProps) {
        super(props);
        this.venueHierarchyService = new VenueHierarchyService();
        this.dropdownService = new lookupService();
        // Initialize state and services/utils
        this.state = {
            venueGridDataSource: [],
            showProgressIndicator: false,
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            venueLookUp: [],
            providerLookUp: [],
            frequencyLookUp: [],
            clientId: "",
            refreshSignal: false,
            openForm: false,
            isAddClick: false,
            applySuccessful: "",
            rowId: "",
        };
        this.gridUtils = new gridUtils();
        // Functions
    }

    componentDidMount() {}

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: VenueHierarchyGridProps, prevState: VenueHierarchyGridState) {
        if (this.state.refreshSignal !== prevState.refreshSignal) {
            // refresh the grid
            this.getVenueHierarchyGridDetails();
        }
    }
    handleChangeClient = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState(
            {
                clientId: dxValueChange.value,
            },
            () => {
                this.getVenueHierarchyGridDetails();
            }
        );
        this.handleFormOpenClose("CLOSE", "");
    };
    getVenueHierarchyGridDetails = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.venueHierarchyService
            .getVenueHierarchyData(this.state.clientId)
            .then(this.handleSuccess)
            .catch(this.handleError);
    };
    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            venueGridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    };
    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
    };
    onRowUpdatedInsertedDeleted = () => {
        this.setState({
            refreshSignal: true,
        });
    };

    onRowRemoving = (e: OnRowRemovingEvent) => {
        this.venueHierarchyService
            .deleteVenueHierarchyDataItem(e.data.id)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch(this.handleError);
    };
    renderCustomData = (data: any) => {
        var id = data.data.id;
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    <span className="icon-btn" onClick={() => this.handleForm(id)}>
                        <FontAwesomeIcon icon={faPen} />
                    </span>
                </div>
            </div>
        );
    };
    handleForm = (id: string) => {
        this.handleFormOpenClose("OPEN", id);
    };
    onApplySuccess = (applySignal: string) => {
        // This refresh signal will toggle between true and false to indicate a refresh is required.
        this.setState((prevState: VenueHierarchyGridState) => ({
            refreshSignal: !prevState.refreshSignal,
            applySuccessful: applySignal,
            isAddClick: applySignal == "ADD" ? true : applySignal == "EDIT" ? false : null,
            openForm: false,
        }));
    };
    onAddClick = () => {
        this.setState({ isAddClick: true, applySuccessful: "", rowId: "" });
    };
    handleFormOpenClose = (applySignal: string, id: string, isAddClick?: boolean) => {
        this.setState({
            openForm: applySignal == "OPEN" ? true : applySignal == "CLOSE" ? false : null,
            rowId: id,
            isAddClick: isAddClick,
        });
        //this.props.sendEditFormSignal(true);
    };
    render() {
        let formView = false;
        let isEditClick = false;
        if (this.state.openForm) {
            formView = true;
            isEditClick = true;
        } else if (this.state.isAddClick) {
            if (this.state.applySuccessful == "ADD") {
                formView = false;
            } else {
                formView = true;
            }
        } else if (this.state.applySuccessful == "EDIT") {
            formView = false;
        }
        return (
            <>
                <div className="row mygig">
                    <h3 className="text-left font-weight-bold mt-4">Venue Hierarchy</h3>
                </div>
                <div className="row mb-4">
                    <div className="mt-3 col-1 font-weight-bold">Client</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <DropDownFromLookupTable
                            lookupTypeIndex={LookupTypeIndexes.clientType}
                            onValueChanged={this.handleChangeClient}
                            value={this.state.clientId}
                            recordType={ formView? "all":"active"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        {formView ? (
                            <VenueHierarachyForm
                                location={null}
                                history={null}
                                rowId={this.state.rowId}
                                clientId={this.state.clientId}
                                onApplySuccess={this.onApplySuccess}
                                handleFormOpenClose={this.handleFormOpenClose}
                                isEditForm={isEditClick}
                            />
                        ) : null}
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12 col-lg-2 mb-3">
                        <button className="btn btn-primary btn--large" onClick={this.onAddClick}>
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </span>
                            Add
                        </button>
                    </div>
                </div>

                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.venueGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        onRowRemoving={this.onRowRemoving}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Sorting mode="single" />
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="id" allowSorting={true} calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.id);
                            }} caption="ID"></Column>
                        <Column dataField="clientId" allowSorting={true} caption="CLIENT ID"></Column>
                        <Column dataField="clientName" allowSorting={true} caption="CLIENT"></Column>
                        <Column dataField="venueId" allowSorting={true} caption="VENUE ID"></Column>
                        <Column dataField="venueName" allowSorting={true} caption="VENUE"></Column>
                        <Column
                            dataField="houseId"
                            allowSorting={true}
                            allowExporting={false}
                            caption="HOUSE ID"
                        ></Column>
                        <Column dataField="level" allowSorting={true} caption="LEVEL"></Column>
                        <Column dataField="contactId" allowSorting={true} caption="CONTACT ID"></Column>
                        <Column dataField="contactName" allowSorting={true} caption="CONTACT"></Column>
                        <Column dataField="userId" allowSorting={true} caption="USER ID"></Column>
                        <Column dataField="userName" allowSorting={true} caption="USER"></Column>
                        <Column dataField="rollId" allowSorting={true} caption="ROLE ID"></Column>
                        <Column dataField="rollName" allowSorting={true} caption="ROLE"></Column>
                        <Column dataField="managerId" allowSorting={true} caption="MANAGER ID"></Column>
                        <Column dataField="managerName" allowSorting={true} caption="MANAGER"></Column>
                        <Column
                            dataField="lastUpdatedOn"
                            allowSorting={true}
                            allowExporting={false}
                            caption="LAST UPDATED TIME"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                            }}
                        ></Column>
                        <Column
                            dataField="lastUpdateBy"
                            allowSorting={true}
                            allowExporting={false}
                            caption="LAST UPDATED BY"
                        ></Column>
                        <Column cellRender={this.renderCustomData} />
                        <Editing mode="row" useIcons={true} allowDeleting={true} />
                        <Column type="buttons" buttons={["delete"]} />

                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
                <div></div>
            </>
        );
    }
}

export default VenueHierarchyGrid;
