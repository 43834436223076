import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import sharedUtils from "../grid/sharedUtilities";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import CostReportService, { costReportDates } from "../../services/CostReportService";
import LookupService from "../../services/LookupService";
import outstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { ServiceType } from "../../common/ServiceUtilities";
import SimpleShiftsPanel from "../SimpleShiftsByService/SimpleShiftsSidebarPanel";
import PaymentRequestGrid from "./PaymentRequestGrid";
import { PaymentTypes } from "../../services/FindAnArtistService";
import GridTitleCard from "../Cards/GridTitleCard";
import { faAsterisk, faHandHoldingUsd, faCalendarCirclePlus, faSync } from "@fortawesome/pro-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";

interface PaymentRequestPageProps extends RouteComponentProps, WithTranslation {
    title: string;
    category: string;
    location: any;
    getBuild: (flag: boolean) => void;  
}

interface PaymentRequestPageState {
    dateList: costReportDates[];
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    startDate: string;
    endDate: string;
    sumTotal: string;
    gridLoaded: boolean;
    onUpdate: boolean;
    isApplyButtonClick: boolean;
    paymentTypeId: string;
    isRefreshButtonClicked: boolean;
}

class PaymentRequestPage extends Component<PaymentRequestPageProps> {
    utils: sharedUtils;
    conversionUtils: outstandingActionsUtilities;
    state: PaymentRequestPageState;
    Service: CostReportService;
    lookupService: LookupService;

    constructor(props: PaymentRequestPageProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.utils = new sharedUtils();
        this.conversionUtils = new outstandingActionsUtilities();
        
        // Initialize button states to false as a user selection of a grid row is required
        this.state = {
            dateList: [],
            clientId:
                this.props.location.state && this.props.location.state.clientId
                    ? this.props.location.state.clientId
                    : "",
            venueId:
                this.props.location.state && this.props.location.state.venueId ? this.props.location.state.venueId : "",
            serviceTypeId: "",
            startDate:
                this.props.location.state && this.props.location.state.startDate
                    ? this.props.location.state.startDate
                    : this.calculateDateBefore6Weeks(),
            endDate:
                this.props.location.state && this.props.location.state.endDate ? this.props.location.state.endDate : "",
            sumTotal: "",
            gridLoaded: false,
            onUpdate: false,
            isApplyButtonClick: false,
            paymentTypeId: "",
            isRefreshButtonClicked: false,
        };
    }

    calculateDateBefore6Weeks(){
        let currDate = new Date();
        let newStartDate = moment(currDate).subtract(6,'weeks');
        let date = newStartDate.format("DD/MM/YYYY");
        // let paymentTypeFilter: string = sessionStorage.getItem("paymentTypeFilter") || '';
        // let serviceTypeFilter:string = sessionStorage.getItem("serviceTypeFilter") || '';
        // sessionStorage.setItem("paymentTypeFilter_previous",paymentTypeFilter);
        // sessionStorage.setItem("serviceTypeFilter_previous",serviceTypeFilter);
        return date;
       }

    parentIdSet = (
        venueId: string,
        startDate: string,
        endDate: string,
        clientId: string,
        serviceTypeId: string,
        onUpdate: boolean,
        paymentTypeId: string
    ) => {
        this.setState({
            clientId: clientId ? clientId : "",
            venueId: venueId ? venueId : "",
            serviceTypeId: serviceTypeId ? serviceTypeId : "",
            onUpdate: onUpdate,
            paymentTypeId: paymentTypeId,
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : ""
        });
    };

    setCalculatedValue = (totalValue: number, loaded: boolean) => {
        this.setState({
            sumTotal: totalValue.toString(),
            gridLoaded: loaded,
        });
    };
    componentDidMount(): void {
        //paymentRequestForm(close)->back to paymentRequest screen -> refresh,its taking details from popup
        // if(this.props?.location?.state?.pointOfNavigation == "popUp" )
        // {
        //     this.props.location.state.pointOfNavigation = "burgerMenuPayments";
        // }
    }
    componentWillMount(): void {
        if(this.props?.location?.state?.pointOfNavigation == 'home')
        {
            //if req is from home screen
            sessionStorage.setItem("paymentTypeFilter", "0");
        }
        // console.log('will mounted')
        // let serviceTypeId = sessionStorage.getItem("serviceTypeFilter");
        // let paymentTypeId = sessionStorage.getItem("paymentTypeFilter");
        // this.setState({
        //     paymentTypeId:paymentTypeId,
        //     serviceTypeId:serviceTypeId
        // });
    }
    
    //componentDidUpdate(prepProps:PaymentRequestPageProps): void {
        // let currP = sessionStorage.getItem("paymentTypeFilter") || ''
        // let prevP = sessionStorage.getItem("paymentTypeFilter_previous");

        // let currS = sessionStorage.getItem("serviceTypeFilter") || ''
        // let prevS = sessionStorage.getItem("serviceTypeFilter_previous");

        // if (currP != prevP || currS != prevS) {
        //     if(currP != prevP){
        //         sessionStorage.setItem("paymentTypeFilter_previous",currP);
        //     }
        //     if(currS != prevS){
        //         sessionStorage.setItem("serviceTypeFilter_previous",currS);
        //     }
        //     let date = this.calculateDateBefore6Weeks();
        //     this.setState({ startDate: date });
        // }
    //}

    componentWillUnmount() {
        //sessionStorage.setItem("paymentTypeFilter", "");
    }

    getBuild = (flag: boolean) => {
        this.props.getBuild(flag);
    };

    onRefreshGrid = () => {
        this.setState({isRefreshButtonClicked: true});
    }

    resetRefreshGrid = () => {
        this.setState({isRefreshButtonClicked: false});
    }

    render() {
        var serviceTypeId: string | null = sessionStorage.getItem("serviceTypeFilter");
        let paymentTypeId: string | null = sessionStorage.getItem("paymentTypeFilter");
        var dateDisplayText: string = "";
        var { startDate, endDate, gridLoaded, sumTotal } = this.state;
        if (startDate.localeCompare(endDate) == 0) {
            var startDateText: string = startDate
                ? this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(startDate)
                : "";
            dateDisplayText = `${startDateText ? startDateText : ""}`;
        } else {
            if (startDate.localeCompare(endDate) != 0) {
                var minDateText: string = startDate
                    ? this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(startDate)
                    : "";
                var maxDateText: string = endDate
                    ? this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(endDate)
                    : "";
                dateDisplayText = `${minDateText ? minDateText : ""} - ${maxDateText ? maxDateText : ""}`;
            }
        }
        var headerText: string = "";
        let gridTitleIcon: IconDefinition = faAsterisk;
        let gridTitleStyle: string = "";
        var shiftsText: string = "";
        var taxText: string = "";
        if (serviceTypeId == ServiceType.Entertainment) {
            headerText = "Entertainment";
        } else if (serviceTypeId == ServiceType.ContractCleaning) {
            headerText = "Cleaning";
        } else if (serviceTypeId == ServiceType.Gardening) {
            headerText = "Gardening";
        } else if (serviceTypeId == ServiceType.WindowCleaning) {
            headerText = "Window Cleaning";
        } else if (serviceTypeId == ServiceType.Maintenance) {
            headerText = "Maintenance";
        } else if (serviceTypeId == ServiceType.Miscelleanous) {
            headerText = "Miscelleanous";
        }
        // if(this.state.paymentTypeId){
        //     paymentTypeId = this.state.paymentTypeId;
        // }
        if (paymentTypeId == PaymentTypes.PaymentRequest) {
            shiftsText = "Payment Request";
            gridTitleIcon = faHandHoldingUsd;
            gridTitleStyle = "payment-requests";
        }
        else if (paymentTypeId == PaymentTypes.AddJob) {
            shiftsText = "Add Job";
            gridTitleIcon = faCalendarCirclePlus
            gridTitleStyle = "artist-jobs"
        }
        else {
            shiftsText = "Requests";
        }
        taxText = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ? "(excl. VAT)" : "(excl. VAT and operational fee)";
        var header: string = `${headerText} - ${shiftsText}`;
        var isEventUser: boolean = false;
        if (
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
        ) {
            isEventUser = true;
        }
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <SimpleShiftsPanel
                        location={this.props.location}
                        serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                        onApplyButtonClick={this.parentIdSet}
                        clientId={this.state.clientId}
                        venueId={this.state.venueId}
                        startDate={this.state.startDate}
                        paymentTypeId = {paymentTypeId ? paymentTypeId : ""}
                        endDate={this.state.endDate}
                        pointOfNavigation={this.props.location.state ? this.props.location.state.pointOfNavigation : ""}
                        billableItemId={this.props.location.state && this.props.location.state.id}
                    />
                    <BulletinPanel />
                </SideBarTemplate>

                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mt-3 mb-3">
                        <GridTitleCard title={header} icon={gridTitleIcon} cardStyle={gridTitleStyle}/>                        
                        <h5 className="font-weight-bold text-center text-lg-left">
                            {dateDisplayText ? dateDisplayText : ""}
                        </h5>
                        {sumTotal && gridLoaded ? (
                            <div className="grid-info__stat">
                                <span className="font-weight-bold">
                                    {sumTotal ? this.utils.thousandsSeparator(parseFloat(sumTotal).toFixed(2)) : ""}
                                </span>
                                <span className="ml-1">{taxText}</span>
                            </div>
                        ) : null}
                    </header>
                    <div className="grid-info__button-container text-center text-lg-left" hidden={!isEventUser}>
                        {isEventUser && (serviceTypeId == ServiceType.Security || serviceTypeId == ServiceType.ContractCleaning || serviceTypeId == ServiceType.Entertainment
                            || serviceTypeId == ServiceType.Gardening || serviceTypeId == ServiceType.WindowCleaning || serviceTypeId == ServiceType.Maintenance || serviceTypeId == ServiceType.Miscelleanous) ?
                            <div>
                                <Link
                                    className={"btn btn-primary btn--large btn--with-icon col-12 col-sm-6 col-xl-4 mb-3"}
                                    to={
                                        {
                                            pathname: "/PaymentRequestForm",
                                            state: {
                                                id: "0",
                                            },
                                        }
                                    }
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </span>
                                    Add Payment Request
                                </Link>
                                &nbsp;&nbsp;
                                <button
                                    className="status-flow__refresh-shiftbutton btn gig-icon-btn"
                                    aria-label="Refresh the current billing status"
                                    onClick={this.onRefreshGrid}
                                >
                                    <FontAwesomeIcon icon={faSync} />
                                </button>  
                            </div> : null}
                    </div>
                    <div className="row">
                        <div className="col-md">
                            {
                                <PaymentRequestGrid
                                    serviceTypeId={this.state.serviceTypeId}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    venueId=""
                                    providerId=""
                                    navigateFrom=""
                                    paymentRequestType=""
                                    setPaymentGridRecordDataSource={() => { }}
                                    gridType=""
                                    isHide={false}
                                    providerPayAmount={undefined}
                                    paymentRequestId={""}
                                    paymentTypeId={paymentTypeId ? paymentTypeId : ""}
                                    location={this.props.location}
                                    history={this.props.history}
                                    getBuild={this.getBuild}
                                    isRefreshButtonClicked={this.state.isRefreshButtonClicked}
                                    resetRefreshGrid={this.resetRefreshGrid}
                                />
                            }
                        </div>
                        <div></div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(withTranslation()(PaymentRequestPage));
