import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DateBox, SelectBox, TextBox, NumberBox, TextArea, LoadPanel, Popup, ScrollView, RadioGroup, TagBox } from "devextreme-react";
import {
    DateBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
    NumberBoxOnValueChangedEvent,
    RadioButtonOnSelectionChanged,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
    TagBoxOnValueChangeEvent,
    OnRowUpdatingEvent,
    OnRowInsertingEvent,
    OnRowRemovingEvent,
    onEditCanceledEvent,
} from "../../types/DevExtremeTypes";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Lookup,
    PatternRule,
    SearchPanel,
    StateStoring,
    Export
} from "devextreme-react/data-grid";
import FindAnArtistStatusFlow from "../FindAnArtist/FindAnArtistStatusFlow";
import LookupService, { LookupTypeIndexes, LookupTypeItem, VenueLookupTypeItem } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import FindAnArtistService, {
    ShowInterestType,
    ShowInterestStatusIdTypes,
    RequestStatus,
    EquipmentTypeList,
    MusicList,
    GenreList,
    findAnArtistCycleStatus,
    DjTypeList,
    WeekList,
} from "../../services/FindAnArtistService";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import { alert } from "devextreme/ui/dialog";
import FindAnArtistUtils, { RequestFormData } from "./FindAnArtistUtils";
import GigHighLevelService from "../../services/GigHighLevelService";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import PendingRequestService, { statusTypes, updateRequest } from "../../services/PendingRequestService";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { ServiceType } from "../../common/ServiceUtilities";
import RestrictRouter from "./RestrictRouter";
import { off } from "devextreme/events";
import GlobalService from "../../services/GlobalService";
import { minuteLookup } from "../Outstanding-Actions/OutstandingActionsUtilities";
import QuickAddShiftUtils from "../QuickAddShift/QuickAddShiftUtils";
import AddEditPopUpService, { QuickBillableItemSubmitRequest } from "../../services/AddEditPopUpService";
import sharedUtils from "../grid/sharedUtilities";
import gridUtils from "../grid/GridUtilities";
import { securityShiftDetails } from "../../services/FindAnArtistService";
import moment from "moment";
import notify from 'devextreme/ui/notify';

interface NewRequestFormProps extends WithTranslation {
    location: any;
    history: any;
    artistBusList: string[];
    renderRedirect: () => void;
    pendingRequestFlag: boolean;
    eventUKIndicator: boolean;
    requestId: string;
    fetchArtistInformation: (artistData: any, bookedListData: any, updateFlag: any) => void;
    updateArtist: (
        formDetails: RequestFormData,
        cardDetails: ShowInterestType[],
        updateClickIndicator: boolean,
        statusId: string,
        requestType: string
    ) => void;
    imageLink: (imagelink: string) => void;
    bookArtist: (formDetails: RequestFormData, cardDetails: ShowInterestType[], requestType: string) => void;
    requestBookData: (formDetails: RequestFormData, cardDetails: ShowInterestType[]) => void;
    requestAmountCalculateData: any;
    isRecalculate: boolean;
    refreshSignal: boolean;
    errorMessage: [];
    disableUpdateButton: boolean;
    disableBookButton: boolean;
    firstTimeRequest: boolean;
    cardUIType: string;
    statusId: string;
    addArtist: (isArtistAdd: boolean) => void;
    getVenue: (client: string, venue: string) => void;
    resetRefreshGig: (flag: boolean) => void;
    confirmAdd: boolean;
    isUpdateSuccess: boolean;
    makeoffAmtForBookedArtist: string;
    disabledButtonOnProcessing: boolean;
    isSetOfferedStatus: boolean;
    isRefreshButtonClicked?: boolean;
    reBookData?: any;
    reBookSuccess?: boolean;
    changeReBookSuccess: (flag: boolean) => void;
    changeDisableButton: (flag: boolean) => void;
    isRecalculateChange: (flag: boolean) => void;
}

export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}

interface NewRequestFormState {

    loadPanelVisible: boolean;
    requestFormData: RequestFormData;
    errorMessage: [];
    typeLookUp: LookupTypeItem[];
    showEqipReqd: boolean;
    showPerfInfo: boolean;
    finishTimeIsMandatory: boolean;
    showInterestList: ShowInterestType[];
    isPublished: boolean;
    isUpdateClicked: boolean;
    sessionInformation: any;
    errorText: string;
    isCancelRequestPopupvisible: boolean;
    cancellationReasonLookUp: LookupTypeItem[];
    cancellationRequestData: updateRequest;
    dateWarningText: string;
    endTimeWarningText: string;
    isDisableFrequency: boolean;
    offeringsTypeLookup: LookupTypeItem[];
    subServiceEquipType: LookupTypeItem[];
    subServicePersonnelType: LookupTypeItem[];
    venueLookUp: VenueLookupTypeItem[];
    statusId: string;
    startDate: NullableDate;
    endDate: NullableDate;
    statusLookUp: LookupTypeItem[];
    frequencyDataSource: LookupTypeItem[];
    subServiceEquipDataSource: LookupTypeItem[];
    subServicePersonnelDataSource: LookupTypeItem[];
    equipmentRequiredList: [];
    djStyleList: [];
    weekList: [];
    musicTypeData: [];
    musicTypeDescription: string;
    genreList: string[];
    equipmentRequiredValues: string;
    djStyleValue: string;
    equipmentTypeLookUp: LookupTypeItem[];
    djStyleLookUp: LookupTypeItem[];
    dayTypeLookUp: LookupTypeItem[];
    musicTypeLookUp: LookupTypeItem[];
    genreLookUp: LookupTypeItem[];
    dateValueList: string[];
    date: NullableDate;
    statusValBeforeChange: string;
    isBlocking: boolean;
    specialistDesc: string;
    isShowSpecialityDesc: boolean;
    isShowReoccuranceDate: boolean;
    isShowDjStyle: boolean;
    isRequestButtonClicked: boolean;
    isShowReplacementReason: boolean;
    showRoundPopup: boolean;
    showRoundPopupMax: boolean; 
    showRoundPopupMakeOffer: boolean;   
    showCalculationPopup: boolean;
    showProviderPopup: boolean;
    newBudget: number;
    clickedNo: boolean;
    disabledButtonOnProcessing: boolean;
    dayLookUp: LookupTypeItemGrid[];
    shiftDetailsPersonnelDataSource: securityShiftDetails[];
    shiftDetailsEquipmentDataSource: securityShiftDetails[];
    requestShifts: any[]
    isCallCalculate: false;
}

// Component
class NewRequestForm extends React.Component<NewRequestFormProps> {
    publishedDateVal: string;
    requesterDetails: string;
    utils: FindAnArtistUtils;
    sharedUtils: sharedUtils;
    quickAddShiftUtils: QuickAddShiftUtils;
    state: NewRequestFormState;
    newReqSvc: FindAnArtistService;
    lookupService: LookupService;
    lookUpService: LookupService;
    requestService: GigHighLevelService;
    pendingRequestService: PendingRequestService;
    addEditPopUpService: AddEditPopUpService;
    featuredList: LookupTypeItem[];
    residencyEndKnownList: LookupTypeItem[];
    replacementArtistList: LookupTypeItem[];
    residencyList: LookupTypeItem[];
    isPublished: boolean;
    buttonClicked: string;
    convertedRequestFormData: any;
    parentMappingServiceId: string;
    gridUtils: gridUtils;
    constructor(props: NewRequestFormProps) {
        super(props);
        this.parentMappingServiceId = "";
        this.publishedDateVal = "";
        this.requesterDetails = "";
        this.isPublished = false;
        this.buttonClicked = "";
        this.newReqSvc = new FindAnArtistService();
        this.lookUpService = new LookupService();
        this.lookupService = new LookupService();
        this.requestService = new GigHighLevelService();
        this.pendingRequestService = new PendingRequestService();
        this.addEditPopUpService = new AddEditPopUpService();
        this.utils = new FindAnArtistUtils();
        this.quickAddShiftUtils = new QuickAddShiftUtils();
        this.sharedUtils = new sharedUtils();
        this.gridUtils = new gridUtils();
        this.featuredList = this.lookUpService.getDecisionTypes();
        this.residencyEndKnownList = this.lookUpService.getResidencyEndKnown();
        this.replacementArtistList = this.lookupService.getReplacementArtist();
        this.residencyList = this.lookUpService.getDecisionTypes();
        let sessionData: any =
            this.props.location.state && this.props.location.state.navigation != "gigRequestAdd" || !this.props.firstTimeRequest || (!this.utils.isBlank(this.props.requestId) && !this.props.requestId) ? this.getSession() : null;
        this.convertedRequestFormData = GlobalService.getGigComingFrom() !== "Entertainment" ? GlobalService.getGigComingFrom() == "Security" ? this.utils.initializeSecurityRequestItem(sessionData) : this.utils.initializeCleaningRequestItem(sessionData) : this.utils.initializeRequestItem(sessionData);
        let convertedCancellationFormData = this.utils.initializeCancellationData();
        let showEqipReqdIndct =
            sessionData && sessionData.serviceSubTypeId ? sessionStorage.getItem("showEqipReqd") : null;
        let showPerfInfoIndct =
            sessionData && sessionData.serviceSubTypeId ? sessionStorage.getItem("showPerfInfo") : null;
        let finishTimeIsMandatoryIndct =
            sessionData && sessionData.serviceSubTypeId ? sessionStorage.getItem("finishTimeIsMandatory") : null;
        // Initialize state

        this.state = {
            loadPanelVisible: false,
            statusId: "",
            startDate: null,
            endDate: null,
            requestFormData: this.convertedRequestFormData,
            // requestFormData: any,
            typeLookUp: [],
            offeringsTypeLookup: [],
            subServiceEquipType: [],
            subServicePersonnelType: [],
            statusLookUp: [],
            frequencyDataSource: [],
            subServiceEquipDataSource: [],
            subServicePersonnelDataSource: [],
            errorMessage: this.props.errorMessage ? this.props.errorMessage : [],
            showEqipReqd: showEqipReqdIndct ? (showEqipReqdIndct == "true" ? true : false) : false,
            showPerfInfo: showPerfInfoIndct ? (showPerfInfoIndct == "true" ? true : false) : false,
            finishTimeIsMandatory: finishTimeIsMandatoryIndct
                ? finishTimeIsMandatoryIndct == "true"
                    ? true
                    : false
                : false,
            showInterestList: [],
            isPublished: false,
            isUpdateClicked: false,
            sessionInformation: sessionData,
            errorText: "",
            isCancelRequestPopupvisible: false,
            cancellationReasonLookUp: [],
            cancellationRequestData: convertedCancellationFormData,
            dateWarningText: "",
            endTimeWarningText: "",
            isDisableFrequency: false,
            equipmentRequiredList: [],
            djStyleList: [],
            weekList: [],
            musicTypeData: [],
            musicTypeDescription: "",
            genreList: [],
            equipmentRequiredValues: "",
            equipmentTypeLookUp: [],
            djStyleLookUp: [],
            dayTypeLookUp: [],
            musicTypeLookUp: [],
            genreLookUp: [],
            dateValueList: [],
            date: undefined,
            statusValBeforeChange: "",
            isBlocking: false,
            venueLookUp: [],
            djStyleValue: "",
            specialistDesc: "",
            isShowSpecialityDesc: false,
            isShowReoccuranceDate: sessionStorage.getItem("isShowReoccuranceDate") == "true" ? true : false,
            isShowDjStyle: false,
            isRequestButtonClicked: false,
            isShowReplacementReason: false,
            showRoundPopup: false,
            showRoundPopupMax: false,
            showRoundPopupMakeOffer: false,
            showCalculationPopup: false,
            showProviderPopup: false,
            newBudget: 0,
            clickedNo: false,
            disabledButtonOnProcessing: false,
            dayLookUp: [],
            shiftDetailsPersonnelDataSource: [],
            shiftDetailsEquipmentDataSource: [],
            requestShifts: [],
            isCallCalculate: false,
        };
    }

    componentDidMount() {
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.subServiceType)
            .then(this.handleSuccessSubServiceLookUp)
            .catch(this.handleError)
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.frequencyType)
            .then(this.handleSuccessFrequencyTypeLookUp)
            .catch(this.handleError)
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.equipmentType)
            .then(this.handleSuccessEquipmentTypeLookUp)
            .catch(this.handleError)
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.djStyle)
            .then(this.handleSuccessDjStyleTypeLookUp)
            .catch(this.handleError)
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.dayType)
            .then(this.handleSuccessDayTypeLookUp)
            .catch(this.handleError)
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.MusicType)
            .then(this.handleSuccessMusicTypeLookUp)
            .catch(this.handleError)
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.Genre)
            .then(this.handleSuccessGenreLookUp)
            .catch(this.handleError)
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.offeringsType)
            .then(this.handleTypeLUSuccess)
            .catch(this.handleError);
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleTypeVenueSuccess)
            .catch(this.handleError);
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.cancellationReason)
            .then(this.handleCancellationLookUpSuccess)
            .catch(this.handleError);
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.offeringsType)
            .then(this.handleRoleLUSuccess)
            .catch(this.handleError);
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.dayType)
            .then(this.handleDayLUSuccess)
            .catch(this.handleError);
        if (this.props.pendingRequestFlag) {
            this.getCardData();
        }
        this.lookupService
            .getLookupByLookupTypeIndexAll(LookupTypeIndexes.requeststatuslookup)
            .then(this.handleRequestLookupSuccess)
            .catch(this.handleStandardError);
    }
    componentDidUpdate = (prevProps: NewRequestFormProps, prevState: NewRequestFormState) => {
        if (
            this.state.isPublished != prevState.isPublished ||
            this.props.refreshSignal != prevProps.refreshSignal ||
            (prevState.sessionInformation &&
                prevState.sessionInformation.id == this.state.sessionInformation.id &&
                !this.props.pendingRequestFlag &&
                !this.utils.isBlank(this.props.requestId) &&
                this.state.showInterestList.length > 0)
        ) {
            this.getCardData(); 
        }
        if(this.props.isRefreshButtonClicked != prevProps.isRefreshButtonClicked && this.props.isRefreshButtonClicked == true){    
            sessionStorage.removeItem("formdata");
            sessionStorage.removeItem("showEqipReqd");
            sessionStorage.removeItem("showPerfInfo");
            sessionStorage.removeItem("finishTimeIsMandatory");
            sessionStorage.removeItem("isPublished");
            sessionStorage.removeItem("isShowReoccuranceDate");
            this.setState({
                requestFormData: [],
                showEqipReqd: false,
                showPerfInfo: false,
                finishTimeIsMandatory: false,
                isPublished: false,
            });
            this.getCardData(); 
        }
        if (prevProps.confirmAdd !== this.props.confirmAdd) {
            this.onUpdate();
        }
        if (prevProps.isUpdateSuccess !== this.props.isUpdateSuccess && this.props.isUpdateSuccess) {
            this.getCardData();
        }
        // if (prevProps.makeoffAmtForBookedArtist !== this.props.makeoffAmtForBookedArtist && this.props.makeoffAmtForBookedArtist !== undefined) {
        //     this.setState({
        //         requestFormData: {
        //             ...this.state.requestFormData,
        //             budget: this.props.makeoffAmtForBookedArtist
        //         }
        //     });
        // }
        if (prevProps.disabledButtonOnProcessing !== this.props.disabledButtonOnProcessing && !this.props.disabledButtonOnProcessing) {
            this.setState({disabledButtonOnProcessing:false});
        }
        if (prevProps.reBookSuccess !== this.props.reBookSuccess && this.props.reBookSuccess) {
            let filterbyId = this.state.requestFormData.billableItemId ? this.state.requestFormData.billableItemId : "";
            this.addEditPopUpService
                .getEditViewData(parseInt(filterbyId))
                .then(this.handleSuccess)
                .catch(this.handleError);
        }
        if(prevProps.isRecalculate !== this.props.isRecalculate || prevProps.requestAmountCalculateData.budget !== this.props.requestAmountCalculateData.budget || prevProps.requestAmountCalculateData.maxBudget !== this.props.requestAmountCalculateData.maxBudget || 
            (this.state.isCallCalculate && prevProps.makeoffAmtForBookedArtist !== this.props.makeoffAmtForBookedArtist && this.props.makeoffAmtForBookedArtist !== undefined && this.props.makeoffAmtForBookedArtist !== "")) {
                this.showLoadpanel();
                if(this.props.makeoffAmtForBookedArtist !== undefined && this.props.makeoffAmtForBookedArtist !== ""){
                    this.props.requestAmountCalculateData.budget = this.props.makeoffAmtForBookedArtist;
                }                
                this.addEditPopUpService
                    .requestAmountCalculation(this.props.requestAmountCalculateData)
                    .then(this.calculationSuccess)
                    .catch(this.handleFormSubmissionFailure); 
        }
        if(prevProps.statusId !== this.props.statusId) {
             if(this.props.statusId == ShowInterestStatusIdTypes.Selected){
                this.setState(
                    {
                        requestFormData: {
                            ...this.state.requestFormData,
                            requestStatusId: "24",
                        },
                    }
                ),
                    () => {
                        this.saveSession(this.state.requestFormData);
                    };
             }
             else {
                this.setState(
                    {
                        requestFormData: {
                            ...this.state.requestFormData,
                            requestStatusId: this.state.statusValBeforeChange,
                        },
                    }
                ),
                    () => {
                        this.saveSession(this.state.requestFormData);
                    };
             }

        }
    };
    //A function that would make the API call and populate the Object which would be displayed on the cards post destructuring.
    getCardData = () => {
        this.showLoadpanel();
        if (this.props.requestId && this.props.requestId != "0") {
            this.requestService
                .getHighLevelCardData(this.props.requestId)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleError);
        }
        else {
            this.hideLoadpanel();
        }
    };

    getRandomImage = () => {
        if (this.state.offeringsTypeLookup && this.state.offeringsTypeLookup.length > 0) {
            if (this.state.requestFormData.serviceSubTypeId && this.state.requestFormData.typeLookUpId) {
                this.requestService.getRandomImage(ServiceType.Entertainment, this.state.requestFormData.serviceSubTypeId, this.state.requestFormData.typeLookUpId)
                    .then(this.handleSuccessEntertainmentTypeAndSubType)
                    .catch(this.handleError)
            }
        }
        else if (this.state.requestFormData.serviceSubTypeId) {
            this.requestService.getRandomImage(ServiceType.Entertainment, this.state.requestFormData.serviceSubTypeId, "")
                .then(this.handleSuccessEntertainmentTypeAndSubType)
                .catch(this.handleError)
        }

    };
    handleSuccessEntertainmentTypeAndSubType = (response: AxiosResponse<any>) => {
        if (response.data.data) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        imageLinkId: response.data.data.id,
                        displayLink: response.data.data.displayLink
                    },
                }
            );
            this.props.imageLink(response.data.data.displayLink);
        }
    }

    handleTypeVenueSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            venueLookUp: response.data.data
        });
    };

    handleRoleLUSuccess = (response: AxiosResponse<any>) => {
        var roleDropDown = this.lookUpCreation(response);       
        return roleDropDown; 
    };

    handleDayLUSuccess = (response: AxiosResponse<any>) => {
        var roleDropDown = this.lookUpCreation(response);
        this.setState({
            dayLookUp: roleDropDown,
        });
    };

    lookUpCreation = (response: AxiosResponse<any>): LookupTypeItemGrid[] => {
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: parseInt(item.id),
                value: item.value,
                parentMappingId: item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };


    handleSuccessEquipmentTypeLookUp = (response: AxiosResponse<any>) => {
        let equipmentTypeLUItem: LookupTypeItem;
        let equipmentTypeLUItemArray: LookupTypeItem[];
        equipmentTypeLUItemArray = [];
        response.data.data.map((item: any) => {
            equipmentTypeLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            equipmentTypeLUItemArray.push(equipmentTypeLUItem);
        });
        this.setState({
            equipmentTypeLookUp: equipmentTypeLUItemArray,
        });
    };

    handleSuccessDjStyleTypeLookUp = (response: AxiosResponse<any>) => {
        let djStyleLUItem: LookupTypeItem;
        let djStyleLUItemmArray: LookupTypeItem[];
        djStyleLUItemmArray = [];
        response.data.data.map((item: any) => {
            djStyleLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            djStyleLUItemmArray.push(djStyleLUItem);
        });
        this.setState({
            djStyleLookUp: djStyleLUItemmArray,
        });
    }

    handleSuccessDayTypeLookUp = (response: AxiosResponse<any>) => {
        let dayTypeLUItem: LookupTypeItem;
        let dayTypeLUItemmArray: LookupTypeItem[];
        dayTypeLUItemmArray = [];
        response.data.data.map((item: any) => {
            dayTypeLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            dayTypeLUItemmArray.push(dayTypeLUItem);
        });
        this.setState({
            dayTypeLookUp: dayTypeLUItemmArray,
        });
    }

    handleSuccessMusicTypeLookUp = (response: AxiosResponse<any>) => {
        let musicTypeLUItem: LookupTypeItem;
        let musicTypeLUItemArray: LookupTypeItem[];
        musicTypeLUItemArray = [];
        response.data.data.map((item: any) => {
            musicTypeLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            musicTypeLUItemArray.push(musicTypeLUItem);
        });
        this.setState({
            musicTypeLookUp: musicTypeLUItemArray,
        });
    };

    handleSuccessGenreLookUp = (response: AxiosResponse<any>) => {
        let genreLUItem: LookupTypeItem;
        let genreLUItemArray: LookupTypeItem[];
        genreLUItemArray = [];
        response.data.data.map((item: any) => {
            genreLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            genreLUItemArray.push(genreLUItem);
        });
        this.setState({
            genreLookUp: genreLUItemArray,
        });
    };

    // set equipment, music type and genre value onload
    setRequestSelectedData = () => {
        this.equipmentRequiredSet();
        this.musictypeSet();
        this.genretypeSet();
        this.djStyleSet();
        this.weekListSet();
    }

    equipmentRequiredSet = () => {
        let equipmentRequiredIdArray: string[];
        equipmentRequiredIdArray = [];
        let equipmentRequiredValueArray: string[];
        let equipmentRequiredValue = "";
        equipmentRequiredValueArray = [];
        if (this.state.requestFormData?.equipmentType && this.state.requestFormData.equipmentType.length > 0) {
            this.state.requestFormData.equipmentType.forEach((item: any, uniqueKey: number) => {
                equipmentRequiredIdArray.push(item.id);
                equipmentRequiredValueArray.push(item.value);
            });
        }
        equipmentRequiredValue = equipmentRequiredValueArray.toString();
        this.setState({
            equipmentRequiredList: equipmentRequiredIdArray,
            equipmentRequiredValues: equipmentRequiredValue
        });
    };

    djStyleSet = () => {
        let djStyleIdArray: string[];
        djStyleIdArray = [];
        let djStyleValueArray: string[];
        let djStyleValue = "";
        djStyleValueArray = [];
        if (this.state.requestFormData.djStyleList) {
            this.state.requestFormData.djStyleList.forEach((item: any, uniqueKey: number) => {
                djStyleIdArray.push(item.id);
                djStyleValueArray.push(item.value);
            });
        }
        djStyleValue = djStyleValueArray.toString();
        this.setState({
            djStyleList: djStyleIdArray,
            djStyleValue: djStyleValue
        });
    }

    musictypeSet = () => {
        let musicTypeIdArray: string[];
        musicTypeIdArray = [];
        let musicTypeDescription;
        if (this.state.requestFormData.musicTypeList) {
            this.state.requestFormData.musicTypeList.forEach((item: any, uniqueKey: number) => {
                musicTypeIdArray.push(item.id);
                if (item.description !== "") musicTypeDescription = item.description
            });
        }
        this.setState({
            musicTypeData: musicTypeIdArray,
            musicTypeDescription: musicTypeDescription
        });
    };

    genretypeSet = () => {
        let genreIdArray: string[];
        let genreTypeDesc = "";
        genreIdArray = [];
        if (this.state.requestFormData.genreList) {
            this.state.requestFormData.genreList.forEach((item: any, uniqueKey: number) => {
                genreIdArray.push(item.id);
                if (item.description !== "") genreTypeDesc = item.description
            });
        }
        this.setState({
            genreList: genreIdArray,
            specialistDesc: genreTypeDesc
        });
    };

    weekListSet = () => {
        let weekListArray: string[];
        weekListArray = [];
        let weekListValueArray: string[];
        let weekListValue = "";
        weekListValueArray = [];
        if (this.state.requestFormData.daysOfWeekList) {
            this.state.requestFormData.daysOfWeekList.forEach((item: any, uniqueKey: number) => {
                weekListArray.push(item.id.toString());
                weekListValueArray.push(item.value);
            });
        }
        weekListValue = weekListValueArray.toString();
        this.setState({
            weekList: weekListArray,
            weekListValue: weekListValue
        });
    }

    setNavBlocking = (prevValue: any, currentValue: any) => {
        if (prevValue !== currentValue && !this.state.isRequestButtonClicked) {
            this.setState({ isBlocking: true })
        }
    }

    handleChangeEquipmentType = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setNavBlocking(this.state.equipmentRequiredList, dxValue.value);
        if (this.state.equipmentRequiredList.length !== dxValue.value.length) {
            this.setState({
                equipmentRequiredList: dxValue.value
            });
            this.setEquipmentTypeListOnChange(dxValue.value);
        }
    };

    handleChangeDjStyleType = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setNavBlocking(this.state.djStyleList, dxValue.value);
        if (this.state.djStyleList.length !== dxValue.value.length) {
            this.setState({
                djStyleList: dxValue.value
            });
            this.setDjStyleListOnChange(dxValue.value);
        }
    }

    handleChangeDays = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setNavBlocking(this.state.weekList, dxValue.value);
        if (this.state.weekList.length !== dxValue.value.length) {
            this.setState({
                weekList: dxValue.value
            });
            this.setWeekListOnChange(dxValue.value);
        }
    }

    handleChangeHours = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    hours: dxValueChange.value
                }
            });
        }
    };

    handleChangeMinutes = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    minutes: dxValueChange.value
                }
            });
        }
    };

    onContentReadyNumberBox = (e: any) => {
        this.onClickElementSelection(e);
    }

    onClickElementSelection = (e: any) => {
        e.element.addEventListener('click', function () {
            let inputElement = e.element.querySelector(".dx-texteditor-input");
            inputElement.selectionStart = 0;
            inputElement.selectionEnd = inputElement.value.length;
        });
    }

    // to check the item in array object to add condition just like description for music type tribute
    checkArrayObj = (array: any, key: any, value: any) => {
        if (array !== undefined && array.length > 0) {
            return array.some((object: { [x: string]: any; }) => object[key] === value);
        }
    }

    handleChangeMusicType = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setNavBlocking(this.state.musicTypeData, dxValue.value);
        if (this.state.musicTypeData.length !== dxValue.value.length) {
            this.setState({
                musicTypeData: dxValue.value
            });
            this.setMusicTypeListOnChange(dxValue.value);
        }
    };

    handleChangeTributeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.musicTypeDescription, dxValueChange.value);
        if (dxValueChange.value !== undefined && this.state.musicTypeDescription !== dxValueChange.value) {
            this.setState({
                musicTypeDescription: dxValueChange.value
            });
            this.setTributeDescriptionOnChange(dxValueChange.value);
        }
    };

    handleChangeGenre = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setNavBlocking(this.state.genreList, dxValue.value);
        if (this.state.genreList.length !== dxValue.value.length) {
            this.setState({
                genreList: dxValue.value
            });
            if (this.state.genreList.includes("99")) {
                this.setState({
                    isShowSpecialityDesc: true
                });
            }
            else {
                this.setState({
                    isShowSpecialityDesc: false,
                    specialistDesc: ""
                });
            }
            this.setGenreListOnChange();
        }
    };

    setEquipmentTypeListOnChange = (listOfEquipmentType: number[]) => {
        let equipmentTypeList: EquipmentTypeList;
        let equipmentTypeListArray: EquipmentTypeList[];
        equipmentTypeListArray = [];
        listOfEquipmentType.forEach((item: any) => {
            equipmentTypeList = {
                id: item,
                value: "",
            };
            equipmentTypeListArray.push(equipmentTypeList);
        });
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                equipmentType: equipmentTypeListArray,
            },
        },
            () => {
                this.saveSession(this.state.requestFormData);
            });
    };

    setDjStyleListOnChange = (listOfDjStyleType: number[]) => {
        let djStyleList: DjTypeList;
        let djStyleListArray: DjTypeList[];
        djStyleListArray = [];
        listOfDjStyleType.forEach((item: any) => {
            djStyleList = {
                id: item,
                value: "",
            };
            djStyleListArray.push(djStyleList);
        });
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                djStyleList: djStyleListArray,
            },
        },
            () => {
                this.saveSession(this.state.requestFormData);
            });
    };

    setWeekListOnChange = (listOfDayType: number[]) => {
        let weekListArray: WeekList[];
        weekListArray = [];
        listOfDayType.forEach((item: any) => {
            weekListArray.push(item);
        });
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                dayOfWeekList: weekListArray,
            },
        });
    };

    setMusicTypeListOnChange = (listOfMusicType: number[]) => {
        let musicTypeSelect: MusicList;
        let musicTypeListArray: MusicList[];
        musicTypeListArray = [];
        listOfMusicType.forEach((item: any) => {
            musicTypeSelect = {
                id: item,
                value: "",
                description: "",
            };
            musicTypeListArray.push(musicTypeSelect);
        });
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                musicTypeList: musicTypeListArray,
            },
        },
            () => {
                this.saveSession(this.state.requestFormData);
            });
    };

    setTributeDescriptionOnChange = (description: string) => {
        let musicTypeSelect: MusicList;
        let musicTypeListArray: MusicList[];
        musicTypeListArray = [];
        this.state.musicTypeData.forEach((item: any) => {
            if (item == "20") {
                musicTypeSelect = {
                    id: item,
                    value: "",
                    description: description,
                };
            }
            else {
                musicTypeSelect = {
                    id: item,
                    value: "",
                    description: "",
                };
            }
            musicTypeListArray.push(musicTypeSelect);
        });
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                musicTypeList: musicTypeListArray,
            },
        },
            () => {
                this.saveSession(this.state.requestFormData);
            });
    };

    setGenreListOnChange = () => {
        let genreList: GenreList;
        let genreListArray: GenreList[];
        genreListArray = [];
        this.state.genreList.forEach((item: any) => {
            if (item == "99") {
                genreList = {
                    id: item,
                    value: "",
                    description: this.state.specialistDesc
                };
            }
            else {
                genreList = {
                    id: item,
                    value: "",
                    description: "",
                };
            }
            genreListArray.push(genreList);
        });
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                genreList: genreListArray,
            },
        },
            () => {
                this.saveSession(this.state.requestFormData);
            });
    };


    handleRequestLookupSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            statusLookUp: response.data.data,
        });
    }

    handleSuccessSubServiceLookUp = (response: AxiosResponse<any>) => { 
        let equipDataSource = response.data.data.filter((item: any) => item.id == "12");
        let personnelDataSource = response.data.data.filter((item: any) => item.id == "11");
        this.setState({
            subServiceEquipDataSource: equipDataSource,
            subServicePersonnelDataSource: personnelDataSource
        });
    }


    handleSuccessFrequencyTypeLookUp = (response: AxiosResponse<any>) => { 
        this.setState({
            frequencyDataSource: response.data.data,
        });
    }

    handleStandardError = () => {

    }

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        this.hideLoadpanel();  
        if (this.state.requestFormData.additionalInformation == "" || this.props.isUpdateSuccess || this.props.isRefreshButtonClicked == true) {
            this.props.resetRefreshGig(false);
            let viewDataSource = this.utils.convertServerResponseToFormData(response.data.data);
            let showInterestDetails = response.data.data.showInterest;
            let isLiveMusic = viewDataSource && viewDataSource.serviceSubTypeId == "31" ? true : false;
            let isDJ = viewDataSource && viewDataSource.serviceSubTypeId == "32" ? true : false;
            let alreadyBookedList =
                showInterestDetails && showInterestDetails.length > 0
                    ? showInterestDetails.filter((item: ShowInterestType) => {
                        return item.showInterestStatusId == ShowInterestStatusIdTypes.Booked;
                    })
                    : [];
            if (viewDataSource.reoccurrence == "No") {
                this.setState({
                    isDisableFrequency: true
                });
            }
            let shiftPersonnelData:any = [];
            let shiftEquipmentData:any = [];
            if(viewDataSource?.shiftDetails && viewDataSource?.shiftDetails?.length > 0){
                viewDataSource?.shiftDetails.forEach((item: any) => {
                    if(item.serviceSubTypeId == "11"){
                        shiftPersonnelData.push(item);
                    }
                    else if(item.serviceSubTypeId == "12"){
                        shiftEquipmentData.push(item);
                    }         
                });
            }

            this.setState(
                {
                    requestFormData: viewDataSource,
                    showEqipReqd: isDJ ? true : false,
                    showPerfInfo: isLiveMusic ? true : false,
                    showInterestList: showInterestDetails,
                    isShowDjStyle: isDJ ? true : false,
                    shiftDetailsEquipmentDataSource: shiftEquipmentData,
                    shiftDetailsPersonnelDataSource: shiftPersonnelData
                },
                () => { this.setRequestSelectedData() }
            );

            if (viewDataSource.publishedDate) {
                let formatedDate = this.updateLocaleTime(viewDataSource.publishedDate);
                this.publishedDateVal = `(Published Time ${formatedDate})`;
            }
            if (this.state.requestFormData?.requestApprover !== null && this.state.requestFormData?.requestApproverPhoneNumber !== null) {
                this.requesterDetails = `Requester: ${this.state.requestFormData?.requestApprover} (${this.state.requestFormData?.requestApproverPhoneNumber})`;
            }
            if (this.state.requestFormData?.requestApprover !== null && this.state.requestFormData?.requestApproverPhoneNumber == null) {
                this.requesterDetails = `Requester: ${this.state.requestFormData?.requestApprover}`;
            }
            let displayLink = viewDataSource?.displayLink ? viewDataSource.displayLink : ""
            this.props.imageLink(displayLink);
            this.props.fetchArtistInformation(showInterestDetails, alreadyBookedList, this.state.isUpdateClicked)
            this.filterDataSource(this.state.typeLookUp, viewDataSource.serviceSubTypeId);
        }
        else {
            let showInterestDetails = response.data.data.showInterest;
            let isLiveMusic = this.state.requestFormData?.serviceSubTypeId == "31" ? true : false;
            let isDJ = this.state.requestFormData?.serviceSubTypeId == "32" ? true : false;
            let alreadyBookedList =
                showInterestDetails && showInterestDetails.length > 0
                    ? showInterestDetails.filter((item: ShowInterestType) => {
                        return item.showInterestStatusId == ShowInterestStatusIdTypes.Booked;
                    })
                    : [];
            if (this.state.requestFormData?.reoccurrence == "No") {
                this.setState({
                    isDisableFrequency: true
                });
            }

            this.setState(
                {
                    showEqipReqd: isDJ ? true : false,
                    showPerfInfo: isLiveMusic ? true : false,
                    showInterestList: showInterestDetails,
                    isShowDjStyle: isDJ ? true : false
                },
                () => { this.setRequestSelectedData() }
            );
            if (this.state.requestFormData?.publishedDate) {
                let formatedDate = this.updateLocaleTime(this.state.requestFormData?.publishedDate);
                this.publishedDateVal = `(Published Time ${formatedDate})`;
            }
            if (this.state.requestFormData?.requestApprover !== null && this.state.requestFormData?.requestApproverPhoneNumber !== null) {
                this.requesterDetails = `Requester: ${this.state.requestFormData?.requestApprover} (${this.state.requestFormData?.requestApproverPhoneNumber})`;
            }
            if (this.state.requestFormData?.requestApprover !== null && this.state.requestFormData?.requestApproverPhoneNumber == null) {
                this.requesterDetails = `Requester: ${this.state.requestFormData?.requestApprover}`;
            }
            let displayLink = this.state.requestFormData?.displayLink ? this.state.requestFormData.displayLink : "";
            this.props.imageLink(displayLink);
            this.props.fetchArtistInformation(showInterestDetails, alreadyBookedList, this.state.isUpdateClicked)
            this.filterDataSource(this.state.typeLookUp, this.state.requestFormData?.serviceSubTypeId);
        }

        sessionStorage.setItem("showEqipReqd", JSON.stringify(this.state.showEqipReqd));
        sessionStorage.setItem("showPerfInfo", JSON.stringify(this.state.showPerfInfo));
        sessionStorage.setItem("finishTimeIsMandatory", JSON.stringify(this.state.finishTimeIsMandatory));
    };

    handleCancellationLookUpSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data && response.data.data && response.data.data.length > 0) {
            let cancellationDropDown = this.utils.lookUpCreation(response);
            this.setState({
                cancellationReasonLookUp: cancellationDropDown
            });
        }
    }

    handleTypeLUSuccess = (response: AxiosResponse<any>) => {
        let typeDropDown = this.utils.lookUpCreation(response);
        let equipDataSource = typeDropDown.filter((item: any) => item.parentMappingId == "12");
        let personnelDataSource = typeDropDown.filter((item: any) => item.parentMappingId == "11");
        this.setState({
            typeLookUp: typeDropDown,            
            subServiceEquipType: equipDataSource,
            subServicePersonnelType: personnelDataSource
        });
    };
    filterDataSource = (data: LookupTypeItem[], filterId: string) => {
        let typeLookupItems = !this.utils.isBlank(filterId)
            ? data.filter((item: LookupTypeItem) => {
                return item.parentMappingId === filterId;
            })
            : data;        
        this.setState({
            offeringsTypeLookup: typeLookupItems
        }, () => { this.getRandomImage() });
    };
    handleError = (err: any) => {
        this.props.resetRefreshGig(false);
        this.setState({
            errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data?.error)) : null, // For capturing response at the time of exception and showing error message
            showError: true,
            loadPanelVisible: false,
            disabledButtonOnProcessing: false,
        });
    };
    handleChangeStatus = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        let statusValBeforeChange: string = "";
        if (this.state.requestFormData.requestStatusId) {
            statusValBeforeChange = this.state.requestFormData.requestStatusId;
            this.setState({statusValBeforeChange: statusValBeforeChange})
        }
        if (this.state.requestFormData.requestStatusId !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        requestStatusId: dxValueChange.value
                    },
                    statusValBeforeChange: statusValBeforeChange
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }        
        if (dxValueChange.value == RequestStatus.Published) {
            this.isPublished = true;
        }
    }

    handleChangeClientSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.clientBusinessEntityId, dxValueChange.value);
        if (this.state.requestFormData.clientBusinessEntityId !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        clientBusinessEntityId: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };

    handleChangeFrequency = (dxValueChange: SelectBoxOnValueChangedEvent) => { 
        this.setNavBlocking(this.state.requestFormData.reoccurrenceFrequency, dxValueChange.value);
        if (this.state.requestFormData.reoccurrenceFrequency !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        reoccurrenceFrequency: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    }
    handleChangeVenueSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        let venues = this.state.venueLookUp.filter((item: VenueLookupTypeItem) => {
            return item.id == dxValueChange.value;
        });
        this.setNavBlocking(this.state.requestFormData.venueId, dxValueChange.value);
        if (this.state.requestFormData.venueId !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        venueId: dxValueChange.value,
                        venueTypeId: venues.length > 0 ? venues[0].venueTypeId : ""
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };

    handleChangeVenueTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.venueTypeId, dxValueChange.value);
        if (this.state.requestFormData.venueTypeId !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        venueTypeId: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };
    handleChangeTypeLookUpSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setNavBlocking(this.state.requestFormData.typeLookUpId, dxValueChange.value);
            if (this.state.requestFormData.typeLookUpId !== dxValueChange.value) {
                this.setState(
                    {
                        requestFormData: {
                            ...this.state.requestFormData,
                            typeLookUpId: dxValueChange.value,
                        },
                    },
                    () => {
                        this.saveSession(this.state.requestFormData);
                        this.getRandomImage();
                    }
                );
            }
        }
    };
    handleChangeFeatured = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setNavBlocking(this.state.requestFormData.featured, dxValue.value);
        if (this.state.requestFormData.featured !== dxValue.value) {
            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    featured: dxValue.value
                },
            },
                () => {
                    this.saveSession(this.state.requestFormData);
                });
        }
    }

    handleChangerReplacementReason = (dxValueChange: RadioButtonOnSelectionChanged) => {
        this.setState({
            cancellationRequestData: {
                ...this.state.cancellationRequestData,
                rePublishGigRequest: JSON.parse(dxValueChange.value)
            }
        });
    }

    handleChangeResidencyEndKnown = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setNavBlocking(this.state.requestFormData.reoccurrenceEndDateKnown, dxValue.value);
        if (this.state.requestFormData.reoccurrenceEndDateKnown !== dxValue.value) {
            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    reoccurrenceEndDateKnown: dxValue.value,
                    reoccurrenceEndDate: null
                },
            },
                () => {
                    this.saveSession(this.state.requestFormData);
                });
        }
        if (this.state.requestFormData.reoccurrenceEndDateKnown == "Yes") {
            this.setState({
                isShowReoccuranceDate: true
            },
                () => {
                    sessionStorage.setItem("isShowReoccuranceDate", JSON.stringify(this.state.isShowReoccuranceDate));
                });
        }
        else {
            this.setState({
                isShowReoccuranceDate: false
            },
                () => {
                    sessionStorage.setItem("isShowReoccuranceDate", JSON.stringify(this.state.isShowReoccuranceDate));
                });
        }
    }

    handleChangeResidency = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setNavBlocking(this.state.requestFormData.reoccurrence, dxValue.value);
        if (this.state.requestFormData.reoccurrence !== dxValue.value) {
            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    reoccurrence: dxValue.value
                },
            },
                () => {
                    this.saveSession(this.state.requestFormData);
                });
            if (dxValue.value == "No") {
                this.setState({
                    requestFormData: {
                        ...this.state.requestFormData,
                        reoccurrenceFrequency: ""
                    },
                    isDisableFrequency: true
                },
                    () => {
                        this.saveSession(this.state.requestFormData);
                    });
            }
            else if (dxValue.value == "Yes") {
                this.setState({
                    isDisableFrequency: false
                });
            }
        }
    }

    handleChangeSubServiceTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setNavBlocking(this.state.requestFormData.serviceSubTypeId, dxValueChange.value);
            if (this.state.requestFormData.serviceSubTypeId !== dxValueChange.value) {
                this.setState(
                    {
                        requestFormData: {
                            ...this.state.requestFormData,
                            serviceSubTypeId: dxValueChange.value,
                            typeLookUpId: "",
                            djStyleList: [],
                            otherDescription: "",
                            reoccurrence: "",
                            reoccurrenceFrequency: "",
                            dayOfWeekList: [],
                            hours: 0,
                            minutes: "",
                            rate: 0
                        },
                        isShowDjStyle: dxValueChange.value == 32 ? true : false,
                        showEqipReqd: dxValueChange.value == 32 ? true : false,
                        showPerfInfo: dxValueChange.value == 31 ? true : false,
                        finishTimeIsMandatory:
                            dxValueChange.value == 32 || dxValueChange.value == 33 || dxValueChange.value == 34 ? true : false,
                    },
                    () => {
                        this.saveAllSesionData();
                        this.filterDataSource(this.state.typeLookUp, this.state.requestFormData.serviceSubTypeId);
                    }
                );
            }
        }
    };
    saveAllSesionData = () => {
        this.saveSession(this.state.requestFormData);
        sessionStorage.setItem("showEqipReqd", JSON.stringify(this.state.showEqipReqd));
        sessionStorage.setItem("showPerfInfo", JSON.stringify(this.state.showPerfInfo));
        sessionStorage.setItem("finishTimeIsMandatory", JSON.stringify(this.state.finishTimeIsMandatory));
    };
    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.additionalInformation, dxValueChange.value);
        if (this.state.requestFormData.additionalInformation !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        additionalInformation: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };
    handleChangeHeadline = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.entertainmentDescription, dxValueChange.value);
        if (this.state.requestFormData.entertainmentDescription !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        entertainmentDescription: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };

    handleChangeSpecialistGenre = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.specialistDesc, dxValueChange.value);
        if (this.state.specialistDesc !== dxValueChange.value) {
            this.setState(
                {
                    specialistDesc: dxValueChange.value
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                    this.setGenreListOnChange();
                }
            );
        }
    }

    handleChangeComment = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.internalComments, dxValueChange.value);
        if (this.state.requestFormData.internalComments !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        internalComments: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };

    handleChangeOtherDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.otherDescription, dxValueChange.value);
        if (this.state.requestFormData.otherDescription !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        otherDescription: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };

    handleChangeWorkDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.dateFrom, dxValueChange.value);
        if (this.state.requestFormData.dateFrom !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        dateFrom: dxValueChange.value,
                    }
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
        this.pastDateWarningSet(dxValueChange.value);
    };

    handleChangeDateTo = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.dateTo, dxValueChange.value);
        if (this.state.requestFormData.dateTo !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        dateTo: dxValueChange.value,
                    }
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
        this.pastDateWarningSet(dxValueChange.value);
    };

    handleChangeWorkDatePicker = (dxValueChange: any) => {        
        this.setNavBlocking(this.state.date, dxValueChange.value);
        if (this.state.date !== dxValueChange) {
            this.setState(
                {
                    date: dxValueChange
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                });
            this.pastDateWarningSet(dxValueChange);
        }
    };

    handleChangeEquipment = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.equipmentRequired, dxValueChange.value);
        if (this.state.requestFormData.equipmentRequired !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        equipmentRequired: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };
    handleChangeStartTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.startTime, dxValueChange.value);
        if (this.state.requestFormData.startTime !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        startTime: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };
    handleChangeEndTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.finishTime, dxValueChange.value);
        if (this.state.requestFormData.finishTime !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        finishTime: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
        this.endTimeWarningSet();
    };

    handleChangeCancellationRequest = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState(
            {
                cancellationRequestData: {
                    ...this.state.cancellationRequestData,
                    cancellationreasonId: dxValueChange.value,
                },
            }, () => { this.showReplacementReason(dxValueChange.value) });
    }

    showReplacementReason = (cancelReasonId: string) => {
        if (cancelReasonId == "30") {
            this.setState({
                isShowReplacementReason: true
            });
        }
        else {
            this.setState({
                isShowReplacementReason: false
            });
        }
    }

    handleChangeBudget = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.budget, dxValueChange.value);
        if (this.state.requestFormData.budget !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        budget: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };
    handleChangeMaxBudget = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.maxBudget, dxValueChange.value);
        if (this.state.requestFormData.maxBudget !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        maxBudget: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };
    handleChangeMarketplaceBudget = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.marketPlaceBudget, dxValueChange.value);
        if (this.state.requestFormData.marketPlaceBudget !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        marketPlaceBudget: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };

    handleChangeProviderNet = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (this.state.requestFormData.providerPayAmountLocal !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        providerPayAmountLocal: dxValueChange.value,
                    },
                });
        }
    };
    handleChangeEventNet = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (this.state.requestFormData.grossProfitAmountLocal !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        grossProfitAmountLocal: dxValueChange.value,
                    },
                });
        }
    };
    handleChangeClientNet = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (this.state.requestFormData.clientBillableAmountLocal !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        clientBillableAmountLocal: dxValueChange.value,
                    },
                });
        }
    };
    handleChangeSetNumber = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.performanceInformationSetsNumber, dxValueChange.value);
        if (this.state.requestFormData.performanceInformationSetsNumber !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        performanceInformationSetsNumber: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };
    handleChangeDuration = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setNavBlocking(this.state.requestFormData.performanceInformationSetsDuration, dxValueChange.value);
        if (this.state.requestFormData.performanceInformationSetsDuration !== dxValueChange.value) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        performanceInformationSetsDuration: dxValueChange.value,
                    },
                },
                () => {
                    this.saveSession(this.state.requestFormData);
                }
            );
        }
    };

    handleChangeProviderTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                providerBusinessEntityId: dxValueChange.value
            },
        });
    }

    handleChangeRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    rate: dxValueChange.value
                }
            });
        }
    };

    // to check rounded budget
    setRoundedBudget = (clickedFrom: string) => {
        this.buttonClicked = clickedFrom;
        let serviceType = GlobalService.getGigComingFrom(); 
        if(this.props.makeoffAmtForBookedArtist !== undefined && this.props.makeoffAmtForBookedArtist !== "") {
            this.setRoundBudgetMakeOffer();
        }      
        else if (this.state.requestFormData.budget == 0 && this.state.requestFormData.maxBudget !== 0 && serviceType !== "ContractCleaning"  && serviceType !== "Security"){
            this.setRoundMaxBudget();
        }
        else if(this.state.requestFormData.budget !== 0 && serviceType !== "ContractCleaning"  && serviceType !== "Security"){
            this.setRoundBudget();
        }
        else{
            this.submitRequestForm();
        }
    }

    setRoundBudgetMakeOffer = () => {
        let serviceType = GlobalService.getGigComingFrom();        
        if(serviceType !== "ContractCleaning"  && serviceType !== "Security"){
            this.setState({ 
                showRoundPopupMakeOffer: true,
                newBudget: 0,
            })
        }
        else{
            this.submitRequestForm();
        }
    }

    setRoundBudget = () => {
        let budget = this.state.requestFormData?.budget ? this.state.requestFormData.budget : 1;
        let serviceType = GlobalService.getGigComingFrom();        
        if(this.state.requestFormData?.budget !== 0 && serviceType !== "ContractCleaning"  && serviceType !== "Security"){
            let roundedValue = (Math.round(budget / 5) * 5);
            this.setState({ 
                showRoundPopup: true,
                newBudget: roundedValue,
            })
        }
        else{
            this.submitRequestForm();
        }
    }

    setRoundMaxBudget = () => {
        let budget = this.state.requestFormData?.maxBudget ? this.state.requestFormData.maxBudget : 1;
        // let decimalValue = budget ? budget % 1 : 0;  
        // let budgetValue = decimalValue == 0 && budget ? budget % 5 : decimalValue;
        let serviceType = GlobalService.getGigComingFrom();        
        if(this.state.requestFormData?.maxBudget !== 0 && serviceType !== "ContractCleaning"  && serviceType !== "Security"){
            let roundedValue = (Math.round(budget / 5) * 5);
            this.setState({ 
                showRoundPopupMax: true,
                newBudget: roundedValue,
            })
        }
        else{
            this.submitRequestForm();
        }
    }

    // initiate request to submit Gig or cleaning
    submitRequestForm = () => {
        this.props.changeDisableButton(true);
        this.setState({
            disabledButtonOnProcessing: true,
            newBudget: 0,
        });
        switch (this.buttonClicked) {
            case "publishedAdd":
                this.addPublish("ADD");
                break;
            case "update":
                this.onUpdate();
                break;
            case "book":
                this.onBook();
                break; 
            case "re-book":
                this.onReBook();
                break; 
            case "securityBook":
                this.onSecurityBook();
                break; 
            case "publish":
                this.addPublish("PUBLISH");
                break;  
        }
    }


    addPublish = (navigateFrom: string) => {
        this.endTimeWarningSet();
        this.pastDateWarningSet(this.state.requestFormData.dateFrom);
        let statusId: string = "";
        if (navigateFrom.toLowerCase() == "add") {
            if(this.props.isSetOfferedStatus == true){
                statusId = RequestStatus.Offered;
            }
            else{
                statusId = RequestStatus.Pending;
            }            
            this.serviceCallforAddPublish(statusId);
        }
        else if (navigateFrom.toLowerCase() == "publish" && this.state.requestFormData.id && this.state.requestFormData.id == "0") {
            this.setState({ isBlocking: false, isRequestButtonClicked: true })
            if(this.props.isSetOfferedStatus == true){
                statusId = RequestStatus.Offered;
            }
            else{
                statusId = RequestStatus.Published;
            }             
            this.serviceCallforAddPublish(statusId);
        }
        else if (navigateFrom.toLowerCase() == "publish" && this.state.requestFormData.id && this.state.requestFormData.id != "0") {
            this.setState({ isBlocking: false, isRequestButtonClicked: true })
            if(this.props.isSetOfferedStatus == true){
                statusId = RequestStatus.Offered;
            }
            else{
                statusId = RequestStatus.Published;
            } 
            this.serviceCallEditPublish(statusId);
        }

    }

    setSecurityRequest = (key: any, values:any) => {
        if(this.state.requestFormData.reoccurrence == "Yes"){
            if(this.state.requestShifts.find((x:any) => x.dayOfWeek === key.toString())){
                let index = this.state.requestShifts ? this.state.requestShifts?.findIndex(item => item.dayOfWeek == key.toString()) : -1;
                this.state.requestShifts[index].shiftDetails.push(values);
            }
            else{
                let shiftDetailsInfo={
                    dayOfWeek: key.toString(),
                    shiftDetails: [values],
                }
                this.state.requestShifts?.push(shiftDetailsInfo);
            }
        }
        else{
            if(this.state.requestShifts.find((x:any) => (moment(x.dateFrom.split("/").reverse().join("-")).isSame(moment(key.split("/").reverse().join("-")))) == true)){
                let index = this.state.requestShifts ? this.state.requestShifts?.findIndex(item => (moment(item.dateFrom.split("/").reverse().join("-")).isSame(key.split("/").reverse().join("-"))) == true) : -1;   
                this.state.requestShifts[index].shiftDetails.push(values);
            }
            else{
                let shiftDetailsInfo={
                    dateFrom: key,
                    shiftDetails: [values],
                }
                this.state.requestShifts?.push(shiftDetailsInfo);
            }
        }
        
    }

    serviceCallforAddPublish = (statusId: string) => {
        let dateFromArr: string[] = [];
        let gigType = GlobalService.getGigComingFrom();
        if (this.state.date) {
            let dateFromSubArr = this.state.date.toString().split(",");
            if (dateFromSubArr.length > 0) {
                dateFromSubArr.forEach((item: any) => {
                    dateFromArr.push(item);
                })
            }
        }
        let newRequestSubmitRequest = gigType !== "Entertainment" ? gigType == "Security" ? this.utils.convertFormDataToNewRequestSecurity(
            this.state.requestFormData,
            statusId,
            dateFromArr
        ) : this.utils.convertFormDataToNewRequestCleaning(
            this.state.requestFormData,
            statusId,
            dateFromArr
        )
        : this.utils.convertFormDataToNewRequest(
            this.state.requestFormData,
            this.props.artistBusList,
            this.props.eventUKIndicator,
            statusId,
            dateFromArr
        );
        if (this.props.location.state && this.props.location.state.navigation && 
            this.props.location.state.navigation == "gigRequestAdd" && gigType == "Security") {
            if(this.state.requestFormData?.shiftDetails && this.state.requestFormData?.shiftDetails.length > 0){
                this.setState({requestShifts:[]});
                if(this.state.requestFormData.reoccurrence == "Yes"){
                    this.state.requestFormData?.shiftDetails.forEach((item: any) => {
                        this.setSecurityRequest(item.request_Days_ID, item);                   
                    });
                }
                else{
                    this.state.requestFormData?.shiftDetails.forEach((item: any) => {
                        this.setSecurityRequest(item.date, item);                   
                    }); 
                }
            }
            newRequestSubmitRequest.shiftDetails = [];
            newRequestSubmitRequest.requestShifts = this.state.requestShifts; 
            this.newReqSvc
                .saveNewArtistRequest(newRequestSubmitRequest)
                .then(this.handleFormDataSubmissionSuccess)
                .catch(this.handleFormSubmissionFailure)
                .finally(() => {
                    this.setState({disabledButtonOnProcessing: false});
                    this.hideLoadpanel();
                });
        }
        else{
            this.newReqSvc
                .saveNewArtistRequest(newRequestSubmitRequest)
                .then(this.handleFormDataSubmissionSuccess)
                .catch(this.handleFormSubmissionFailure)
                .finally(() => {
                    this.setState({disabledButtonOnProcessing: false});
                    this.hideLoadpanel();
                });
        }
    }

    handleFormDataSubmissionSuccess = () => {
        this.hideLoadpanel();
        sessionStorage.removeItem("formdata");
        sessionStorage.removeItem("showEqipReqd");
        sessionStorage.removeItem("showPerfInfo");
        sessionStorage.removeItem("finishTimeIsMandatory");
        sessionStorage.removeItem("isPublished");
        sessionStorage.removeItem("isShowReoccuranceDate");
        this.setState({
            requestFormData: [],
            showEqipReqd: false,
            showPerfInfo: false,
            finishTimeIsMandatory: false,
            isPublished: false,
            disabledButtonOnProcessing: false,
        });
        if ((this.props.location.state && this.props.location.state.navigation && this.props.location.state.navigation == "gigRequestAdd") || this.props.requestId && this.props.requestId != "0") {
            this.props.history.goBack();
        }
        else {
            this.props.renderRedirect();
        }
    };

    hideLoadpanel = () => {
        this.setState({ loadPanelVisible: false });
    };
    showLoadpanel = () => {
        this.setState({
            loadPanelVisible: true,
        });
    };
    handleFormSubmissionFailure = (err: any) => {
        this.hideLoadpanel();
        this.setState({
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null,
            showError: true,
            disabledButtonOnProcessing: false,
        });
        this.props.changeReBookSuccess(false);
        this.props.isRecalculateChange(false);
    };
    onClose = () => {
        if(this.state.clickedNo == true){
            sessionStorage.removeItem("formdata");
            sessionStorage.removeItem("showEqipReqd");
            sessionStorage.removeItem("showPerfInfo");
            sessionStorage.removeItem("finishTimeIsMandatory");
            sessionStorage.removeItem("isPublished");
            sessionStorage.removeItem("isShowReoccuranceDate");
            this.setState({
                requestFormData: [],
                showEqipReqd: false,
                showPerfInfo: false,
                finishTimeIsMandatory: false,
                isPublished: false,
            });
        }
        this.props.history.goBack();
    };
    onCancel = () => {
        sessionStorage.removeItem("formdata");
        sessionStorage.removeItem("showEqipReqd");
        sessionStorage.removeItem("showPerfInfo");
        sessionStorage.removeItem("finishTimeIsMandatory");
        sessionStorage.removeItem("isPublished");
        sessionStorage.removeItem("isShowReoccuranceDate");
        this.setState({
            requestFormData: [],
            showEqipReqd: false,
            showPerfInfo: false,
            finishTimeIsMandatory: false,
            isPublished: false,
        });
        this.props.renderRedirect();
    };
    onUpdate = () => {
        if (this.props.location.state && this.props.location.state.navigation && this.props.location.state.navigation == "gigRequestAdd") {
            this.addPublish("ADD");
            this.setState({
                isBlocking: false,
                isRequestButtonClicked: true
            });
        }
        else {
            this.endTimeWarningSet();
            this.pastDateWarningSet(this.state.requestFormData.dateFrom);
            //this.showLoadpanel();
            if(this.props.isSetOfferedStatus == true){
                let statusId = RequestStatus.Offered;
                this.serviceCallEditPublish(statusId);
            }
            else{
                this.serviceCallEditPublish("");
            }             
            sessionStorage.removeItem("formdata");
            sessionStorage.removeItem("showEqipReqd");
            sessionStorage.removeItem("showPerfInfo");
            sessionStorage.removeItem("finishTimeIsMandatory");
            sessionStorage.removeItem("isPublished");
            sessionStorage.removeItem("isShowReoccuranceDate");
            this.setState({
                isUpdateClicked: true,
                isBlocking: false,
                isRequestButtonClicked: true
            });
        }
    };

    onSecurityBook = () => {
        this.endTimeWarningSet();
        this.pastDateWarningSet(this.state.requestFormData.dateFrom);
        //this.showLoadpanel();
        sessionStorage.removeItem("formdata");
        sessionStorage.removeItem("showEqipReqd");
        sessionStorage.removeItem("showPerfInfo");
        sessionStorage.removeItem("finishTimeIsMandatory");
        sessionStorage.removeItem("isPublished");
        sessionStorage.removeItem("isShowReoccuranceDate");
        this.setState({
            isUpdateClicked: true,
            isBlocking: false,
            isRequestButtonClicked: true,
        });
        if(this.state.requestFormData.providerBusinessEntityId == "" || this.state.requestFormData.providerBusinessEntityId == "0"){
            this.setState({
                showProviderPopup: true,
            });
        }
        else{
            this.bookProceed();
        }
    };

    onProceedMakeOffer = () => {
        this.setState({ 
            showRoundPopupMakeOffer: false,
            requestFormData: {
                ...this.state.requestFormData,
                isRounded: true,
            }
        }, () => {
            this.checkRecalculation();
        })
    };

    onProceed = () => {
        this.setState({ 
            showRoundPopup: false,
            requestFormData: {
                ...this.state.requestFormData,
                isRounded: true,
                budget: this.state.newBudget
            }
        }, () => {
            this.checkRecalculation();
        })
    };

    onProceedMax = () => {
        this.setState({ 
            showRoundPopupMax: false,
            requestFormData: {
                ...this.state.requestFormData,
                isRounded: true,
                maxBudget: this.state.newBudget
            }
        }, () => {
            this.checkRecalculation();
        })
    };

    onRoundedCancel = () => {
        this.setState({ 
            showRoundPopup: false,
            showRoundPopupMax: false,
            showRoundPopupMakeOffer: false,
            requestFormData: {
                ...this.state.requestFormData,
                isRounded: false

            },
            newBudget: 0,
        }, () => {
            this.checkRecalculation();
        })
        
    };

    checkRecalculation = () => {
        if(this.buttonClicked == "book" || this.buttonClicked == "re-book") {
            this.setState({isCallCalculate: true});
            this.getCalculation();
        }
        else {
            this.submitRequestForm();
        }
    }

    getCalculation = () => {
        let showInterestInfoList = this.utils.mapShowInterest(this.props.artistBusList, this.props.eventUKIndicator);
        let showInterestList: any = [];
        if ((this.state.showInterestList && this.state.showInterestList.length > 0) && this.props.artistBusList.length > 0) {
            showInterestInfoList.length > 0 && showInterestInfoList.forEach((item: any) => {
                showInterestList.push(item);
            });
            this.state.showInterestList.length > 0 && this.state.showInterestList.forEach((item: any) => {
                showInterestList.push(item);
            });

        }
        else if (this.state.showInterestList && this.state.showInterestList.length > 0) {
            this.state.showInterestList.length > 0 && this.state.showInterestList.forEach((item: any) => {
                showInterestList.push(item);
            });
        }
        else if (showInterestInfoList) {
            showInterestInfoList.length > 0 && showInterestInfoList.forEach((item: any) => {
                showInterestList.push(item);
            });
        }        
        this.props.requestBookData(this.state.requestFormData, showInterestList)      
    }

    calculationSuccess = (response: AxiosResponse<any>) => {
        this.hideLoadpanel();
        this.setState({ 
            showCalculationPopup: true,
            requestFormData: {
                ...this.state.requestFormData,
                providerPayAmountLocal : this.sharedUtils.convertStringToFloat(response.data.data.providerPayAmountLocal),
                grossProfitAmountLocal : this.sharedUtils.convertStringToFloat(response.data.data.grossProfitAmountLocal),
                clientBillableAmountLocal : this.sharedUtils.convertStringToFloat(response.data.data.clientBillableAmountLocal),
            }
           
        })
        this.props.isRecalculateChange(false);
    }

    serviceCallPublish = () => {
        let type = this.state.requestFormData?.typeLookUpId ? this.state.requestFormData.typeLookUpId : "";
        let publishRequest = this.utils.mapPublishRequest(type);
        this.newReqSvc.publishStatus(this.state.requestFormData.id, publishRequest)
            .then(this.handleFormDataSubmissionSuccess)
            .catch(this.handleFormSubmissionFailure)
            .finally(() => {
                this.hideLoadpanel();
            });
    }

    handleSuccessEditCall = (statusId: string) => {
        if (statusId || this.isPublished) {
            this.serviceCallPublish();
        }
        else {
            this.handleFormDataSubmissionSuccess();
        }
    }

    changeShiftDetailsDateFormat = () => {
        if(this.state.requestFormData?.shiftDetails){
            this.state.requestFormData.shiftDetails.length > 0 && this.state.requestFormData.shiftDetails.forEach((item: any) => {
                if(item.date){
                    if(moment(item.date, 'DD/MM/YYYY', true).isValid()){
                        // nothing to change
                    }
                    else{
                        item.date = moment(item.date).format("DD/MM/YYYY");
                    }   
                }             
            });
        }
    }

    serviceCallEditPublish = (statusId: string) => {
        let showInterestInfoList = this.utils.mapShowInterest(this.props.artistBusList, this.props.eventUKIndicator);
        let updateSubmitRequest = this.utils.createUpdateRequest(this.state.requestFormData, showInterestInfoList, statusId);
        let showInterestCardList: any[] = [];
        if ((this.state.showInterestList && this.state.showInterestList.length > 0) && this.props.artistBusList.length > 0) {
            //showInterestCardList.push(this.state.showInterestList);
            showInterestInfoList.length > 0 && showInterestInfoList.forEach((item: any) => {
                showInterestCardList.push(item);
            });
            this.state.showInterestList.length > 0 && this.state.showInterestList.forEach((item: any) => {
                showInterestCardList.push(item);
            });

        }
        else if (this.state.showInterestList && this.state.showInterestList.length > 0) {
            this.state.showInterestList.length > 0 && this.state.showInterestList.forEach((item: any) => {
                showInterestCardList.push(item);
            });
        }
        else if (showInterestInfoList) {
            showInterestInfoList.length > 0 && showInterestInfoList.forEach((item: any) => {
                showInterestCardList.push(item);
            });
        }
        if(this.buttonClicked == "securityBook"){
            this.changeShiftDetailsDateFormat();
            this.props.updateArtist(this.state.requestFormData, showInterestCardList, false, statusId, "securityBook"); 
        }
        else{
            this.changeShiftDetailsDateFormat();
            this.setState({
                isUpdateClicked: true,
            }, () => { this.props.updateArtist(this.state.requestFormData, showInterestCardList, this.state.isUpdateClicked, statusId, "") });            
        }
        
    }
    saveSession = (obj: any) => {
        sessionStorage.setItem("formdata", JSON.stringify(obj));
        return true;
    };
    getSession = () => {
        let obj = {};
        if (typeof sessionStorage.formdata !== "undefined") {
            obj = JSON.parse(sessionStorage.formdata);
        }
        return obj;
    };
    onBook = () => {
        let showInterestInfoList = this.utils.mapShowInterest(this.props.artistBusList, this.props.eventUKIndicator);
        let showInterestList: any = [];
        let serviceType = GlobalService.getGigComingFrom();
        if(serviceType !== "Entertainment" && (this.state.requestFormData.providerBusinessEntityId == "" || this.state.requestFormData.providerBusinessEntityId == "0")){
            this.setState({ 
                showProviderPopup: true,
            })
        }
        else{
            if ((this.state.showInterestList && this.state.showInterestList.length > 0) && this.props.artistBusList.length > 0) {
                showInterestInfoList.length > 0 && showInterestInfoList.forEach((item: any) => {
                    showInterestList.push(item);
                });
                this.state.showInterestList.length > 0 && this.state.showInterestList.forEach((item: any) => {
                    showInterestList.push(item);
                });

            }
            else if (this.state.showInterestList && this.state.showInterestList.length > 0) {
                this.state.showInterestList.length > 0 && this.state.showInterestList.forEach((item: any) => {
                    showInterestList.push(item);
                });
            }
            else if (showInterestInfoList) {
                showInterestInfoList.length > 0 && showInterestInfoList.forEach((item: any) => {
                    showInterestList.push(item);
                });
            }
            this.props.bookArtist(this.state.requestFormData, showInterestList, "book");
            this.setState({ isBlocking: false, isRequestButtonClicked: true })
        }
    };
    onReBook = () => {
        this.showLoadpanel();
        this.bookProceed();        
    }
    handleSuccess = (response: AxiosResponse<any>) => {
        // when there will be no content in the response the code will be 204
        if (response.status == 204) {
            return;
        } else {
            let multiplierOverride;
            if(this.props.reBookData.isFeeonBillAmountAvailable){
                multiplierOverride = parseFloat(this.props.reBookData.grossProfitAmountLocal) / parseFloat(this.props.reBookData.clientBillableAmountLocal);
            }
            else {
                multiplierOverride = parseFloat(this.props.reBookData.grossProfitAmountLocal) / parseFloat(this.props.reBookData.providerPayAmountLocal);
            }
            let billableData = response.data.data;
            // update data as per booked request
            billableData.clientId = this.state.requestFormData.clientBusinessEntityId;
            billableData.venueId = this.state.requestFormData.venueId;
            billableData.serviceTypeId = this.state.requestFormData.serviceTypeId;
            billableData.serviceSubTypeId = this.state.requestFormData.serviceSubTypeId;
            billableData.typeId = this.state.requestFormData.typeLookUpId;
            billableData.date = this.state.requestFormData.dateFrom;
            billableData.dateTo = this.state.requestFormData.dateFrom;
            billableData.shift_Start = this.state.requestFormData.startTime;
            billableData.shift_End = this.state.requestFormData.finishTime;
            billableData.scheduledStartTime = this.state.requestFormData.startTime;
            billableData.scheduledFinishTime = this.state.requestFormData.finishTime;
            billableData.performanceInformation = this.state.requestFormData.entertainmentDescription;
            billableData.additionalInformation = this.state.requestFormData.additionalInformation;
            billableData.equipmentRequired = this.state.requestFormData.equipmentRequired;
            billableData.otherDescription = this.state.requestFormData.otherDescription;
            billableData.reoccurance = this.state.requestFormData.reoccurrence;
            billableData.rate = this.props.makeoffAmtForBookedArtist !== undefined && this.props.makeoffAmtForBookedArtist !== "" ? this.props.makeoffAmtForBookedArtist : this.state.requestFormData.budget;
            billableData.scheduleRate = this.props.makeoffAmtForBookedArtist !== undefined && this.props.makeoffAmtForBookedArtist !== "" ? this.props.makeoffAmtForBookedArtist : this.state.requestFormData.budget;
            billableData.billableRate = this.props.makeoffAmtForBookedArtist !== undefined && this.props.makeoffAmtForBookedArtist !== "" ? this.props.makeoffAmtForBookedArtist : this.state.requestFormData.budget;
            billableData.providerPayAmountLocal = this.props.makeoffAmtForBookedArtist !== undefined && this.props.makeoffAmtForBookedArtist !== "" ? this.props.makeoffAmtForBookedArtist : this.state.requestFormData.budget;
            billableData.rate_Decimal = this.props.makeoffAmtForBookedArtist !== undefined && this.props.makeoffAmtForBookedArtist !== "" ? this.props.makeoffAmtForBookedArtist : this.state.requestFormData.budget;
            billableData.performanceInformationSetsNumber = this.state.requestFormData.performanceInformationSetsNumber?.toString();
            billableData.performanceInformationSetsDuration = this.state.requestFormData.performanceInformationSetsDuration?.toString();
            billableData.providerNetOverride = this.props.reBookData.providerPayAmountLocal;
            billableData.multiplierOverride = multiplierOverride.toString();
            let billableDataSubmitRequest = this.quickAddShiftUtils.convertFormDataToQuickShiftRequest(billableData, true);
            let billableItemSubmitRequestArr: QuickBillableItemSubmitRequest[] = [];
            billableItemSubmitRequestArr.push(billableDataSubmitRequest);
            billableItemSubmitRequestArr[0].navigateFrom = "rebookBillableItem";
            this.addEditPopUpService
            .uploadEditAddDataV3(billableItemSubmitRequestArr)
            .then(this.handleV3FormDataSubmissionSuccess)
            .catch(this.handleFormSubmissionFailure);
        }   
    }
    handleV3FormDataSubmissionSuccess = () => {
        this.props.changeReBookSuccess(false);
        this.setState({ 
            loadPanelVisible: false }, () => {
                notify({ message: "Request has been booked successfully!!", width: 300, shading: true, position: "center" }, 'success', 600);                
                this.props.history.goBack();
        })
    }
    bookProceed = () => {
        if(this.buttonClicked == "securityBook"){            
            this.serviceCallEditPublish("");
        }
        else{
            let showInterestInfoList = this.utils.mapShowInterest(this.props.artistBusList, this.props.eventUKIndicator);
            let showInterestList: any = [];
            this.setState({ 
                showProviderPopup: false,
            })
            if ((this.state.showInterestList && this.state.showInterestList.length > 0) && this.props.artistBusList.length > 0) {
                showInterestInfoList.length > 0 && showInterestInfoList.forEach((item: any) => {
                    showInterestList.push(item);
                });
                this.state.showInterestList.length > 0 && this.state.showInterestList.forEach((item: any) => {
                    showInterestList.push(item);
                });

            }
            else if (this.state.showInterestList && this.state.showInterestList.length > 0) {
                this.state.showInterestList.length > 0 && this.state.showInterestList.forEach((item: any) => {
                    showInterestList.push(item);
                });
            }
            else if (showInterestInfoList) {
                showInterestInfoList.length > 0 && showInterestInfoList.forEach((item: any) => {
                    showInterestList.push(item);
                });
            }   
            this.props.bookArtist(this.state.requestFormData, showInterestList, this.buttonClicked);
            this.setState({ isBlocking: false, isRequestButtonClicked: true })
        }
    };
    onCancelRequest = () => {
        let gigType = GlobalService.getGigComingFrom();
        if(gigType !== "Entertainment"){
            this.onCancelRequestConfirmation();
        }
        else{
            this.showCancelRequestPopup();
        }
    }
    showCancelRequestPopup = () => {
        this.setState({
            isCancelRequestPopupvisible: true
        });
    }

    hideCancelRequestPopup = () => {
        this.setState({
            isCancelRequestPopupvisible: false
        });
    }

    onCancelRequestConfirmation = () => {
        this.showLoadpanel();
        let isRePublish: boolean | null = null;
        if (this.state.cancellationRequestData.rePublishGigRequest == JSON.parse("true")) {
            isRePublish = true;
        }
        else if (this.state.cancellationRequestData.rePublishGigRequest == JSON.parse("false")) {
            isRePublish = false;
        }

        let updatedRequestObject: updateRequest = {
            requestStatusValue: statusTypes.Cancelled,
            cancellationreasonId: this.state.cancellationRequestData.cancellationreasonId,
            rePublishGigRequest: isRePublish
        };
        this.pendingRequestService
            .updateRequestData(this.state.requestFormData.id, updatedRequestObject)
            .then(this.handleCancelRequestSuccess)
            .catch(this.handleFailure);
    };

    handleCancelRequestSuccess = () => {
        this.hideLoadpanel();
        this.hideCancelRequestPopup();
        alert("Thank you - booking request has been cancelled", "Success");
        this.props.history.goBack();
    }

    handleChangeDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.requestFormData.reoccurrenceEndDate !== dxValueChange.value) {
            this.setNavBlocking(this.state.requestFormData.reoccurrenceEndDate, dxValueChange.value);
            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    reoccurrenceEndDate: dxValueChange.value,
                }
            },
                () => {
                    this.saveSession(this.state.requestFormData);
                });
        }
    };


    handleFailure = () => {
        this.hideLoadpanel();
        this.hideCancelRequestPopup();
    }

    endTimeWarningSet = () => {
        if (!this.state.requestFormData.finishTime) {
            this.setState({
                endTimeWarningText: "Warning: Please add End Time (if relevant and known)"
            });
        }
        else {
            this.setState({
                endTimeWarningText: ""
            });
        }
    }

    pastDateWarningSet = (dateValue: any) => {
        let date = new Date(dateValue);
        let curDate = new Date(new Date().toDateString()).getTime();
        this.setState({
            dateWarningText: date.getTime() < curDate ? "Warning: The date of gig is in the past" : ""
        });
    }

    onClickAddArtist = () => {
        this.props.addArtist(true);
        this.props.getVenue(this.state.requestFormData.clientBusinessEntityId, this.state.requestFormData.venueId);
    }

    onFocusIn = (e: any) => {
        off(e.element.querySelector(".dx-texteditor-input"), "dxmousewheel");
    }

    hideRoundPopup = () => {
        this.setState({
            showRoundPopup: false,
            showRoundPopupMax: false,
            showRoundPopupMakeOffer: false,
            showProviderPopup: false,
            showCalculationPopup: false,
        });
    };

    renderHeading = () => {
        let gigType = GlobalService.getGigComingFrom();
        this.parentMappingServiceId = "";
        if(gigType == "ContractCleaning"){
            this.parentMappingServiceId = ServiceType.ContractCleaning;
            return (
                <h3 className="card-form__heading">Cleaning Work Requests</h3>
            )
        }
        else if(gigType == "Gardening"){
            this.parentMappingServiceId = ServiceType.Gardening;
            return (
                <h3 className="card-form__heading">Gardening Work Requests</h3>
            )
        }
        else if(gigType == "WindowCleaning"){
            this.parentMappingServiceId = ServiceType.WindowCleaning;
            return (
                <h3 className="card-form__heading">Window Cleaning Work Requests</h3>
            )
        }
        else if(gigType == "Maintenance"){
            this.parentMappingServiceId = ServiceType.Maintenance;
            return (
                <h3 className="card-form__heading">Maintenance Work Requests</h3>
            )
        }
        else if(gigType == "Miscelleanous"){
            this.parentMappingServiceId = ServiceType.Miscelleanous;
            return (
                <h3 className="card-form__heading">Miscelleanous Work Requests</h3>
            )
        } 
        else if(gigType == "Security"){
            this.parentMappingServiceId = ServiceType.Security;
            return (
                <h3 className="card-form__heading">Door Supervision Work Requests</h3>
            )
        }
        else if(gigType == "Entertainment"){
            this.parentMappingServiceId = ServiceType.Entertainment;
            return (
                <h3 className="card-form__heading">Entertainment Booking Request</h3> 
            )
        }
    }

    isNavigate = (flag: boolean) => {
        if(flag == true){
            this.setState({
                clickedNo: false,
            });
        }
        else{
            this.setState({
                clickedNo: true,
            });
        }
    };

    updateLocaleTime = (date: string) => {
        if (date !== null) {
            let finalFormattedDate: string = "";
            let termsAgreedDateTime = date;
            let termsAgreedDateTimeLocal = new Date(termsAgreedDateTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(termsAgreedDateTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDayDateMonthToString(termsAgreedDateTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
            return finalFormattedDate;
        }
    };

    // security form personnel grid operations

    onRowUpdating = (e: OnRowUpdatingEvent) => {
        let index:number = -1;
        if(e.oldData.request_Days_ID !== e.newData.request_Days_ID){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.request_Days_ID === e.oldData.request_Days_ID  && item.serviceSubTypeId?.toString() == "11") : -1;
        }
        if(e.oldData.typeId !== e.newData.typeId){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.typeId === e.oldData.typeId  && item.serviceSubTypeId?.toString() == "11") : -1;
        }
        if(e.oldData.dateFrom !== e.newData.dateFrom){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.date === e.oldData.date  && item.serviceSubTypeId.toString() == "11") : -1;
        }
        if(e.oldData.equipmentScheduledQuantity !== e.newData.equipmentScheduledQuantity){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.equipmentScheduledQuantity === e.oldData.equipmentScheduledQuantity  && item.serviceSubTypeId.toString() == "11") : -1;
        }
        if(e.oldData.scheduledStartTime !== e.newData.scheduledStartTime){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.scheduledStartTime === e.oldData.scheduledStartTime  && item.serviceSubTypeId?.toString() == "11") : -1;
        }
        if(e.oldData.scheduledFinishTime !== e.newData.scheduledFinishTime){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.scheduledFinishTime === e.oldData.scheduledFinishTime  && item.serviceSubTypeId?.toString() == "11") : -1;
        }
        if(e.oldData.scheduledFinishTime !== e.newData.scheduledFinishTime){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.scheduledFinishTime === e.oldData.scheduledFinishTime  && item.serviceSubTypeId?.toString() == "11") : -1;
        }
        
        let shiftDetailsUpdatedData = {
                id: e.oldData.id,
                request_ID: e.oldData.request_ID,
                request_Days_ID:
                    e.newData.request_Days_ID ||
                    e.newData.request_Days_ID === null ||
                    e.newData.request_Days_ID === ""
                        ? e.newData.request_Days_ID
                        : e.oldData.request_Days_ID,
                serviceSubTypeId:
                    e.newData.serviceSubTypeId || e.newData.serviceSubTypeId === null || e.newData.serviceSubTypeId === ""
                        ? e.newData.serviceSubTypeId
                        : e.oldData.serviceSubTypeId,
                typeId:
                    e.newData.typeId || e.newData.typeId === null || e.newData.typeId === ""
                        ? e.newData.typeId
                        : e.oldData.typeId,
                date:
                    e.newData.date || e.newData.date === null || e.newData.date === ""
                        ?  moment(e.newData.date).format("DD/MM/YYYY")
                        : moment(e.oldData.date).format("DD/MM/YYYY"),
                equipmentScheduledQuantity:
                    e.newData.equipmentScheduledQuantity || e.newData.equipmentScheduledQuantity === null || e.newData.equipmentScheduledQuantity === ""
                        ? e.newData.equipmentScheduledQuantity
                        : e.oldData.equipmentScheduledQuantity,
                scheduledStartTime:
                    e.newData.scheduledStartTime || e.newData.scheduledStartTime === null || e.newData.scheduledStartTime === ""
                        ? e.newData.scheduledStartTime
                        : e.oldData.scheduledStartTime,
                scheduledFinishTime:
                    e.newData.scheduledFinishTime || e.newData.scheduledFinishTime === null || e.newData.scheduledFinishTime === ""
                        ? e.newData.scheduledFinishTime
                        : e.oldData.scheduledFinishTime,
                scheduledMinutes:
                    e.newData.scheduledMinutes || e.newData.scheduledMinutes === null || e.newData.scheduledMinutes === ""
                        ? this.calculateSecurityScheduledMinutes(e.newData)
                        : e.oldData.scheduledMinutes,
            };
        if(index !== -1){
            this.state.requestFormData?.shiftDetails ? this.state.requestFormData.shiftDetails[index] = shiftDetailsUpdatedData : null;
        }         
    };

    onRowInserting = (e: OnRowInsertingEvent) => { 
        let shiftDetailsInfo: any;
        shiftDetailsInfo = {
            id: 0,
            request_ID: 0,
            request_Days_ID: e.data.request_Days_ID,
            date:  e.data.date !== undefined ? moment(e.data.date).format("DD/MM/YYYY") : undefined,
            serviceSubTypeId: e.data.serviceSubTypeId,
            typeId: e.data.typeId,
            equipmentScheduledQuantity: e.data.equipmentScheduledQuantity,
            scheduledStartTime: e.data.scheduledStartTime,
            scheduledFinishTime: e.data.scheduledFinishTime,
            scheduledMinutes: this.calculateSecurityScheduledMinutes(e.data),
        };   
        this.setState({
            active: true,
        });           
        this.state.requestFormData.shiftDetails?.push(shiftDetailsInfo);
    };    

    onRowRemoving = (e: OnRowRemovingEvent) => {
        const updatedList = this.state.shiftDetailsPersonnelDataSource?.filter((item) => item.id !== e.data.id && item.serviceSubTypeId.toString() == "11");
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                shiftDetails: updatedList
            },
        });
    };

    onEditCancelled = (e: onEditCanceledEvent) => {
        this.setState({
            errorMessage: [],
        });
    };

    onRowEditting = () => {
        this.setState({
            active: false,
        });
    };
    onRowAdding = () => {
        this.setState({
            active: true,
        });
    };

    // security form equipment grid operations

    onRowEquipUpdating = (e: OnRowUpdatingEvent) => {
        let index:number = -1;
        if(e.oldData.request_Days_ID !== e.newData.request_Days_ID){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.request_Days_ID === e.oldData.request_Days_ID && item.serviceSubTypeId.toString() == "12") : -1;
        }
        if(e.oldData.typeId !== e.newData.typeId){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.typeId === e.oldData.typeId && item.serviceSubTypeId.toString() == "12") : -1;
        }
        if(e.oldData.dateFrom !== e.newData.dateFrom){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.date === e.oldData.date && item.serviceSubTypeId.toString() == "12") : -1;
        }
        if(e.oldData.equipmentScheduledQuantity !== e.newData.equipmentScheduledQuantity){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.equipmentScheduledQuantity === e.oldData.equipmentScheduledQuantity && item.serviceSubTypeId.toString() == "12") : -1;
        }
        if(e.oldData.scheduledStartTime !== e.newData.scheduledStartTime){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.scheduledStartTime === e.oldData.scheduledStartTime && item.serviceSubTypeId.toString() == "12") : -1;
        }
        if(e.oldData.scheduledFinishTime !== e.newData.scheduledFinishTime){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.scheduledFinishTime === e.oldData.scheduledFinishTime && item.serviceSubTypeId.toString() == "12") : -1;
        }
        if(e.oldData.scheduledFinishTime !== e.newData.scheduledFinishTime){
            index = this.state.requestFormData?.shiftDetails ? this.state.requestFormData?.shiftDetails?.findIndex(item => item.scheduledFinishTime === e.oldData.scheduledFinishTime && item.serviceSubTypeId.toString() == "12") : -1;
        }
        
        let shiftDetailsUpdatedData = {
                id: e.oldData.id,
                request_ID: e.oldData.request_ID,
                request_Days_ID:
                    e.newData.request_Days_ID ||
                    e.newData.request_Days_ID === null ||
                    e.newData.request_Days_ID === ""
                        ? e.newData.request_Days_ID
                        : e.oldData.request_Days_ID,
                serviceSubTypeId:
                        e.newData.serviceSubTypeId || e.newData.serviceSubTypeId === null || e.newData.serviceSubTypeId === ""
                            ? e.newData.serviceSubTypeId
                            : e.oldData.serviceSubTypeId,
                typeId:
                    e.newData.typeId || e.newData.typeId === null || e.newData.typeId === ""
                        ? e.newData.typeId
                        : e.oldData.typeId,
                date:
                    e.newData.date || e.newData.date === null || e.newData.date === ""
                        ?  moment(e.newData.date).format("DD/MM/YYYY")
                        : moment(e.oldData.date).format("DD/MM/YYYY"),
                equipmentScheduledQuantity:
                    e.newData.equipmentScheduledQuantity || e.newData.equipmentScheduledQuantity === null || e.newData.equipmentScheduledQuantity === ""
                        ? e.newData.equipmentScheduledQuantity
                        : e.oldData.equipmentScheduledQuantity,
                scheduledStartTime:
                    e.newData.scheduledStartTime || e.newData.scheduledStartTime === null || e.newData.scheduledStartTime === ""
                        ? e.newData.scheduledStartTime
                        : e.oldData.scheduledStartTime,
                scheduledFinishTime:
                    e.newData.scheduledFinishTime || e.newData.scheduledFinishTime === null || e.newData.scheduledFinishTime === ""
                        ? e.newData.scheduledFinishTime
                        : e.oldData.scheduledFinishTime,
                scheduledMinutes:
                    e.newData.scheduledMinutes || e.newData.scheduledMinutes === null || e.newData.scheduledMinutes === ""
                        ? this.calculateSecurityScheduledMinutes(e.newData)
                        : e.oldData.scheduledMinutes,
            };
        if(index !== -1){
            this.state.requestFormData?.shiftDetails ? this.state.requestFormData.shiftDetails[index] = shiftDetailsUpdatedData : null;
        }         
    };

    onRowEquipInserting = (e: OnRowInsertingEvent) => { 
        let shiftDetailsInfo: any;
        shiftDetailsInfo = {
            id: 0,
            request_ID: 0,
            request_Days_ID: e.data.request_Days_ID,
            date: e.data.date !== undefined ? moment(e.data.date).format("DD/MM/YYYY") : undefined,
            serviceSubTypeId: e.data.serviceSubTypeId,
            typeId: e.data.typeId,
            equipmentScheduledQuantity: e.data.equipmentScheduledQuantity,
            scheduledStartTime: e.data.scheduledStartTime,
            scheduledFinishTime: e.data.scheduledFinishTime,
            scheduledMinutes: this.calculateSecurityScheduledMinutes(e.data),
        };   
        this.setState({
            active: true,
        });           
        this.state.requestFormData.shiftDetails?.push(shiftDetailsInfo);
    };    

    onRowEquipRemoving = (e: OnRowRemovingEvent) => {
        const updatedList = this.state.shiftDetailsEquipmentDataSource?.filter((item) => item.id !== e.data.id && item.serviceSubTypeId.toString() == "12");
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                shiftDetails: updatedList
            },
        });
    };

    onEquipEditCancelled = (e: onEditCanceledEvent) => {
        this.setState({
            errorMessage: [],
        });
    };

    onRowEquipEditting = () => {
        this.setState({
            active: false,
        });
    };
    onRowEquipAdding = () => {
        this.setState({
            active: true,
        });
    };

    // calculate Security ScheduledMinutes
    calculateSecurityScheduledMinutes = (rowData: any) => {        
        let start = this.gridUtils.AdjustStartTime(rowData.scheduledStartTime, rowData.scheduledFinishTime);
        let end =  this.gridUtils.AdjustFinishTime(rowData.scheduledStartTime, rowData.scheduledFinishTime);
        var ms = moment(end,"YYYY-MM-DD HH:mm A").diff(moment(start,"YYYY-MM-DD HH:mm A"));  
        var dt = moment.duration(ms);
        var sdt = Math.floor(dt.asHours()) + moment.utc(ms).format(":mm:ss");
        let finalDt = sdt !=="" ? this.gridUtils.convertH2M(sdt) : "";
        return finalDt !=="" ? finalDt.toString() : "";
    };

    displaySecurityScheduledMinutes = (rowData: any) => {        
        // let start = this.gridUtils.AdjustStartTime(rowData.scheduledStartTime, rowData.scheduledFinishTime);
        // let end =  this.gridUtils.AdjustFinishTime(rowData.scheduledStartTime, rowData.scheduledFinishTime);
        // var ms = moment(end,"YYYY-MM-DD HH:mm A").diff(moment(start,"YYYY-MM-DD HH:mm A"));  
        // var dt = moment.duration(ms);
        // var sdt = Math.floor(dt.asHours()) + moment.utc(ms).format(":mm:ss");
        // //let finalDt = sdt !=="" ? this.gridUtils.convertH2M(sdt) : "";
        // let finalDt = sdt !=="" ? this.gridUtils.convertToHoursMinutesFormat(sdt) : "";
        let finalDt = this.gridUtils.convertMinutesStringToHours(rowData.scheduledMinutes)
        return finalDt !=="" ? finalDt.toString() : "";
    };
    //#region render Component
    render() {
        let isEdit: boolean = false;
        let isAdd: boolean = false;
        let gigType = GlobalService.getGigComingFrom();
        let billableItemId = this.state.requestFormData?.billableItemId !== null && this.state.requestFormData?.billableItemId !== "" ? this.state.requestFormData?.billableItemId : null;
        let approvalDate = this.state.requestFormData?.approvalDate !== null && this.state.requestFormData?.approvalDate !== "" ? this.state.requestFormData?.approvalDate : null;
        let invoiceId = this.state.requestFormData?.invoiceId !== null && this.state.requestFormData?.invoiceId !== "" ? this.state.requestFormData?.invoiceId : null;
        let paymentId = this.state.requestFormData?.paymentId !== null && this.state.requestFormData?.paymentId !== "" ? this.state.requestFormData?.paymentId : null;
        if (this.props.requestId && this.props.requestId != "0") {
            isEdit = true;
        }
        else {
            isAdd = true;
        }
        if (!this.state.isUpdateClicked) {
            // this.saveSession(this.state.requestFormData);
            sessionStorage.setItem("showEqipReqd", JSON.stringify(this.state.showEqipReqd));
            sessionStorage.setItem("showPerfInfo", JSON.stringify(this.state.showPerfInfo));
            sessionStorage.setItem("finishTimeIsMandatory", JSON.stringify(this.state.finishTimeIsMandatory));
        }
        let isDisableOfferingsType: boolean = true;
        if ((this.state.offeringsTypeLookup && this.state.offeringsTypeLookup.length > 0) && this.state.requestFormData.serviceSubTypeId) {
            isDisableOfferingsType = false;
        }
        else {
            isDisableOfferingsType = true;
        }
        let buttonName: string = "";
        let showCancelRequest: boolean = false;
        let errList =
            this.props.pendingRequestFlag && this.props.errorMessage && this.props.errorMessage.length > 0
                ? this.props.errorMessage
                : this.state.errorMessage;
        let showInternalComments =
            this.props.eventUKIndicator && (this.props.pendingRequestFlag || this.props.requestId) ? true : false;
        let placeholderTextforAddInfo = "What genre of music do you require and what atmosphere do you want to create?";
        let placeholderTextforGigHeadline = "Best bar in town requires a great DJ";
        let placeholderTextforGigHeadlineOther = "Please describe the work required so it's brief but clear to approvers";
        let placeholderTextforEqipReqd = "i.e. laptop, decks & mixer, lighting, PA, full obile rig etc.";
        let isReadOnly: boolean = false;
        let isHideAddArtists: boolean = false;
        let EditStatusDataSource : LookupTypeItem[] = []; 
        let editFrequencyDataSource : LookupTypeItem[] = []; 
        let isShowOther: boolean = false;
        let isShowType: boolean = false;
        let isShowTypeEnt: boolean = false;
        
        if(gigType == "ContractCleaning" || gigType == "Gardening" || gigType == "WindowCleaning" || gigType == "Maintenance" || gigType == "Miscelleanous" || gigType == "Security"){
            EditStatusDataSource = this.state.statusLookUp.filter(item => item.id !== "20" && item.id !== "22" && item.id !== "24" && item.id !== "25");
            editFrequencyDataSource = this.state.frequencyDataSource.filter(item => item.id !== "20" && item.id !== "21");
            if((this.state.requestFormData.serviceSubTypeId == "22" && this.state.requestFormData.typeLookUpId == "227") ||
                this.state.requestFormData.typeLookUpId == "405" || this.state.requestFormData.typeLookUpId == "516" 
                || this.state.requestFormData.serviceSubTypeId == "83"){
                isShowOther = true;
            }
            if(this.state.requestFormData.serviceSubTypeId == "22" || this.state.requestFormData.serviceTypeId == "40"  || this.state.requestFormData.serviceTypeId == "50"){
                isShowType = true;
            }
        }
        if(gigType == "Entertainment" && this.state.requestFormData.serviceSubTypeId == "50"){
            isShowOther = true;
        }
        if(gigType == "Entertainment"){
            isShowTypeEnt = true;
        }
        if (this.props.location.state && this.props.location.state.readOnly) {
            isReadOnly = this.props.location.state.readOnly;
        }
        if (this.props.location.state && this.props.location.state.navigation && this.props.location.state.navigation == "gigRequestAdd") {
            buttonName = "Add";
            isHideAddArtists = true;
        }
        else {
            buttonName = "Update";
            showCancelRequest = true;
        }
        let statusValue: string = "";
        if (this.props.statusId && this.props.statusId == ShowInterestStatusIdTypes.Selected) {
            statusValue = findAnArtistCycleStatus.ArtistSelected;
        }
        else if (this.state.requestFormData && this.state.requestFormData.requestStatusId) {
            statusValue = this.state.requestFormData.requestStatusId;
        }
        let displayLink = this.state.requestFormData?.displayLink ? this.state.requestFormData.displayLink : "";
        return (<>

            <FindAnArtistStatusFlow
                statusId={statusValue}
                displayLink={displayLink}
            />
            <form data-testid="addEditPayment-form" className="card card-form my-5">
                <div className="card-body">
                    <section>
                        {this.renderHeading()}
                        <RestrictRouter
                            when={this.state.isBlocking}
                            location={this.props.location}
                            history={this.props.history}
                            isNavigate={this.isNavigate}
                        />
                        <div className="card-form__field card-form__field--narrow">
                            Status
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <SelectBox
                                dataSource={gigType !== "Entertainment" ? EditStatusDataSource : this.state.statusLookUp}
                                displayExpr="value"
                                valueExpr="id"
                                onValueChanged={this.handleChangeStatus}
                                value={
                                    this.props.statusId && this.props.statusId == ShowInterestStatusIdTypes.Selected && this.state.requestFormData.requestStatusId !== "30" ? findAnArtistCycleStatus.ArtistSelected : this.state.requestFormData.requestStatusId
                                }
                                disabled={isReadOnly}
                            />
                        </div>

                        <div className="card-form__field card-form__field--narrow">
                            Client
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.clientType}
                                onValueChanged={this.handleChangeClientSelect}
                                value={this.state.requestFormData.clientBusinessEntityId}
                                recordType={this.props.requestId ? "all" : "active"}
                                disableSignal={isReadOnly}
                            />
                            <input
                                data-testid={LookupTypeIndexes.clientType}
                                type="hidden"
                                name={LookupTypeIndexes.clientType}
                            ></input>
                        </div>
                        <div className="card-form__field card-form__field--narrow">
                            <h4 className="card-form__subheading">
                                Venue
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </h4>
                            <LookupTypeListDynamic
                                lookupTypeIndex={LookupTypeIndexes.venueType}
                                onValueChanged={this.handleChangeVenueSelect}
                                isRoot={true}
                                parentMappingId={this.state.requestFormData.clientBusinessEntityId}
                                value={this.state.requestFormData.venueId}
                                displayExpression={"value"}
                                recordType={
                                    this.props.requestId ? "all" : "active"
                                }
                                isDisabled={isReadOnly}
                            />
                        </div>

                        <div className="card-form__field card-form__field--narrow"  hidden = {gigType !== "Entertainment"}>
                            Venue Type
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.venueTypeLookUp}
                                onValueChanged={this.handleChangeVenueTypeSelect}
                                value={this.state.requestFormData.venueTypeId}
                                recordType={
                                    this.props.requestId ? "all" : "active"
                                }
                                disableSignal={isReadOnly}
                            />
                        </div>

                        <div className="row"  hidden = {gigType == "Security"}>
                            <div className="card-form__inline-field col-md-5 col-sm-12">
                                <h4 className="card-form__subheading">
                                    Sub Type
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h4>
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                    isRoot={false}
                                    parentMappingId={this.parentMappingServiceId}
                                    displayExpression={"value"}
                                    onValueChanged={this.handleChangeSubServiceTypeSelect}
                                    value={this.state.requestFormData.serviceSubTypeId}
                                />
                            </div>  
                        </div>
                        <div className="row">
                            <div className="card-form__inline-field col-md-5 col-sm-12 mt-3" hidden = {!isShowTypeEnt}>
                                <h4 className="card-form__subheading">
                                    Type
                                    <sup className="card-form__mandatory-symbol" hidden={isDisableOfferingsType}>*</sup>
                                </h4>
                                <SelectBox
                                    dataSource={this.state.offeringsTypeLookup}
                                    displayExpr={"value"}
                                    valueExpr={"id"}
                                    onValueChanged={this.handleChangeTypeLookUpSelect}
                                    value={this.state.requestFormData.typeLookUpId}
                                    disabled={isReadOnly || isDisableOfferingsType}
                                />
                            </div>
                            <div className="card-form__inline-field col-md-5 col-sm-12 mt-3" hidden = {!isShowType}>
                                <h4 className="card-form__subheading">
                                    Type
                                </h4>
                                <SelectBox
                                    dataSource={this.state.offeringsTypeLookup}
                                    displayExpr={"value"}
                                    valueExpr={"id"}
                                    onValueChanged={this.handleChangeTypeLookUpSelect}
                                    value={this.state.requestFormData.typeLookUpId}
                                    disabled={isReadOnly || isDisableOfferingsType}
                                />
                            </div>
                        </div>
                        <div className="card-form__field card-form__field--narrow mt-3" hidden={!isShowOther}>
                            Other Description
                            <sup className="card-form__mandatory-symbol"  hidden={gigType == "Entertainment"}>*</sup>
                            <TextArea
                                onValueChanged={this.handleChangeOtherDescription}
                                value={this.state.requestFormData.otherDescription}
                                disabled={isReadOnly}
                            ></TextArea>
                        </div>

                        <div className="row" hidden={isAdd}>
                            <div className="card-form__field card-form__field--narrow col-md-4 col-sm-12">
                                <h4 className="card-form__subheading">
                                    Date
                                    <sup className="card-form__mandatory-symbol" hidden={gigType != "Entertainment"}>*</sup>
                                </h4>
                                <DateBox
                                    onValueChanged={this.handleChangeWorkDate}
                                    value={
                                        this.state.requestFormData.dateFrom
                                            ? this.state.requestFormData.dateFrom
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                    disabled={isReadOnly}
                                ></DateBox>

                            </div>
                            <div className="card-form__warning-message col-md-4 col-sm-12">
                                {this.state.dateWarningText != "" ? (
                                    <span className="text-warning">
                                        <ul>
                                            <li>{this.state.dateWarningText}</li>
                                        </ul>
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <br hidden={isEdit} />
                        <div className="card-form__field card-form__field--narrow" hidden={isEdit}>
                            <h4 className="card-form__subheading">
                                Date
                                <sup className="card-form__mandatory-symbol" hidden={gigType != "Entertainment"}>*</sup>
                            </h4>
                            <br />
                            <DatePicker
                                placeholder="Click here to select multiple dates"
                                inputClass="multidatepicker-custom-input"
                                className="teal"
                                multiple
                                value={this.state.date}
                                format="DD/MM/YYYY"
                                onChange={this.handleChangeWorkDatePicker}
                                plugins={[
                                    <DatePanel sort="date" />
                                ]}

                            >
                            </DatePicker>
                        </div>
                        {   gigType == "Security" ?                               
                            <>
                                <div className="card-form__field card-form__field--narrow">
                                    <h4 className="card-form__subheading">
                                        Date To
                                    </h4>
                                    <br />
                                    <DateBox
                                        onValueChanged={this.handleChangeDateTo}
                                        value={
                                            this.state.requestFormData.dateTo
                                                ? this.state.requestFormData.dateTo
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                    ></DateBox>
                                </div>
                                <div className="card-form__field card-form__field--narrow">
                                    Recurring?
                                    <RadioGroup
                                        items={this.residencyList && this.residencyList.length > 0 ? this.residencyList : []}
                                        displayExpr="value"
                                        valueExpr="value"
                                        value={
                                            this.state.requestFormData.reoccurrence == "Yes" ||
                                                this.state.requestFormData.reoccurrence == "true"
                                                ? "Yes"
                                                : "No"
                                        }
                                        onValueChanged={this.handleChangeResidency}
                                        layout="horizontal"
                                        readOnly={isReadOnly}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--narrow">Frequency
                                    <DropDownFromLookupTableDynamic
                                        lookupTypeIndex={LookupTypeIndexes.frequencyType}
                                        isRoot={true}
                                        parentMappingId={"0"}
                                        onValueChanged={this.handleChangeFrequency}
                                        value={this.state.requestFormData.reoccurrenceFrequency}
                                        isDisabled={this.state.isDisableFrequency}
                                        isReadOnly={isReadOnly}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--narrow">
                                    Recurring End Known
                                    <RadioGroup
                                        items={this.residencyEndKnownList && this.residencyEndKnownList.length > 0 ? this.residencyEndKnownList : []}
                                        displayExpr="value"
                                        valueExpr="value"
                                        value={
                                            this.state.requestFormData.reoccurrenceEndDateKnown == "Yes" ||
                                                this.state.requestFormData.reoccurrenceEndDateKnown == "true"
                                                ? "Yes"
                                                : "Until Further Notice"
                                        }
                                        onValueChanged={this.handleChangeResidencyEndKnown}
                                        layout="horizontal"
                                        readOnly={isReadOnly}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--narrow" hidden={!this.state.isShowReoccuranceDate}>
                                    Recurring End Date
                                    <DateBox
                                        onValueChanged={this.handleChangeDate}
                                        value={
                                            this.state.requestFormData.reoccurrenceEndDate
                                                ? this.state.requestFormData.reoccurrenceEndDate
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                    ></DateBox>
                                </div>
                            </> 
                            : null
                        }                         
                        {   gigType !== "Entertainment" ? 
                                this.state.requestFormData.serviceSubTypeId == "21" ?                               
                                    <><div className="card-form__field card-form__field--narrow">
                                        Repeat
                                        <RadioGroup
                                            items={this.residencyList && this.residencyList.length > 0 ? this.residencyList : []}
                                            displayExpr="value"
                                            valueExpr="value"
                                            value={
                                                this.state.requestFormData.reoccurrence == "Yes" ||
                                                    this.state.requestFormData.reoccurrence == "true"
                                                    ? "Yes"
                                                    : "No"
                                            }
                                            onValueChanged={this.handleChangeResidency}
                                            layout="horizontal"
                                            readOnly={isReadOnly}
                                        />
                                    </div>
                                    <div className="card-form__field card-form__field--narrow">Frequency
                                        <SelectBox
                                            dataSource={editFrequencyDataSource}
                                            displayExpr="value"
                                            valueExpr="id"
                                            onValueChanged={this.handleChangeFrequency}
                                            value={this.state.requestFormData.reoccurrenceFrequency}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                    <div className="card-form__field card-form__field--narrow">
                                        Days of the Week
                                        <div>
                                            <TagBox
                                                value={this.state.weekList}
                                                dataSource={this.state.dayTypeLookUp}
                                                width={"100%"}
                                                valueExpr="id"
                                                displayExpr="value"
                                                showSelectionControls={true}
                                                onValueChanged={this.handleChangeDays}
                                                disabled={isReadOnly}
                                            ></TagBox>
                                        </div>
                                    </div>
                                    <div className="card-form__field card-form__field--narrow">
                                        Hours
                                        <sup className="card-form__mandatory-symbol">
                                            *
                                        </sup>
                                        <div>
                                            <NumberBox
                                                onValueChanged={this.handleChangeHours}
                                                valueChangeEvent="keyup"
                                                value={this.state.requestFormData.hours}
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                            />
                                        </div>
                                    </div>
                                    <div className="card-form__field card-form__field--narrow">
                                        Min
                                        <sup className="card-form__mandatory-symbol">
                                            *
                                        </sup>
                                        <div>
                                            <SelectBox
                                                dataSource={minuteLookup}
                                                displayExpr="value"
                                                valueExpr="value"
                                                onValueChanged={this.handleChangeMinutes}
                                                value={
                                                    this.state.requestFormData.minutes
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="card-form__field card-form__field--narrow">
                                        Rate
                                        <sup className="card-form__mandatory-symbol">
                                            *
                                        </sup>
                                        <div>
                                            <NumberBox
                                                format={{
                                                    type: "currency",
                                                    precision: 2,
                                                    currency: "GBP",
                                                }}
                                                onValueChanged={this.handleChangeRate}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.requestFormData.rate
                                                }
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                            />
                                        </div>
                                    </div></> : null
                            :
                                <><div className="card-form__field card-form__field--narrow">
                                    Residency
                                    <RadioGroup
                                        items={this.residencyList && this.residencyList.length > 0 ? this.residencyList : []}
                                        displayExpr="value"
                                        valueExpr="value"
                                        value={
                                            this.state.requestFormData.reoccurrence == "Yes" ||
                                                this.state.requestFormData.reoccurrence == "true"
                                                ? "Yes"
                                                : "No"
                                        }
                                        onValueChanged={this.handleChangeResidency}
                                        layout="horizontal"
                                        readOnly={isReadOnly}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--narrow">Frequency
                                    <DropDownFromLookupTableDynamic
                                        lookupTypeIndex={LookupTypeIndexes.frequencyType}
                                        isRoot={true}
                                        parentMappingId={"0"}
                                        onValueChanged={this.handleChangeFrequency}
                                        value={this.state.requestFormData.reoccurrenceFrequency}
                                        isDisabled={this.state.isDisableFrequency}
                                        isReadOnly={isReadOnly}
                                    />
                                </div></>
                        }                        
                        <div className="card-form__field card-form__field--narrow" hidden={gigType !== "Entertainment"}>
                            Residency End Known
                            <RadioGroup
                                items={this.residencyEndKnownList && this.residencyEndKnownList.length > 0 ? this.residencyEndKnownList : []}
                                displayExpr="value"
                                valueExpr="value"
                                value={
                                    this.state.requestFormData.reoccurrenceEndDateKnown == "Yes" ||
                                        this.state.requestFormData.reoccurrenceEndDateKnown == "true"
                                        ? "Yes"
                                        : "Until Further Notice"
                                }
                                onValueChanged={this.handleChangeResidencyEndKnown}
                                layout="horizontal"
                                readOnly={isReadOnly}
                            />
                        </div>
                        <div className="card-form__field card-form__field--narrow" hidden={!this.state.isShowReoccuranceDate || gigType == "Security"}>
                            Residency End Date
                            <DateBox
                                onValueChanged={this.handleChangeDate}
                                value={
                                    this.state.requestFormData.reoccurrenceEndDate
                                        ? this.state.requestFormData.reoccurrenceEndDate
                                        : undefined
                                }
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                            ></DateBox>
                        </div>
                        <div className="row" hidden={gigType !== "Entertainment"}>
                            <div className="card-form__field card-form__field--narrow col-md-5 col-sm-12">
                                Start Time
                                <sup className="card-form__mandatory-symbol">*</sup>
                                <DateBox
                                    type="time"
                                    interval={15}
                                    placeholder="00:00"
                                    useMaskBehavior={true}
                                    displayFormat={"HH:mm"}
                                    onValueChanged={this.handleChangeStartTime}
                                    value={
                                        this.state.requestFormData.startTime
                                            ? this.state.requestFormData.startTime
                                            : undefined
                                    }
                                    disabled={isReadOnly}
                                    onFocusIn={this.onFocusIn}
                                />
                            </div>
                        </div>
                        <div className="row" hidden={gigType !== "Entertainment"}>
                            <div className="card-form__field card-form__field--narrow col-md-5 col-sm-12">
                                End Time
                                <sup className="card-form__mandatory-symbol" hidden={!this.state.finishTimeIsMandatory}>
                                    *
                                </sup>
                                <DateBox
                                    type="time"
                                    interval={15}
                                    placeholder="00:00"
                                    useMaskBehavior={true}
                                    displayFormat={"HH:mm"}
                                    onValueChanged={this.handleChangeEndTime}
                                    value={
                                        this.state.requestFormData.finishTime
                                            ? this.state.requestFormData.finishTime
                                            : undefined
                                    }
                                    disabled={isReadOnly}
                                    onFocusIn={this.onFocusIn}
                                />
                            </div>

                            <div className="card-form__warning-message col-md-4 col-sm-12">
                                {this.state.endTimeWarningText != "" ? (
                                    <span className="text-warning">
                                        <ul>
                                            <li>{this.state.endTimeWarningText}</li>
                                        </ul>
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div hidden={!this.state.showPerfInfo} className="card-form__field card-form__field--narrow">
                            <h4 className="card-form__subheading">
                                Sets
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </h4>
                            Specify the number of sets and the time for each set
                            <div className="row">
                                <div className="card-form__inline-field col-md-4 col-sm-12">
                                    <NumberBox
                                        max={5}
                                        min={0}
                                        showSpinButtons={true}
                                        value={this.state.requestFormData.performanceInformationSetsNumber}
                                        onValueChanged={this.handleChangeSetNumber}
                                        disabled={isReadOnly}
                                        step={0}
                                    />
                                </div>
                                <div className="card-form__inline-field col-md-2 col-sm-12">set(s) of</div>
                                <div className="card-form__inline-field col-md-4 col-sm-12">
                                    <NumberBox
                                        min={0}
                                        max={90}
                                        step={15}
                                        format="#0 mins"
                                        showSpinButtons={true}
                                        value={this.state.requestFormData.performanceInformationSetsDuration}
                                        onValueChanged={this.handleChangeDuration}
                                        disabled={isReadOnly}
                                    />
                                </div>
                                <div className="card-form__inline-field col-md-2 col-sm-12">duration</div>
                            </div>
                        </div>

                        <div className="card-form__field card-form__field--narrow" hidden={gigType !== "Entertainment"}>
                            Equipment Required
                            <div>
                                <TagBox
                                    value={this.state.equipmentRequiredList}
                                    dataSource={this.state.equipmentTypeLookUp}
                                    width={"100%"}
                                    valueExpr="id"
                                    displayExpr="value"
                                    showSelectionControls={true}
                                    onValueChanged={this.handleChangeEquipmentType}
                                    disabled={isReadOnly}
                                ></TagBox>
                            </div>
                        </div>
                        <div className="card-form__field card-form__field--narrow" hidden={gigType !== "Entertainment"}>
                            Additional Equipment
                            <TextBox
                                // disabled={this.state.createAdjustment ? this.state.createAdjustment : this.props.location.state.isAmend}
                                onValueChanged={this.handleChangeEquipment}
                                value={this.state.requestFormData.equipmentRequired}
                                placeholder={placeholderTextforEqipReqd}
                                disabled={isReadOnly}
                            ></TextBox>
                        </div>

                        <div className="card-form__field card-form__field--narrow" hidden={!this.state.isShowDjStyle}>
                            DJ Style
                            <TagBox
                                value={this.state.djStyleList}
                                dataSource={this.state.djStyleLookUp}
                                width={"100%"}
                                valueExpr="id"
                                displayExpr="value"
                                showSelectionControls={true}
                                onValueChanged={this.handleChangeDjStyleType}
                                disabled={isReadOnly}
                            ></TagBox>
                        </div>

                        <div className="card-form__field card-form__field--narrow" hidden={gigType !== "Entertainment"}>
                            Music Type
                            <div>
                                <TagBox
                                    value={this.state.musicTypeData}
                                    dataSource={this.state.musicTypeLookUp}
                                    width={"100%"}
                                    valueExpr="id"
                                    displayExpr="value"
                                    showSelectionControls={true}
                                    onValueChanged={this.handleChangeMusicType}
                                    disabled={isReadOnly}
                                ></TagBox>
                            </div>
                        </div>

                        <div className="card-form__field card-form__field--narrow" hidden={!this.checkArrayObj(this.state.requestFormData?.musicTypeList, 'id', '20')}>
                            Tribute Description
                            <TextArea
                                onValueChanged={this.handleChangeTributeDescription}
                                value={this.state.musicTypeDescription}
                                disabled={isReadOnly}
                            ></TextArea>
                        </div>

                        <div className="card-form__field card-form__field--narrow" hidden={gigType !== "Entertainment"}>
                            Genre
                            <div>
                                <TagBox
                                    value={this.state.genreList}
                                    dataSource={this.state.genreLookUp}
                                    width={"100%"}
                                    valueExpr="id"
                                    displayExpr="value"
                                    showSelectionControls={true}
                                    onValueChanged={this.handleChangeGenre}
                                    disabled={isReadOnly}
                                ></TagBox>
                            </div>
                        </div>

                        <div className="card-form__field card-form__field--narrow" hidden={!this.state.isShowSpecialityDesc}>
                            Specialist Genre
                            <div>
                                <TextArea
                                    onValueChanged={this.handleChangeSpecialistGenre}
                                    value={this.state.specialistDesc}
                                    disabled={isReadOnly}
                                ></TextArea>
                            </div>
                        </div>



                        <div className="card-form__field card-form__field--narrow" hidden={gigType == "Security"}>
                            <h4 className="card-form__subheading">
                                {gigType !== "Entertainment" ? "Description" : "Gig Headline" }
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </h4>
                            Please provide a brief description
                            <TextArea
                                maxLength={100}
                                onValueChanged={this.handleChangeHeadline}
                                value={this.state.requestFormData.entertainmentDescription}
                                placeholder={gigType !== "Entertainment" ? placeholderTextforGigHeadlineOther : placeholderTextforGigHeadline}
                                disabled={isReadOnly}
                            ></TextArea>
                        </div>

                        <div className="card-form__field card-form__field--narrow" hidden={gigType !== "Security"}>
                            <h4 className="card-form__subheading">
                                Description
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </h4>
                            Please provide a brief description
                            <TextArea
                                maxLength={100}
                                onValueChanged={this.handleChangeDescription}
                                value={this.state.requestFormData.additionalInformation}
                                placeholder={gigType !== "Entertainment" ? placeholderTextforGigHeadlineOther : placeholderTextforGigHeadline}
                                disabled={isReadOnly}
                            ></TextArea>
                        </div>


                        <div className="card-form__field card-form__field--narrow" hidden={gigType !== "Entertainment"}>
                            <h4 className="card-form__subheading">
                            Gig info
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </h4>
                            Please provide as much insight as possible
                            <TextArea
                                maxLength={250}
                                // disabled={this.state.createAdjustment ? this.state.createAdjustment : this.props.location.state.isAmend}
                                onValueChanged={this.handleChangeDescription}
                                value={this.state.requestFormData.additionalInformation}
                                placeholder={placeholderTextforAddInfo}
                                disabled={isReadOnly}
                            ></TextArea>
                        </div>
                        <div className="card-form__field card-form__field--narrow" hidden={gigType !== "Entertainment"}>
                            Featured
                            <RadioGroup
                                items={this.featuredList && this.featuredList.length > 0 ? this.featuredList : []}
                                displayExpr="value"
                                valueExpr="value"
                                value={
                                    this.state.requestFormData.featured == "Yes" ||
                                        this.state.requestFormData.featured == "true"
                                        ? "Yes"
                                        : "No"
                                }
                                onValueChanged={this.handleChangeFeatured}
                                layout="horizontal"
                                readOnly={isReadOnly}
                            />
                        </div>

                        <div className="card-form__field card-form__field--narrow"  hidden={((gigType == "ContractCleaning" && this.state.requestFormData.serviceSubTypeId == "21") || gigType == "Security")}>
                            <h4 className="card-form__subheading">
                                Venue Budget
                            </h4>
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                onValueChanged={this.handleChangeMaxBudget}
                                valueChangeEvent="keyup"
                                value={this.state.requestFormData.maxBudget}
                                disabled={isReadOnly}
                                min={0}
                                max={9999.99}
                                step={0}
                                onContentReady={this.onContentReadyNumberBox}
                            />
                        </div>
                        <div className="card-form__field card-form__field--narrow"  hidden={((gigType == "ContractCleaning" && this.state.requestFormData.serviceSubTypeId == "21") || gigType == "Security")}>
                            <h4 className="card-form__subheading">
                                Marketplace Reduction Budget
                            </h4>
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                onValueChanged={this.handleChangeMarketplaceBudget}
                                valueChangeEvent="keyup"
                                value={this.state.requestFormData.marketPlaceBudget}
                                disabled={isReadOnly}
                                min={0}
                                max={9999.99}
                                step={0}
                                onContentReady={this.onContentReadyNumberBox}
                            />
                        </div>
                        <div className="card-form__field card-form__field--narrow" hidden={((gigType == "ContractCleaning" && this.state.requestFormData.serviceSubTypeId == "21") || gigType == "Security")}>
                            <h4 className="card-form__subheading">
                            {gigType !== "Entertainment" ? "Provider Fee" : "Fee For Gig List" }
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </h4>
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                onValueChanged={this.handleChangeBudget}
                                valueChangeEvent="keyup"
                                value={this.state.requestFormData.budget}
                                disabled={isReadOnly}
                                min={0}
                                max={9999.99}
                                step={0}
                                onContentReady={this.onContentReadyNumberBox}
                            />
                        </div>

                        <div className="card-form__field card-form__field--narrow" hidden={!showInternalComments}>
                            <h4 className="card-form__subheading mt-4">Internal Comments</h4>
                            <TextArea
                                onValueChanged={this.handleChangeComment}
                                value={this.state.requestFormData.internalComments}
                                disabled={isReadOnly}
                            ></TextArea>
                        </div>
                        <div className="card-form__field card-form__field--narrow" hidden={gigType == "Entertainment"}>
                            <h4 className="card-form__subheading mt-4">Provider</h4>
                            <LookupTypeListDynamic
                                lookupTypeIndex={LookupTypeIndexes.providerType}
                                onValueChanged={this.handleChangeProviderTypeSelect}
                                isRoot={false}
                                parentMappingId={""}
                                displayExpression={"value"}
                                value={this.state.requestFormData.providerBusinessEntityId}
                            />
                        </div>   
                        <div className="card-form__field card-form__field--narrow" hidden={this.state.requestFormData.id == "0"}>
                            <h4 className="card-form__subheading mt-2">
                                ID: {this.state.requestFormData.id}
                            </h4>
                            { this.state.requestFormData.dateCreated !== null ?
                                <h4 className="card-form__subheading mt-2">
                                    Request Date:
                                    {/* {this.state.requestFormData.requestDate} */}
                                    {this.state.requestFormData.dateCreated
                                    ? this.updateLocaleTime(this.state.requestFormData.dateCreated)
                                    : ""}
                                    &nbsp;&nbsp;&nbsp;
                                    {this.requesterDetails}
                                </h4>
                                : null
                            }
                            <h4 className="card-form__subheading mt-2">
                                Last Updated {" "}
                                {this.state.requestFormData.lastUpdatedOn
                                    ? this.updateLocaleTime(this.state.requestFormData.lastUpdatedOn)
                                    : ""}
                                &nbsp;&nbsp;&nbsp;
                                {this.publishedDateVal}
                            </h4>
                        </div>                        
                    </section>
                    {errList && errList.length > 0 ? (
                        <div className="row justify-content-left pt-3">
                            <span className="text-danger">
                                <ul>
                                    {errList.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        </div>
                    ) : null}
                    { gigType == "Security" ?
                        <>
                            <div className="card-form__field card-form__field--narrow mt-5">
                                <h4 className="card-form__subheading mt-2">
                                    PERSONNEL
                                </h4>
                            </div> 
                            <DataGrid
                                dataSource={this.state.shiftDetailsPersonnelDataSource}
                                showBorders={false}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                columnAutoWidth={true}
                                allowColumnReordering={true}
                                onRowUpdating={this.onRowUpdating}
                                onRowInserting={this.onRowInserting}
                                onRowRemoving={this.onRowRemoving}
                                onEditCanceling={this.onEditCancelled}
                                onEditingStart={this.onRowEditting}
                                onInitNewRow={this.onRowAdding}
                                allowColumnResizing={true}
                                columnResizingMode={"widget"}
                                className="security-shift-grid"
                            >
                                <SearchPanel visible={true} placeholder={"Search"} />
                                <Sorting mode="single" />
                                <Paging defaultPageSize={5} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                <Editing
                                    mode="row"
                                    useIcons={true}
                                    allowAdding={this.state.requestFormData.requestStatusId == "30" ? false : true}
                                    allowUpdating={this.state.requestFormData.requestStatusId == "30" ? false : true}
                                    allowDeleting={this.state.requestFormData.requestStatusId == "30" ? false : true}
                                />
                                <Column type="buttons" buttons={["edit", "delete"]} />
                                <Column dataField="id" allowSorting={true} caption="ID" allowEditing={false} calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertStringToInt(rowData.id);
                                }} />
                                <Column dataField="serviceSubTypeId" allowSorting={true} caption="SUB TYPE">
                                    <Lookup dataSource={this.state.subServicePersonnelDataSource} displayExpr="value" valueExpr="id" />
                                </Column>
                                <Column dataField="typeId" allowSorting={true} caption="ROLE">
                                    <Lookup dataSource={this.state.subServicePersonnelType} displayExpr="value" valueExpr="id" />
                                </Column>
                                <Column
                                    dataField="date"
                                    allowSorting={true}
                                    caption="DATE"
                                    format="dd/MM/yyyy"
                                    dataType="date"
                                    visible={this.state.requestFormData.reoccurrence == "No" || this.state.requestFormData.reoccurrence == ""}
                                >
                                </Column>
                                <Column dataField="request_Days_ID" allowSorting={true} caption="DAY" visible={this.state.requestFormData.reoccurrence == "Yes"}>
                                    <Lookup dataSource={this.state.dayLookUp} displayExpr="value" valueExpr="id" />
                                </Column>
                                <Column 
                                    dataField="scheduledStartTime" 
                                    allowSorting={true} 
                                    caption="START TIME"
                                    setCellValue={(newData: any, value: any,currRowData:any) => {
                                        newData.scheduledStartTime = value;
                                        currRowData.scheduledStartTime = value;
                                        newData.scheduledMinutes = this.calculateSecurityScheduledMinutes(currRowData);
                                        //rowData.scheduledMinutes = this.calculateSecurityScheduledMinutes(rowData)
                                    }}
                                >
                                    <PatternRule
                                        message={"Start time should be in hh:mm format"}
                                        pattern={/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/}
                                    />
                                </Column>
                                <Column 
                                    dataField="scheduledFinishTime" 
                                    allowSorting={true} 
                                    caption="END TIME"
                                    setCellValue={(newData: any, value: any,currRowData:any) => {
                                        newData.scheduledFinishTime = value;
                                        currRowData.scheduledFinishTime = value;
                                        newData.scheduledMinutes = this.calculateSecurityScheduledMinutes(currRowData);
                                        //rowData.scheduledMinutes = this.calculateSecurityScheduledMinutes(rowData)
                                    }}
                                >
                                    <PatternRule
                                        message={"End time should be in hh:mm format"}
                                        pattern={/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/}
                                    />
                                </Column>
                                <Column 
                                    dataField="scheduledMinutes" 
                                    allowSorting={true} 
                                    caption="HOURS/MINUTES" 
                                    allowEditing={false} 
                                    calculateCellValue={this.displaySecurityScheduledMinutes}
                                />
                                <FilterRow visible={false} applyFilter="auto" />
                            </DataGrid>
                            <div className="card-form__field card-form__field--narrow mt-5">
                                <h4 className="card-form__subheading mt-2">
                                    EQUIPMENT
                                </h4>
                            </div> 
                            <DataGrid
                                dataSource={this.state.shiftDetailsEquipmentDataSource}
                                showBorders={false}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                columnAutoWidth={true}
                                allowColumnReordering={true}
                                onRowUpdating={this.onRowEquipUpdating}
                                onRowInserting={this.onRowEquipInserting}
                                onRowRemoving={this.onRowEquipRemoving}
                                onEditCanceling={this.onEquipEditCancelled}
                                onEditingStart={this.onRowEquipEditting}
                                onInitNewRow={this.onRowEquipAdding}
                                allowColumnResizing={true}
                                columnResizingMode={"widget"}
                                className="security-shift-grid"
                            >
                                <SearchPanel visible={true} placeholder={"Search"} />
                                <Sorting mode="single" />
                                <Paging defaultPageSize={5} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                <Editing
                                    mode="row"
                                    useIcons={true}
                                    allowAdding={this.state.requestFormData.requestStatusId == "30" ? false : true}
                                    allowUpdating={this.state.requestFormData.requestStatusId == "30" ? false : true}
                                    allowDeleting={this.state.requestFormData.requestStatusId == "30" ? false : true}
                                />
                                <Column type="buttons" buttons={["edit", "delete"]} />
                                <Column dataField="id" allowSorting={true} caption="ID" allowEditing={false} calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertStringToInt(rowData.id);
                                }} />
                                <Column dataField="serviceSubTypeId" allowSorting={true} caption="SUB TYPE">
                                    <Lookup dataSource={this.state.subServiceEquipDataSource} displayExpr="value" valueExpr="id" />
                                </Column>
                                <Column dataField="typeId" allowSorting={true} caption="TYPE">
                                    <Lookup dataSource={this.state.subServiceEquipType} displayExpr="value" valueExpr="id" />
                                </Column>
                                <Column
                                    dataField="date"
                                    allowSorting={true}
                                    caption="DATE"
                                    format="dd/MM/yyyy"
                                    dataType="date"
                                    visible={this.state.requestFormData.reoccurrence == "No" || this.state.requestFormData.reoccurrence == ""}
                                >
                                </Column>
                                <Column dataField="request_Days_ID" allowSorting={true} caption="DAY" visible={this.state.requestFormData.reoccurrence == "Yes"}>
                                    <Lookup dataSource={this.state.dayLookUp} displayExpr="value" valueExpr="id" />
                                </Column>
                                <Column 
                                    dataField="equipmentScheduledQuantity" 
                                    allowSorting={true} 
                                    caption="QUANTITY"
                                    dataType="number"
                                >
                                </Column>
                                <FilterRow visible={false} applyFilter="auto" />
                            </DataGrid> 
                        </>: null                       
                    }
                    <Popup
                        visible={this.state.isCancelRequestPopupvisible}
                        onHiding={this.hideCancelRequestPopup}
                        dragEnabled={false}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        showCloseButton={true}
                        title={"CANCELLATION REASON"}
                        resizeEnabled={true}
                        width="550px"
                        height="auto"
                    >
                        <ScrollView width="100%" height="100%">
                            <div>
                                <div className="card">
                                    <div className="card-body">
                                        <p>Please Select a reason for cancellation</p>
                                        <br></br>
                                        <SelectBox
                                            dataSource={this.state.cancellationReasonLookUp}
                                            displayExpr={"value"}
                                            valueExpr={"id"}
                                            onValueChanged={this.handleChangeCancellationRequest}
                                            value={this.state.cancellationRequestData.cancellationreasonId}
                                        />
                                        {this.state.isShowReplacementReason ? <>
                                            <p className="mt-3">Does the venue require a replacement artist?</p>
                                            <RadioGroup
                                                items={this.replacementArtistList && this.replacementArtistList.length > 0 ? this.replacementArtistList : []}
                                                displayExpr="value"
                                                valueExpr="id"
                                                onValueChanged={this.handleChangerReplacementReason}
                                                layout="horizontal"
                                                readOnly={isReadOnly}
                                            />
                                        </> : null}
                                        <button
                                            className="btn btn-primary btn--large mt-3"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.onCancelRequestConfirmation();
                                            }}
                                        >
                                            SUBMIT CANCELLATION
                                        </button>


                                    </div>
                                </div>
                            </div>
                        </ScrollView>
                    </Popup>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="row mt-5" hidden={this.props.pendingRequestFlag}>
                        <div className="col-0 col-lg-4 col-xl-8"></div>
                        <div className="col-12 col-lg-4 col-xl-2">
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onCancel();
                                }}
                                disabled={isReadOnly}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-2">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.setRoundedBudget("publishedAdd");
                                }}
                                disabled={isReadOnly}
                            >
                                Submit Request
                            </button>
                        </div>
                    </div>
                    <div className="row mt-5" hidden={!this.props.pendingRequestFlag}>
                        <div className="col-12 col-lg-1">
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onClose();
                                }}
                            >
                                Close
                            </button>
                        </div>
                        <div className="col-12 col-lg-2" hidden={!this.props.eventUKIndicator || gigType !== "Entertainment"}>
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.setRoundedBudget("publish");
                                }}
                                disabled={
                                    this.state.requestFormData.requestStatusId == RequestStatus.Booked || this.state.disabledButtonOnProcessing 
                                        ? true
                                        : false || isReadOnly || this.props.disableUpdateButton
                                }
                            >
                                Publish
                            </button>
                        </div>
                        <div className="col-12 col-lg-2" hidden={!this.props.eventUKIndicator || gigType == "Security" || (billableItemId !== null && (approvalDate == null && invoiceId == null  && paymentId == null))}>
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.setRoundedBudget("book");
                                }}
                                //this.props.disableBookButton ||  
                                disabled={ this.state.requestFormData.requestStatusId == RequestStatus.Booked || this.state.disabledButtonOnProcessing || ((gigType == "ContractCleaning" || gigType == "Gardening" || gigType == "WindowCleaning" || gigType == "Maintenance" || gigType == "Miscelleanous") && this.state.requestFormData.requestStatusId == RequestStatus.Cancelled)
                                    ? true
                                    : gigType == "Entertainment" ? false || isReadOnly || this.props.disableBookButton : false}
                            >
                                Book
                            </button>
                        </div>
                        <div className="col-12 col-lg-2" hidden={!this.props.eventUKIndicator || gigType !== "Security" || this.props.location.state.navigation == "gigRequestAdd"}>
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.setRoundedBudget("securityBook");
                                }}
                                //this.props.disableBookButton ||  
                                disabled={ this.state.requestFormData.requestStatusId == RequestStatus.Booked || this.state.disabledButtonOnProcessing || ((gigType == "ContractCleaning" || gigType == "Gardening" || gigType == "WindowCleaning" || gigType == "Maintenance" || gigType == "Miscelleanous") && this.state.requestFormData.requestStatusId == RequestStatus.Cancelled)
                                    ? true
                                    : gigType == "Entertainment" ? false || isReadOnly || this.props.disableBookButton : false}
                            >
                                Book
                            </button>
                        </div>
                        <div className="col-12 col-lg-3" hidden={!this.props.eventUKIndicator || gigType !== "Entertainment" || (billableItemId == null 
                            || (billableItemId !== null && (approvalDate !== null || invoiceId !== null  || paymentId !== null)))}>
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.setRoundedBudget("re-book");
                                }}
                                disabled={ this.state.disabledButtonOnProcessing
                                    ? true
                                    : false}
                            >
                                UPDATE EXISTING BOOKING
                            </button>
                        </div>
                        <div className="col-12 col-lg-2" hidden={!this.props.eventUKIndicator}>
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.setRoundedBudget("update");
                                }}
                                disabled={this.state.requestFormData.requestStatusId == RequestStatus.Booked || this.state.disabledButtonOnProcessing
                                    ? true
                                    : false || isReadOnly || this.props.disableUpdateButton}
                            >
                                {buttonName}
                            </button>
                        </div>
                        <div className="col-12 col-lg-2" hidden={!this.props.eventUKIndicator || !showCancelRequest}>
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                //type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onCancelRequest();
                                }}
                                disabled={isReadOnly}
                            >
                                Cancel Request
                            </button>
                        </div>

                        <div className="col-12 col-lg-2" hidden={!this.props.eventUKIndicator || gigType !== "Entertainment"}>
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onClickAddArtist();
                                }}
                                disabled={isReadOnly}
                                hidden={isHideAddArtists}
                            >
                                Add Artist(s)
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <Popup
                visible={this.state.showRoundPopupMakeOffer}
                onHiding={this.hideRoundPopup}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                showCloseButton={true}
                title={"Proceed with Rounding to Nearest £5?"}
                resizeEnabled={true}
                width={"30%"}
                height={300}                        
            >
                <ScrollView width='100%' height='100%'>
                    <div>
                        <div className="text-center">Make offer is <b>£{this.props.makeoffAmtForBookedArtist}</b></div>
                        <div className="text-center pt-3">Do you want the Client Amount Rounded?</div>
                        <div className="text-center">(if you select the ROUND button below)</div>
                    </div>
                    <div className="row px-3">
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn-primary btn--large mt-3"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onProceedMakeOffer();
                                    }}
                                >
                                    ROUND?
                                </button>
                            </div>
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onRoundedCancel();
                                    }}
                                >
                                    DO NOT ROUND?
                                </button>
                            </div>
                        </div> 
                </ScrollView>
            </Popup>
            <Popup
                visible={this.state.showRoundPopup}
                onHiding={this.hideRoundPopup}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                showCloseButton={true}
                title={"Proceed with Rounding to Nearest £5?"}
                resizeEnabled={true}
                width={"30%"}
                height={300}                        
            >
                <ScrollView width='100%' height='100%'>
                    <div>
                        <div className="text-center">Please confirm to ROUND to the Nearest £5 or click DO NOT ROUND to keep the existing figures</div>
                        <div className="text-center pt-3"><b>Current Budget</b> is <b>£{this.state.requestFormData.budget}</b></div>
                        <div className="text-center pt-3"><b>New Budget</b> will be rounded to <b>£{this.state.newBudget}</b></div>
                        <div className="text-center">(if you select the ROUND button below)</div>
                    </div>
                    <div className="row px-3">
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn-primary btn--large mt-3"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onProceed();
                                    }}
                                >
                                    ROUND?
                                </button>
                            </div>
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onRoundedCancel();
                                    }}
                                >
                                    DO NOT ROUND?
                                </button>
                            </div>
                        </div> 
                </ScrollView>
            </Popup>
            <Popup
                visible={this.state.showRoundPopupMax}
                onHiding={this.hideRoundPopup}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                showCloseButton={true}
                title={"Proceed with Rounding to Nearest £5?"}
                resizeEnabled={true}
                width={"30%"}
                height={300}                        
            >
                <ScrollView width='100%' height='100%'>
                    <div>
                        <div className="text-center">Please confirm to ROUND to the Nearest £5 or click DO NOT ROUND to keep the existing figures</div>
                        <div className="text-center pt-3"><b>Current Budget</b> is <b>£{this.state.requestFormData.maxBudget}</b></div>
                        <div className="text-center pt-3"><b>New Budget</b> will be rounded to <b>£{this.state.newBudget}</b></div>
                        <div className="text-center">(if you select the ROUND button below)</div>
                    </div>
                    <div className="row px-3">
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn-primary btn--large mt-3"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onProceedMax();
                                    }}
                                >
                                    ROUND?
                                </button>
                            </div>
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onRoundedCancel();
                                    }}
                                >
                                    DO NOT ROUND?
                                </button>
                            </div>
                        </div> 
                </ScrollView>
            </Popup>
            <Popup
                visible={this.state.showCalculationPopup}
                onHiding={this.hideRoundPopup}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                showCloseButton={true}
                title={"CONFIRM: Amounts Re-Calculated"}
                resizeEnabled={true}
                width={"45%"}
                height={450}                        
            >
                <ScrollView width='100%' height='100%'>
                    <div>
                        <div className="text-center card-form__subheading"><b>Amounts have been re-calculated as below - edit any changes required and click CONFIRM</b></div>
                    </div>                    
                    <div className="card-form__field card-form__field--narrow">
                        <h4 className="card-form__subheading">
                            Provider NET
                        </h4>
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeProviderNet}
                            valueChangeEvent="keyup"
                            value={this.state.requestFormData.providerPayAmountLocal}
                            disabled={isReadOnly}
                            min={0}
                            max={9999.99}
                            step={0}
                            onContentReady={this.onContentReadyNumberBox}
                        />
                    </div>
                    <div className="card-form__field card-form__field--narrow">
                        <h4 className="card-form__subheading">
                            Event NET
                        </h4>
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeEventNet}
                            valueChangeEvent="keyup"
                            value={this.state.requestFormData.grossProfitAmountLocal}
                            disabled={isReadOnly}
                            min={0}
                            max={9999.99}
                            step={0}
                            onContentReady={this.onContentReadyNumberBox}
                        />
                    </div>
                    <div className="card-form__field card-form__field--narrow">
                        <h4 className="card-form__subheading">
                            Client NET
                        </h4>
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeClientNet}
                            valueChangeEvent="keyup"
                            value={this.state.requestFormData.clientBillableAmountLocal}
                            disabled={isReadOnly}
                            min={0}
                            max={9999.99}
                            step={0}
                            onContentReady={this.onContentReadyNumberBox}
                        />
                    </div>
                    
                    <div className="row px-3">
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn-primary btn--large mt-3"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.setState({showCalculationPopup:false},()=>{
                                            this.submitRequestForm();
                                        })
                                        //this.submitRequestForm();
                                    }}
                                    // onDoubleClick={(e: MouseEvent) => {
                                    //     e.preventDefault();
                                    //     console.log('double clicked')
                                    //     return;
                                    // }}
                                >
                                    CONFIRM
                                </button>
                            </div>
                        </div> 
                </ScrollView>
            </Popup>
            <Popup
                visible={this.state.showProviderPopup}
                onHiding={this.hideRoundPopup}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                showCloseButton={true}
                title={"Select Provider and Confirm to Book"}
                resizeEnabled={true}
                width={"30%"}
                height={300}                        
            >
                <ScrollView width='100%' height='100%'>
                    <div className="card-form__field card-form__field--narrow w-100">
                        Provider
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <LookupTypeListDynamic
                            lookupTypeIndex={LookupTypeIndexes.providerType}
                            onValueChanged={this.handleChangeProviderTypeSelect}
                            isRoot={false}
                            parentMappingId={""}
                            displayExpression={"value"}
                            value={this.state.requestFormData.providerBusinessEntityId}
                            recordType={this.props.location.state.id ? "all" : "active"}
                        />
                    </div>
                    <div className="confirm-book-btn">
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="submit"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.bookProceed();
                            }}
                        >
                            Confirm Book
                        </button>
                        </div> 
                </ScrollView>
            </Popup>
        </>);
    }
}

export default withTranslation()(NewRequestForm);
