import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, { Pager, Paging, FilterRow, Column, Sorting, Export, SearchPanel } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import EntertainmentResidencyService, { ResidencyGridRowItem } from "../../services/EntertainmentResidencyService";
import ResidencyCellComponent from "./ResidencyCellComponent";
import gridUtils, { currencyTypeToSymbolMatrix, currencyTypes } from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";
import EntertainmentSubstituteForm from "../EntertainmentAuthoringSubstitute/EntertainmentSubstituteForm";
import EntertainmentSubstituteGrid from "../EntertainmentAuthoringSubstitute/EntertainmentSubstituteGrid";

//props
interface ResidencyGridProps {
    refreshSignal: boolean;
    sendEditFormSignal: (editFormSignal: boolean) => void;
    showSubstituteChildGrid: boolean;
}

export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}
// State
interface ResidencyGridState {
    residencyGridDataSource: ResidencyGridRowItem[];
    showProgressIndicator: boolean;
    showSubstituteGrid: boolean;
    id: string;
    isActive: string;
    dateFrom: string;
    dateTo: string;
    day: string;
    openForm: boolean;
    isAddClick: boolean;
    applySuccessful: string;
    refreshGrid: boolean;
    isCancel: boolean;
    substituteId: string;
}

// Component - displays the Rate Matrix Grid
class ResidencyGrid extends React.Component<ResidencyGridProps> {
    //Initialize the component's state.
    state: ResidencyGridState;
    entertainmentService: EntertainmentResidencyService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    constructor(props: ResidencyGridProps) {
        super(props);
        this.entertainmentService = new EntertainmentResidencyService();
        this.sharedUtils = new sharedUtils();
        // Initialize state and services/utils
        this.state = {
            residencyGridDataSource: [],
            showProgressIndicator: false,
            showSubstituteGrid: false,
            id: "",
            isActive: "",
            dateFrom: "",
            dateTo: "",
            day: "",
            openForm: false,
            isAddClick: false,
            applySuccessful: "",
            refreshGrid: false,
            substituteId: "",
            isCancel: false,
        };
        this.gridUtils = new gridUtils();
        // Functions
        this.getResidencyGridData = this.getResidencyGridData.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.onRowUpdatedInserted = this.onRowUpdatedInserted.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onApplySuccess = this.onApplySuccess.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.handleFormOpenClose = this.handleFormOpenClose.bind(this);
    }

    componentDidMount() {
        this.getResidencyGridData();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: ResidencyGridProps) {
        if (this.props.refreshSignal !== prevProps.refreshSignal) {
            // refresh the grid
            this.getResidencyGridData();
        }
    }

    getResidencyGridData() {
        this.setState({
            showProgressIndicator: true,
        });
        this.entertainmentService.getResidencyData().then(this.handleSuccess).catch(this.handleError);
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            residencyGridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    }

    handleError(error: AxiosResponse<any>) {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "getResidencyData failed with response: " + JSON.stringify(error);

        if (!this.entertainmentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }

    onRowUpdatedInserted() {
        this.getResidencyGridData();
    }
    onSelectionChanged(e: any) {
        const data = e.selectedRowsData[0];

        this.setState({
            showSubstituteGrid: !!data,
            id: data && data.residenciesId,
            isActive: data.isActive,
            dateFrom: data.dateFrom,
            dateTo: data.dateTo,
            day: data.day,
            refreshGrid: !this.state.refreshGrid,
        });
    }

    onApplySuccess(applySignal: string) {
        // This refresh signal will toggle between true and false to indicate a refresh is required.
        this.setState({
            refreshGrid: !this.state.refreshGrid,
            applySuccessful: applySignal,
            isAddClick: applySignal == "ADD" ? true : applySignal == "EDIT" ? false : null,
            openForm: false,
        });
    }
    onAddClick() {
        this.setState({
            isAddClick: true,
            applySuccessful: "",
            substituteId: "",
        });
    }
    handleFormOpenClose(applySignal: string, id: string) {
        this.setState({
            openForm: applySignal == "OPEN" ? true : applySignal == "CLOSE" ? false : null,
            substituteId: id,
            isAddClick: !this.state.isAddClick,
        });
        this.props.sendEditFormSignal(true);
    }

    render() {
        var showChildGrid = this.state.showSubstituteGrid;
        if (!this.props.showSubstituteChildGrid && !showChildGrid) {
            showChildGrid = false;
        }
        let formView = false;
        let isEditClick = false;
        if (this.state.openForm) {
            formView = true;
            isEditClick = true;
        } else if (this.state.isAddClick) {
            if (this.state.applySuccessful == "ADD") {
                formView = false;
            } else {
                formView = true;
            }
        } else if (this.state.applySuccessful == "EDIT") {
            formView = false;
        }

        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <>
                        <DataGrid
                            dataSource={this.state.residencyGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            selection={{ mode: "single" }}
                            onSelectionChanged={this.onSelectionChanged}
                            allowColumnResizing={true}
                            columnResizingMode="widget"
                        >
                            <Export enabled={true} />
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Sorting mode="single" />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <Column dataField="residenciesId" allowSorting={true} caption="ID" calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.residenciesId);
                            }}></Column>
                            <Column dataField="clientName" allowSorting={true} caption="CLIENT" width={165}></Column>
                            <Column dataField="serviceTypeName" allowSorting={true} caption="SERVICE"></Column>
                            <Column dataField="serviceSubTypeName" allowSorting={true} caption="SUB TYPE"></Column>
                            <Column dataField="typeLookUp" allowSorting={true} caption="TYPE"></Column>
                            <Column dataField="providerName" allowSorting={true} caption="ARTIST"></Column>

                            <Column dataField="venueName" allowSorting={true} caption="VENUE NAME"></Column>
                            <Column dataField="isActive" allowSorting={true} caption="IS ACTIVE"></Column>
                            <Column
                                dataField="dateFrom"
                                allowSorting={true}
                                caption="DATE FROM"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateFrom);
                                }}
                            ></Column>
                            <Column
                                dataField="dateTo"
                                allowSorting={true}
                                caption="DATE TO"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                                }}
                            ></Column>
                            <Column dataField="frequencyName" allowSorting={true} caption="FREQUENCY" />
                            <Column dataField="day" allowSorting={true} caption="DAY" />

                            <Column
                                dataField="rate"
                                allowSorting={true}
                                caption="RATE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate);
                                }}
                                calculateDisplayValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToCurrency(
                                        rowData.rate,
                                        currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                    );
                                }}
                            ></Column>

                            <Column
                                dataField="feeOverride"
                                allowSorting={true}
                                caption="OVERRIDE FEE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToFloat(rowData.feeOverride);
                                }}
                                calculateDisplayValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToCurrency(
                                        rowData.feeOverride,
                                        currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                    );
                                }}
                            ></Column>
                            <Column
                                dataField="startTime"
                                allowSorting={true}
                                caption="START TIME"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.converthhmmStringToTime(rowData.startTime);
                                }}
                            ></Column>
                            <Column
                                dataField="endTime"
                                allowSorting={true}
                                caption="END TIME"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.converthhmmStringToTime(rowData.endTime);
                                }}
                            ></Column>
                            <Column
                                dataField="lastUpdatedon"
                                allowSorting={true}
                                caption="LAST UPDATED TIME"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedon);
                                }}
                            ></Column>
                            <Column dataField="lastUpdatedBy" allowSorting={true} caption="LAST UPDATED BY" />
                            <Column cellComponent={ResidencyCellComponent}></Column>

                            <FilterRow visible={true} applyFilter="auto" />
                        </DataGrid>

                        <div className="row">
                            <div className="col-md">
                                {formView ? (
                                    <EntertainmentSubstituteForm
                                        location={null}
                                        history={null}
                                        subRowId={this.state.substituteId}
                                        onApplySuccess={this.onApplySuccess}
                                        handleFormOpenClose={this.handleFormOpenClose}
                                        isEditForm={isEditClick}
                                        resId={this.state.id}
                                        isActive={this.state.isActive}
                                        dateFrom={this.state.dateFrom}
                                        dateTo={this.state.dateTo}
                                        day={this.state.day}
                                    />
                                ) : null}
                                {showChildGrid ? (
                                    <div>
                                        <>
                                            <div className="row pt-5">
                                                <h4 className="font-weight-bold ml-3">
                                                    {" "}
                                                    View Substitutes For Residency Selected Above
                                                </h4>
                                            </div>
                                            <div className="row pt-5">
                                                <div className="col-12 col-lg-2 mb-3">
                                                    <button
                                                        className="btn btn-primary btn--large"
                                                        onClick={this.onAddClick}
                                                    >
                                                        Add Substitute
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        <div className="row">
                                            <div className="col-md">
                                                <EntertainmentSubstituteGrid
                                                    refreshSignal={this.state.refreshGrid}
                                                    resId={this.state.id}
                                                    handleFormOpenClose={this.handleFormOpenClose}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default ResidencyGrid;
