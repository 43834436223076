import React from "react";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Lookup,
    SearchPanel,
    Export,
} from "devextreme-react/data-grid";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import {
    OnRowUpdatingEvent,
    OnRowInsertingEvent,
    OnRowRemovingEvent,
    onEditCanceledEvent,
} from "../../types/DevExtremeTypes";
import lookupService, { LookupTypeIndexes } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import MatrixService, { ServiceTypeMappingRowItem } from "../../services/MatrixService";

interface ServiceTypeMappingsProps {}

export interface ServiceTypeMappingAddEditObjectItem {
    mappingId: number;
    serviceTypeLookUpId: number;
    serviceSubTypeLookUpId: number;
    typeLookUpId: number;
}
export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}
interface ServiceTypeMappingsState {
    mappingGridDataSource: ServiceTypeMappingRowItem[];
    serviceLookUp: LookupTypeItemGrid[];
    subServiceLookUp: LookupTypeItemGrid[];
    roleLookUp: LookupTypeItemGrid[];
    showProgressIndicator: boolean;
    errorMessage: any[];
    loadPanelVisible: boolean;
    ServiceTypeMappingAddEditObject: ServiceTypeMappingAddEditObjectItem;
}
export class ServiceTypeMappings extends React.Component<ServiceTypeMappingsProps> {
    state: ServiceTypeMappingsState;
    dropdownService: lookupService;
    mappingService: MatrixService;
    constructor(props: ServiceTypeMappingsProps) {
        super(props);
        this.dropdownService = new lookupService();
        this.mappingService = new MatrixService();
        this.state = {
            mappingGridDataSource:[],
            showProgressIndicator: false,
            serviceLookUp: [],
            subServiceLookUp: [],
            roleLookUp: [],
            ServiceTypeMappingAddEditObject: {
                mappingId: 0,
                serviceTypeLookUpId: 0,
                serviceSubTypeLookUpId: 0,
                typeLookUpId: 0,
            },
            errorMessage: [],
            loadPanelVisible: false,
        };
    }
componentDidMount = () =>{
    this.dropDownDataSource();
     this.serviceTypeMappingDataSource();
}

dropDownDataSource = ()=>{
    this.dropdownService
    .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
    .then(this.handleServiceLUSuccess)
    .catch(this.handleError);
this.dropdownService
    .getLookupByLookupTypeIndex(LookupTypeIndexes.subServiceType)
    .then(this.handleSubServiceLUSuccess)
    .catch(this.handleError);
this.dropdownService
    .getLookupByLookupTypeIndex(LookupTypeIndexes.offeringsType)
    .then(this.handleRoleLUSuccess)
    .catch(this.handleError);
}

serviceTypeMappingDataSource = () => {
    this.setState({
        showProgressIndicator: true,
    });
    this.mappingService.getServiceTypeMappingDataItem().then(this.handleSuccess).catch(this.handleError);
};

handleSuccess = (response: AxiosResponse<any>) => {
    this.setState({
        mappingGridDataSource: response.data.data,
        showProgressIndicator: false,
        loadPanelVisible: false,
    });
};
handleError = (error: AxiosResponse<any>) => {
    this.setState({
        showProgressIndicator: false,
        loadPanelVisible: false,
    });
    var respMessage: string = "Mapping service failed with response: " + JSON.stringify(error);

    if (!this.mappingService.traceAsErrorToAppInsights(respMessage)) {
        // AppInsights is not available
        console.error(respMessage);
    }
};
lookUpCreation = (response: AxiosResponse<any>): LookupTypeItemGrid[] => {
    var itemLUItem: LookupTypeItemGrid;
    var itemLUItemArray: LookupTypeItemGrid[];
    itemLUItemArray = [];
    response.data.data.map((item: any, uniqueKey: number) => {
        itemLUItem = {
            id: parseInt(item.id),
            value: item.value,
            parentMappingId: item.parentMappingId,
        };
        itemLUItemArray.push(itemLUItem);
    });
    return itemLUItemArray;
};
handleServiceLUSuccess = (response: AxiosResponse<any>) => {
    var serviceDropDown = this.lookUpCreation(response);
    this.setState({
        serviceLookUp: serviceDropDown,
    });
};

handleSubServiceLUSuccess = (response: AxiosResponse<any>) => {
    var subServiceDropDown = this.lookUpCreation(response);
    this.setState({
        subServiceLookUp: subServiceDropDown,
    });
};

handleRoleLUSuccess = (response: AxiosResponse<any>) => {
    var roleDropDown = this.lookUpCreation(response);
    this.setState({
        roleLookUp: roleDropDown,
    });
};

    onRowUpadating = (e: OnRowUpdatingEvent) => {
        this.setState({
            loadPanelVisible: true,
            ServiceTypeMappingAddEditObject:{
                ...this.state.ServiceTypeMappingAddEditObject,
                mappingId:e.oldData.mappingId,
                serviceTypeLookUpId:
                e.newData.serviceTypeLookUpId ||
                e.newData.serviceTypeLookUpId === null ||
                e.newData.serviceTypeLookUpId === ""
                    ? e.newData.serviceTypeLookUpId
                    : e.oldData.serviceTypeLookUpId,
            serviceSubTypeLookUpId:
                e.newData.serviceSubTypeLookUpId ||
                e.newData.serviceSubTypeLookUpId === null ||
                e.newData.serviceSubTypeLookUpId === ""
                    ? e.newData.serviceSubTypeLookUpId
                    : e.oldData.serviceSubTypeLookUpId,
            typeLookUpId:
                e.newData.typeLookUpId || e.newData.typeLookUpId === null || e.newData.typeLookUpId === ""
                    ? e.newData.typeLookUpId
                    : e.oldData.typeLookUpId,
            }

        })
        this.mappingService
        .saveServiceTypeMappingDataItem(this.state.ServiceTypeMappingAddEditObject)
        .then(this.onRowUpdatedInsertedDeleted)
        .catch((err) => {
            this.setState({
                loadPanelVisible: false,
                errorMessage:
                    err.response && err.response.data && err.response.data.error
                        ? JSON.parse(JSON.stringify(err.response.data.error))
                        : null,
            });
        });
    };
    onRowInserting = (e: OnRowInsertingEvent) => {
        
        this.setState({
            loadPanelVisible: true,
            ServiceTypeMappingAddEditObject:{
                ...this.state.ServiceTypeMappingAddEditObject,
                mappingId:0,
                serviceTypeLookUpId: e.data.serviceTypeLookUpId ? e.data.serviceTypeLookUpId : null,
                serviceSubTypeLookUpId: e.data.serviceSubTypeLookUpId ? e.data.serviceSubTypeLookUpId : null,
                typeLookUpId: e.data.typeLookUpId ? e.data.typeLookUpId : null,
            }
        })
        this.mappingService
        .saveServiceTypeMappingDataItem(this.state.ServiceTypeMappingAddEditObject)
        .then(this.onRowUpdatedInsertedDeleted)
        .catch((err) => {
            this.setState({
                loadPanelVisible: false,
                errorMessage:
                    err.response && err.response.data && err.response.data.error
                        ? JSON.parse(JSON.stringify(err.response.data.error))
                        : null,
            });
        });
    };
    onRowUpdatedInsertedDeleted = () => {
        this.setState({
            loadPanelVisible: false,
            errorMessage: [],
        });
    }
    onRowRemoving = (e: OnRowRemovingEvent) => {
        this.mappingService
            .deleteServiceTypeMappingDataItem(e.data.mappingId)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch(this.handleError);
    };
    onEditCancelled = (e: onEditCanceledEvent) => {
        this.setState({
            errorMessage: [],
        });
    };

    getFilteredSubService = (options: any) => {
        return {
            store: this.state.subServiceLookUp,
            filter: options.data ? ["parentMappingId", "=", options.data.serviceTypeLookUpId] : null,
        };
    };

    getFilteredRoleType = (options: any) => {
        return {
            store: this.state.roleLookUp,
            filter: options.data ? ["parentMappingId", "=", options.data.serviceSubTypeLookUpId] : null,
        };

    };
    setServiceCellValue = (rowData: any, value: any) => {
        rowData.serviceSubTypeLookUpId = null;
        rowData.typeLookUpId = null;
        rowData.serviceTypeLookUpId = value;
    };

    setSubServiceCellValue = (rowData: any, value: any) => {
        rowData.typeLookUpId = null;
        rowData.serviceSubTypeLookUpId = value;
    };
    renderErrorMessage = (errorMessage: any[]) => {
        let errorData: React.ReactNode = <></>;
        if (errorMessage) {
            errorData = (
                <span className="unscheduled-shift">
                    <ul>
                        {errorMessage.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    {item.columnName}: {item.errorMessage}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            );
        }
        return errorData;
    };
    
    render() {
        var { errorMessage, loadPanelVisible } = this.state;
        return (
            <div>
                {this.renderErrorMessage(errorMessage)}
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={loadPanelVisible} />
                <DataGrid
                 dataSource= {this.state.mappingGridDataSource}
                    showBorders={false}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    onRowUpdating={this.onRowUpadating}
                    onRowInserting={this.onRowInserting}
                    onRowRemoving={this.onRowRemoving}
                    onEditCanceling={this.onEditCancelled}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                >
                    <Editing mode="row" useIcons={true} allowAdding={true} allowUpdating={true} allowDeleting={true} />
                    <Export enabled={true} allowExportSelectedData={true} />
                    <SearchPanel visible={true} placeholder={"Search"} />
                    <Sorting mode="single" />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                    <Column type="buttons" buttons={["edit", "delete"]} />
                    <Column dataField="serviceTypeMappingId" allowSorting={true} caption="ID" allowEditing={false} />
                    <Column
                        dataField="serviceTypeLookUpId"
                        allowSorting={true}
                        caption="SERVICE"
                        setCellValue={this.setServiceCellValue}
                    >
                        <Lookup dataSource={this.state.serviceLookUp} displayExpr="value" valueExpr="id" />
                    </Column>
                    <Column
                        dataField="serviceSubTypeLookUpId"
                        allowSorting={true}
                        caption="SERVICE SUB TYPE"
                        setCellValue={this.setSubServiceCellValue}
                    >
                        <Lookup dataSource={this.getFilteredSubService} displayExpr="value" valueExpr="id" />
                    </Column>
                    <Column dataField="typeLookUpId" allowSorting={true} caption="TYPE">
                        <Lookup dataSource={this.getFilteredRoleType} displayExpr="value" valueExpr="id" />
                    </Column>
                    <Column
                        dataField="lastUpdatedOn"
                        allowSorting={true}
                        caption="LAST UPDATED ON"
                        allowEditing={false}
                    />
                    <Column
                        dataField="lastUpdatedBy"
                        allowSorting={true}
                        caption="LAST UPDATED BY"
                        allowEditing={false}
                    />
                    <FilterRow visible={true} applyFilter="auto" />
                </DataGrid>
               </>
               )}
           </div>
        );
    }
}
