import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import {
    TextBox,
    SelectBox,
    RadioGroup,
    LoadPanel,
    Popup,
    ScrollView
} from "devextreme-react";
import { confirm } from "devextreme/ui/dialog";
import ArtistService,{logicAppPostRequest} from "../../services/ArtistService";
import {
    TextBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    RadioButtonOnSelectionChanged,
    LookUpOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import artistProfileUtils, { ContactPageFormData } from "../Artist-Profile/ArtistProfileUtilitise";
import "../../../assets/images/event-UK-image.jpeg";
import { withTranslation, WithTranslation } from "react-i18next";
import sharedUtils from "../grid/sharedUtilities";
import notify from "devextreme/ui/notify";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";

interface AddContactProps extends WithTranslation {
    history?: any;
    location?: any;
    mode?: string;
    id?: string;
    businessEntityId?: string;
    contactType?: any;
    handlePopUpClose: (isPopupClose: boolean, isEditMode: string) => void;
    handleContact: (isAdded: string) => void;
}

interface AddContactState {
    isEditMode: boolean;
    requestFormData: ContactPageFormData;
    organisationLookUp: LookupTypeItem[];
    roleLookUp: LookupTypeItem[];
    showChangeEmailPopup: boolean;
    showEmailValidationPopup: boolean;
    email: string;
    loadPanelVisible: boolean;
    contactCreatedDateTime: string;
    userCreatedDateTime: string;
    errorMessage: [];
    IsShowSuccess: boolean;
    IsShowError: boolean;
}

class AddContact extends React.Component<AddContactProps> {
    conversionUtils: sharedUtils;
    lookupService: lookupService;
    artistService: ArtistService;
    utils: artistProfileUtils;
    taxApplicable: LookupTypeItem[];
    state: AddContactState;
    convertedRequestFormData: any;
    constructor(props: AddContactProps) {
        super(props);
        this.conversionUtils = new sharedUtils();        
        this.lookupService = new lookupService();
        this.artistService = new ArtistService();
        this.utils = new artistProfileUtils();
        this.taxApplicable = this.lookupService.getDecisionTypes();
        this.convertedRequestFormData = this.utils.initializeContactPageItem(this.props.contactType);
        this.state = {
            isEditMode: false,
            requestFormData: this.convertedRequestFormData,
            organisationLookUp: [],
            roleLookUp: [],
            showChangeEmailPopup: false,
            showEmailValidationPopup: false,
            email: "",
            loadPanelVisible: false,
            contactCreatedDateTime: "",
            userCreatedDateTime: "",
            errorMessage: [],
            IsShowSuccess: false,
            IsShowError: false,
        };
    }

    componentDidMount() {
        this.setState({
            isEditMode: this.props.mode === "edit",
        });
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.organisationType)
            .then(this.handleSuccessOrganisationTypeLookUp)
            .catch(this.handleError);
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.userRoleType)
            .then(this.handleSuccessRoleTypeLookUp)
            .catch(this.handleError);

        if(this.props.businessEntityId){    
            this.artistService
                .getContactPageDataById(parseInt(this.props.businessEntityId))
                .then(this.handleSuccessContactViewPage)
                .catch((err) => {
                    this.setState({
                        errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                        loadPanelVisible: false,
                        IsShowError: true,
                        IsShowSuccess: false,
                    });
                })
                .finally(() => {
                    this.setState({
                        loadPanelVisible: false,
                    });
                });
        }        
    }

    handleSuccessContactViewPage = (response: AxiosResponse<any>) => {
        // Convert to proper data types
        let contactCreatedDateTime = "";
        let userCreatedDateTime = "";
        var viewDataSource = this.utils.convertContactProfileResponeToFormData(response.data.data);
        this.setState(
            {
                requestFormData: viewDataSource,
            } , () => {
                contactCreatedDateTime = this.contactLastUpdatedDisplay(this.state.requestFormData.lastUpdatedOn, true);
                userCreatedDateTime = this.contactLastUpdatedDisplay(this.state.requestFormData.user.lastUpdatedOn, true);
        });
        this.setState({
            contactCreatedDateTime: contactCreatedDateTime,
            userCreatedDateTime: userCreatedDateTime,
        })
    };

    handleError = (error: any) => {
        var respMessage: string = "Get Configuration Text service failed with response: " + JSON.stringify(error);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    handleSuccessOrganisationTypeLookUp = (response: AxiosResponse<any>) => {
        var itemData = response.data.data;
        var organisationTypeLUItem: LookupTypeItem;
        var organisationTypeLUItemArray: LookupTypeItem[];
        organisationTypeLUItemArray = [];
        if (itemData) {
            itemData.map((item: any, uniqueKey: number) => {
                organisationTypeLUItem = {
                    id: item.id,
                    value: item.value,
                    parentMappingId: "0",
                };
                organisationTypeLUItemArray.push(organisationTypeLUItem);
            });
        }

        this.setState({
            organisationLookUp: organisationTypeLUItemArray,
        });
    };

    handleSuccessRoleTypeLookUp = (response: AxiosResponse<any>) => {
        var itemData = response.data.data;
        var roleTypeLUItem: LookupTypeItem;
        var roleTypeLUItemArray: LookupTypeItem[];
        roleTypeLUItemArray = [];
        if (itemData) {
            itemData.map((item: any, uniqueKey: number) => {
                roleTypeLUItem = {
                    id: item.id,
                    value: item.value,
                    parentMappingId: "0",
                };
                roleTypeLUItemArray.push(roleTypeLUItem);
            });
        }

        this.setState({
            roleLookUp: roleTypeLUItemArray,
        });
    };

    handleChangeContactName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                name: dxValue.value,
            },
        });
    };

    handleChangeOrganisationValue = (dxValue: SelectBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                organisationTypeId: dxValue.value,
            },
        });
    };

    handleContactTypeValue = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                contactTypeId: dxValueChange.value,
            },
        });
    };

    handleChangeEmail = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                email: dxValue.value,
            },
        });
    };

    handleChangeNewEmail = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({email: dxValue.value});
    };

    handleChangeMobile = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                mobile: dxValue.value,
            },
        });
    };

    handleChangeJobTitle = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                jobTitle: dxValue.value,
            },
        });
    };

    handleChangeContactActive = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                active: dxValue.value,
            },
        });
    }

    handleChangeUserName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                user: {
                    ...this.state.requestFormData.user,
                    name: dxValue.value,
                },
            },
        });
    };

    handleChangeUserRoleValue = (dxValue: SelectBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                user: {
                    ...this.state.requestFormData.user,
                    userRole: {
                        ...this.state.requestFormData.user.userRole,
                        id: dxValue.value,
                    }                    
                },
            },
        });
    };

    handleChangeUserActive = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                user: {
                    ...this.state.requestFormData.user,
                    active: dxValue.value,
                },
            },
        });
    }

    handleChangeAllowWebApp = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                user: {
                    ...this.state.requestFormData.user,
                    allowWebApp: dxValue.value,
                },
            },
        });
    }

    handleChangeDisallowApp = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                user: {
                    ...this.state.requestFormData.user,
                    disallowApp: dxValue.value,
                },
            },
        });
    }
    handleChangeDisallowPortal = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                user: {
                    ...this.state.requestFormData.user,
                    disallowPortal: dxValue.value,
                },
            },
        });
    }

    handleChangeProviderTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                businessEntityId: dxValueChange.value,
            },
        });
    }

    handleChangeClientTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                businessEntityId: dxValueChange.value,
            },
        });
    }

    handleChangeCompanyTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                businessEntityId: dxValueChange.value,
            },
        });
    }

    handleCancelOnClick = () => {
        confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
            if (hasConfirmed) {
                this.props.handlePopUpClose(false, "");
            }
        });
    };

    onSubmit = () => {
        this.setState({
            loadPanelVisible: true,
        });
         
        var profileSubmitRequest = this.utils.convertFormDataToContactProfileRequest(this.state.requestFormData);    
        this.artistService
            .saveContactPageData(profileSubmitRequest)
            .then(this.handleSuccessOnFormSubmit)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    loadPanelVisible: false,
                    IsShowError: true,
                    IsShowSuccess: false,
                });
                this.topFunction();
            })
            .finally(() => {
                this.setState({
                    loadPanelVisible: false,
                });
            });
    };

    handleSuccessOnFormSubmit = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            IsShowError: false,
            IsShowSuccess: true,
        });
        if(this.state.requestFormData.id == ""){
            this.props.handleContact("true");
        }
        //let id = this.state.requestFormData.id || '';
        let id = response?.data?.data?.id || '';
        //if (id) {
            let postObject: logicAppPostRequest = {
                ContactId: id
            };
            this.artistService.triggerLogicAppForContactSync(postObject);
        //}
        notify({ message: "Thank You! Profile Information Saved Successfully!", width: 300, shading: true, position: "center" }, 'success', 600);
        this.props.handlePopUpClose(false, "");
    };

    topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    changeEmail = () => {
        if(this.state.requestFormData.email == "" || this.state.requestFormData.email == null){
            this.setState({
                showEmailValidationPopup: true,
            });
        }
        else{
            this.setState({
                showChangeEmailPopup: true,
            });
        }        
    };

    hideDetail = () => {
        this.setState({
            showChangeEmailPopup: false,
            showEmailValidationPopup: false,
        })
    } 
    hideValidationDetail = () => {
        if(this.state.showChangeEmailPopup == true){
            this.setState({
                showChangeEmailPopup: true,
                showEmailValidationPopup: false,
            })
        }
        else {
            this.setState({
                showChangeEmailPopup: false,
                showEmailValidationPopup: false,
            })
        }
    }  

    onConfirm = () => {
        if(this.state.email == "" || this.state.email == null){
            this.setState({
                showEmailValidationPopup: true,
            });
        }
        else{
            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    email: this.state.email,
                    updateEmail: true,
                },
                showChangeEmailPopup: false,
                showEmailValidationPopup: false,
            });
        }         
    }

    onEditButtonClick = () => {        
        this.setState({
            isEditMode: true,
        });
        this.props.handlePopUpClose(true, "editMode");
    };

    contactLastUpdatedDisplay = (date:any, type:boolean) => {
        let finalFormattedDate: string = "";
        let stringFormattedDate;
        let formattedDate;
        if(this.state.requestFormData.lastUpdatedOn !== null && this.state.requestFormData.lastUpdatedOn !== ""){
            let dateCreatedTime = this.state.requestFormData.lastUpdatedOn;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.conversionUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            if(type == true){
                stringFormattedDate = this.conversionUtils.convertDateToString(dateCreatedTimeLocal);
                formattedDate = this.conversionUtils.convertStringToddmmmyyyyForm(stringFormattedDate);
            }
            else {
                formattedDate = this.conversionUtils.convertDateToString(dateCreatedTimeLocal);
            }
            finalFormattedDate = `${formattedDate} ${formattedTime}`;            
        }
        return finalFormattedDate;
    };

    render() {
        return (
            <div className="page-content">
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                <div className="container">
                    <div className="row mt-3 mb-5" hidden={this.state.isEditMode}>
                        <div className="col-12 col-md-2">
                            <button className="btn btn-primary btn--large" onClick={this.onEditButtonClick}>
                                EDIT
                            </button>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-9">
                            <h5 className="artist-profile__section-header">
                                <b>Contact Details</b>
                                <span className="mx-3">({this.state.requestFormData.id})</span>
                            </h5>
                            <div>
                                Name
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeContactName}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.name}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>                                                    
                            <div className="mt-3">
                                Organisation
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <SelectBox
                                    className="mt-2"
                                    dataSource={this.state.organisationLookUp}
                                    width={"100%"}
                                    readOnly={false}
                                    valueExpr="id"
                                    displayExpr="value"
                                    onValueChanged={this.handleChangeOrganisationValue}
                                    value={this.state.requestFormData.organisationTypeId}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3" hidden={this.state.requestFormData.organisationTypeId == "10" ||  this.state.requestFormData.organisationTypeId == "30"}>
                                Provider
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div hidden={this.state.requestFormData.organisationTypeId == "10" ||  this.state.requestFormData.organisationTypeId == "30"}>
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.providerType}
                                    onValueChanged={this.handleChangeProviderTypeSelect}
                                    isRoot={false}
                                    parentMappingId={""}
                                    displayExpression={"value"}
                                    value={this.state.requestFormData.businessEntityId}
                                    isDisabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3" hidden={this.state.requestFormData.organisationTypeId == "20" ||  this.state.requestFormData.organisationTypeId == "30"}>
                                Client
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div hidden={this.state.requestFormData.organisationTypeId == "20" ||  this.state.requestFormData.organisationTypeId == "30"}>
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                    onValueChanged={this.handleChangeClientTypeSelect}
                                    isRoot={false}
                                    parentMappingId={""}
                                    displayExpression={"value"}
                                    value={this.state.requestFormData.businessEntityId}
                                    isDisabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3" hidden={this.state.requestFormData.organisationTypeId == "20" ||  this.state.requestFormData.organisationTypeId == "10"}>
                                Company
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div hidden={this.state.requestFormData.organisationTypeId == "20" ||  this.state.requestFormData.organisationTypeId == "10"}>
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.contactCompanyType}
                                    onValueChanged={this.handleChangeCompanyTypeSelect}
                                    isRoot={false}
                                    parentMappingId={""}
                                    displayExpression={"value"}
                                    value={this.state.requestFormData.businessEntityId}
                                    isDisabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Contact Type
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.contactTypeDetails}
                                    onValueChanged={this.handleContactTypeValue}
                                    isRoot={false}
                                    parentMappingId={this.state.requestFormData.organisationTypeId}
                                    displayExpression={"value"}
                                    value={this.state.requestFormData.contactTypeId}
                                    isDisabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Email
                                <span className="unscheduled-shift" hidden={this.state.requestFormData.user.active == undefined || this.state.requestFormData.user.active == "No" || this.state.requestFormData.user.active == "False" 
                                    || this.state.requestFormData.user.active == ""}>*</span>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-12 row pr-0">
                                    <div className="col-12 col-xl-8 pl-0">
                                        <TextBox
                                            width={"100%"}
                                            readOnly={false}
                                            onValueChanged={this.handleChangeEmail}
                                            valueChangeEvent="keyup"
                                            value={this.state.requestFormData.email}
                                            disabled={!this.state.isEditMode}
                                        />
                                    </div>
                                    <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0" hidden={!this.state.isEditMode}>
                                        <button
                                            className="btn btn-primary btn--large p-1"
                                            disabled={this.state.requestFormData.email == ""}
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.changeEmail();
                                            }}
                                        >
                                            Change Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                Mobile
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeMobile}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.mobile}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Job Title
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeJobTitle}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.jobTitle}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Active
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <RadioGroup
                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                    displayExpr="value"
                                    valueExpr="value"
                                    value={this.state.requestFormData.active == "Yes" || this.state.requestFormData.active == "True"
                                            ? "Yes"
                                            : "No"
                                    }
                                    onValueChanged={this.handleChangeContactActive}
                                    layout="horizontal"
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="artist-profile__section-header mt-3">
                                <span className="font-size-one">Last Updated:&nbsp;{this.state.contactCreatedDateTime}</span>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="artist-profile__edit-section"></div>
                            <h5 className="artist-profile__section-header">
                                <b>User Details</b>
                                <span className="mx-3">({this.state.requestFormData.user.id})</span>
                            </h5>
                            <div>
                                User Name
                                <span className="unscheduled-shift" hidden={this.state.requestFormData.user.active == undefined || this.state.requestFormData.user.active == "No" || this.state.requestFormData.user.active == "False" 
                                    || this.state.requestFormData.user.active == ""}>*</span>
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeUserName}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.user.name}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                User Role
                                <span className="unscheduled-shift" hidden={this.state.requestFormData.user.active == undefined || this.state.requestFormData.user.active == "No" || this.state.requestFormData.user.active == "False" 
                                    || this.state.requestFormData.user.active == ""}>*</span>
                            </div>
                            <div>
                                <SelectBox
                                    className="mt-2"
                                    dataSource={this.state.roleLookUp}
                                    width={"100%"}
                                    readOnly={false}
                                    valueExpr="id"
                                    displayExpr="value"
                                    onValueChanged={this.handleChangeUserRoleValue}
                                    value={this.state.requestFormData.user.userRole.id}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>                                    
                            <div className="row">
                                <div className='col'>
                                    <div className="mt-3">
                                        Active User
                                        <span className="unscheduled-shift">*</span>
                                    </div>
                                    <div>
                                        <RadioGroup
                                            items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                            displayExpr="value"
                                            valueExpr="value"
                                            value={this.state.requestFormData?.user?.active == "Yes" || this.state.requestFormData?.user?.active == "True"
                                                ? "Yes"
                                                : "No"
                                            }
                                            onValueChanged={this.handleChangeUserActive}
                                            layout="horizontal"
                                            disabled={!this.state.isEditMode}
                                        />
                                    </div>
                                </div>
                                {/* {true && <> */}
                                <div className="col">
                                    <div className="mt-3">
                                        Allow Internal Web Access?
                                        {/* <span className="unscheduled-shift">*</span> */}
                                    </div>

                                    <div>
                                        <div>
                                        <RadioGroup
                                            items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                            displayExpr="value"
                                            valueExpr="value"
                                            value={this.state.requestFormData?.user?.allowWebApp == "Yes" || this.state.requestFormData?.user?.allowWebApp == "True"
                                                ? "Yes"
                                                : "No"
                                            }
                                            onValueChanged={this.handleChangeAllowWebApp}
                                            layout="horizontal"
                                            disabled={!this.state.isEditMode}
                                        />
                                    </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="mt-3">
                                        Disallow App Access?
                                        {/* <span className="unscheduled-shift">*</span> */}
                                    </div>

                                    <div>
                                        <RadioGroup
                                            items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                            displayExpr="value"
                                            valueExpr="value"
                                            value={this.state.requestFormData?.user?.disallowApp == "Yes" || this.state.requestFormData?.user?.disallowApp == "True"
                                                ? "Yes"
                                                : "No"
                                            }
                                            onValueChanged={this.handleChangeDisallowApp}
                                            layout="horizontal"
                                            disabled={!this.state.isEditMode}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="mt-3">
                                        Disallow Portal Access?
                                        {/* <span className="unscheduled-shift">*</span> */}
                                    </div>

                                    <div>
                                        <RadioGroup
                                            items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                            displayExpr="value"
                                            valueExpr="value"
                                            value={this.state.requestFormData?.user?.disallowPortal == "Yes" || this.state.requestFormData?.user?.disallowPortal == "True"
                                                ? "Yes"
                                                : "No"
                                            }
                                            onValueChanged={this.handleChangeDisallowPortal}
                                            layout="horizontal"
                                            disabled={!this.state.isEditMode}
                                        />
                                    </div>
                                </div>
                                {/* </>} */}
                            </div>
                            <div className="artist-profile__section-header mt-3">
                                <span className="font-size-one">Last Updated:&nbsp;{this.state.userCreatedDateTime}</span>
                            </div>
                            {this.state.IsShowError && this.state.errorMessage != null ? (
                                <span className="unscheduled-shift">
                                    <ul>
                                        {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {item.columnName}: {item.errorMessage}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </span>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mx-0 mb-3" hidden={!this.state.isEditMode}>
                        <div className="col-12 col-lg-12 row pr-0">
                            <div className="col-12 col-xl-2 pl-0">
                                <button
                                    className="btn btn--ghost btn--large"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.handleCancelOnClick()
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col-12 col-xl-2 pl-0 mt-3 mt-xl-0">
                                <button
                                    className="btn btn-primary btn--large"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onSubmit();
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <Popup
                    visible={this.state.showChangeEmailPopup}
                    onHiding={this.hideDetail}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="CHANGE EMAIL"
                    width={"30%"}
                    height={"auto"}
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="text-center"><b>EXISTING EMAIL: {this.state.requestFormData.email}</b></div>
                            <div className="mt-3">Please enter new email here</div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeNewEmail}
                                    valueChangeEvent="keyup"
                                    value={this.state.email}
                                />
                            </div>
                            <div className="text-center mt-3">This will take effect once you save the profile but contact IT before making this change as Azure needs to change first.</div>
                        </div>
                        <div className="text-center">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onConfirm();
                                }}
                            >
                                CONFIRM
                            </button>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.showEmailValidationPopup}
                    onHiding={this.hideValidationDetail}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Error"
                    width={"30%"}
                    height={"auto"}
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="text-center font-size-one mt-3">Please enter a valid email first.</div>
                        </div>
                    </ScrollView>
                </Popup>
            </div >
        );
    }
}
export default withTranslation()(AddContact);
