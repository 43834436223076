import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Scrolling,
    Editing,
    Texts,
    SearchPanel,
    FilterPanel,
    GroupPanel,
} from "devextreme-react/data-grid";
import gridUtils from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";
import { LoadIndicator, Popup, ScrollView } from "devextreme-react";
import { confirm } from "devextreme/ui/dialog";
import AddVenueComponent from "./AddVenueComponent";
import FindAnArtistService from "../../services/FindAnArtistService";
import { VenuePageFormData } from "../Artist-Profile/ArtistProfileUtilitise";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import MatrixGridService from "../../services/MatrixService";
import { LoadPanel } from "devextreme-react";

//props
interface VenueGridProps {
    location: any;
    history: any;
    artistAdded: string;
}

// State
interface VenueGridState {
    VenueGridDataSource: VenuePageFormData[];
    showProgressIndicator: boolean;
    businessEntityId: string;
    providerPopupVisibleEntertainment?: boolean;
    loadPanelVisible: boolean;
}

// Component - displays both the header and child grids
class VenueGrid extends React.Component<VenueGridProps> {
    state: VenueGridState;
    service: FindAnArtistService;
    matrixService: MatrixGridService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    isEditMode: string;
    constructor(props: VenueGridProps) {
        super(props);
        this.isEditMode = "";
        // Initialize state and services/utils
        this.state = {
            VenueGridDataSource: [],
            showProgressIndicator: false,
            businessEntityId: "0",
            providerPopupVisibleEntertainment: false,
            loadPanelVisible: false,
        };
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        // Functions
        this.service = new FindAnArtistService();
        this.matrixService = new MatrixGridService();
    } 

    componentDidMount() {
        this.setState({loadPanelVisible:true});
        this.service.getListOfVenueGrid("10").then(this.handleSuccess).catch(this.handleFailure);
    }
    
    componentDidUpdate = (prevprops: VenueGridProps, prevState: VenueGridState) => { 
        if (this.props.artistAdded !== prevprops.artistAdded) {
            this.setState({loadPanelVisible:true});
            this.service.getListOfVenueGrid("10").then(this.handleSuccess).catch(this.handleFailure);         
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                loadPanelVisible: false,
                showProgressIndicator: false,
                VenueGridDataSource: response.data.data,
            });
    };

    handleProfileSuccess = () => {
        this.setState(
            {
                loadPanelVisible: false,
                showProgressIndicator: false,
            });
    };

    handleFailure = () => {
        this.setState({
            loadPanelVisible: false,
            showProgressIndicator: false,
        });
    };

    showInfoProvider = (businessEntityId: string) => {
        this.setState({
            businessEntityId: businessEntityId,
            providerPopupVisibleEntertainment: true,
        });
    };


    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
        });
        if(editmode == ""){
            this.setState({loadPanelVisible:true,});
            this.service.getListOfVenueGrid("10").then(this.handleSuccess).catch(this.handleFailure);  
        }
    }

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
            });
        }
    };

    artistProfileDisplay = (cellInfo: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <button
                    className={"btn icon-btn"}
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.id);
                    }}
                >
                    <FontAwesomeIcon icon={faUser} />
                </button>
            </div>
        );
    }

    contactLastUpdatedDisplay = (cellInfo: any) => {
        let finalFormattedDate: string = "";
        if(cellInfo.data?.lastUpdatedOn !== null && cellInfo.data?.lastUpdatedOn !== ""){
            let dateCreatedTime = cellInfo.data?.lastUpdatedOn;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        return (
            <div>
                {cellInfo.data?.lastUpdatedOn !== null && cellInfo.data?.lastUpdatedOn !== "" 
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };

    userLastUpdatedDisplay = (cellInfo: any) => {
        let finalFormattedDate: string = "";
        if(cellInfo.data?.user !== null && cellInfo.data?.user?.lastUpdatedOn !== null && cellInfo.data?.user?.lastUpdatedOn !== ""){
            let dateCreatedTime = cellInfo.data?.user?.lastUpdatedOn;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        return (
            <div>
                {cellInfo.data?.user?.lastUpdatedOn !== null && cellInfo.data?.user?.lastUpdatedOn !== "" 
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };

    render() {
        return (
            <div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (  
                    <DataGrid
                        dataSource={this.state.VenueGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        columnResizingMode={"widget"}
                        noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                        columnMinWidth={100}
                        keyExpr="id"
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <GroupPanel visible={true} />
                        <FilterPanel visible={true} />
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Export enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        
                        <Column
                            caption="PROFILE"
                            allowExporting={false}
                            allowFiltering={false}
                            allowSorting={false}
                            allowGrouping={false}
                            cellRender={this.artistProfileDisplay}
                        />
                        <Column 
                            dataField="id" 
                            caption="Venue Id" 
                        />
                        <Column 
                            dataField="businessEntity.shortName" 
                            caption="Client"
                        />
                        <Column 
                            dataField="venueName" 
                            caption="Venue Name" 
                        />
                        <Column 
                            dataField="venueNameWOLocation" 
                            caption="Short Name" 
                        />
                        <Column 
                            dataField="organisationType.value" 
                            caption="Organisation" 
                        />
                        <Column 
                            dataField="contact.contactTypeLookUp.value" 
                            caption="Contact Type"
                        />
                        <Column 
                            dataField="houseNumber" 
                            caption="House Number" 
                        />
                        <Column 
                            dataField="email" 
                            caption="Email" 
                        />
                        <Column 
                            dataField="phone" 
                            caption="Phone" 
                        />
                        <Column 
                            dataField="generalManager.value" 
                            caption="General Manager" 
                        />
                        <Column 
                            dataField="website" 
                            caption="Website" 
                        />
                        <Column 
                            dataField="address" 
                            caption="Address" 
                        />
                        <Column 
                            dataField="city" 
                            caption="Town/City" 
                        />
                        <Column 
                            dataField="county" 
                            caption="Country" 
                        />
                        <Column 
                            dataField="postCode" 
                            caption="Post Code" 
                        />
                        <Column 
                            dataField="brand" 
                            caption="Brand" 
                        />
                        <Column 
                            dataField="division" 
                            caption="Division" 
                        />
                        <Column 
                            dataField="venueType.value" 
                            caption="Venue Type" 
                        />
                        <Column 
                            dataField="contact.active" 
                            caption="Active" 
                        />
                        <Column 
                            dataField="lastUpdatedOn" 
                            caption="Last Updated" 
                            cellRender={this.contactLastUpdatedDisplay}
                            dataType="date"
                        />
                        <Column 
                            dataField="user.active" 
                            caption="Active User" 
                        />
                        <Column 
                            dataField="user.name" 
                            caption="User Name" 
                        />
                        <Column 
                            dataField="user.userRole.value" 
                            caption="User Role" 
                        />
                        <Column 
                            dataField="user.lastUpdatedOn" 
                            caption="User Last Updated" 
                            cellRender={this.userLastUpdatedDisplay}
                            dataType="date"
                        />  
                        <Column 
                            dataField="contractCleaningBusinessEntity.shortName" 
                            caption="Cleaning Account" 
                        />   
                        <Column 
                            dataField="primaryCleaningContact.name" 
                            caption="Cleaning OPS Contact" 
                        />                   
                        <Column 
                            dataField="securityProviderBusinessEntity.shortName" 
                            caption="Security Account" 
                        />
                        <Column 
                            dataField="primarySecurityContact.name" 
                            caption="Security OPS Contact" 
                        /> 
                        <Column 
                            dataField="gardeningProviderBusinessEntity.shortName" 
                            caption="Gardening Account" 
                        /> 
                        <Column 
                            dataField="primaryGardeningContact.name" 
                            caption="Gardening OPS Contact" 
                        />
                        <Column 
                            dataField="windowProviderBusinessEntity.shortName" 
                            caption="Windows Account" 
                        />
                        <Column 
                            dataField="primaryWindowContact.name" 
                            caption="Windows OPS Contact" 
                        /> 

                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>                   
                )}
                {
                this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title="Venue Details"
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <AddVenueComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    businessEntityId={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    handleContact={() => { return null }}
                                    // contactType={this.state.contactType}
                                ></AddVenueComponent>
                            </ScrollView>
                        </Popup>
                    ) : (null)
                }
            </div>
        );
    }
}

export default VenueGrid;
