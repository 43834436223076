import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Export,
    SearchPanel,
} from "devextreme-react/data-grid";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import VenueHierarchyService, { VenueHierarchyGridRowItem, VenueHierarchyStatus } from "../../../services/VenueHierarachyService";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../../services/LookupService";
import { OnRowRemovingEvent, SelectBoxOnValueChangedEvent } from "../../../types/DevExtremeTypes";
import SelectBoxFromLookupTable from "../../select/SelectBoxFromLookupTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import MatchingVenueGrid from "./MatchingVenueGrid";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import FileService from "../../../services/FileService";
import notify from 'devextreme/ui/notify';


export interface VenueHierarchyRowItem {
    clientId: string;
    venue: string;
    houseNumber: string;
    amEmail: string;
    opsManagerEmail: string;
    opsDirectorEmail: string;
    itemMatched: boolean | null;
    itemUpdated: boolean | null;
    venueId:string;
    uuid: string;
}
//props
interface VenueHierarchyBulkContainerProps { }

// State
interface VenueHierarchyBulkContainerState {
    pastedGridDataSource: VenueHierarchyRowItem[];
    clientLookUp: LookupTypeItem[];
    clientId: string;
    matchingVenueGridDataSource: any[];
    findMatchesClicked: boolean;
    showFindMatches: boolean;
    showApplyVenueChanges: boolean;
    showMatchingVenueGrid: boolean;
    showLoadPanel: boolean;
    selectedMatchingVenues: any[];
}

// Component - displays the Rate Matrix Grid
class VenueHierarchyBulkContainer extends React.Component<VenueHierarchyBulkContainerProps> {
    //Initialize the component's state.
    state: VenueHierarchyBulkContainerState;
    venueHierarchyService: VenueHierarchyService;
    dataGridRef: any;
    fileService:FileService;
    constructor(props: VenueHierarchyBulkContainerProps) {
        super(props);
        // Initialize state and services/utils
        this.dataGridRef = null;
        this.venueHierarchyService = new VenueHierarchyService();
        this.fileService = new FileService();
        this.state = {
            pastedGridDataSource: [],
            clientLookUp: [],
            clientId: "",
            showLoadPanel: false,
            showFindMatches: false,
            showMatchingVenueGrid: false,
            showApplyVenueChanges: false,
            matchingVenueGridDataSource: [],
            findMatchesClicked: false,
            selectedMatchingVenues: []
        };
    }

    componentDidMount() {
        let obj = this.getDefaultObject(0);
        let stateArr = this.state.pastedGridDataSource;
        stateArr.push(obj);
        this.setState({ pastedGridDataSource: stateArr })
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: VenueHierarchyBulkContainerProps, prevState: VenueHierarchyBulkContainerState) {
    }

    handleChangeClient = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState(
            {
                clientId: dxValueChange.value,
            },
            () => {
                let obj = this.getDefaultObject(0);
                let stateArr = [];
                stateArr.push(obj);

                //let venueDetails = this.state.pastedGridDataSource;
                //let venueDetails = stateArr;
                //let newVenueDetails = venueDetails.map((obj, i) => ({ ...obj, clientId: dxValueChange.value }));
                // this.setState({ pastedGridDataSource: newVenueDetails})
                this.setState({
                    pastedGridDataSource: stateArr,
                    showFindMatches: false,
                    findMatchesClicked: false,
                    showApplyVenueChanges: false,
                    matchingVenueGridDataSource: [],
                    showMatchingVenueGrid: false,
                    selectedMatchingVenues: []
                })
            }
        );
    };

    isApplyVenueDisabled = () => {
        let disabled = true;
        if (this.state.matchingVenueGridDataSource?.length > 0 && this.state.selectedMatchingVenues?.length > 0) {
            disabled = false;
        }
        return disabled;
    }

    onClickFindMatches = () => {
        // this.setState({ findMatchesClicked: true, showMatchingVenueGrid: true });
        let uniquePastedRow = this.removeDuplicate(this.state.pastedGridDataSource);
        this.setState({ pastedGridDataSource: uniquePastedRow, findMatchesClicked: true, showMatchingVenueGrid: true });
    };

    // handleSuccessDataSubmission = () => {
    //     this.setState({
    //         loadPanelVisible: false,
    //     });
    // };

    // handleError = (err: any) => {
    //     this.setState({
    //         loadPanelVisible: false,
    //         errorMessage:
    //             err.response && err.response.data && err.response.data.error
    //                 ? JSON.parse(JSON.stringify(err.response.data.error))
    //                 : null, // For capturing response at the time of exception and showing error message
    //     });
    // };

    displayBooleanContent = (Val: any) => {
        let valToShow: string = "";
        if (Val !== null) {
            if (Val) {
                valToShow = "Yes";
            }
            else {
                valToShow = "No";
            }
        }
        return (
            <div style={{ margin: '10px', minHeight: '10px' }}>
                {
                    valToShow == 'Yes' ? <b><span>{valToShow}</span></b> : <span>{valToShow}</span>
                }
                {/* <span>{valToShow}</span> */}
            </div>
        );
    }

    calculateBooleanContent = (Val: any) => {
        let valToShow: string = '';
        if (Val) {
            valToShow = 'Yes';
        }
        else {
            valToShow = 'No';
        }
        return valToShow;
    }

    getDefaultObject = (index: any) => {
        let obj: VenueHierarchyRowItem = {
            venue: 'click here to paste from excel ',
            houseNumber: '',
            amEmail: '',
            opsManagerEmail: '',
            opsDirectorEmail: '',
            itemMatched: index == 0 ? null : false,
            itemUpdated: index == 0 ? null : false,
            uuid: index,
            clientId: this.state.clientId,
            venueId:''
        }
        return obj;
    }

    checkForRowsToAdd = (currentRowIndex: any, pastedRows: any) => {
        let patedsRowLength = pastedRows.length;
        let gridRows = this.state.pastedGridDataSource;
        let availableRows = gridRows.slice(currentRowIndex, gridRows.length)
        if (availableRows?.length <= patedsRowLength) {
            let rowsToadd = patedsRowLength - availableRows.length;
            // for (let i = 0; i <= rowsToadd; i++) {
            for (let i = 0; i < rowsToadd; i++) {
                let index = gridRows.length;
                let obj = this.getDefaultObject(index);
                gridRows.push(obj);

            }
        }
        return gridRows;
    }

    // hasDuplicate = (dataFields: any, currentDataField: any, splittedCells: any) => {
    //     let duplicate = false;
    //     for (let fieldID = 0; fieldID < dataFields.length; fieldID++) {
    //         if (dataFields[fieldID] === currentDataField) {
    //             for (let i = 0; i < dataFields.length - fieldID && i < splittedCells.length; i++) {
    //                 let key = dataFields[fieldID + i];
    //                 let val = splittedCells[i];
    //                 if (key == 'houseNumber') {
    //                     let filtered = this.state.pastedGridDataSource.filter(a => a.houseNumber == val)
    //                     if (filtered && filtered.length && filtered.length > 0) {
    //                         duplicate = true;
    //                     }
    //                 }

    //             }
    //         }
    //     }
    //     return duplicate;
    // }

    hasDuplicateVenue = (key: any, val: any) => {
        let duplicate = false;
        if (key == 'houseNumber') {
            let filtered = this.state.pastedGridDataSource.filter(a => a.houseNumber == val)
            if (filtered && filtered.length && filtered.length > 0) {
                duplicate = true;
            }
        }
        return duplicate;
    }

    removeDuplicate = (stateObj: any) => {
        stateObj = stateObj.filter((value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
                t.houseNumber === value.houseNumber
            ))
        ).map((a: any, index: any) => { return { ...a, uuid: index } })
        // const key = 'houseNumber';
        // stateObj = [...new Map(stateObj.map((item:any) => [item[key], item])).values()].map((a: any, index: any) => { return { ...a, uuid: index } })
        return stateObj;
    }

    handlePaste = (editEvent: any, e: any, currentRow: any, currentDataField: any) => {
        let pastedData = e.clipboardData.getData('Text');
        let pastedRows = pastedData.split('\n')?.filter((a: any) => a !== '');
        let rowIndex = currentRow.dataIndex;
        //rowIndex = currentRow.data.uuid;
        let stateObj = this.checkForRowsToAdd(rowIndex, pastedRows);
        let allRows = stateObj;

        pastedRows.forEach((element: any, rindex: any) => {
            let roToUpdate = rowIndex + rindex;
            let currPastedRow = pastedRows[rindex];
            // let splittedCells = pastedRows[rindex].split('\t');
            let splittedCells = currPastedRow.replace(/(\r)/gm, '').split('\t');
            let currRow = allRows[roToUpdate]
            const dataFields = Object.keys(currRow).slice(0, 5);
            //check for duplicate
            for (let fieldID = 0; fieldID < dataFields.length; fieldID++) {
                if (dataFields[fieldID] === currentDataField) {
                    for (let i = 0; i < dataFields.length - fieldID && i < splittedCells.length; i++) {
                        let key = dataFields[fieldID + i];
                        let val = splittedCells[i];
                        //val = val.replace(/(\r)/gm,'');
                        //let duplicateVenue = this.hasDuplicateVenue(key, val);
                        //if (!duplicateVenue) {
                            this.dataGridRef.instance.cellValue(
                                roToUpdate,
                                key,
                                val
                            );
                            if (key == 'venue') {
                                currRow['venue'] = val;
                            } else if (key == 'houseNumber') {
                                // let filtered = this.state.pastedGridDataSource.filter(a=>a.houseNumber == val)
                                // if(filtered && filtered.length && filtered.length > 0){
                                //   //skip to update
                                // } else{
                                //     currRow['houseNumber'] = val;
                                // }
                                // if(!duplicateVenue){
                                //     currRow['houseNumber'] = val;
                                // }
                                currRow['houseNumber'] = val;
                            } else if (key == 'amEmail') {
                                currRow['amEmail'] = val;
                            } else if (key == 'opsManagerEmail') {
                                currRow['opsManagerEmail'] = val;
                            } else if (key == 'opsDirectorEmail') {
                                currRow['opsDirectorEmail'] = val;
                            }
                            currRow.itemMatched = false;
                            currRow.itemUpdated = false;
                        //}
                    }

                }
            }

        });
        this.dataGridRef.instance.saveEditData();

        let show = false;
        if (stateObj && stateObj.some(a => a.venue?.trim() && a.houseNumber?.trim() && (a.amEmail?.trim() || a.opsManagerEmail?.trim() || a.opsDirectorEmail?.trim()))) {
            show = true;
        }
        //remove duplicate
        //stateObj = this.removeDuplicate(stateObj);
        this.setState({ pastedGridDataSource: stateObj, showFindMatches: show });
        editEvent.cancel = true;
    }
    onCellClick = (clickEvent: any) => {
        const currentDataField = clickEvent.column.dataField;
        const currentRow = clickEvent.row;

        clickEvent.cellElement.onpaste = (pasteEvent: any) => {
            this.handlePaste(clickEvent, pasteEvent, currentRow, currentDataField);
        }

    }

    onRefreshClick = () => {
        let obj = this.getDefaultObject(0);
        let stateArr = [];
        stateArr.push(obj);

        this.setState({
            pastedGridDataSource: stateArr,
            showFindMatches: false,
            findMatchesClicked: false,
            showApplyVenueChanges: false,
            matchingVenueGridDataSource: [],
            showMatchingVenueGrid: false,
            selectedMatchingVenues: []
        })
    }
    updateFoundRecords = (matchedGridSource: any[], pastedGridSource: any[]) => {
        this.setState({
            matchingVenueGridDataSource: matchedGridSource,
            pastedGridDataSource: pastedGridSource,
            showApplyVenueChanges: true,
            findMatchesClicked: false,
            selectedMatchingVenues: []
        })
    }
    setSelectedRows = (rows: any) => {
        this.setState({ selectedMatchingVenues: rows });
    }

    prepareVenueUpdateRequest = () => {
        let venues = this.state.selectedMatchingVenues || [];
        let payload: any = [];
        venues.forEach((venue: any) => {
            let finalObj = {
                clientId: venue.clientId,
                venueId: venue.venueId,
                areaManagerContactIdNew: null,
                areaManagerUserIdNew: null,
                opsManagerContactIdNew: null,
                opsManagerUserIdNew: null,
                opsDirectorContactIdNew: null,
                opsDirectorUserIdNew: null
            }
            if (venue.amStatus && venue.amStatus == VenueHierarchyStatus.NEW) {
                let obj = {
                    areaManagerContactIdNew: venue.areaManagerContactId_New,
                    areaManagerUserIdNew: venue.areaManagerUserId_New
                }
                finalObj = { ...finalObj, ...obj };
            }
            if (venue.opsManagerStatus && venue.opsManagerStatus == VenueHierarchyStatus.NEW) {
                let obj = {
                    opsManagerContactIdNew: venue.opsManagerContactId_New,
                    opsManagerUserIdNew: venue.opsManagerUserId_New
                }
                finalObj = { ...finalObj, ...obj };
            }
            if (venue.opsDirectorStatus && venue.opsDirectorStatus == VenueHierarchyStatus.NEW) {
                let obj = {
                    opsDirectorContactIdNew: venue.opsDirectorContactId_New,
                    opsDirectorUserIdNew: venue.opsDirectorUserId_New
                }
                finalObj = { ...finalObj, ...obj };
            }
            //if (Object.keys(finalObj).length > 2) {
                payload.push(finalObj);
            //}

        })
        console.log(payload);
        return payload;
    }

    onApplyVenueChanges = () => {
        //return;
        let payload: any = [];
        payload = this.prepareVenueUpdateRequest();
        this.setState({ showLoadPanel: true });
        this.venueHierarchyService.saveMatchingVenues(payload)
            .then((res: any) => this.handleSaveVenueSuccess(res, payload))
            .catch(this.handleFailure);
    }

    handleSaveVenueSuccess = (response: AxiosResponse<any>, requestObj: any) => {
        //let pastedVenues = requestObj;
        let pastedVenues = this.state.pastedGridDataSource;
        let data = response?.data?.data || [];
        let updated = pastedVenues.map((pastedVenue: any) => {
            let filter = data.filter((resItem: any) => {
                return pastedVenue.venueId == resItem.venueId
            });
            if (filter && filter.length && filter.length > 0) {
                return { ...pastedVenue, itemUpdated: true }
            }
            else {
                return { ...pastedVenue, itemUpdated: false }
            }
        })
        this.setState({ pastedGridDataSource: updated, showLoadPanel: false },()=>{
            notify({ message: "Changes Applied Successfully!", width: 300, shading: true, position: "center" }, 'success', 600);
        })
        
    };

    handleFailure = (error: any) => {
        this.setState({
            showLoadPanel: false
        },()=>{
            notify({ message: "Sorry! The changes could not be applied.", width: 300, shading: true }, 'error', 600);
        });
    };


    renderMatchingVenueGrid = () => {
        if (this.state.showMatchingVenueGrid) {
            return <>
                <h2 className="page-title__black-table">Matching Venues</h2>
                <MatchingVenueGrid
                    updateFoundRecords={this.updateFoundRecords}
                    setSelectedRows={this.setSelectedRows}
                    findMatchesClicked={this.state.findMatchesClicked}
                    clientId={this.state.clientId}
                    pastedVenueDetails={this.state.pastedGridDataSource}>

                </MatchingVenueGrid>
            </>
        }
    }

    onDownloadClick = () => {
        this.fileService
            .downloadTemplate('14')
            .then(this.handleDownloadSuccess)
            .catch(this.handleDownloadError);
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        saveAs(response.data,'template_venue_hierarchy.xlsx');
    };

    handleDownloadError = (error: any) => {
        var respMessage: string = "Download template failed with response: " + JSON.stringify(error);

        if (!this.fileService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    render() {
        return (
            <>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.showLoadPanel} />
                <div className="row mygig">
                    <h3 className="text-left font-weight-bold mt-4">Venue Hierarchy Bulk Update</h3>
                </div>
                <div className="row mb-4">
                    <div className="mt-3 col-1 font-weight-bold">Client</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <SelectBoxFromLookupTable
                            lookupTypeIndex={LookupTypeIndexes.clientType}
                            onValueChanged={this.handleChangeClient}
                            value={this.state.clientId}
                        />
                    </div>
                </div>

                {this.state.clientId ? <>
                    <div style={{ display: 'flex' }}>
                        <h2 className="page-title__black-table">Manage Venue Hierarchy
                            {true && <button
                                className="status-flow__refresh-button btn icon-btn"
                                aria-label="Refresh the current manage pay dates"
                                onClick={this.onRefreshClick}
                            >
                                <FontAwesomeIcon icon={faSync} title='reset grid data' />
                            </button>}
                        </h2>
                        <h5 style={{padding:'15px 0px 0px 15px'}}>
                            <Link
                                onClick={(e) => {
                                    this.onDownloadClick();
                                }}
                                className="approval-query-column"
                                to="#"
                            >
                                Download Template
                            </Link>
                        </h5>
                    </div>
                    <DataGrid
                        //keyExpr="houseNumber"
                        dataSource={this.state.pastedGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        allowColumnReordering={true}
                        columnMinWidth={100}
                        noDataText={"No data found for the filters applied"}
                        onCellClick={this.onCellClick}
                        ref={(ref) => { this.dataGridRef = ref; }}>
                        <Paging defaultPageSize={20} enabled={false} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} visible={false} />
                        <Editing mode="cell" allowUpdating={false} startEditAction="click" allowDeleting={false} />
                        {false && <Column dataField="uuid" caption="UUID" />}
                        <Column dataField="venue" caption="VENUE" />
                        <Column dataField="houseNumber" caption="HOUSE NUMBER" />
                        <Column dataField="amEmail" caption="AM EMAIL" />
                        <Column dataField="opsManagerEmail" caption="OPS MANAGER EMAIL" />
                        <Column dataField="opsDirectorEmail" caption="OPS DIRECTOR EMAIL" />
                        <Column dataField="itemMatched" caption="VENUE FOUND?"
                            cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.itemMatched) }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.calculateBooleanContent(rowData?.itemMatched)
                            }}
                            calculateCellValue={(cellInfo: any) => { return this.calculateBooleanContent(cellInfo?.itemMatched) }}
                        />

                        <Column dataField="itemUpdated" caption="VENUE UPDATED?"
                            cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.itemUpdated) }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.calculateBooleanContent(rowData?.itemUpdated)
                            }}
                            calculateCellValue={(cellInfo: any) => { return this.calculateBooleanContent(cellInfo?.itemUpdated) }}
                        />
                    </DataGrid>
                    <div className="col-12 col-lg-2 mb-3 mr-auto" >
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="button"
                            disabled={!this.state.showFindMatches}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                this.onClickFindMatches();
                            }}
                        >
                            FIND MATCHES
                        </button>
                    </div>
                </> : null}
                {this.renderMatchingVenueGrid()}

                <div className="col-12 col-lg-2 mb-3 mr-auto" hidden={!this.state.showApplyVenueChanges}>
                    <button
                        className="btn btn-primary btn--large mt-3"
                        type="button"
                        disabled={this.isApplyVenueDisabled()}
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            this.onApplyVenueChanges();
                        }}
                    >
                        APPLY VENUE CHANGES
                    </button>
                </div>
            </>
        );
    }
}

export default VenueHierarchyBulkContainer;
