import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import { LoadPanel, TextBox, CheckBox, NumberBox, DateBox } from "devextreme-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import ServiceFeeFormUtil, { AddEditServiceFeeFormData } from "./ServiceFeeFormUtil";
import ServiceFeeService from "../../services/ServiceFeesService";
import BillingFormUtils from "../Billing/BillingFormUtils";
import {
    SelectBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
    NumberBoxOnValueChangedEvent,
    DateBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";

type NullableDate = Date | null | undefined;
//#region props
interface ServiceFeeFormProps extends WithTranslation {
    location: any;
    history: any;
    onApplySuccess: (applySignal: string) => void;
    isEditForm: any;
}

// State
interface ServiceFeeFormState {
    //TODO : Define the form state here.
    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    venueLookUp: LookupTypeItem[];
    providerLookUp: LookupTypeItem[];
    frequencyLookUp: LookupTypeItem[];
    editServiceFeeFormData: AddEditServiceFeeFormData;
    //PeriodMatrix: any;
    loadPanelVisible: boolean;
    disableDayTextbox: boolean;
    filterId: string;
    errorMessage: [];
    showError: boolean;
    //weekLookUp: LookupTypeItem[];
}

class ServiceFeeForm extends React.Component<ServiceFeeFormProps> {
    lookupService: LookupService;
    state: ServiceFeeFormState;
    utils: ServiceFeeFormUtil;
    cleaningService: ServiceFeeService;
    periodWeekUtil: BillingFormUtils;
    constructor(props: ServiceFeeFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.utils = new ServiceFeeFormUtil(props);
        this.cleaningService = new ServiceFeeService();
        this.periodWeekUtil = new BillingFormUtils();
        let convertedEditPeriodData = this.utils.initializeServiceFeeFormItem();
        this.state = {
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            venueLookUp: [],
            providerLookUp: [],
            frequencyLookUp: [],
            editServiceFeeFormData: convertedEditPeriodData,
            loadPanelVisible: false,
            disableDayTextbox: false,
            filterId: "20",
            errorMessage: [],
            showError: false,
        };
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        this.getEditViewItemDataSource();
    }

    getEditViewItemDataSource = () => {
        if (this.props.location.state && this.props.location.state.id) {
            this.cleaningService
                .getServiceFeeMatrixDataItem(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleError);
        }
    };
    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        let viewDataSource = this.utils.convertContractItemResponeToFormData(response.data.data);
        this.setState({
            editServiceFeeFormData: viewDataSource,
        });
    };

    handleError = (err: any) => {
        this.setState({
            errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
            showError: true,
            loadPanelVisible: false,
        });
    };

    handleChangeClient = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                clientBusinessEntityId: dxValueChange.value,
            },
        });
    };
    handleChangeService = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                serviceTypeId: dxValueChange.value,
            },
        });
    };
    handleChangeServiceType = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                serviceSubTypeId: dxValueChange.value,
            },
        });
    };

    handleChangeServiceFee = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                serviceFeeCalculationLookUpId: dxValueChange.value,
            },
        });
    };

    handleChangeBudgetType = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                budgetTypeId: dxValueChange.value,
            },
        });
    };
    handleChangeActive = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                isActive: dxValueChange.value,
            },
        });
    };
    handleChangeFasterPayAvailable = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                fasterPayAvailable: dxValueChange.value,
            },
        });
    };
    handleChangeVisibiltyDateOverride = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                visibilityDateOverride: dxValueChange.value,
            },
        });
    };
    handleChangeEarlyPay = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                earlyPay: dxValueChange.value,
            },
        });
    };
    handleChangeRefund = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                refund: dxValueChange.value,
            },
        });
    };

    handleChangeAccruals = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                isAccruals: dxValueChange.value,
            },
        });
    };

    handleChangeProviderAcceptances = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                providerAcceptances: dxValueChange.value,
            },
        });
    };

    handleChangeMultiplier = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                multiplier: dxValueChange.value,
            },
        });
    };
    handleChangeFasterPayMultiplier = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                fasterPayMultiplier: dxValueChange.value,
            },
        });
    };
    handleChangeMinFee = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                minGrossProfitValue: dxValueChange.value,
            },
        });
    };
    handleChangeMaxFee = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                maxGrossProfitValue: dxValueChange.value,
            },
        });
    };

    handleChangeVisibiltyDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                approvalVisibilityDate: dxValueChange.value,
            }
        });
    };

    handleChangeVisibiltyOffset = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                approvalVisibilityOffset: dxValueChange.value,
            },
        });
    };

    handleChangeVisibiltyOffsetBatch = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                approvalVisibilityOffsetBatch: dxValueChange.value,
            },
        });
    };

    handleChangeVisibiltyOffsetImport = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                approvalVisibilityOffsetImport: dxValueChange.value,
            },
        });
    };

    handleChangeMinOffer = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                makeOfferLowerAdjustment: dxValueChange.value,
            },
        });
    };

    handleChangeMaxOffer = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                makeOfferUpperAdjustment: dxValueChange.value,
            },
        });
    };

    handleChangeVisibiltyOffsetPayment = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                paymentOffset: dxValueChange.value,
            },
        });
    };

    handleChangeMarketplaceReduction = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editServiceFeeFormData: {
                ...this.state.editServiceFeeFormData,
                marketPlaceReduction: dxValueChange.value,
            },
        });
    };


    onApplyClick = () => {
        this.setState({
            loadPanelVisible: true,
        });
        let serviceFeeSubmitRequest = this.utils.convertFormDataToContractItemRequest(
            this.state.editServiceFeeFormData
        );

        this.cleaningService
            .saveServiceFeeMatrixDataItem(serviceFeeSubmitRequest)
            .then(this.handleSuccessDataSubmission)
            .catch(this.handleError);
    };

    handleSuccessDataSubmission = () => {
        this.setState({
            loadPanelVisible: false,
        });

        if (this.props.location.state && this.props.location.state.id) {
            this.props.onApplySuccess("EDIT");
        } else if (this.state.editServiceFeeFormData.id == "0") {
            this.props.onApplySuccess("ADD");
        }
    };

    isBlank = (filterId: string) => {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    };
    //#region render Component
    render() {
        return (
            <form data-testid="addEditShift-form" className="edit-form my-5 form_border">
                <div className="container mb-6 edit-form__column">
                    <div className="row pt-3">
                        <h2 className="billingForm-heading">Client Service Fees Matrix Form</h2>
                    </div>
                    <br></br>
                    <div className="row justify-content-left pt-3" hidden={!this.state.showError}>
                        <span className="text-danger font-weight-bold text-left">
                            <ul>
                                {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                    return (
                                        <li key={uniqueKey}>
                                            {item.columnName}: {item.errorMessage}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    </div>
                    <div className="row" hidden={!this.props.isEditForm}>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">ID</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editServiceFeeFormData.id} readOnly={true} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Client</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.clientType}
                                onValueChanged={this.handleChangeClient}
                                value={this.state.editServiceFeeFormData.clientBusinessEntityId}
                                recordType={this.props.location.state && this.props.location.state.id ? "all" : "active" }
                            />
                        </div>

                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Service</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.serviceType}
                                onValueChanged={this.handleChangeService}
                                value={this.state.editServiceFeeFormData.serviceTypeId}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Service Sub Type</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DropDownFromLookupTableDynamic
                                lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                isRoot={true}
                                parentMappingId={this.state.editServiceFeeFormData.serviceTypeId}
                                onValueChanged={this.handleChangeServiceType}
                                value={this.state.editServiceFeeFormData.serviceSubTypeId}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Fee Basis</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.servicefeecalculationtype}
                                onValueChanged={this.handleChangeServiceFee}
                                value={this.state.editServiceFeeFormData.serviceFeeCalculationLookUpId}
                            />
                        </div>

                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Multiplier</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                format={{ type: "fixedPoint", precision: 4 }}
                                onValueChanged={this.handleChangeMultiplier}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.multiplier}
                            />
                        </div>
                        <div className="mt-3 col-3 col-lg-2">
                            <CheckBox
                                value={this.state.editServiceFeeFormData.isAccruals}
                                onValueChanged={this.handleChangeAccruals}
                                width={150}
                                text="Accruals?"
                            />
                        </div>
                        <div className="mt-3 col-3 col-lg-2">
                            <CheckBox
                                value={this.state.editServiceFeeFormData.providerAcceptances}
                                onValueChanged={this.handleChangeProviderAcceptances}
                                width={150}
                                text="Provider Acceptances?"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Min Fee</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                format={{ type: "fixedPoint", precision: 2 }}
                                onValueChanged={this.handleChangeMinFee}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.minGrossProfitValue}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Max Fee</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                format={{ type: "fixedPoint", precision: 2 }}
                                onValueChanged={this.handleChangeMaxFee}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.maxGrossProfitValue}
                            />
                        </div>

                        <div className="mt-3 col-3 col-lg-2">
                            <CheckBox
                                value={this.state.editServiceFeeFormData.refund}
                                onValueChanged={this.handleChangeRefund}
                                width={150}
                                text="Refund?"
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-2">
                            <CheckBox
                                value={this.state.editServiceFeeFormData.isActive}
                                onValueChanged={this.handleChangeActive}
                                width={150}
                                text="Active?"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Budget</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.budgetType}
                                onValueChanged={this.handleChangeBudgetType}
                                value={this.state.editServiceFeeFormData.budgetTypeId}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Faster Pay</div>
                        <div className="mt-3 col-3 col-lg-1">
                            <CheckBox
                                value={this.state.editServiceFeeFormData.fasterPayAvailable}
                                onValueChanged={this.handleChangeFasterPayAvailable}
                                width={150}
                                text="Available"
                            />
                        </div>
                        <div className="mt-3 col-10 col-lg-2">
                            <NumberBox
                                format={{ type: "fixedPoint", precision: 4 }}
                                onValueChanged={this.handleChangeFasterPayMultiplier}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.fasterPayMultiplier}
                            />
                        </div>
                        <div className="mt-3 col-3 col-lg-2">
                            <CheckBox
                                value={this.state.editServiceFeeFormData.earlyPay}
                                onValueChanged={this.handleChangeEarlyPay}
                                width={150}
                                text="Early Pay?"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Visibility Offset</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                onValueChanged={this.handleChangeVisibiltyOffset}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.approvalVisibilityOffset}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Visibility Date</div>
                        <div className="mt-3 col-3 col-lg-1">
                            <CheckBox
                                value={this.state.editServiceFeeFormData.visibilityDateOverride}
                                onValueChanged={this.handleChangeVisibiltyDateOverride}
                                width={150}
                                text="Override"
                            />
                        </div>
                        <div className="mt-3 col-10 col-lg-2">
                            <DateBox
                                onValueChanged={this.handleChangeVisibiltyDate}
                                value={
                                    this.state.editServiceFeeFormData.approvalVisibilityDate
                                        ? this.state.editServiceFeeFormData.approvalVisibilityDate
                                        : undefined
                                }
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                            ></DateBox>
                        </div>  
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Batch Offset</div>
                        <div className="mt-3 col-10 col-lg-1">
                            <NumberBox
                                onValueChanged={this.handleChangeVisibiltyOffsetBatch}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.approvalVisibilityOffsetBatch}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Import Offset</div>
                        <div className="mt-3 col-10 col-lg-1">
                            <NumberBox
                                onValueChanged={this.handleChangeVisibiltyOffsetImport}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.approvalVisibilityOffsetImport}
                            />
                        </div>                      
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Min Offer</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                format={{ type: "fixedPoint", precision: 4 }}
                                onValueChanged={this.handleChangeMinOffer}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.makeOfferLowerAdjustment}
                            />
                        </div>   
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Max Offer</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                format={{ type: "fixedPoint", precision: 4 }}
                                onValueChanged={this.handleChangeMaxOffer}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.makeOfferUpperAdjustment}
                            />
                        </div> 
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Payment Offset</div>
                        <div className="mt-3 col-10 col-lg-1">
                            <NumberBox
                                onValueChanged={this.handleChangeVisibiltyOffsetPayment}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.paymentOffset}
                            />
                        </div>                       
                    </div>
                    <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Marketplace Reduction</div>
                        <div className="mt-3 col-10 col-lg-1">
                            <NumberBox
                                format={{ type: "fixedPoint", precision: 4 }}
                                onValueChanged={this.handleChangeMarketplaceReduction}
                                valueChangeEvent="keyup"
                                value={this.state.editServiceFeeFormData.marketPlaceReduction}
                            />
                        </div> 
                    </div>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                            Last Updated On
                        </div>
                        <div className="mt-3 col-10 col-lg-3" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editServiceFeeFormData.lastUpdatedOn} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                            Last Updated By
                        </div>
                        <div className="mt-3 col-10 col-lg-3" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editServiceFeeFormData.lastUpdatedBy} readOnly={true} />
                        </div>
                        <div
                            className="mt-3 col-2 col-lg-1 font-weight-bold font_size"
                            hidden={this.props.isEditForm}
                        ></div>
                        <div
                            className="mt-3 col-2 col-lg-7 font-weight-bold font_size"
                            hidden={this.props.isEditForm}
                        ></div>
                        <div className="col-12 col-lg-2 mt-3">
                            <Link
                                to={{
                                    pathname: "/matrixView",
                                    state: {
                                        isCancel: true,
                                    },
                                }}
                                className="btn btn--ghost btn--large"
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                                {this.props.t("addEditShiftRow.financeSection.cancelButton.text")}
                            </Link>
                        </div>
                        <div className="col-12 col-lg-2 mt-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="button"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onApplyClick();
                                }}
                            >
                                <span className="btn__icon"></span>
                                {this.props.t("addEditShiftRow.financeSection.applyButton.text")}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default withTranslation()(ServiceFeeForm);
