import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Texts,
    SearchPanel,
    Export,
} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import BulletinActionCellComponent from "./BulletinActionCell";
import MatrixGridService, { IbulletinGridRowItem } from "../../../services/MatrixService";
import gridUtils from "./../../grid/GridUtilities";
import MaskedStatusForActive from "../../ServiceFeeMatrix/MaskedStatusForActive";
import MaskedPopupStatus from "./MaskedPopupStatus";

//props
interface BulletinGridProps {
    refreshSignal: boolean;
    isRefresh?:boolean;
    onRefresh?: (flag:boolean) => void;
}

// State
interface BulletinGridState {
    bulletinDataSource: IbulletinGridRowItem[];
    showProgressIndicator: boolean;
}

// Component - displays the Rate Matrix Grid
class BulletinGrid extends React.Component<BulletinGridProps> {
    //Initialize the component's state.
    state: BulletinGridState;
    bulletinService: MatrixGridService;
    gridUtils: gridUtils;
    constructor(props: BulletinGridProps) {
        super(props);
        this.bulletinService = new MatrixGridService();
        this.gridUtils = new gridUtils();
        // Initialize state and services/utils
        this.state = {
            bulletinDataSource: [],
            showProgressIndicator: false,
        };
    }

    componentDidMount() {
        this.bulletinDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate = (prevProps: BulletinGridProps) => {
        if (this.props.refreshSignal !== prevProps.refreshSignal || (this.props.isRefresh != prevProps.isRefresh && this.props.isRefresh == true)) {
            // refresh the grid
            this.bulletinDataSource();
        }
    }

    bulletinDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.bulletinService.getBulletinDataItem().then(this.handleSuccess).catch(this.handleError);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        let ordered = response.data.data.sort((a:any, b:any) => (parseInt(b.id)- parseInt(a.id)))
        this.setState({
            // bulletinDataSource: response.data.data,
            bulletinDataSource: ordered,
            showProgressIndicator: false,
        },()=>{
            this.props.onRefresh ? this.props.onRefresh(false) :null;
        });
    }

    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "response failed with response: " + JSON.stringify(error);

        if (!this.bulletinService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.props.onRefresh ? this.props.onRefresh(false) :null;
    }

    render() {
        var { bulletinDataSource } = this.state;
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={bulletinDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        columnResizingMode={"widget"}
                        allowColumnResizing={true}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Sorting mode="single" />
                        <Export enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="id" allowSorting={true} caption="ID" calculateSortValue={(rowData: any) => {
                            return this.gridUtils.convertStringToInt(rowData.id);
                        }} />
                        <Column dataField="bulletinCategoryValue" allowSorting={true} caption="CATEGORY" />
                        <Column dataField="bulletinPriorityValue" allowSorting={true} caption="PRIORITY" />
                        <Column dataField="bulletinName" allowSorting={true} caption="NAME" />
                        <Column dataField="bulletinTitle" allowSorting={true} caption="TITLE" />
                        <Column dataField="bulletinDescription" allowSorting={true} caption="DESCRIPTION" />
                        <Column dataField="userRoleValue" allowSorting={true} caption="AUDIENCE" />
                        <Column dataField="businessEntityShortName" allowSorting={true} caption="COMPANY" />
                        <Column dataField="serviceTypeValue" allowSorting={true} caption="SERVICE" />
                        <Column
                            dataField="isPopUpRequired"
                            allowSorting={true}
                            caption="POP UP"
                            cellComponent={MaskedPopupStatus}
                        ></Column>
                        <Column
                            dataField="bulletinDate"
                            allowSorting={true}
                            caption="DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.bulletinDate);
                            }}
                        />
                        <Column
                            dataField="validTo"
                            allowSorting={true}
                            caption="VALID TO"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.validTo);
                            }}
                        />
                        <Column
                            dataField="validFrom"
                            allowSorting={true}
                            caption="VALID FROM"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.validFrom);
                            }}
                        />
                        <Column
                            dataField="isActive"
                            allowSorting={true}
                            caption="ACTIVE"
                            cellComponent={MaskedStatusForActive}
                        ></Column>
                        <Column
                            dataField="lastUpdatedOn"
                            allowSorting={true}
                            caption="LAST UPDATED"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                            }}
                        />
                        <Column
                            dataField="lastUpdatedBy"
                            allowSorting={true}
                            caption="LAST UPDATED BY"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedBy);
                            }}
                        />
                        <Column cellComponent={BulletinActionCellComponent} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default BulletinGrid;
