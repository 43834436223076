import React from "react";
import { DateBox, LoadPanel } from "devextreme-react";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import PaymentService, { paymentStatus, paymentCycleStatus } from "../../services/PaymentService";
import paymentFormUtils, { PaymentFormData, PaymentStatus, ActionType } from "../Payments/PaymentFormUtils";
import NewPaymentsGrid from "./NewPaymentsGrid";
import GlobalService from "../../services/GlobalService";
import { DateBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { Link } from "react-router-dom";
import notify from "devextreme/ui/notify";

interface PaymentPaidSectionProps {
    viewDisabled: boolean;
    paymentCycleId: number;
    paymentData: PaymentFormData;
    statusId: string;
    invoiceSentDate: NullableDate;
    calculationsObject: any;
    syncDateFields: (date: NullableDate, type: string) => void;
    onPaymentProduced: (status: string, statusId: string, isRestartDisable: boolean) => void;
    setCalculationsCallback: (calculationsObject: { subTotal: string; total: string; tax: string }) => void;
    refreshPage?: boolean;
}

interface PaymentPaidSectionState {
    disablePaidButton: boolean;
    loadPanelVisible: boolean;
    errorMessage: [];
    statusIdOnClick: string;
    paymentRefresh: boolean;
    paymentFormData: PaymentFormData;
    calculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
}

class PaymentPaidSection extends React.Component<PaymentPaidSectionProps> {
    state: PaymentPaidSectionState;
    PaymentService: PaymentService;
    paymentFormUtils: paymentFormUtils;
    sharedUtils: sharedUtils;

    constructor(props: PaymentPaidSectionProps) {
        super(props);
        this.PaymentService = new PaymentService();
        this.paymentFormUtils = new paymentFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            disablePaidButton: this.props.viewDisabled ? true : false,
            loadPanelVisible: false,
            errorMessage: [],
            statusIdOnClick: "",
            paymentRefresh: false,
            paymentFormData: this.props.paymentData,
            calculationsObject: {
                subTotal: "0.00",
                total: "0.00",
                tax: "0.00",
            },
        };
    }
    componentDidUpdate(prevProps: PaymentPaidSectionProps) {
        if (this.props.statusId != prevProps.statusId && this.props.statusId == paymentStatus.PaymentRemitted) {
            this.setState({
                ...this.state,
                paymentRefresh: !this.state.paymentRefresh,
            });
        }
        if (this.props.refreshPage != prevProps.refreshPage) {
            this.setState({
                paymentRefresh: !this.state.paymentRefresh,
            });
        }
    }
    //Function which is invoked when the "Produce" as well as "Sent" button is clicked.
    onPaymentPaid = (statusId: string, status: string) => {
        let data: PaymentFormData = this.paymentFormUtils.paymentDataInitializePaymentItems(
            this.props,
            statusId,
            status
        );
        data.paidDate = this.state.paymentFormData.paidDate;

        this.setState({
            gridData: data,
            loadPanelVisible: true,
            statusIdOnClick: statusId,
        });
        this.onSubmit(data);
    };

    onSubmit = (paymentData: PaymentFormData) => {
        var paymentCycleSubmitRequest = this.paymentFormUtils.convertFormDataToPaymentCycleRequest(
            paymentData,
            ActionType.Save,
            "",
            ""
        );
        this.PaymentService.postPaymentCycleDataV2(paymentCycleSubmitRequest)
            .then(this.handleSuccess)
            .catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        const { statusIdOnClick } = this.state;
        this.setState(
            (prevState: PaymentPaidSectionState) => ({
                loadPanelVisible: false,
                disablePaidButton: true,
                errorMessage: [],
                paymentRefresh: !this.state.paymentRefresh,
            }),
            () => this.props.onPaymentProduced(paymentStatus.PaymentSettled, statusIdOnClick, true)
        );
        GlobalService.removePaymentCycleIds();
    };

    handleFailure = (error: any) => {
        var respMessage: string = "postPaymentCycleInvoice failed with response: " + JSON.stringify(error);

        if (!this.PaymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            errorMessage: error.response !== null ? JSON.parse(JSON.stringify(error.response.data.error)) : null,
            loadPanelVisible: false,
            disablePaidButton: false
        });
    };
    setCalculations = (calculations: any) => {
            if (calculations.total || calculations.subTotal || calculations.tax) {
                this.setState(
                    {
                        calculationsObject: {
                            ...this.state.calculationsObject,
                            total: calculations.total,
                            subTotal: calculations.subTotal,
                            tax: calculations.tax,
                        },
                    },
                    () => this.props.setCalculationsCallback(this.state.calculationsObject)
                );
            }
    };

    setPaymentCycleIdOnParent = (paymentId: string) => {};

    //Trigger the onChange function for "Invoice Sent Date" field which is to be synchronised with the form.
    handleChangePaidDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            paymentFormData: {
                ...this.state.paymentFormData,
                paidDate: dxValueChange.value,
            },
        });
        this.props.syncDateFields(dxValueChange.value, "Paid");
    };

    getPayDateFileName = () =>{
        let payDate = this.state.paymentFormData &&  this.state.paymentFormData.payDate ;
        //let payDateString = payDate?.toString();
        let payDateFileName = '';
        if(payDate){
            // let dateParts = payDateString.split("/");
            // let day = parseInt(dateParts[0]);
            // let month = parseInt(dateParts[1]);
            // let year = parseInt(dateParts[2]);

            let day = payDate.getDate();
            let month = payDate.getMonth() + 1;
            let year = payDate.getFullYear();

            payDateFileName = `_${year}_${month}_${day}`;
        }
        return payDateFileName;
    }

    isDownloadDisabled = () =>{
        let disabled = true;
        if(this.state.paymentFormData &&  this.state.paymentFormData.payDate){
            disabled = false;
        }

        return disabled;

    }

    onDownloadClick = () => {
        let downloadId = this.state.paymentFormData?.paymentCycleId || '';
        if (downloadId) {
            this.setState({
                loadPanelVisible: true
            },()=>{
                this.PaymentService.getPaymentCycleExtractDocument(downloadId).then(this.handleDownloadSuccess).catch(this.handleDownloadError);
            });
        }
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false
        });
        let downloadId = this.state.paymentFormData?.paymentCycleId || '';
        let name = 'Payment_Cycle_Extract';
        if(downloadId){
            name += `_${downloadId}`
        }
        let payDateName = this.getPayDateFileName();
        if(payDateName){
            name += payDateName;
        }
        name += '.csv';
        if(response.status == 204){
            notify({ message: "No content to download!", width: 300, shading: true, position: "center" }, 'info', 600);
        } else {
            saveAs(response.data, name);
        }
        
    };

    handleDownloadError = (error: any) => {
        this.setState({
            loadPanelVisible: false
        });
        var respMessage: string = "Download template failed with response: " + JSON.stringify(error);

        if (!this.PaymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    render() {
        var viewDisabled: boolean = this.props.viewDisabled;
        var { disablePaidButton } = this.state;
        var { statusId } = this.props;
        var disablePaymentPaidButton: boolean = statusId != paymentStatus.PaymentAdvised ? false : true;
        var paidButtonCssClass: string = "btn disabledCycleButtonColor btn--large";
        var paidButtonDisabled: boolean = true;
        if (disablePaymentPaidButton && disablePaidButton == false) {
            paidButtonDisabled = false;
            paidButtonCssClass = "btn saveCycleButtonColor btn--large";
        }
        return (
            <div className="card my-3">
                <div className="card-body">
                    <h4>Payment Paid 
                            <Link style={{ padding: '10px' }}
                                onClick={(e) => {
                                    this.onDownloadClick();
                                }}
                                className={`approval-query-column ${this.isDownloadDisabled() ? 'disabled':''}`}
                                to="#">
                                Download Accounts Extract
                            </Link>
                        </h4>
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <div className="col-12 mt-3">
                            <>
                                <NewPaymentsGrid
                                    id={this.props.paymentCycleId.toString()}
                                    setCalculations={this.setCalculations}
                                    setPaymentCycleIdOnParent={this.setPaymentCycleIdOnParent}
                                    paymentsRefresh={this.state.paymentRefresh}
                                    pontOfNavigation="paymentspaid"
                                ></NewPaymentsGrid>
                            </>
                        </div>
                        {this.state.errorMessage ? (
                            <span className="unscheduled-shift">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className="row align-items-center">
                            <div className="col-2">
                                <button
                                    className={(viewDisabled ? "disabled " : "") + paidButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onPaymentPaid(
                                            paymentStatus.PaymentSettled,
                                            paymentCycleStatus.PaymentSettled
                                        );
                                    }}
                                    disabled={viewDisabled ? true : paidButtonDisabled}
                                >
                                    Payment Paid
                                </button>
                            </div>
                            <div className="col-2 text-right">Paid Date</div>
                            <div className="col-4">
                                <DateBox
                                    displayFormat="dd/MM/yyyy"
                                    onValueChanged={this.handleChangePaidDate}
                                    value={
                                        this.state.paymentFormData.paidDate
                                            ? this.state.paymentFormData.paidDate
                                            : this.props.paymentData.paidDate
                                            ? this.props.paymentData.paidDate
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    disabled={viewDisabled}
                                />
                            </div>
                        </div>
                    </>
                </div>
            </div>
        );
    }
}
export default PaymentPaidSection;
