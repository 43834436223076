import React, { Component, MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import DataGrid, { Paging, HeaderFilter, FilterRow, Column, Export, FilterPanel, GroupPanel, Pager, SearchPanel, StateStoring, Scrolling, Selection } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import sharedUtils from "../grid/sharedUtilities";
import QueryService, { queryStatusType, queryTypes } from "../../services/QueryService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileAlt, faPen, faCheckDouble, faSquare } from "@fortawesome/pro-regular-svg-icons";
import { ScrollView, Popup, LoadPanel, TextArea } from "devextreme-react";
import queryUtils, { QueryFormObject } from "../Query/QueryUtils";
import { alert, confirm } from "devextreme/ui/dialog";
import { TextBoxOnValueChangedEvent, DataGridOnSelectionChanged } from "../../types/DevExtremeTypes";
import QuickAddShiftForm from "../QuickAddShift/QuickAddShiftForm"
import TagCell from "../GigRequest/TagCell";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import SupplierInformationMainContent from "../SupplierInformation/SupplierInformationMainContent";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import notify from "devextreme/ui/notify";
import QueryManageForm from "./QueryManageForm";
import QueryGridRenderDataRow from "./QueryGridRenderDataRow";
import ClientInformationMainContent from "../ClientInformation/ClientInformationMainContent";

let billableItemId: string = "";
let queryFormId: string = "";
interface QueryGridProps extends WithTranslation {
    statusId: string;
    serviceTypeId: string;
    venueId: string;
    clientId: string;
    location: any;
    history?: any;
    navigateFrom?: string;
    queryId?: string;
    contactId?: string;
    raisedById?:string;
    isRefreshButtonClicked?:boolean
    onRefresh?: (flag: boolean) => void;
}

interface QueryGridState {
    dataSource: [],
    titlePopupVisible: boolean;
    title: string;
    internalCommentsPopupVisible: boolean;
    internalComments: string;
    resolutionReasonPopUpVisible: boolean;
    resolutionReason: string;
    loadIndicatorForGrid: boolean;
    resolveQueryItem: QueryFormObject;
    onQueryRaising: boolean;
    loadPanelVisible: boolean;
    isVisibleManageShiftPopup: boolean;
    isVisibleQueryPopup: boolean;
    billableItemId: string;
    isPopupVisible: boolean;
    providerPopupVisibleOther: boolean;
    providerPopupVisibleEntertainment: boolean;
    clientId: string;
    venueId: string;
    businessEntityId: string;
    serviceId: string;
    isDisabled: boolean;
    venuePopupVisible:boolean;
    serviceName : string;
    providerType:string;
}

class QueryGrid extends Component<QueryGridProps> {
    state: QueryGridState;
    queryService: QueryService;
    sharedUtils: sharedUtils;
    queryUtils: queryUtils;
    remoteOperations: {
        sorting: boolean;
        paging: boolean;
        filtering: boolean;
        grouping: boolean;
        summary: boolean;
    }
    isEditMode: string;
    dataGrid:any;
    otherdataGrid:any;
    clearRef:any;
    constructor(props: QueryGridProps) {
        super(props);
        this.dataGrid = null;
        this.otherdataGrid = null;
        this.queryUtils = new queryUtils();
        let convertedResolveQueryFormData = this.queryUtils.initializeQueryItem(
            ""
        );
        this.isEditMode = "";
        this.state = {
            dataSource: [],
            isPopupVisible: false,
            titlePopupVisible: false,
            title: "",
            internalCommentsPopupVisible: false,
            internalComments: "",
            resolutionReasonPopUpVisible: false,
            resolutionReason: "",
            onQueryRaising: false,
            loadIndicatorForGrid: false,
            resolveQueryItem: convertedResolveQueryFormData,
            loadPanelVisible: false,
            isVisibleManageShiftPopup: false,
            isVisibleQueryPopup: false,
            billableItemId: "",
            providerPopupVisibleOther: false,
            providerPopupVisibleEntertainment: false,
            clientId: this.props.clientId,
            venueId: this.props.venueId,
            businessEntityId: "",
            serviceId: "",
            isDisabled: true,
            venuePopupVisible:false,
            serviceName : '',
            providerType:''
        }
        this.queryService = new QueryService();
        this.sharedUtils = new sharedUtils();
        this.remoteOperations = {
            sorting: true,
            filtering: true,
            grouping: true,
            paging: true,
            summary: false
        }
        this.clearRef = React.createRef;
        this.clearRef.current = false;
    }

    isNavigateFromPendingQueries = () =>{
        let isPendingQueries = false;
        let navigateFrom = this.props.location?.state && this.props.location?.state?.navigateFrom || '';
        if (navigateFrom == "PendingQueries_JustPay" || navigateFrom == "PendingQueries_EventUK" || navigateFrom == "PendingQueries_Other") {
           isPendingQueries = true;
        }
        return isPendingQueries;
    }

    componentDidMount() {
        this.setState({
            loadIndicatorForGrid: true
        });
        let navigateFrom = this.props.location?.state && this.props.location?.state?.navigateFrom || '';
        if (this.isNavigateFromPendingQueries()) {
            this.clearRef.current = true;
        }
    }

    clearFiltersOnLoad_DrillDown = () =>{
        //this.clearRef.current = true;
        let navigateFrom = this.props.location.state && this.props.location.state.navigateFrom || '';
        if(navigateFrom && this.clearRef?.current && (this.isNavigateFromPendingQueries())){
                this.otherdataGrid?.instance?.clearFilter();
                this.clearRef.current = false;
        }
    }


    getResults = (loadOptions: any) => {
        this.clearFiltersOnLoad_DrillDown();
        let params = buildRequestParameters(loadOptions);
        if(this.props.raisedById){
            let raisedId = parseInt(this.props.raisedById);
            let filters = ["businessEntity.businessEntityTypeLookUpId","=",raisedId];
            params = buildRequestParameters(loadOptions,'filter',filters);
        }

        if(this.props.navigateFrom == "queryform"){
            let queryId = this.props?.queryId ? this.props?.queryId : "";
            let contactId = this.props?.contactId ? this.props?.contactId : "";
            let linkedParams = params + `&queryId=${queryId}&contactId=${contactId}`;
            return this.queryService.linkedQueryData(linkedParams)
                .then((response) => {
                    return this.handleSuccessDataSource(response)
                })
                .catch(() => { throw 'Data Loading Error'; });
        }
        else{
            // if (this.props.location && this.props.location.state && this.props.location.state.navigateFrom == "PendingQueries") {
            //      if(params !== "") params = params + `&serviceTypeId=${this.props.location.state.serviceType}&status=${this.props.statusId}&clientId=&venueId=&navigatefrom=pendingqueries`;
            //     else params = params + `?serviceTypeId=${this.props.location.state.serviceType}&status=${this.props.statusId}&clientId=&venueId=&navigatefrom=pendingqueries`;
            // }
            if (this.props.location && this.props.location.state && this.isNavigateFromPendingQueries()) {
                if (params) {
                    params += `&`;
                } else {
                    params += `?`;
                }
                params+= `serviceTypeId=${this.props.location.state.serviceType}&status=&clientId=&venueId=&navigatefrom=pendingqueries`;

                // if(params !== "") params = params + `&serviceTypeId=${this.props.location.state.serviceType}&status=${this.props.statusId}&clientId=&venueId=&navigatefrom=pendingqueries`;
                // else params = params + `?serviceTypeId=${this.props.location.state.serviceType}&status=${this.props.statusId}&clientId=&venueId=&navigatefrom=pendingqueries`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.navigateFrom == "shiftMatrix") {
                this.otherdataGrid.instance.clearFilter(); // to clear the already added filters
                if(params !== "") params = params + `&serviceTypeId=&status=${this.props.statusId}&filter=[%22case_ID%22,%22=%22,${this.props.location.state.queryId}]&clientId=&venueId=`;
                else params = params + `?serviceTypeId=&status=${this.props.statusId}&filter=[%22case_ID%22,%22=%22,${this.props.location.state.queryId}]&clientId=&venueId=`;
            }
            else if (params) {
                if(params !== "") params = params + `&serviceTypeId=${this.props.serviceTypeId}&status=${this.props.statusId}&clientId=${this.props.clientId}&venueId=${this.props.venueId}`;
                else params = params + `?serviceTypeId=${this.props.serviceTypeId}&status=${this.props.statusId}&clientId=${this.props.clientId}&venueId=${this.props.venueId}`;
            }
            else {
                params = `?serviceTypeId=${this.props.serviceTypeId}&status=${this.props.statusId}&clientId=${this.props.clientId}&venueId=${this.props.venueId}`;
            }
            return this.queryService.getQueryGridRowsWithinRemoteOperations(params)
                .then((response) => {
                    return this.handleSuccessDataSource(response)
                })
                .catch(() => { 
                    //this.props.onRefresh ? this.props.onRefresh(false) :null;
                    throw 'Data Loading Error'; 
            });
        }

    }
    handleSuccessDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });
        //this.props.onRefresh ? this.props.onRefresh(false) :null;
        return {
            data: response.data.data ? response.data.data : [],
            totalCount: response.data.totalCount
        };
    }


    componentDidUpdate(prevpros: QueryGridProps) {
        if (prevpros.statusId != this.props.statusId || prevpros.serviceTypeId != this.props.serviceTypeId 
            || prevpros.clientId != this.props.clientId || prevpros.venueId != this.props.venueId 
            || prevpros.queryId != this.props.queryId || prevpros.contactId != this.props.contactId
            || prevpros.raisedById != this.props.raisedById || (this.props.isRefreshButtonClicked != prevpros.isRefreshButtonClicked && this.props.isRefreshButtonClicked == true)) {
            this.loadDataSource();
            this.props.onRefresh ? this.props.onRefresh(false) :null;
        }
        else {
            return false;
        }
    }


    loadDataSource = () => {
        let dataSource = new CustomStore({
            key: "case_ID",
            load: this.getResults,
        });
        this.setState({
            dataSource: dataSource
        });

    }
    showRelatedItemPopup = (itemId: string) => {
        billableItemId = itemId;
        this.setState({
            isVisibleManageShiftPopup: true
        });
    }    

    closeManageShiftPopup = () => {
        billableItemId="";
        this.setState({
            isVisibleManageShiftPopup: false
        });
    }

    showQueryFormPopup = (itemId: string) => {
        queryFormId = itemId;
        this.setState({
            isVisibleQueryPopup: true
        });
    }

    closeQueryFormPopup = () => {
        queryFormId="";
        this.setState({
            isVisibleQueryPopup: false
        });
    }

    handleResolveOnClick = (rowInfo: any) => {
        if (rowInfo.data.caseStatusLU.case_Status_ID && rowInfo.data.caseStatusLU.case_Status_ID != queryStatusType.Resolved) {
            this.setState({
                resolveQueryItem: {
                    ...this.state.resolveQueryItem,
                    id: rowInfo.data.case_ID,
                    resolutionReason: rowInfo.data.resolution_Reason,
                    queryTypeLookupID: rowInfo.data.queryTypeLookUp.id.toString()
                }
            }, () => { this.showQueryPopUp() });
        }
    }
    showQueryPopUp = () => {
        this.setState({
            onQueryRaising: true
        });
    }
    closeQueryBox = () => {
        this.setState({
            onQueryRaising: false
        });
    };

    handleQuerySubmissionFailure = () => {
        this.setState({
            loadPanelVisible: false,
        });
        notify({ message: "Query Resolving failed", width: 500, shading: true }, 'error', 600);
    };
    
    renderDataRow = (rowInfo: any) => {
        let finalFormattedLastUpdatedDate: string = "";
        let finalFormattedDate: string = "";
        let formattedDateFrom :string = "";
        if (rowInfo.data.date_Created) {
            let dateCreatedTime = rowInfo.data.date_Created;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        if(rowInfo.data.lastUpdated_DateTime){
            let lastUpdatedTime = rowInfo.data.lastUpdated_DateTime;
            let lastUpdatedTimeLocal = new Date(lastUpdatedTime);
            let formattedLastUpdatedTime = this.sharedUtils.convertDateTimeStringToHHMM(lastUpdatedTimeLocal.toString());
            let formattedLastUpdatedDate = this.sharedUtils.convertDateToString(lastUpdatedTimeLocal);
            finalFormattedLastUpdatedDate = `${formattedLastUpdatedDate} ${formattedLastUpdatedTime}`;
        }

        if (rowInfo.data.billableItem?.dateFrom) {
            let dateFrom = rowInfo.data.billableItem?.dateFrom;
            let dateFromTimeLocal = new Date(dateFrom);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateFromTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateFromTimeLocal);
            // formattedDateFrom = `${formattedDate} ${formattedTime}`;
            formattedDateFrom = `${formattedDate}`;
        }
        let billableItemId: string = "";
        let linkedId: string = "";
        let disablerelatedItemIcon: boolean = true;
        if (rowInfo.data.billableItemId) {
            billableItemId = rowInfo.data.billableItemId;
        }

        if (rowInfo.data.linkedId && (rowInfo.data.queryTypeLookUp.id.toString() == queryTypes.AddJob || rowInfo.data.queryTypeLookUp.id.toString() == queryTypes.PaymentRequest)) {
            linkedId = rowInfo.data.linkedId
        }
        if (rowInfo.data.queryTypeLookUp.id.toString() == queryTypes.AddJob || rowInfo.data.queryTypeLookUp.id.toString() == queryTypes.PaymentRequest || rowInfo.data.queryTypeLookUp.id.toString() == queryTypes.BillableItem || rowInfo.data.queryTypeLookUp.id.toString() == queryTypes.CostReport) {
            disablerelatedItemIcon = false;
        }
        let iconToShow: any;
        if (rowInfo.data.caseStatusLU.case_Status_ID && rowInfo.data.caseStatusLU.case_Status_ID == queryStatusType.Resolved) {
            iconToShow = faCheckDouble;
        }
        else {
            iconToShow = faSquare;
        }
        return (
            <>
                <tr>
                    <td rowSpan={2}>                        
                        <div className="d-flex justify-content-between align-items-center">
                            <Link title="Edit"
                                className={"icon-btn"}
                                to={{
                                    pathname: "/QueryManageForm",
                                    state: {
                                        id: rowInfo.data.case_ID,
                                        isView: false,
                                        pointOfNavigation: "queryGrid"
                                    },
                                }}
                            >
                                <FontAwesomeIcon icon={faPen} /></Link>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex justify-content-between align-items-center">
                            <Link title="View"
                                className={"icon-btn"}
                                to={{
                                    pathname: "/QueryManageForm",
                                    state: {
                                        id: rowInfo.data.case_ID,
                                        isView: true,
                                        pointOfNavigation: "queryGrid"
                                    },
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} /></Link>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex justify-content-between align-items-center">

                            <button
                                className={"without-border-button"}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.handleResolveOnClick(rowInfo);
                                }}
                            >
                                <FontAwesomeIcon icon={iconToShow} />
                            </button>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex justify-content-between align-items-center">
                            {billableItemId ?
                                <button
                                    className={disablerelatedItemIcon ? "disabled icon-btn without-border-button" : "without-border-button"}
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.showRelatedItemPopup(billableItemId);
                                    }}
                                    disabled={disablerelatedItemIcon}
                                >
                                    <FontAwesomeIcon icon={faFileAlt} />
                                </button> :
                                <button
                                    className={disablerelatedItemIcon ? "disabled icon-btn without-border-button" : "without-border-button"}
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        window.open(`/MatchedPopUpPage?filterBy=${linkedId}`, "Related Payment Request/ Artist Job Notification",
                                            "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1050,height=400,left=100,top=100")
                                    }}
                                    disabled={disablerelatedItemIcon} >
                                    <FontAwesomeIcon icon={faFileAlt} />
                                </button>}
                        </div>
                    </td>
                    <td> <TagCell data={rowInfo} i18n={rowInfo} tReady={false} t={rowInfo} /> </td>
                    <td className="datagrid__text-cell">{rowInfo.data.taggedUser?.name}</td>
                    <td className="datagrid__text-cell">{rowInfo.data.caseStatusLU.case_Status_Value}</td>
                    <td className="datagrid__text-cell">{rowInfo.data.queryTypeLookUp.value}</td>
                    <td className="datagrid__text-cell">{rowInfo.data.billableItem?.serviceSubTypeLookUp?.value ? rowInfo.data.billableItem?.serviceSubTypeLookUp?.value:''}</td>
                    <td className="datagrid__text-cell">{formattedDateFrom}</td>
                    {/* <td className="datagrid__text-cell">{rowInfo.data.billableItem?.dateFrom ? this.sharedUtils.convertTDateFormatToFormatedDate(rowInfo.data.billableItem?.dateFrom): ''}</td>  */}
                    <td className="datagrid__text-cell">{rowInfo.data.billableItem?.name ? rowInfo.data.billableItem?.name : ''}</td>
                    <td className="datagrid__text-cell">{finalFormattedDate}</td>
                    {/* <td className="datagrid__text-cell">{rowInfo.data.queryRaisedBy}</td> */}
                    <td className="datagrid__text-cell">{this.colorStatusForRaisedBy(rowInfo)}</td>
                    <td className="datagrid__text-cell">{rowInfo.data.clientBusinessEntity ? rowInfo.data.clientBusinessEntity.shortName : ""}</td>
                    <td className="datagrid__text-cell">{this.clientServiceDisplay(rowInfo)}</td>
                    {/* <td className="datagrid__text-cell">{rowInfo.data.venue ? rowInfo.data.venue.venueName : ""}</td> */}
                    <td className="datagrid__text-cell">{rowInfo.data.venue ? this.venueCellDisplayContent(rowInfo): ''}</td>
                    <td className="datagrid__text-cell">{this.onboardedCellDisplayContent(rowInfo)}</td>
                    <td className="datagrid__text-cell">{rowInfo.data.serviceTypeLookUp ? rowInfo.data.serviceTypeLookUp.value : ""}</td>
                    <td className="datagrid__text-cell">
                        {this.titleCellDisplayContent(rowInfo)}
                    </td>
                    <td className="datagrid__text-cell">{this.providerCellDisplayContent(rowInfo)}</td>
                    <td className="datagrid__text-cell">{rowInfo.data.providerBusinessEntity?.id}</td>
                    <td className="datagrid__text-cell">{this.providerServiceDisplay(rowInfo)}</td>
                    <td className="datagrid__text-cell">{this.resolutionReasonCellDisplayContent(rowInfo)}</td>
                    <td className="datagrid__text-cell">{this.internalCommentsCellDisplayContent(rowInfo)}</td>
                    <td className="datagrid__text-cell">{rowInfo.data.raisedByContactName}</td>
                    <td className="datagrid__text-cell">{rowInfo.data.raisedByContactTelNumber}</td>
                    <td className="datagrid__text-cell">{finalFormattedLastUpdatedDate}</td>
                    <td className="datagrid__text-cell">{rowInfo.data.user ? rowInfo.data.user.name : ""}</td>
                    <td rowSpan={2} className="datagrid__text-cell">{rowInfo.data.case_ID}</td>
                    <td rowSpan={2} className="datagrid__text-cell">{rowInfo.data.linkedCaseID}</td>
                </tr>
                <tr>
                    <td className="datagrid__description-row datagrid__text-description-cell" colSpan={15}><div>{rowInfo.data.query_Description}</div></td>
                </tr>
            </>
        )
    }

    titleCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoTitle(cellInfo.data.query_Title);
                    }}
                    className="approval-query-column"
                    to="#"

                >
                    {cellInfo.data.query_Title
                        ? cellInfo.data.query_Title.length > 16
                            ? `${cellInfo.data.query_Title.substring(0, 16)}...`
                            : cellInfo.data.query_Title
                        : ""}
                </Link>
            </div>
        );
    };

    internalCommentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoInternalComments(cellInfo.data.internal_Comments);
                    }}
                    className="approval-query-column"
                    to="#"

                >
                    {cellInfo.data.internal_Comments
                        ? cellInfo.data.internal_Comments.length > 16
                            ? `${cellInfo.data.internal_Comments.substring(0, 16)}...`
                            : cellInfo.data.internal_Comments
                        : ""}
                </Link>
            </div>
        );
    };
    resolutionReasonCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoResolutionReason(cellInfo.data.resolution_Reason);
                    }}
                    className="approval-query-column"
                    to="#"

                >
                    {cellInfo.data.resolution_Reason
                        ? cellInfo.data.resolution_Reason.length > 16
                            ? `${cellInfo.data.resolution_Reason.substring(0, 16)}...`
                            : cellInfo.data.resolution_Reason
                        : ""}
                </Link>
            </div>
        );
    };
    showInfoTitle = (title: string) => {
        this.setState({
            titlePopupVisible: true,
            title: title,
        });
    };

    hideInfoTitle = () => {
        this.setState({
            titlePopupVisible: false
        });
    };
    showInfoInternalComments = (comments: string) => {
        this.setState({
            internalCommentsPopupVisible: true,
            internalComments: comments
        });
    };

    hideInfoInternalComments = () => {
        this.setState({
            internalCommentsPopupVisible: false
        });
    };
    showInfoResolutionReason = (reason: string) => {
        this.setState({
            resolutionReasonPopUpVisible: true,
            resolutionReason: reason
        });
    };

    hideInfoResolutionReason = () => {
        this.setState({
            resolutionReasonPopUpVisible: false
        });
    };

    onQuerySubmission = () => {
        if (!this.state.resolveQueryItem.resolutionReason) {
            alert("Please enter the resolution reason before pushing the submit button", "Resolve Query");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            let convertedresolveQueryItem = this.queryUtils.convertFormDataToQueryRequest(this.state.resolveQueryItem, "");
            this.queryService.postResolveQueryRequestFormData(convertedresolveQueryItem)
                .then(() => this.handleQuerySubmissionSuccess())
                .catch(this.handleQuerySubmissionFailure);
        }
    }
    handleQuerySubmissionSuccess = () => {
        this.setState({
            loadPanelVisible: false
        });
        this.closeQueryBox();
        notify({ message: "Thank you - Query Resolved", width: 500, shading: true }, 'success', 600);
        setTimeout(this.loadDataSource, 2000);

    };
    handleChangeQueryComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.value != undefined) {
            this.setState({
                resolveQueryItem: {
                    ...this.state.resolveQueryItem,
                    resolutionReason: dxValueChange.value
                }
            });
        }
    }

    showInfoProvider = (clientId: string, venueId: string, serviceTypeId: string, businessEntityId: string,data:any) => {
        let type = data?.providerType || '';
        let serviceName = data?.serviceTypeLookUp?.value;
        //let serviceName = ''
        // if (type) {
        //     if (this.state.providerType == 'artist') {
        //         serviceName = 'Entertainment';
        //     } else if (this.state.providerType == 'provider') {
        //         serviceName = 'viewProviders';
        //     }
        // }
        this.setState({
            clientId: clientId,
            venueId: venueId,
            serviceId: serviceTypeId,
            businessEntityId: businessEntityId,
            serviceName : serviceName,
            providerType:type?.toLowerCase()
        });

        if (type) {//if (type == 'artist' || type == 'provider') {
            this.setState({
                providerPopupVisibleEntertainment: true
            });
        } 
        // else {
        //     this.setState({
        //         providerPopupVisibleOther: true
        //     }); 
        // }
        // if (serviceTypeId == "10") {
        //     this.setState({
        //         providerPopupVisibleOther: true,
        //     });
        // }
        // else {
        //     this.setState({
        //         providerPopupVisibleEntertainment: true,
        //     });            
        // }
        
        // if(serviceTypeId == "30"){
        //     this.setState({
        //         providerPopupVisibleEntertainment: true,
        //     });
        // }
        // else{
        //     this.setState({
        //         providerPopupVisibleOther: true,
        //     }); 
        // }
    };

    providerCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.clientBusinessEntity?.id, cellInfo.data.venue?.id, cellInfo.data.serviceTypeLookUp?.id, cellInfo.data.providerBusinessEntity?.id,cellInfo.data);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.providerBusinessEntity?.shortName}
                </Link>
            </div>
        );
    };

    raisedByCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.clientBusinessEntity?.id, cellInfo.data.venue?.id, cellInfo.data.serviceTypeLookUp?.id, cellInfo.data.providerBusinessEntity?.id,cellInfo.data);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo?.data?.queryRaisedBy}
                </Link>
            </div>
        );
    };

    onboardedCellDisplayContent = (cellInfo: any) => {
        let onboardedVal = "";
        onboardedVal = cellInfo.data.venue ? cellInfo.data.venue?.onboarded : cellInfo.data.onboarded;
        let valToShow: string = "";
        if (onboardedVal) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {valToShow}
            </div>
        );

    }

    hidePopup = (isPopupVisible: boolean) => {
        this.setState({
            isVisibleManageShiftPopup: isPopupVisible
        });
    }

    hideInfoProvider = (e: any) => {       
        this.setState({
            providerPopupVisibleOther: false,
        });  
    };

    handlePopUpClose = (flag:boolean, editmode: string) => {        
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
        });
    }

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if(this.isEditMode == "editMode"){
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else{
            this.setState({
                providerPopupVisibleEntertainment: false,
            });  
        }
    };

    getServiceType = () =>{
        let serviceType = this.state.serviceName;
            if(this.state.providerType){
                if(this.state.providerType == 'artist'){
                    serviceType = 'Entertainment';
                } else if(this.state.providerType == 'provider'){
                    //if(!serviceType){
                        serviceType = 'viewProviders'; //check for general queries where no service available
                    //}
                }
            } 
        return serviceType;
    }

    getTitleForPopup = () => {
        //let serviceType = this.state.serviceName;
        let title = '';
        if(this.state.providerType){
            if(this.state.providerType == 'artist'){
             title = "Artist Details" 
            } else if(this.state.providerType == 'provider'){
                title = "Provider Details"
            }
        }
        return title;
    }

    clientServiceDisplay = (cellInfo: any) => {
        return (
            <span>
                <div className="mb-0">
                    {cellInfo.data.clientServices
                        ? cellInfo.data.clientServices.map((item: any, uniqueKey: number) => {
                            return (
                                <>
                                    <div key={uniqueKey}>
                                        {item}
                                    </div>
                                </>
                            );
                        })
                        : null}
                </div>
            </span>
        );
    }

    providerServiceDisplay = (cellInfo: any) => {
        return (
            <span>
                <div className="mb-0">
                    {cellInfo.data.providerServices
                        ? cellInfo.data.providerServices.map((item: any, uniqueKey: number) => {
                            return (
                                <>
                                    <div key={uniqueKey}>
                                        {item}
                                    </div>
                                </>
                            );
                        })
                        : null}
                </div>
            </span>
        );
    }
    showInfoVenue = (clientId: string, venueId: string,data:any) => {
        let type = data?.type || 'artist';
        let serviceName = data?.serviceTypeLookUp?.value || '';
        let serviceTypeId = data?.serviceTypeLookUp?.id || '';
        let businessEntityId = data?.clientBusinessEntity?.id
        this.setState({
            venuePopupVisible: true,
            clientId: clientId,
            venueId: venueId,
            serviceId: serviceTypeId,
            businessEntityId: businessEntityId,
            serviceName : serviceName,
            type:type?.toLowerCase()
        });
    };
    venueCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoVenue(cellInfo.data.clientBusinessEntity?.id, cellInfo.data.venue?.id,cellInfo.data);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data?.venue?.venueName}
                </Link>
            </div>
        );
    };


    hideInfoVenue = () => {
        this.setState({
            venuePopupVisible: false,
        });
    };

    onCustomGridLoad = () => {
        return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.queryGrid);
    }

    onCustomGridSave = (gridState: Object) => {
        CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.queryGrid, gridState);
    }

    saveLinkedQueries = () => {
        let selectedRowsData = this.dataGrid.instance.getSelectedRowsData();
        let Ids:any = [];   
        let linkedId:any = [];   
        if(selectedRowsData.length > 0){
            this.setState({
                loadPanelVisible: true,
                isDisabled: false,
            });
            selectedRowsData.forEach((items: any) => {                 
                Ids.push(items.case_ID);
            });
            let queryId = this.props?.queryId ? this.props?.queryId : "";
            linkedId = Ids;            
            this.queryService
                .savelinkedQuery(queryId, linkedId)
                .then((response) => {
                    this.setState({
                        loadPanelVisible: false
                    });
                    this.dataGrid.instance.clearSelection();
                    setTimeout(this.loadDataSource, 2000);
                })
                .catch(() => { throw 'Data Loading Error'; });
        }
    }


    renderManageShiftPopup = () => {
        return (
            <Popup
                // elementAttr={{
                //     class: "pending-request-panel__raise-query-pop-up",
                // }}
                visible={this.state.isVisibleManageShiftPopup}
                onHiding={this.closeManageShiftPopup}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showTitle={true}
                showCloseButton={true}
                title="Related Item"
                defaultWidth="90%"
                defaultHeight="80%"
                resizeEnabled={true}
            >
                <ScrollView width="100%" height="100%">
                    <div>
                        {billableItemId ? <QuickAddShiftForm location={this.props.location} history={this.props.history} billableItemId={billableItemId} navigateFrom={"queryGrid"} hidePopup={this.hidePopup}></QuickAddShiftForm> : null}
                    </div>
                </ScrollView>
            </Popup>
        )
    }

    renderQueryFormPopup = () => {
        return (
            <Popup
                visible={this.state.isVisibleQueryPopup}
                onHiding={this.closeQueryFormPopup}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showTitle={true}
                showCloseButton={true}
                title="Query"
                defaultWidth="90%"
                defaultHeight="80%"
                resizeEnabled={true}
            >
                <ScrollView width="100%" height="100%">
                    <div>
                        {queryFormId ? <QueryManageForm location={this.props.location} history={this.props.history} queryFormId={queryFormId} ></QueryManageForm> : null}
                    </div>
                </ScrollView>
            </Popup>
        )
    }

    onSelectionChanged = (value: DataGridOnSelectionChanged) => {
        if (value.selectedRowsData && value.selectedRowsData.length > 0) {
            this.setState({isDisabled:false});
        }
        else{
            this.setState({isDisabled:true});
        }
    };

    headerOfSelectedCell = () => {
        return <div>SELECT</div>;
    };
    colorStatusForRaisedBy = (cellInfo:any) =>{
        // let val = cellInfo?.data?.data?.queryRaisedBy;
        let raisedByVal = cellInfo?.data?.queryRaisedBy;
        let renderedVal =<div></div>
        if(raisedByVal){
            if(raisedByVal?.toLowerCase() =='client'){
                renderedVal = <div style={{color:'red'}}>{`${raisedByVal}`}</div>
            } else if(raisedByVal?.toLowerCase() =='provider'){
                if(cellInfo?.data?.providerType){
                    renderedVal = this.raisedByCellDisplayContent(cellInfo);
                }
                else {
                     renderedVal = <div style={{color:'blue'}}>{`${raisedByVal}`}</div>
                }
            } else if(raisedByVal?.toLowerCase() =='company'){
                renderedVal = <div style={{color:'black'}}>{`${raisedByVal}`}</div>
            } else {
                renderedVal = <div>{`${raisedByVal}`}</div>
            }
        }
        return renderedVal;
    }

    render() {
        return (
            <>  
                <div className="grid-info__button-container text-center text-lg-left" hidden={this.props?.navigateFrom !== "queryform"}>
                    <button className={"btn btn-primary btn--large col-12 col-sm-6 col-xl-3 mb-3"} onClick={this.saveLinkedQueries} disabled={this.state.isDisabled}>
                        Link Queries
                    </button>
                </div> 
                <LightModePageTemplate>
                    { this.props?.navigateFrom == "queryform" ?    
                            <DataGrid
                                elementAttr={{
                                    id: "gridContainer"
                                }}
                                dataSource={this.state.dataSource}
                                showBorders={false}
                                showColumnLines={false}
                                allowColumnReordering={false}
                                allowColumnResizing={true}
                                columnAutoWidth={true}
                                columnResizingMode={"widget"}
                                hoverStateEnabled={true}
                                remoteOperations={this.remoteOperations}
                                // wordWrapEnabled={true}
                                onSelectionChanged={this.onSelectionChanged}
                                noDataText={"No queries found for the filters used"}
                                ref={(ref) => { this.dataGrid = ref; }}
                                dataRowComponent={QueryGridRenderDataRow}
                                className="datagrid"
                            >
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                <HeaderFilter visible={true} allowSearch={true} />
                                <FilterRow visible={true} applyFilter="auto" />
                                <GroupPanel visible={true} />
                                <FilterPanel visible={true} />
                                <SearchPanel visible={true} placeholder={"Search"} />
                                <Scrolling useNative={true} showScrollbar={"always"} />
                                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always"/>

                                <Column headerCellRender={this.headerOfSelectedCell} type="selection"/>   
                                <Column
                                    caption="RELATED ITEM"
                                    allowExporting={false}
                                />
                                <Column
                                    caption="TAG ME"
                                    cellComponent={TagCell}

                                ></Column>
                                <Column
                                    dataField="taggedUser.name"
                                    caption="TAGGED"
                                />
                                <Column
                                    dataField="caseStatusLU.case_Status_Value"
                                    caption="STATUS"
                                />
                                <Column
                                    dataField="queryTypeLookUp.value"
                                    caption="TYPE"
                                />
                                <Column
                                    dataField="date_Created"
                                    caption="DATE/TIME RAISED"
                                    format="dd/MM/yyyy"
                                    dataType="date"
                                />
                                <Column
                                    dataField="queryRaisedBy"
                                    caption="RAISED BY"
                                    allowFiltering={false}
                                    allowSearch={false}
                                    allowSorting={false}
                                />
                                <Column
                                    dataField="clientBusinessEntity.shortName"
                                    caption="CLIENT"
                                />
                                <Column
                                    caption="VENUE"
                                    dataField="venue.venueName"
                                />
                                <Column 
                                    dataField="venue.onboarded" 
                                    caption="ONBOARDED" 
                                />
                                <Column
                                    caption="SERVICE"
                                    dataField="serviceTypeLookUp.value"
                                />
                                <Column
                                    dataField="query_Title"
                                    caption="TITLE"
                                />
                                <Column
                                    dataField="providerBusinessEntity.shortName"
                                    caption="PROVIDER"
                                />
                                <Column
                                    dataField="providerBusinessEntity.id"
                                    caption="PROVIDER ID"
                                />
                                <Column
                                    dataField="resolution_Reason"
                                    caption="RESOLUTION REASON"
                                />
                                <Column
                                    dataField="internal_Comments"
                                    caption="INTERNAL COMMENTS"
                                />
                                <Column
                                    dataField="raisedByContactName"
                                    caption="RAISED BY CONTACT"
                                />
                                <Column
                                    dataField="raisedByContactTelNumber"
                                    caption="CONTACT NO"
                                />
                                <Column
                                    dataField="LastUpdated_DateTime"
                                    caption="LAST UPDATED"
                                    format="dd/MM/yyyy"
                                    dataType="date" />

                                <Column
                                    dataField="user.name"
                                    caption="UPDATED BY"
                                />
                                <Column
                                    dataField="case_ID"
                                    caption="QUERY ID"
                                />
                                <Column
                                    dataField="linkedCaseID"
                                    caption="LINKED CASE ID"
                                />
                            </DataGrid>
                        : <DataGrid
                            elementAttr={{
                                id: "gridContainer"
                            }}
                            dataSource={this.state.dataSource}
                            showBorders={false}
                            showColumnLines={false}
                            allowColumnReordering={false}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            columnResizingMode={"widget"}
                            hoverStateEnabled={true}
                            remoteOperations={this.remoteOperations}
                            noDataText={"No queries found for the filters used"}
                            dataRowRender={this.renderDataRow}
                            ref={(ref) => { this.otherdataGrid = ref; }}
                            className="datagrid"
                        >
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <HeaderFilter visible={true} allowSearch={true} />
                            <Export enabled={true} /> 
                            <FilterRow visible={true} applyFilter="auto" />
                            <GroupPanel visible={true} />
                            <FilterPanel visible={true} />
                            <StateStoring
                                enabled={true}
                                type="custom"
                                customLoad={this.onCustomGridLoad}
                                customSave={this.onCustomGridSave}
                            />
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Scrolling useNative={true} showScrollbar={"always"} />
                            <Column
                                caption="CHANGE"
                                allowExporting={false}
                            />
                            <Column
                                caption="VIEW"
                                allowExporting={false}
                            />                             
                            <Column
                                caption="RESOLVE?"
                                allowExporting={false}
                            />
                            <Column
                                caption="RELATED ITEM"
                                allowExporting={false}
                            />
                            <Column
                                caption="TAG ME"
                                cellComponent={TagCell}

                            ></Column>
                            <Column
                                dataField="taggedUser.name"
                                caption="TAGGED"
                            />
                            <Column
                                dataField="caseStatusLU.case_Status_Value"
                                caption="STATUS"
                            />
                            <Column
                                dataField="queryTypeLookUp.value"
                                caption="TYPE"
                            />
                            <Column
                                dataField="billableItem.serviceSubTypeLookUp.value"
                                caption="SUB TYPE"
                            />
                            <Column
                                dataField="billableItem.dateFrom"
                                caption="DATE FROM"
                                // calculateSortValue={(rowData: any) => {
                                //     return this.sharedUtils.convertTDateFormatToFormatedDate(rowData.billableItem?.dateFrom);
                                // }}
                                // calculateCellValue={(rowData: any) => {
                                //     return this.sharedUtils.convertTDateFormatToFormatedDate(rowData.billableItem?.dateFrom);
                                // }}
                                format="dd/MM/yyyy"
                                dataType="date"
                            />
                            <Column
                                dataField="billableItem.name"
                                caption="NAME"
                            />
                            <Column
                                dataField="date_Created"
                                caption="DATE/TIME RAISED"
                                format="dd/MM/yyyy"
                                dataType="date"
                            />
                            <Column
                                dataField="queryRaisedBy"
                                caption="RAISED BY"
                                allowFiltering={false}
                                allowSearch={false}
                                allowSorting={false}
                            />
                            <Column
                                dataField="clientBusinessEntity.shortName"
                                caption="CLIENT"
                            />
                            <Column
                                dataField="clientServices"
                                caption="CLIENT SERVICES"
                                allowFiltering={false}
                                allowSearch={false}
                                allowSorting={false}
                                //cellRender={this.clientServiceDisplay}
                            />
                            <Column
                                caption="VENUE"
                                dataField="venue.venueName"
                            />
                            <Column 
                                dataField="venue.onboarded" 
                                caption="ONBOARDED" 
                            />
                            <Column
                                caption="SERVICE"
                                dataField="serviceTypeLookUp.value"
                            />
                            <Column
                                dataField="query_Title"
                                caption="TITLE"
                            />
                            <Column
                                dataField="providerBusinessEntity.shortName"
                                caption="PROVIDER"
                            />
                            <Column
                                dataField="providerBusinessEntity.id"
                                caption="PROVIDER ID"
                            />
                            <Column
                                dataField="providerServices"
                                caption="PROVIDER SERVICES"
                                allowFiltering={false}
                                allowSearch={false}
                                allowSorting={false}
                                //cellRender={this.providerServiceDisplay}
                            />
                            <Column
                                dataField="resolution_Reason"
                                caption="RESOLUTION REASON"
                            />
                            <Column
                                dataField="internal_Comments"
                                caption="INTERNAL COMMENTS"
                            />
                            <Column
                                dataField="raisedByContactName"
                                caption="RAISED BY CONTACT"
                            />
                            <Column
                                dataField="raisedByContactTelNumber"
                                caption="CONTACT NO"
                            />
                            <Column
                                dataField="LastUpdated_DateTime"
                                caption="LAST UPDATED"
                                format="dd/MM/yyyy"
                                dataType="date" />

                            <Column
                                dataField="user.name"
                                caption="UPDATED BY"
                            />
                            <Column
                                dataField="case_ID"
                                caption="QUERY ID"
                            />
                            <Column
                                dataField="linkedCaseID"
                                caption="LINKED CASE ID"
                            />
                        </DataGrid>
                    }
                </LightModePageTemplate>
                <Popup
                    visible={this.state.titlePopupVisible}
                    onHiding={this.hideInfoTitle}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"TITLE"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.title}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.internalCommentsPopupVisible}
                    onHiding={this.hideInfoInternalComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"INTERNAL COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.internalComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.resolutionReasonPopUpVisible}
                    onHiding={this.hideInfoResolutionReason}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"RESOLUTION REASON"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.resolutionReason}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.onQueryRaising}
                    onHiding={this.closeQueryBox}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Resolve Query?"
                    maxWidth="500px"
                    height={350}
                >
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="pending-request-panel__raise-query">
                        <div className="pending-request-panel__query-box">
                            <TextArea
                                height="100%"
                                placeholder="Please enter a resolution reason......"
                                onValueChanged={this.handleChangeQueryComments}
                                value={this.state.resolveQueryItem.resolutionReason}
                            />

                            <button className="btn btn-primary btn--full-width"
                                onClick={this.onQuerySubmission}
                            >
                                Resolve
                            </button>
                        </div>
                    </div>
                </Popup>
                <Popup
                    visible={this.state.providerPopupVisibleOther}
                    onHiding={this.hideInfoProvider}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Supplier Information & Venue Conditions"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <SupplierInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            serviceTypeId={this.state.serviceId}
                            navigationFromShift="SimpleShiftGrid"
                        ></SupplierInformationMainContent>
                    </ScrollView>
                </Popup>
                {
                    this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title={this.getTitleForPopup()}
                            //title={this.state.serviceTypeId == "30" ? "Artist Details" : "Provider Details"}
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <ArtistProfileComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    businessEntityId={this.state.businessEntityId}
                                    handleFeedbackUpdate = {()=>{return null}}
                                    handleAddArtist = {()=>{return null}}
                                    // serviceType={"Entertainment"}
                                    serviceType={this.getServiceType()}
                                ></ArtistProfileComponent>
                            </ScrollView>
                        </Popup>
                    ):(null)
                }
                {this.renderManageShiftPopup()}
                <Popup
                    visible={this.state.venuePopupVisible}
                    onHiding={this.hideInfoVenue}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Client Information & Schedule"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <ClientInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            isShowByVenue={true}
                            serviceTypeId={this.state.serviceId}
                        ></ClientInformationMainContent>
                    </ScrollView>
                </Popup>
            </>

        );
    }
}

export default withTranslation()(QueryGrid);