import React, { Component, MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import DataGrid, { Paging, HeaderFilter, FilterRow, Column, Export, FilterPanel, GroupPanel, Pager, SearchPanel, StateStoring, Scrolling} from "devextreme-react/data-grid";
import UserService, { RoleGroupNames } from "../../services/UserService";
import GigRequestChangeCell from "./GigRequestChangeCell";
import { Popup, ScrollView } from "devextreme-react";
import { Format } from "devextreme-react/bar-gauge";
import { Link } from "react-router-dom";
import GigRequestArtistConfirmedCell from "./GigRequestArtistConfirmedCell";
import GigRequestVenueConfirmedCell from "./GigRequestVenueConfirmedCell";
import TagCell from "./TagCell";
import ClientInformationMainContent from "../ClientInformation/ClientInformationMainContent";
import { serviceTypes } from "../../services/FileService";
import GigRequestOptionsSentCell from "./GigRequestOptionsSentCell";
import SupplierInformationMainContent from "../SupplierInformation/SupplierInformationMainContent";
import { RequestFormData } from "../FindAnArtist/FindAnArtistUtils";
import sharedUtils from "../grid/sharedUtilities";
import moment from "moment";

let storageVal: string | null = "";

interface SecurityRequestGridProps extends WithTranslation {
    dataSource: RequestFormData[],
    datagridIns: any,
    getRequestGridStatusUpdate: (flag: boolean) => void;
}

interface SecurityRequestGridState {
    venuePopupVisible?: boolean;
    clientId: string;
    venueId: string;
    internalComments: string;
    serviceTypeId: string;
    providerPopupVisibleOther?: boolean;
    businessEntityId: string;
}

class SecurityRequestGrid extends Component<SecurityRequestGridProps> {
    state: SecurityRequestGridState;
    remoteOperations: {
        sorting: boolean,
        paging: boolean,
        filtering: boolean,
        grouping: boolean,
        summary: boolean
    }
    sharedUtils: sharedUtils;
    constructor(props: SecurityRequestGridProps) {
        super(props);
        this.state = {
            venuePopupVisible: false,
            clientId: "",
            venueId: "",
            internalComments: "",
            serviceTypeId: "",
            providerPopupVisibleOther: false,
            businessEntityId: "",
        }
        this.remoteOperations = {
            sorting: true,
            paging: true,
            filtering: true,
            grouping: true,
            summary: false
        }
        this.sharedUtils = new sharedUtils();
    }

    venueCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoVenue(cellInfo.data.clientBusinessEntity.id, cellInfo.data.venue.id);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.venue.venueName}
                </Link>
            </div>
        );
    };

    onboardedCellDisplayContent = (cellInfo: any) => {
        let onboardedVal = cellInfo.data.data.venue?.onboarded;
        let isShowInColour: boolean = false;
        let valToShow: string = "";
        if (onboardedVal) {
            isShowInColour = true;
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{valToShow}</span> : valToShow}
            </div>
        );

    }

    showInfoVenue = (clientId: string, venueId: string) => {
        this.setState({
            venuePopupVisible: true,
            clientId: clientId,
            venueId: venueId,
        });
    };
    hideInfoVenue = () => {
        this.setState({
            venuePopupVisible: false,
        });
    };

    onSecurityOptionChanged = () => {
        storageVal = localStorage.getItem('storageSecurityReqGrid');
        if (storageVal) {
            this.setState({
                storageVal: true
            });
        }
    }

    hideInfoProvider = (e: any) => {
        this.setState({
            providerPopupVisibleOther: false,
        });
    };

    showInfoProvider = (clientId: string, venueId: string, serviceTypeId: string, businessEntityId: string) => {
        this.setState({
            clientId: clientId,
            venueId: venueId,
            serviceTypeId: serviceTypeId,
            businessEntityId: businessEntityId,

        });
        this.setState({
            providerPopupVisibleOther: true,
        });
    };

    providerCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.clientBusinessEntity.id, cellInfo.data.venue.id, cellInfo.data.serviceTypeLookUp.id, cellInfo.data.providerBusinessEntity?.id);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.providerBusinessEntity?.shortName}
                </Link>
            </div>
        );
    };

    featureHeaderFilter = (options: any) => {
        options.dataSource.postProcess = (results: any) => {
            results.forEach((item: any) => {
                item.text = item.value ? "Yes" : "No"
            });
            return results;
        };
    }

    reoccurrenceCellDisplayContent = (cellInfo: any) => {
        let reoccurrenceVal = cellInfo.data.data.reoccurrence;
        let isShowInColour: boolean = false;
        let valToShow: string = "";
        if (reoccurrenceVal) {
            isShowInColour = true;
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{valToShow}</span> : valToShow}
            </div>
        );

    }

    getRequestGridStatusUpdate = (flag: boolean) => {
        if(flag == true){
            this.props.getRequestGridStatusUpdate(true);
        }
    };


    gigRequestOptionsSent = (cellInfo: any) => {
        return(
            <div>
                <GigRequestOptionsSentCell data={cellInfo} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate} i18n={cellInfo} tReady={false} t={cellInfo}/>
            </div>        
        );
    }

    gigRequestArtistConfirmed = (cellInfo: any) => {
        return(
            <div>
                <GigRequestArtistConfirmedCell data={cellInfo} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate} i18n={cellInfo} tReady={false} t={cellInfo}/>
            </div>        
        );
    }

    gigRequestVenueConfirmed = (cellInfo: any) => {
        return(
            <div>
                <GigRequestVenueConfirmedCell data={cellInfo} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate} i18n={cellInfo} tReady={false} t={cellInfo}/>
            </div>        
        );
    }
    dateFromDisplayContent = (cellInfo: any) => {
        let dateFrom = cellInfo.data?.dateFrom;

        let dateString = new Date(dateFrom).toDateString()
        return (
            <div>
                {!this.sharedUtils.isDefaultDate(dateString) ? moment(dateString).format("DD/MM/YYYY"): ''}
            </div>
        );

    }

    render() {
        var isEventUk = UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        return (
            <div>
                <DataGrid
                    elementAttr={{
                        id: "gridContainer"
                    }}
                    ref={this.props.datagridIns}
                    dataSource={this.props.dataSource}
                    showBorders={false}
                    showColumnLines={false}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnResizingMode={"widget"}
                    hoverStateEnabled={true}
                    wordWrapEnabled={false}
                    remoteOperations={this.remoteOperations}
                    onContentReady={this.onSecurityOptionChanged}
                    noDataText={"No requests found for the filters used"}
                    columnMinWidth={150}
                >
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Scrolling useNative={true} showScrollbar={"always"} />
                    <Export enabled={true} />
                    <FilterRow visible={true} applyFilter="auto" />
                    <GroupPanel visible={true} />
                    <FilterPanel visible={true} />
                    <SearchPanel visible={true} placeholder={"Search"} />
                    <StateStoring
                        enabled={true}
                        type="localStorage" storageKey="storageSecurityReqGrid"
                    />
                    <Column
                        caption="CHANGE"
                        cellComponent={GigRequestChangeCell}
                        allowExporting={false}
                        allowSearch={false}
                    />
                    <Column
                        caption="TAG?"
                        cellComponent={TagCell}

                    ></Column>
                    <Column
                        dataField="taggedUser.name"
                        caption="TAGGED TO"
                        dataType="string"
                    />
                    <Column
                        dataField="requestStatus.value"
                        caption="STATUS"
                        dataType="string"
                    />
                    <Column
                        dataField="venue.venueName"
                        caption="VENUE"
                        cellRender={this.venueCellDisplayContent}
                        dataType="string"
                    />
                    <Column
                        dataField="venue.houseNumber"
                        caption="VENUE NO"
                        dataType="string"
                    />
                    <Column 
                        dataField="venue.onboarded" 
                        caption="ONBOARDED" 
                        cellComponent={this.onboardedCellDisplayContent}
                        dataType="boolean"
                    />
                    <Column
                        dataField="clientBusinessEntity.shortName"
                        caption="CLIENT"
                        dataType="string"
                    />
                    <Column
                        dataField="optionsSent"
                        caption="OPTIONS SENT"
                        cellRender={this.gigRequestOptionsSent} 
                        dataType="boolean"
                        allowExporting={false}
                        allowSearch={false}
                    />
                    <Column
                        dataField="artistConfirm"
                        caption="PROVIDER CONFIRMED"
                        cellRender={this.gigRequestArtistConfirmed}
                        dataType="boolean"
                        allowExporting={false}
                        allowSearch={false}
                    />
                    <Column
                        dataField="venueConfirm"
                        caption="VENUE CONFIRMED"
                        cellRender={this.gigRequestVenueConfirmed}
                        dataType="boolean"
                        allowExporting={false}
                        allowSearch={false}
                    />
                    <Column
                        caption="SUB TYPE"
                        dataField="serviceSubTypeLookUp.value"
                        dataType="string"
                    /> 
                    <Column
                        dataField="typeLookUp.value"
                        caption="TYPE"
                        dataType="string"
                    />
                    <Column
                        dataField="dateFrom"
                        caption="WORK DATE"
                        //format="dd/MM/yyyy"
                        dataType="date"
                        cellRender={this.dateFromDisplayContent}
                    />
                    <Column
                        dataField="sumOfScheduledMinutes"
                        caption="SCHEDULED HOURS"
                        dataType="string"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />    
                    <Column
                        dataField="requestDate"
                        caption="REQUEST DATE"
                        format="dd/MM/yyyy"
                        dataType="date"
                    /> 
                    <Column
                        dataField="bookedDate"
                        caption="Booked DATE"
                        format="dd/MM/yyyy"
                        dataType="date"
                    />
                    {isEventUk ?
                        <Column
                            dataField="internalComments"
                            caption="INTERNAL COMMENTS"
                            dataType="string"
                        /> : null}
                    <Column
                        dataField="reoccurrence"
                        caption="RECURRING"
                        cellComponent={this.reoccurrenceCellDisplayContent}
                        dataType="boolean"
                    >
                    <HeaderFilter
                        dataSource={this.featureHeaderFilter}
                    />
                    </Column>
                    <Column
                        dataField="providerBusinessEntity.shortName"
                        caption="PROVIDER"
                        dataType="string"
                        cellRender={this.providerCellDisplayContent}
                    />
                    <Column
                        dataField="providerBusinessEntity.id"
                        caption="PROVIDER ID"
                        dataType="string"
                    />   
                    <Column dataField='id' caption='ID'/>                    
                </DataGrid>
                <Popup
                    visible={this.state.venuePopupVisible}
                    onHiding={this.hideInfoVenue}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"Door Supervision - Client Information & Schedule"}
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <ClientInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            isShowByVenue={true}
                            serviceTypeId={serviceTypes.security}
                        ></ClientInformationMainContent>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.providerPopupVisibleOther}
                    onHiding={this.hideInfoProvider}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Supplier Information & Venue Conditions"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <SupplierInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            serviceTypeId={this.state.serviceTypeId}
                            navigationFromShift="SimpleShiftGrid"
                        ></SupplierInformationMainContent>
                    </ScrollView>
                </Popup>
            </div>
        );
    }
}

export default withTranslation()(SecurityRequestGrid);