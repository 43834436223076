import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Selection,
    Editing,
    Texts,
    Export,
    StateStoring,
    SearchPanel,
} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import SimpleGridService, {
    SimpleGridServiceRowItem,
    PeriodGridTypeParams,
    GridTypes,
    filterTypes,
} from "../../services/SimpleGridService";
import AllUserGridStatus from "../grid/AllUserGridStatus";
import AllUserGridService from "../grid/AllUserGridService";
import AllUserGridBillableHrs from "../grid/AllUserGridBillableHrs";
import CheckInOutCell from "./ManageViewShiftGridRowCell/CheckInOutCellComponent";
import AllUserGridShift from "./ManageViewShiftGridRowCell/AllUserGridShiftCellComponent";
import gridUtils, {
    currencyTypeToSymbolMatrix,
    currencyTypes,
} from "./GridUtilities";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import CostCell from "./ManageViewShiftGridRowCell/CostCellComponent";
import AllUserGridStatusForGigList from "../grid/AllUserGridStatusForGigList";
import UserService, { RoleGroupNames } from "../../services/UserService";
import sharedUtils from "./sharedUtilities";
import ManageShiftGlobalFilterService, { gridFilter } from "../../services/ManageShiftGlobalFilterService";
import MaskedStatusForGig from "./MaskedStatusForGig";
import { ServiceType } from "../../common/ServiceUtilities";
//props
interface SimpleGridProps {
    setParentButtonStatesSignal: (isButtonStateChanged: boolean) => void;
    setSummary: (SummaryObject: any) => void;
    setIndicatorStates: (isGridLoadSuccess: boolean) => void;
    setGigCount: (totalCount: number | undefined) => void;
    refreshSignal?: boolean;
    filterText?: string;
    billableItemId?: string;
    clientId?: any;
    venueId?: any;
    shiftStart?: any;
    shiftEnd?: any;
    isSchedulePage?: boolean;
    filterId?: string;
    periodGridTypeParams?: PeriodGridTypeParams;
    location?: any;
    history?: any;
    gridType?: string;
    signalForArrowClick?: string;
    paymentId: string;
    serviceTypeValue?: string;
}

// State
interface SimpleGridState {
    gridDataSource: SimpleGridServiceRowItem[];
    showProgressIndicator: boolean;
    noDataDisplay: boolean;
    SummaryObject: {
        billableHours: string;
        billableCost: string;
        equipmentCost: string;
        total: string;
        service: string;
    };
    hideColForGig: boolean;
    loadIndicatorOnCalendar: boolean;
    hideColForArtist: boolean;
    serviceTypeValue: string;
    globalFilterValues: gridFilter;
}

// Component - displays the simple Grid for all users
class SimpleGrid extends React.Component<SimpleGridProps> {
    state: SimpleGridState;
    Service: SimpleGridService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    constructor(props: SimpleGridProps) {
        super(props);
        // Initialize state and services/utils
        this.Service = new SimpleGridService();
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            gridDataSource: [],
            showProgressIndicator: false,
            noDataDisplay: true,
            SummaryObject: {
                billableHours: "",
                billableCost: "",
                equipmentCost: "",
                total: "",
                service: "",
            },
            hideColForGig: this.props.gridType && this.props.gridType == GridTypes.Gig ? true : false,
            hideColForArtist: this.props.gridType && this.props.gridType == GridTypes.Artist ? true : false,
            loadIndicatorOnCalendar: true,
            serviceTypeValue: "",
            globalFilterValues: {
                serviceType: "",
                dateFrom: undefined,
                dateTo: undefined,
            },
        };
        // Functions
        this.updateGridDataSource = this.updateGridDataSource.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.onCustomGridLoad = this.onCustomGridLoad.bind(this);
        this.onCustomGridSave = this.onCustomGridSave.bind(this);
    }

    componentDidMount() {
        if (this.props.filterId || this.props.gridType == GridTypes.Gig || this.props.gridType == GridTypes.Artist) {
            this.updateGridDataSource();
        }
    }

    fetchFilterValues = () => {
        var filterValuesFromGlobalService: gridFilter;
        filterValuesFromGlobalService = ManageShiftGlobalFilterService.getFilterValues();
        this.setState({
            globalFilterValues: filterValuesFromGlobalService,
        });
        return filterValuesFromGlobalService;
    };

    serviceTypeValueSet = () => {
        var filterValues = this.fetchFilterValues();
        var serviceType = filterValues.serviceType;
        this.setState({
            serviceTypeValue: serviceType,
        });
    };

    filterDataSourceBy = (filterType: string) => {
        let filteredGrid = this.state.gridDataSource.filter((item) => {
            return item.service.includes(filterType);
        });
        return filteredGrid;
    };

    summaryCalculations = () => {
        let billHours = 0.0;
        let billCost = 0.0;
        let equipCost = 0.0;
        let Total = 0.0;
        let dataForSecurity = this.filterDataSourceBy(filterTypes.security);
        let dataForCleaning = this.filterDataSourceBy(filterTypes.cleaning);
        let dataForEntertainment = this.filterDataSourceBy(filterTypes.entertainment);
        if (
            (dataForSecurity.length > 0 && dataForCleaning.length > 0 && dataForCleaning.length > 0) ||
            (dataForSecurity.length > 0 && dataForCleaning.length > 0) ||
            (dataForSecurity.length > 0 && dataForEntertainment.length > 0) ||
            (dataForCleaning.length > 0 && dataForEntertainment.length > 0)
        ) {
            let totalPay = this.state.gridDataSource.map((item) => item.provider_Pay_Amount_Local_Ccy);
            totalPay.forEach((rowElement) => {
                Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
            });
            this.setState(
                {
                    SummaryObject: {
                        ...this.state.SummaryObject,
                        billableHours: "",
                        billableCost: "",
                        equipmentCost: "",
                        total: Total.toString(),
                        service: "All",
                    },
                },
                () => this.props.setSummary(this.state.SummaryObject)
            );
        } else {
            if (dataForSecurity.length > 0) {
                let dataPersonel = dataForSecurity.filter((item) => {
                    return item.service.includes("Personnel");
                });
                let dataEquipment = dataForSecurity.filter((item) => {
                    return item.service.includes("Equipment");
                });
                let hours = dataForSecurity.map((item) => item.billable_Minutes);
                let cost = dataPersonel.map((item) => item.provider_Pay_Amount_Local_Ccy);
                let equipment = dataEquipment.map((item) => item.provider_Pay_Amount_Local_Ccy);
                let totalPay = dataForSecurity.map((item) => item.provider_Pay_Amount_Local_Ccy);

                hours.forEach((rowElement) => {
                    billHours = billHours + this.gridUtils.convertDecimalAsStringToFloat(rowElement);
                });
                billHours = billHours / 60;
                cost.forEach((rowElement) => {
                    billCost = billCost + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                equipment.forEach((rowElement) => {
                    equipCost = equipCost + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                totalPay.forEach((rowElement) => {
                    Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                this.setState(
                    {
                        SummaryObject: {
                            ...this.state.SummaryObject,
                            billableHours: billHours.toString(),
                            billableCost: billCost.toString(),
                            equipmentCost: equipCost.toString(),
                            total: Total.toString(),
                            service: "Security",
                        },
                    },
                    () => this.props.setSummary(this.state.SummaryObject)
                );
            } else if (dataForCleaning.length > 0) {
                let totalPay = dataForCleaning.map((item) => item.provider_Pay_Amount_Local_Ccy);
                totalPay.forEach((rowElement) => {
                    Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                this.setState(
                    {
                        SummaryObject: {
                            ...this.state.SummaryObject,
                            billableHours: "",
                            billableCost: "",
                            equipmentCost: "",
                            total: Total.toString(),
                            service: "Cleaning",
                        },
                    },
                    () => this.props.setSummary(this.state.SummaryObject)
                );
            } else if (dataForEntertainment.length > 0) {
                let totalPay = dataForEntertainment.map((item) => item.provider_Pay_Amount_Local_Ccy);
                totalPay.forEach((rowElement) => {
                    Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                this.setState(
                    {
                        SummaryObject: {
                            ...this.state.SummaryObject,
                            billableHours: "",
                            billableCost: "",
                            equipmentCost: "",
                            total: Total.toString(),
                            service: "Entertainment",
                        },
                    },
                    () => this.props.setSummary(this.state.SummaryObject)
                );
            }
        }
    };

    isBlank(billableItemId: string | undefined) {
        return !billableItemId || /^\s*$/.test(billableItemId) || 0 === billableItemId.length;
    }

    updateGridDataSource() {
        var { billableItemId, filterId } = this.props;
        var filterbyId: string = "";
        filterbyId = billableItemId ? billableItemId : filterId ? filterId : "";
        var filterValues = this.fetchFilterValues();
        var dateFrom = this.sharedUtils.convertDateToString(filterValues.dateFrom);
        var dateTo = this.sharedUtils.convertDateToString(filterValues.dateTo);
        this.setState({
            showProgressIndicator: true,
        });
        // const { dateFromText, dateToText } = this.props;
        this.props.setIndicatorStates(true);
        if (!this.props.paymentId) {
            this.setState({
                gridDataSource: null,
            });
        }
        if (this.props.gridType == GridTypes.SimpleGrid) {
            if (this.props.isSchedulePage) {
                this.Service.getManageShiftGrid(
                    this.props.clientId,
                    this.props.venueId,
                    this.props.shiftStart,
                    this.props.shiftEnd
                )
                    .then(this.handleSuccess)
                    .catch(this.handleError);
                this.setState({
                    noDataDisplay: false,
                });
            } else if (!this.isBlank(filterbyId)) {
                this.Service.getManageShiftGridByBillableId(filterbyId)
                    .then(this.handleSuccess)
                    .catch(this.handleError);
                this.setState({
                    noDataDisplay: false,
                });
            } else if (this.props.periodGridTypeParams && this.props.periodGridTypeParams.serviceTypeId.length > 0) {
                if (this.props.periodGridTypeParams.periodWeek.length > 0) {
                    this.Service.getManageShiftGridByPeriodNumberAndWeek(
                        this.props.clientId,
                        this.props.venueId,
                        this.props.periodGridTypeParams.serviceTypeId,
                        this.props.periodGridTypeParams.periodNumber,
                        this.props.periodGridTypeParams.periodWeek
                    )
                        .then(this.handleSuccess)
                        .catch(this.handleError);
                } else {
                    this.Service.getManageShiftGridByPeriodNumber(
                        this.props.clientId,
                        this.props.venueId,
                        this.props.periodGridTypeParams.serviceTypeId,
                        this.props.periodGridTypeParams.periodNumber
                    )
                        .then(this.handleSuccess)
                        .catch(this.handleError);
                }
            } else if (this.props.filterText != "" || dateFrom || dateTo) {
                this.Service.getSimpleGridRowsWithinDateRange(dateFrom, dateTo).then(this.handleSuccess).catch(this.handleError);
                this.setState({
                    noDataDisplay: false,
                });
            } else {
                this.setState({
                    showProgressIndicator: false,
                });
            }
        }
        if (!this.isBlank(this.props.gridType) && this.props.gridType == GridTypes.Gig) {
            this.Service.getMyGigList().then(this.handleSuccess).catch(this.handleError);
        } else if (
            !this.isBlank(this.props.gridType) &&
            this.props.gridType == GridTypes.Artist &&
            this.props.paymentId
        ) {
            this.Service.getSimpleGridRowsbyPaymentId(this.props.paymentId)
                .then(this.handleSuccess)
                .catch(this.handleError);
        } else {
            this.setState({
                showProgressIndicator: false,
            });
        }
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.serviceTypeValueSet();
        // when there will be no content in the response the code will be 204
        if (response.status == 204) {
            if (this.props.signalForArrowClick == "YES") {
                this.setState(
                    {
                        loadIndicatorOnCalendar: false,
                    },
                    () => {
                        this.indicatorStateSet();
                    }
                );
            }
            this.setState(
                {
                    gridDataSource: [],
                    showProgressIndicator: false,
                    SummaryObject: {
                        ...this.state.SummaryObject,
                        billableHours: "",
                        billableCost: "",
                        equipmentCost: "",
                        total: "",
                        service: "",
                    },
                },
                () => this.props.setSummary(this.state.SummaryObject)
            );
        } else {
            this.setState({
                gridDataSource: response.data.data.eventUkUserGridLookUp,
                showProgressIndicator: false,
            });
            if (this.state.serviceTypeValue) {
                let dataGridForDifferentService = this.state.gridDataSource.filter((item) => {
                    return item.serviceTypeId == this.state.serviceTypeValue;
                });
                this.setState({
                    gridDataSource: dataGridForDifferentService,
                    showProgressIndicator: false,
                });
                if (dataGridForDifferentService.length == 0) {
                    this.setState(
                        {
                            SummaryObject: {
                                ...this.state.SummaryObject,
                                billableHours: "",
                                billableCost: "",
                                equipmentCost: "",
                                total: "",
                                service: "",
                            },
                        },
                        () => this.props.setSummary(this.state.SummaryObject)
                    );
                }
            }
            this.summaryCalculations();
            if (response && response.data && response.data.data && response.data.data.eventUkUserGridLookUp) {
                this.props.setGigCount(response.data.data.eventUkUserGridLookUp.length);
            }
        }
        if (this.props.signalForArrowClick == "YES") {
            this.setState(
                {
                    loadIndicatorOnCalendar: false,
                },
                () => {
                    this.indicatorStateSet();
                }
            );
        }
    }
    indicatorStateSet = () => {
        this.props.setIndicatorStates(this.state.loadIndicatorOnCalendar);
    };
    handleError(error: AxiosResponse<any>) {
        if (this.props.signalForArrowClick == "YES") {
            this.setState({
                loadIndicatorOnCalendar: false,
            });
        }
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "Manage Shift Grid data load failed with response: " + JSON.stringify(error);

        if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.props.setIndicatorStates(this.state.loadIndicatorOnCalendar);
    }
    // Update state from server
    componentDidUpdate(prevProps: SimpleGridProps) {
        if (
            this.props.refreshSignal !== prevProps.refreshSignal ||
            this.props.filterText != prevProps.filterText ||
            this.props.paymentId != prevProps.paymentId
        ) {
            // if (this.props.serviceTypeValue != prevProps.serviceTypeValue) {
            //   this.setStateForserviceTypeValue(this.props.serviceTypeValue);
            // }
            // refresh the grid
            this.updateGridDataSource();
        } else {
            var isUpdate = ManageShiftGlobalFilterService.IsUpdateRequired(this.state.globalFilterValues);
            if (isUpdate) {
                this.updateGridDataSource();
            }
        }
    }

    setStateForserviceTypeValue = (serviceTypeValue: string | undefined) => {
        this.setState({
            serviceTypeValue: serviceTypeValue,
        });
    };

    onCustomGridLoad() {
        return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid);
    }

    onCustomGridSave(gridState: Object) {
        CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid, gridState);
    }

    render() {
        var isShowPayDate = false;
        var isShowBillDate = false;
        if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            isShowBillDate = true;
        }
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            isShowPayDate = true;
        }
        var selectionMode = this.state.hideColForGig || this.props.gridType == GridTypes.Artist ? "None" : "multiple";
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <StateStoring
                            enabled={true}
                            type="custom"
                            customLoad={this.onCustomGridLoad}
                            customSave={this.onCustomGridSave}
                            savingTimeout='0'
                        />

                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Selection mode={selectionMode} />
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging defaultPageSize={5} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column
                            dataField="status"
                            caption="STATUS"
                            visible={!this.state.hideColForGig && !this.state.hideColForArtist}
                            cellComponent={AllUserGridStatus}
                        />
                        <Column
                            dataField="status"
                            caption="STATUS"
                            visible={this.state.hideColForGig || this.state.hideColForArtist}
                            cellComponent={
                                this.props.gridType == GridTypes.Gig ? MaskedStatusForGig : AllUserGridStatusForGigList
                            }
                        />

                        <Column
                            dataField="date"
                            caption="DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                        />
                        <Column dataField="client" caption="CLIENT" />
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? (
                            <Column dataField="provider" caption="ARTIST NAME/SERVICE" />
                        ) : this.state.serviceTypeValue == ServiceType.Security ||
                          this.state.serviceTypeValue == ServiceType.ContractCleaning ? (
                            <Column dataField="provider" caption="PROVIDER" />
                        ) : (
                            <Column dataField="provider" caption="PROVIDER/ARTIST" />
                        )}
                        <Column dataField="venue" caption="VENUE" />
                        <Column dataField="service" caption="SERVICE" cellComponent={AllUserGridService} />
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? (
                            <Column dataField="role" caption="TYPE" visible={!this.state.hideColForGig} />
                        ) : this.state.serviceTypeValue == ServiceType.ContractCleaning ? (
                            <Column dataField="role" caption="DESCRIPTION" visible={!this.state.hideColForGig} />
                        ) : (
                            <Column dataField="role" caption="ROLE/TYPE" visible={!this.state.hideColForGig} />
                        )}
                        {this.state.serviceTypeValue == ServiceType.Entertainment ||
                        this.state.serviceTypeValue == ServiceType.ContractCleaning ? null : (
                            <Column dataField="name" caption="NAME" visible={!this.state.hideColForGig} />
                        )}
                        {this.state.serviceTypeValue == ServiceType.ContractCleaning ||
                        this.state.serviceTypeValue == ServiceType.Entertainment ? null : (
                            <Column
                                dataField="identification_Number"
                                caption="IDENTIFICATION NO."
                                visible={!this.state.hideColForGig}
                            />
                        )}
                        {this.state.serviceTypeValue == ServiceType.ContractCleaning ||
                        this.state.serviceTypeValue == ServiceType.Security ||
                        this.state.serviceTypeValue == ServiceType.Entertainment ||
                        this.state.hideColForGig ? null : (
                            <Column dataField="cover" caption="COVER" />
                        )}
                        {this.state.serviceTypeValue == ServiceType.Entertainment ||
                        this.state.serviceTypeValue == ServiceType.ContractCleaning ? null : (
                            <Column
                                dataField="check_In_Out"
                                caption="CHECK IN & OUT"
                                visible={!this.state.hideColForGig}
                                cellComponent={CheckInOutCell}
                            />
                        )}
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? (
                            <Column dataField="performanceInformationSetsDuration" caption="PERFORMANCE TIME" />
                        ) : null}
                        {this.state.serviceTypeValue == ServiceType.ContractCleaning ||
                        this.state.serviceTypeValue == ServiceType.Entertainment ? null : (
                            <Column dataField="shift" caption="SHIFT" cellComponent={AllUserGridShift} />
                        )}
                        {this.state.serviceTypeValue == ServiceType.ContractCleaning ||
                        this.state.serviceTypeValue == ServiceType.Security ? null : (
                            <Column dataField="performanceInformation" caption="PERFORMANCE DETAILS" />
                        )}
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? null : (
                            <Column
                                dataField="billable_Hours_Units"
                                caption="BILLABLE HOURS/UNITS"
                                visible={!this.state.hideColForGig}
                                cellComponent={AllUserGridBillableHrs}
                            />
                        )}
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? (
                            <Column
                                dataField="rate"
                                caption="FEE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate_Decimal);
                                }}
                                calculateDisplayValue={(rowData: any) => {
                                    return this.sharedUtils.thousandsSeparator(rowData.rate_Decimal);
                                }}
                            />
                        ) : this.state.serviceTypeValue == ServiceType.Security ||
                          this.state.serviceTypeValue == ServiceType.ContractCleaning ? (
                            <Column
                                dataField="rate"
                                caption="RATE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate_Decimal);
                                }}
                                calculateDisplayValue={(rowData: any) => {
                                    return this.sharedUtils.thousandsSeparator(rowData.rate_Decimal);
                                }}
                            />
                        ) : (
                            <Column
                                dataField="rate_Decimal"
                                caption="RATE/FEE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate_Decimal);
                                }}
                                // calculateDisplayValue={(rowData: any) => {
                                //     return this.gridUtils.convertDecimalAsStringToCurrency(
                                //         rowData.rate_Decimal,
                                //         currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                //     );
                                // }}
                            />
                        )}
                        <Column dataField="cost" caption="COST" cellComponent={CostCell} />
                        {isShowPayDate ? (
                            <Column
                                dataField="pay_Date"
                                caption="PAY DATE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertddmmyyyyStringToDate(rowData.pay_Date);
                                }}
                            />
                        ) : null}
                        {isShowBillDate ? (
                            <Column
                                dataField="bill_Date"
                                caption="BILL DATE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertddmmyyyyStringToDate(rowData.bill_Date);
                                }}
                            />
                        ) : null}
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default SimpleGrid;
