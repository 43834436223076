import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    FilterPanel,
    PatternRule,
    SearchPanel,
    StateStoring,
    Export
} from "devextreme-react/data-grid";
import { LoadIndicator, LoadPanel,ScrollView } from "devextreme-react";
import MatrixService, { RateMatrixGridRowItem } from "../../services/MatrixService";
import lookupService, { LookupTypeIndexes } from "../../services/LookupService";
import BillingService from "../../services/BillingService";
import sharedUtils from "../grid/sharedUtilities";
import gridUtils from "../grid/GridUtilities";
import {
    OnRowUpdatingEvent,
    OnRowInsertingEvent,
    OnRowRemovingEvent,
    onEditCanceledEvent,
} from "../../types/DevExtremeTypes";
import periodMatrixFormUtil from "./PeriodMatrixFormUtil";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import ShiftMatrixUnpaidGrid from './ShiftMatrixUnpaidGrid';
import moment from "moment";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import FileService from "../../services/FileService";


//props
interface PayDatesGridProps { }

export interface RateMatrixAddEditObjectItem {
    rateMatrixId: number;
    providerBusinessEntityId: number;
    clientBusinessEntityId: number;
    serviceTypeLookUpId: number;
    serviceSubTypeLookUpId: number;
    typeLookUpId: number;
    venueId: string;
    idTypeLookUpId: string;
    idValue: string;
    rate_Lower: number;
    rate_Higher: number;
    comment: string;
    name: string;
}
export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}

export interface PayDatesRowItem {
    service: string;
    id: string;
    currentPayDate: string;
    updatedPayDate: string;
    itemMatched: boolean | null;
    payDateUpdated: boolean | null;
    requestPayDate: string;
    uuid: string;
}
// State
interface PayDatesGridState {
    payDatesGridDataSource: PayDatesRowItem[];
    filters: any[];
    unpaidGridDataSource: any[];
    findMatchesClicked: boolean;
    reloadShiftGrid: boolean;
    showFindMatches: boolean;
    showApplyNewDates: boolean;
    showUnpaidShiftGrid : boolean;
    showLoadPanel: boolean;
    defaultPageSize:number
    //payDatesGridDataSource:[];
}

// Component - displays the Rate Matrix Grid
class PayDatesGrid extends React.Component<PayDatesGridProps> {
    // //Initialize the component's state.
    state: PayDatesGridState;
    billingService: BillingService;
    dataGridRef: any;
    fileService:FileService;
    // rateService: MatrixService;
    // sharedUtils: sharedUtils;
    // dropdownService: lookupService;
    // utils: periodMatrixFormUtil;
    // gridUtils: gridUtils;
    constructor(props: PayDatesGridProps) {
        super(props);
        this.billingService = new BillingService();
        // this.rateService = new MatrixService();
        // this.dropdownService = new lookupService();
        // this.utils = new periodMatrixFormUtil();
        // this.gridUtils = new gridUtils();
        // Initialize state and services/utils
        this.state = {
            payDatesGridDataSource: [],
            filters: [],
            unpaidGridDataSource: [],
            findMatchesClicked: false,
            reloadShiftGrid: false,
            showFindMatches: false,
            showApplyNewDates: false,
            showUnpaidShiftGrid:false,
            showLoadPanel: false,
            defaultPageSize: 10
        };
        this.dataGridRef = null;
        this.fileService = new FileService();
    }

    getDefaultObject = (index: any) => {
        let obj = {
            service: 'click here to paste from excel ',
            id: '',
            currentPayDate: '',
            updatedPayDate: '',
            itemMatched: index == 0 ? null :false,
            payDateUpdated:  index == 0 ? null :false,
            requestPayDate: '',
            uuid: index
        }
        return obj;
    }

    componentDidMount() {
        // const editor = document.getElementById('payDatesGridContainer');
        // editor.onpaste = logPaste;
        let obj = this.getDefaultObject(0);
        let stateArr = this.state.payDatesGridDataSource;
        stateArr.push(obj);
        this.setState({ payDatesGridDataSource: stateArr })
        //CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.managePayDatesUnpaidShiftGrid, []);
        
    }
    componentDidUpdate(prevProps: PayDatesGridProps, prevState: PayDatesGridState): void {
      
    }

    hideFindMatches = () => {
        let hide = true;
        if (this.state.payDatesGridDataSource) {
            if (this.state.payDatesGridDataSource.some(a => a.service && a.id && a.currentPayDate && a.updatedPayDate)) {
                hide = false;
            }
        }
        return hide;
    }

    onPaste(e: React.ClipboardEvent) {
        return;
        var clipboardData, pastedData;

        // Stop data actually being pasted into div
        e.stopPropagation();
        e.preventDefault();

        // Get pasted data via clipboard API
        clipboardData = e.clipboardData;
        pastedData = clipboardData.getData('Text');

        // break pasted data in rows and columns
        alert(pastedData);
        let arr: PayDatesRowItem[] = [];
        let rows = pastedData.split('\n')?.filter((a: any) => a !== '');
        rows.forEach((row: any, i: any) => {
            let data = row.split('\t')
            if (data) {
                arr.push(
                    {
                        service: data[0],
                        id: data[1],
                        currentPayDate: data[2],
                        updatedPayDate: data[3],
                        itemMatched: false,
                        payDateUpdated: false,
                        uuid: Date.now().toString(36),
                        requestPayDate: ''
                    })
            }
        })
        this.setState({ payDatesGridDataSource: arr })
    }

    getPayFilters = (payDate: string) => {
        let arr = [];
        let currPayDate = moment(payDate).format('YYYY-MM-DD');
        let formattedDate = currPayDate + "T00:00:00";

        let nextDate = new Date(currPayDate);
        nextDate.setDate(nextDate.getDate() + 1);

        let nextDay = moment(nextDate).format('YYYY-MM-DD');
        let formattedNextDay = nextDay + "T00:00:00";
        arr.push(['payDate', '>=', formattedDate], 'and', ['payDate', '<', formattedNextDay]);
        return arr;

        //return [["payDate",">=","2023-12-22T00:00:00"],"and",["payDate","<","2023-12-23T00:00:00"]]
    }

    prepareFilters = (record: PayDatesRowItem) => {
        let indFilter: any[] = [];
        if (record.id && record.service && record.currentPayDate) {
            indFilter.push(["serviceTypeLookUp.value", "=", record.service]);
            indFilter.push("and", ['id', '=', record.id]);
            let payDate = record.payDateUpdated ? record.updatedPayDate : record.currentPayDate
            // let payfilters = this.getPayFilters(record.currentPayDate);
            let payfilters = this.getPayFilters(payDate);
            indFilter.push("and", payfilters);
            indFilter.push("and", [["paymentId", "=", null], "or", ["paymentId", "=", ""]])
            //filters.push(indFilter);

        }
        return indFilter;
    }

    createFiltersforShiftGrid = (arr: any) => {
        let filter: any[] = [];
        arr.forEach((record: any, index: any, arr: any) => {
            let test = this.prepareFilters(record);
            if (filter && filter.length && test && test.length) {
                //if(index != arr.length - 1) //skip for last
                filter.push('or');
            }

            if (test && test.length) {
                filter.push(test);
            }

        })
        return filter;
    }

    createFiltersWithId = (dataSource :any)=>{
        let filter: any[] = [];
        // let uniqueIds = Array.from(new Set(this.state.payDatesGridDataSource.map((item: any) => item.id)));
        let uniqueIds = Array.from(new Set(dataSource.map((item: any) => item.id)));
        let ids: any[] = [];
        let arr = uniqueIds.forEach((id) => {
            let arr = ['id', '=', id];
            if (ids.length && ids.length > 0) {
                ids.push('or');
            }
            ids.push(arr);
            //[["id","=","440996"],"or",["id","=",""],["and",["paymentId","=",null],"or",["paymentId","=",""]]]
        })
        filter.push(ids, "and", [["paymentId", "=", null], "or", ["paymentId", "=", ""]])
        console.log(filter);
        return filter;
    }

    onClickFindMatches = () => {
        let filter: any[] = [];
        
        //filter = this.createFiltersforShiftGrid(this.state.payDatesGridDataSource);
        filter = this.createFiltersWithId(this.state.payDatesGridDataSource);
        let defaultSize =  this.state.payDatesGridDataSource?.length || 20;
        this.setState({ filters: filter, findMatchesClicked: true, reloadShiftGrid: false,defaultPageSize:defaultSize,showUnpaidShiftGrid:true });
    }

    isPayDateEqual = (recordPaydate: string, shiftPayDate: string) => {
        let isEqual = false;
        if (new Date(recordPaydate).getTime() === new Date(shiftPayDate).getTime()) {
            isEqual = true;
        }
        return isEqual;
    }

    updateFoundRecords = (matchedRecords: any[],payDatesSource :any[]) => {
        this.setState({ showUnpaidShiftGrid:true,findMatchesClicked: false, reloadShiftGrid: false, unpaidGridDataSource: matchedRecords,payDatesGridDataSource:payDatesSource, showApplyNewDates: true })
    }

    renderShiftMatrix = () => {
        //if (this.state.filters && this.state.filters.length > 0) {
            if(this.state.showUnpaidShiftGrid){
            return <>
                <h2 className="page-title__black-table">Matching Unpaid Items</h2>
                <ShiftMatrixUnpaidGrid 
                    filters={this.state.filters} 
                    updateFoundRecords={this.updateFoundRecords}
                    findMatchesClicked={this.state.findMatchesClicked}
                    reloadShiftGrid={this.state.reloadShiftGrid}
                    defaultPageSize={this.state.defaultPageSize}
                    payDatesGridDataSource={this.state.payDatesGridDataSource}></ShiftMatrixUnpaidGrid></>
        }
    }
    isApplyNewDatesDisabled = () =>{
        let disabled = true;
        if(this.state.unpaidGridDataSource?.length > 0){
            disabled = false;
        }
        return disabled;
    }

    convertStringToDateFormat = (dateString: string) => {
        // convert to 'dd/mm/yyyy
        let finalDate = ''
        if (dateString) {
            finalDate = moment(dateString).format("DD/MM/YYYY");
        }
        //return '22/12/2024'
        return finalDate;
    }

    onApplyNewPayDates = () => {
        let payload: any = [];
        let updated = this.state.payDatesGridDataSource
            // .filter(a => a.itemMatched)
            .map((item: any) => {
                let serviceId = this.state.unpaidGridDataSource.filter(a => a.id === item.id)[0]?.serviceTypeLookUp?.id;
                let formattedPayDate = this.convertStringToDateFormat(item.updatedPayDate);
                if (item.itemMatched) {
                    let obj = {
                        serviceTypeId: serviceId,
                        // payDate: item.updatedPayDate,
                        // PayDate: this.convertStringToDateFormat(item.updatedPayDate),
                        PayDate: formattedPayDate,
                        billableItemIds: [item.id]
                    }
                    payload.push(obj);
                    //return { ...item, requestPayDate: obj.PayDate };
                }
                return { ...item, requestPayDate: formattedPayDate }
                // else {
                //     return { ...item, requestPayDate: this.convertStringToDateFormat(item.updatedPayDate) };
                // }
            });
        this.setState({ showLoadPanel: true });
        this.billingService.updatePayDates(payload)
            .then(res => this.handleDateSuccess(res, updated))
            .catch(this.handleFailure);
    }



    handleDateSuccess = (response: AxiosResponse<any>, requestObj: any) => {
        let data = response?.data?.data || [];
        let updated = requestObj.map((request: PayDatesRowItem) => {
            let filter = data.filter((item: any) => {
                return request.id == item.id && request.requestPayDate == item.payDate
            });
            if (filter && filter.length) {
                return { ...request, payDateUpdated: true }
            }
            else {
                return { ...request, payDateUpdated: false }
            }
        })
        let matchedArr = updated.filter((a: PayDatesRowItem) => a.itemMatched)
        //let filter = this.createFiltersforShiftGrid(matchedArr);
        let filter =  this.createFiltersWithId(matchedArr)
        let defaultSize =  matchedArr.length || 20;

        this.setState({ payDatesGridDataSource: updated, filters: filter, reloadShiftGrid: true, showLoadPanel: false,defaultPageSize:defaultSize })
    };

    handleFailure = (error: any) => {
        //let respMessage: string = "find matches for unpaid grid failed with response: " + JSON.stringify(error);

        // if (!this.BillingService.traceAsErrorToAppInsights(respMessage)) {
        //     // AppInsights is not available
        //     console.error(respMessage);
        // }
        this.setState({
            showLoadPanel: false,
        });
    };

    displayBooleanContent = (Val: any) => {
        let valToShow: string = "";
        if(Val !== null){
        if (Val) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
    }
        return (
            <div style={{margin:'10px',minHeight:'10px'}}>
                {
                    valToShow == 'Yes'?<b><span>{valToShow}</span></b>:<span>{valToShow}</span>
                }
                {/* <span>{valToShow}</span> */}
            </div>
        );
    }

    calculateBooleanContent = (Val:any)=>{
        let valToShow: string = '';
        if (Val) {
            valToShow = 'Yes';
        }
        else {
            valToShow = 'No';
        }
        return valToShow;
    }

    checkForRowsToAdd = (currentRowIndex: any, pastedRows: any) => {
        let patedsRowLength = pastedRows.length;
        let gridRows = this.state.payDatesGridDataSource;
        let availableRows = gridRows.slice(currentRowIndex, gridRows.length)
        if (availableRows?.length <= patedsRowLength) {
            let rowsToadd = patedsRowLength - availableRows.length;
            // for (let i = 0; i <= rowsToadd; i++) {
                for (let i = 0; i < rowsToadd; i++) {
                let index = gridRows.length;
                let obj = this.getDefaultObject(index);
                gridRows.push(obj);

            }
            //  this.setState({ payDatesGridDataSource: gridRows })
        }
        return gridRows;
    }

    handlePaste = (editEvent: any, e: any, currentRow: any, currentDataField: any) => {
        let pastedData = e.clipboardData.getData('Text');
        let pastedRows = pastedData.split('\n')?.filter((a: any) => a !== '');
        let rowIndex = currentRow.dataIndex;
        //rowIndex = currentRow.data.uuid;
        let stateObj = this.checkForRowsToAdd(rowIndex, pastedRows);
        let allRows = stateObj;

        pastedRows.forEach((element: any, rindex: any) => {
            let roToUpdate = rowIndex + rindex;
            let currPastedRow = pastedRows[rindex];
            // let splittedCells = pastedRows[rindex].split('\t');
            let splittedCells = currPastedRow.replace(/(\r)/gm, '').split('\t');
            let currRow = allRows[roToUpdate]
            const dataFields = Object.keys(currRow).slice(0, 4);
            for (let fieldID = 0; fieldID < dataFields.length; fieldID++) {
                if (dataFields[fieldID] === currentDataField) {
                    for (let i = 0; i < dataFields.length - fieldID && i < splittedCells.length; i++) {
                        let key = dataFields[fieldID + i];
                        let val = splittedCells[i];
                        //val = val.replace(/(\r)/gm,'');
                        this.dataGridRef.instance.cellValue(
                            roToUpdate,
                            key,
                            val
                        );
                        if (key == 'service') {
                            currRow['service'] = val;
                        } else if (key == 'id') {
                            currRow['id'] = val;
                        } else if (key == 'currentPayDate') {
                            currRow['currentPayDate'] = val;
                        } else if (key == 'updatedPayDate') {
                            currRow['updatedPayDate'] = val;
                        }
                        currRow.itemMatched = false;
                        currRow.payDateUpdated = false;
                    }
                    // this.dataGridRef.instance.saveEditData();
                    // break;
                }
            }

        });
        this.dataGridRef.instance.saveEditData();

        let show = false;
        if (stateObj && stateObj.some(a => a.service && a.id && a.currentPayDate && a.updatedPayDate)) {
            show = true;
        }
        this.setState({ payDatesGridDataSource: stateObj, showFindMatches: show });
        editEvent.cancel = true;
    }

    onEditorPreparing = (editEvent: any) => {
        // const currentDataField = editEvent.dataField;
        // const currentRow = editEvent.row;
        // if (currentDataField == 'itemMatched' || currentDataField == 'payDateUpdated') {
        //     //editEvent.cancel = true;
        //     return;
        // }
        // editEvent.editorElement.onpaste = (pasteEvent: any) => {
        //     this.handlePaste(editEvent, pasteEvent, currentRow, currentDataField);
        // }
        // editEvent.editorElement.onchange = (pasteEvent: any) => {
        //     //alert('changed')
        // }
        // // let hideFindMatches = this.hideFindMatches();
        // // this.setState({ showFindMatches: !hideFindMatches })

    }

    onCellClick = (clickEvent: any) => {
        const currentDataField = clickEvent.column.dataField;
        const currentRow = clickEvent.row;
        
        clickEvent.cellElement.onpaste = (pasteEvent: any) => {
            this.handlePaste(clickEvent, pasteEvent, currentRow, currentDataField);
        }

    }

    onRefreshClick = () => {
        let obj = this.getDefaultObject(0);
        let stateArr = [];
        stateArr.push(obj);

        this.setState({ payDatesGridDataSource: stateArr,filters:[],showFindMatches:false,showApplyNewDates:false,showUnpaidShiftGrid:false,unpaidGridDataSource:[] })
    }

    onDownloadClick = () => {
        this.fileService
            .downloadTemplate('paydate')
            .then(this.handleDownloadSuccess)
            .catch(this.handleDownloadError);
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        saveAs(response.data,'template_pay_dates.xlsx');
    };

    handleDownloadError = (error: any) => {
        var respMessage: string = "Download template failed with response: " + JSON.stringify(error);

        if (!this.fileService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    render() {
        return (
            <>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.showLoadPanel} />
                <>
                    <div style={{display:'flex'}}>
                        <h2 className="page-title__black-table">Manage Pay Dates
                            {true && <button
                                className="status-flow__refresh-button btn icon-btn"
                                aria-label="Refresh the current manage pay dates"
                                onClick={this.onRefreshClick}
                            >
                                <FontAwesomeIcon icon={faSync} title='reset grid data'/>
                            </button>}
                        </h2>
                        <h5 style={{padding:'15px 0px 0px 15px'}}>
                            <Link
                                onClick={(e) => {
                                    this.onDownloadClick();
                                }}
                                className="approval-query-column"
                                to="#"
                            >
                                Download Template
                            </Link>
                        </h5>
                    </div>
                </>
                <div id='payDatesGridContainer' onPaste={(event) => { this.onPaste(event) }}>
                    <DataGrid
                        // keyExpr="id"
                        dataSource={this.state.payDatesGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        allowColumnReordering={true}
                        columnMinWidth={100}
                        noDataText={"No data found for the filters applied"}
                        onEditorPreparing={this.onEditorPreparing}
                        onCellClick={this.onCellClick}
                        ref={(ref) => { this.dataGridRef = ref; }}>
                        {/* <SearchPanel visible={true} placeholder={"Search"}/>
                        <Export enabled={true} allowExportSelectedData={false} />
                        <FilterPanel visible={false} />
                        <Sorting mode="single"/>
                         <FilterRow visible={true} applyFilter="auto" /> */}
                        <Paging defaultPageSize={20} enabled={false} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} visible={false} />
                        {/* startEditAction="dblClick" */}
                        <Editing mode="cell" allowUpdating={false} startEditAction="click" allowDeleting={false} />
                        {/* <Column type="buttons" buttons={["delete"]}></Column> */}
                       
                        {false && <Column dataField="uuid" caption="UUID" />}
                        <Column dataField="service" caption="SERVICE" />
                        <Column dataField="id" caption="ID" />
                        <Column dataField="currentPayDate" caption="CURRENT PAY DATE" />
                        <Column dataField="updatedPayDate" caption="UPDATED PAY DATE" />
                        <Column dataField="itemMatched" caption="ITEM MATCHED?" 
                            //trueText={'Yes'} falseText={'No'}
                            cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.itemMatched) }}
                            calculateDisplayValue={(rowData: any) => {
                                 return this.calculateBooleanContent(rowData?.itemMatched)
                            }}
                            calculateCellValue={(cellInfo:any)=>{return this.calculateBooleanContent(cellInfo?.itemMatched)}}
                            />

                        <Column dataField="payDateUpdated" caption="PAY DATE UPDATED?" 
                        //trueText={'Yes'} falseText={'No'}
                            cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.payDateUpdated) }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.calculateBooleanContent(rowData?.payDateUpdated)
                           }}
                            calculateCellValue={(cellInfo:any)=>{return this.calculateBooleanContent(cellInfo?.payDateUpdated)}}
                            />
                    </DataGrid>
                </div>
                {true && <>
                    <div className="col-12 col-lg-2 mb-3 mr-auto" >
                        {/* hidden={this.hideFindMatches()} */}
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="button"
                            disabled={!this.state.showFindMatches}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                this.onClickFindMatches();
                            }}
                        >
                            FIND MATCHES
                        </button>
                    </div>
                    {this.renderShiftMatrix()}

                    <div className="col-12 col-lg-2 mb-3 mr-auto" hidden={!this.state.showApplyNewDates}>
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="button"
                            disabled ={this.isApplyNewDatesDisabled()}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                this.onApplyNewPayDates();
                            }}
                        >
                            APPLY NEW PAY DATES
                        </button>
                    </div>
                </>}
            </>
        );
    }
}

export default PayDatesGrid;
