import React, { MouseEvent } from "react";
import axios,{ AxiosResponse } from "axios";
import EventUkUserGrid from "../grid/EventUkUserGrid";
import BillingService, { billingCycleStatus } from "../../services/BillingService";
import { BillingCyclesGridServiceInvoiceItem } from "../../services/BillingCyclesGridService";
import BillingFormUtils, { BillingFormData, BillingStatus } from "../Billing/BillingFormUtils";
import sharedUtils from "../grid/sharedUtilities";
import { LoadPanel, Popup, ScrollView, } from "devextreme-react";
import GlobalService from "../../services/GlobalService";
import { GridTypes } from "../../services/SimpleGridService";
import DataGrid, { Paging, FilterRow, Column, Pager, Editing } from "devextreme-react/data-grid";
import AddShiftContainerForBilling from '../BillingV2/AddShiftContainerForBilling';
import AddEditPopUpService from "../../services/AddEditPopUpService";
import gridUtils from "../grid/GridUtilities";
// Interfaces and Constants
export interface FinancialChartColorPalette {
    [key: string]: string[];
}
// Props
interface LockShiftComponentProps {
    invoiceId: number;
    billingData: BillingFormData;
    setCalculationsCallback: (CalculationsObject: any) => void;
    onClickLockedShift: (isClickLockedShift: boolean, status: BillingStatus, statusId: string) => void;
    isPrepareClicked: boolean;
    isSuccessRestart: boolean;
    statusId: string;
    viewDisable: boolean;
    gridType?: string;
    isPreviewClicked?: boolean;
    previewUpdate?: (isPreview: boolean, isError: boolean, error: []) => void;
}
// State
interface LockShiftComponentState {
    calculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    billingItemList: string;
    gridData: BillingCyclesGridServiceInvoiceItem[];
    loadPanelVisible: boolean;
    refreshSignal: boolean;
    isClickedLockedShift: boolean;
    showPayDatePopup: boolean;
    showAddShiftPopup:boolean;
    filters : any[],
    addToInvoiceClicked:boolean;
    selectedAddShiftResponse:any[];
    //isAddShiftClicked:boolean;
    //selectionChanged:boolean;
    dataSource: [];
    selectedServiceData: [];
    includedShiftData: [];
}
/**
 * Component - Spline chart to display budget and spend
 */
class LockShiftComponent extends React.Component<LockShiftComponentProps> {
    state: LockShiftComponentState;
    BillingService: BillingService;
    billingFormUtils: BillingFormUtils;
    sharedUtils: sharedUtils;
    gridUtils:gridUtils;
    addEditPopUpService: AddEditPopUpService;
    constructor(props: LockShiftComponentProps) {
        super(props);
        this.BillingService = new BillingService();
        this.billingFormUtils = new BillingFormUtils();
        this.sharedUtils = new sharedUtils();
        this.gridUtils = new gridUtils();
        this.addEditPopUpService = new AddEditPopUpService();
        this.state = {
            calculationsObject: {
                subTotal: "",
                total: "",
                tax: "",
            },
            billingItemList: "",
            gridData: [],
            loadPanelVisible: false,
            refreshSignal: false,
            isClickedLockedShift: this.props.viewDisable ? true : false,
            showPayDatePopup: false,
            showAddShiftPopup:false,
            addToInvoiceClicked:false,
            selectedAddShiftResponse:[],
            //selectionChanged:false,
            filters:[],
            dataSource: [],
            selectedServiceData: [],
            includedShiftData: [],
        };
    }

    componentDidUpdate(prevProps: LockShiftComponentProps) {
        if (
            this.props.billingData &&
            this.props.billingData.statusId != prevProps.billingData.statusId &&
            (this.props.billingData.statusId == billingCycleStatus.Pending ||
                this.props.billingData.statusId == billingCycleStatus.ReadyForBilling ||
                this.props.billingData.statusId == billingCycleStatus.BillChecked ||
                this.props.billingData.statusId == billingCycleStatus.BillPackReady ||
                this.props.billingData.statusId == billingCycleStatus.BillPackSent ||
                this.props.billingData.statusId == billingCycleStatus.BillPackRequested ||
                this.props.billingData.statusId == billingCycleStatus.BillQueried ||
                this.props.billingData.statusId == billingCycleStatus.BillPaid)
        ) {
            this.setState((prevState: LockShiftComponentState) => ({
                isClickedLockedShift: true,
            }));
        } else if (this.props.isPrepareClicked != prevProps.isPrepareClicked) {
            this.setState((prevState: LockShiftComponentState) => ({
                refreshSignal: !prevState.refreshSignal,
            }));
        }
        if (
            this.props.statusId &&
            this.props.statusId != prevProps.statusId &&
            (this.props.statusId == billingCycleStatus.Pending ||
                this.props.statusId == billingCycleStatus.ReadyForBilling ||
                this.props.statusId == billingCycleStatus.BillChecked ||
                this.props.statusId == billingCycleStatus.BillPackReady ||
                this.props.statusId == billingCycleStatus.BillPackSent ||
                this.props.statusId == billingCycleStatus.BillPackRequested ||
                this.props.statusId == billingCycleStatus.BillQueried ||
                this.props.statusId == billingCycleStatus.BillPaid)
        ) {
            this.setState((prevState: LockShiftComponentState) => ({
                refreshSignal: !prevState.refreshSignal,
            }));
        }
    }
    /**
     * On component load, check the status and disable/enable the "Lock shifts" button accordingly.
     */
    componentDidMount() {
        GlobalService.removeBillableItemIds();
        if (
            this.props.billingData &&
            (this.props.billingData.statusId == billingCycleStatus.ReadyForBilling ||
                this.props.billingData.statusId == billingCycleStatus.BillChecked ||
                this.props.billingData.statusId == billingCycleStatus.BillPackReady ||
                this.props.billingData.statusId == billingCycleStatus.BillPackSent ||
                this.props.billingData.statusId == billingCycleStatus.BillPackRequested ||
                this.props.billingData.statusId == billingCycleStatus.BillQueried ||
                this.props.billingData.statusId == billingCycleStatus.BillPaid ||
                this.props.billingData.statusId == billingCycleStatus.BillNotPaid)
        ) {
            this.setState({
                isClickedLockedShift: true,
            });
        }
    }

    setParentButtonStatesSignal = (isButtonStateChanged: boolean) => {};
    setBillableItemList = (list: string) => {
        this.setState({
            billingItemList: list,
        });
    };

    //When "gridData" paramter returns true disable the lock shifts button because there is no data in the grid.
    setSummary = (SummaryObject: any, noGridPresent?: boolean) => {
        if (noGridPresent) {
            this.setState({
                isClickedLockedShift: true,
            });
        }
    };

    setIndicatorStates = (isGridLoadSuccess: boolean) => {};

    setCalculations = (calculations: any) => {
        if (calculations.total || calculations.subTotal || calculations.tax) {
            this.setState(
                {
                    calculationsObject: {
                        ...this.state.calculationsObject,
                        total: calculations.total,
                        subTotal: calculations.subTotal,
                        tax: calculations.tax,
                    },
                },
                () => {
                    this.props.setCalculationsCallback(this.state.calculationsObject);
                }
            );
        }
    };

    filterArray = (arr1: string[], arr2: string[]) => {
        const filtered = arr1.filter((item) => {
            return arr2.indexOf(item) === -1;
        });
        return filtered;
    };

    onLockShift = () => {
        var excludedBillingItemIds = GlobalService.getBillableItemIds();
        var arrExcludedBillingItemIds = excludedBillingItemIds.split(",");
        var arrIncludedBillingItemIds = this.state.billingItemList.split(",");
        var actualBillingIdList = this.filterArray(arrIncludedBillingItemIds, arrExcludedBillingItemIds);
        let data: BillingCyclesGridServiceInvoiceItem = this.billingFormUtils.initializeBillingCyclesGridServiceInvoiceItem();
        data.invoiceId = this.props.invoiceId ? this.props.invoiceId.toString() : "";
        data.clientId = this.props.billingData.clientId;
        data.client = this.props.billingData.client;
        data.statusId = "10";
        data.status = "Ready For Billing";
        data.periodMatrixId = this.props.billingData.periodMatrixId;
        data.period = this.props.billingData.period;
        data.week = this.props.billingData.week;
        data.dateRangeFrom = this.props.billingData.dateRangeFrom
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeFrom)
            : "";
        data.dateRangeTo = this.props.billingData.dateRangeTo
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeTo)
            : "";
        data.tax = this.state.calculationsObject.tax ? this.state.calculationsObject.tax.toString() : "";
        data.subTotal = this.state.calculationsObject.subTotal ? this.state.calculationsObject.subTotal.toString() : "";
        data.total = this.state.calculationsObject.total ? this.state.calculationsObject.total.toString() : "";
        data.invoiceDate = this.props.billingData.invoiceDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDate)
            : "";
        data.invoiceReference = this.props.billingData.invoiceReference
            ? this.props.billingData.invoiceReference.toString()
            : "";
        data.internalReference = this.props.billingData.internalReference
            ? this.props.billingData.internalReference.toString()
            : "";
        data.invoiceSentDate = this.props.billingData.invoiceSentDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceSentDate)
            : "";
        data.invoiceDueDate = this.props.billingData.invoiceDueDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDueDate)
            : "";

        data.invoicePaidDate = this.props.billingData.invoicePaidDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoicePaidDate)
            : "";
        data.invoiceAmount = this.props.billingData.invoiceAmount
            ? this.props.billingData.invoiceAmount.toString()
            : "";
        data.description = this.props.billingData.description ? this.props.billingData.description.toString() : "";
        data.actionType = "Save";
        data.billableItemIds = actualBillingIdList.toString();
        data.invoiceServiceMappingList = this.props.billingData.invoiceServiceMappingList;
        data.includeAccruals= this.props.billingData.includeAccruals.toString() == "Include" || this.props.billingData.includeAccruals == true ? true : false;
        data.groupAccruals= this.props.billingData.groupAccruals.toString() == "Group" || this.props.billingData.groupAccruals == true ? true : false;
        
        this.setState(
            {
                gridData: data,
                loadPanelVisible: true,
                billingItemList: actualBillingIdList.toString(),
            },
            () => {
                this.onSubmit();
            }
        );
    };

    showUpdatePayDates = () => {
        let serviceList:any = [];
        let objDetails;
        this.state.includedShiftData && this.state.includedShiftData.length > 0
            ? this.state.includedShiftData.map((item: any) => {
                let details = {
                    "service": "",
                    "serviceId": "",
                    "payDate": ""
                };
                details.service = item.service;
                details.serviceId = item.serviceTypeId;
                details.payDate  = "";
                objDetails = details;
                let index = serviceList.findIndex((obj: any) => {
                    return obj.serviceId == item.serviceTypeId;
                })
                if(index == -1){
                    serviceList.push(objDetails);
                }                
            })
            : null
        this.setState({
            showPayDatePopup: true,
            dataSource: serviceList,
        })
    }

    getServiceAndClientList = () =>{
        let serviceList:any = [];
        let client = '';
        if(this.state.includedShiftData && this.state.includedShiftData.length > 0){
            //serviceList = 
            this.state.includedShiftData.map((item: any) => {
                let index = serviceList.findIndex((obj: any) => {
                    return obj == item.service;
                })
                if(index == -1){
                    serviceList.push(item.service);
                }
            });
            let filtered = this.state.includedShiftData.filter((a:any)=>a.client) || [];
            if(filtered && filtered.length && filtered.length > 0){
                client = filtered[0]['client'];
            }
        }
        
        return [serviceList,client];
    }
     
    setAddToInvoiceClicked = (flag:boolean) =>{
        this.setState({addToInvoiceClicked:flag})
    }
    // onRowSelectionChanged = (changed:boolean)=>{
    // this.setState({
    //     selectionChanged:changed
    // });
    // }

    onClickAddShift = () => {
        let [serviceList, client] = this.getServiceAndClientList();
        let finalFilters = this.prepareFilters(serviceList,client);
        this.setState({ filters:finalFilters,showAddShiftPopup:true,addToInvoiceClicked:false});
    }
    hideAddShiftPopup = () =>{
        this.setState({showAddShiftPopup:false,addToInvoiceClicked:false});
    }

    prepareFilters = (serviceList:any,client:any) => {
        let indFilter: any[] = [];
        //if (record.id && record.service && record.currentPayDate) {
            indFilter.push(["clientBusinessEntity.shortName", "=", client]);
            indFilter.push("and",);
            let serviceFilters:any[] = [];
            serviceList.forEach((record: any, index: any, arr: any) => {
                let filter = ["serviceTypeLookUp.value","=",record];
                    serviceFilters.push(filter);
                if(index < serviceList.length-1) //skip or for last 
                serviceFilters.push('or');
            })
            indFilter.push(serviceFilters);
        return indFilter;
    }

    onAddToInvoice = (selectedRows: any) => {
        let selectedResponse: any[] = [];
        this.setState({ showAddShiftPopup: false, loadPanelVisible: true },()=>{
            selectedRows.forEach((element: any) => {
                let response = this.gridUtils.convertMangeShiftToEventUKGrid(element);
                selectedResponse.push(response);
            });
            this.setState({ selectedAddShiftResponse: selectedResponse, addToInvoiceClicked: true, loadPanelVisible: false })
        });

        // this.addEditPopUpService.getEditViewDataAll(selectedRows)
        //     .then((res: any) => {
        //         selectedResponse = res.map((item: any) => item?.data?.data) || [];
        //         console.log(selectedResponse, 'selectedResponse');
        //         this.setState({selectedAddShiftResponse:selectedResponse,addToInvoiceClicked:true,loadPanelVisible:false})
        //     })
    }

    hideUpdatePayDates = () => {
        this.setState({showPayDatePopup: false})
    }    

    onSubmit = () => {
        this.BillingService.billingDataService(this.state.gridData).then(this.handleSuccess).catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState((prevState: LockShiftComponentState) => ({
            loadPanelVisible: false,
            refreshSignal: !prevState.refreshSignal,
            isClickedLockedShift: true,
        }));
        this.props.onClickLockedShift(true, BillingStatus.ReadyForBilling, billingCycleStatus.ReadyForBilling);
    };

    handleFailure = (error: any) => {
        var respMessage: string = "postBillingCycleInvoice failed with response: " + JSON.stringify(error);

        if (!this.BillingService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            loadPanelVisible: false,
        });
    };    

    gridData = (data:any) => {
        this.setState({
            includedShiftData: data,
        });
    }

    updatingData= () => {
        if(this.state.selectedServiceData.length > 0){     
            this.setState({loadPanelVisible: true});    
            this.state.selectedServiceData.forEach((item:any) => {      
                let ids:any = [];           
                this.state.includedShiftData.forEach((items:any) => { 
                    if(item.serviceTypeId == items.serviceTypeId) {
                        ids.push(items.billableItemId);
                        item.billableItemIds = ids;
                    }
                });
            }); 
            this.BillingService.updatePayDates(this.state.selectedServiceData).then(this.handleDateSuccess).catch(this.handleFailure);
        }      
    };

    onProceed = () => { 
        let payDateData:any = []; 
        if (this.state.dataSource.length > 0) {
            this.state.dataSource.forEach((items:any) => { 
                if(items.payDate !== ""){
                    let convertedDate = items.payDate.split("/").reverse().join("/");
                    payDateData.push({
                        "serviceTypeId": items.serviceId,
                        "payDate": convertedDate,
                        "billableItemIds": []
                    });
                }
            }); 
            this.setState({
                selectedServiceData: payDateData,
            }, () => { this.updatingData(); });
        }
        else{
            this.setState({
                selectedServiceData: [],
            });
        }    
               
    }

    handleDateSuccess = (response: AxiosResponse<any>) => {
        this.setState((prevState: LockShiftComponentState) => ({
            loadPanelVisible: false,
            showPayDatePopup: false,
        }));
    };

    render() {
        var { isClickedLockedShift } = this.state;
        var lockShiftsButtonCssClass: string = isClickedLockedShift
            ? "btn disabledCycleButtonColor btn--large"
            : "btn saveCycleButtonColor btn--large";
        var disableLockShiftsButton: boolean = isClickedLockedShift ? true : false;
        return (
            <div className="card mt-3">
                <div className="card-body">
                    <div className="col-12">
                        <h4 className="font-weight-bold">Included Shifts</h4>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <EventUkUserGrid
                            setParentButtonStatesSignal={this.setParentButtonStatesSignal}
                            setBillableItemObj={()=>{}}
                            setBillableItemList={this.setBillableItemList}
                            setSummary={this.setSummary}
                            setIndicatorStates={this.setIndicatorStates}
                            setCalculations={this.setCalculations}
                            refreshSignal={this.state.refreshSignal}
                            gridType= {this.props.gridType == "BillingPreview" ? "BillingPreview" : GridTypes.billing}//The GridType can take in three of the possible values, "EventUk", "Billing", or "Payments"
                            id={this.props.invoiceId} // To fetch in the List of Billable items for a particular Invoice ID.,
                            filterText=""
                            billableItemId=""
                            clientId=""
                            venueId=""
                            shiftStart=""
                            shiftEnd=""
                            isSchedulePage={false}
                            signalForArrowClick=""
                            serviceTypeValue=""
                            dateFrom=""
                            dateTo=""
                            view = {this.props.viewDisable}
                            setMatchButtonState={()=>{}}
                            setRemoveButtonState={()=>{}}
                            setBillableItemCount={()=>{return null}} 
                            billingData={this.props.billingData}
                            isPreviewClicked={this.props.isPreviewClicked}
                            previewUpdate={this.props.previewUpdate}
                            gridData={this.gridData}
                            addToInvoiceClicked={this.state.addToInvoiceClicked}
                            selectedAddShiftResponse={this.state.selectedAddShiftResponse}
                            setAddToInvoiceClicked={this.setAddToInvoiceClicked}
                            // selectionChanged = {this.state.selectionChanged}
                            // onRowSelectionChanged = {this.onRowSelectionChanged}
                        />
                        <div className="row mt-3" hidden={this.props.gridType == "BillingPreview"}>
                            <div className="col-12 col-lg-2 mb-3">
                                <button
                                    className={lockShiftsButtonCssClass}
                                    type="button"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onLockShift();
                                    }}
                                    disabled={disableLockShiftsButton}
                                >
                                    LOCK SHIFTS
                                </button>
                            </div>
                            <div className="col-12 col-lg-2 mb-3">
                                <button
                                    className="btn btn--large btn--ghost--teal"
                                    type="button"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.showUpdatePayDates();
                                    }}
                                >
                                    UPDATE PAY DATES?
                                </button>
                            </div>
                            <div className="col-12 col-lg-2 mb-3">
                                <button
                                    className="btn btn--large btn--ghost--teal"
                                    disabled={disableLockShiftsButton}
                                    type="button"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onClickAddShift();
                                    }}>
                                    Add Shift
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Popup
                    visible={this.state.showPayDatePopup}
                    onHiding={this.hideUpdatePayDates}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"UPDATE PAY DATES?"}
                    resizeEnabled={true}
                    width={"30%"}
                    height={500}                        
                >
                    <ScrollView width='100%' height='100%'>
                        <div>
                            <div className="text-center">Please enter pay dates for each service and click save icon - then use Update Pay Dates button to apply changes.</div>
                        </div>
                        <div>
                            <DataGrid
                                dataSource={this.state.dataSource}
                                showBorders={false}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                columnAutoWidth={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                columnResizingMode={"widget"}
                                className="payDate-grid"
                            >
                                <Paging defaultPageSize={5} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                <Editing
                                    mode="batch"
                                    useIcons={false}
                                    allowAdding={false}
                                    allowUpdating={true}
                                    allowDeleting={false}
                                />
                                <Column dataField="service" allowSorting={true} allowEditing={false} caption="Service"/>
                                <Column 
                                    dataField="payDate" 
                                    allowSorting={true} 
                                    caption="Pay Date" 
                                    format="dd/MM/yyyy"
                                    dataType="date"
                                />
                                <FilterRow visible={false} applyFilter="auto" />
                            </DataGrid>
                        </div>
                        <div className="row px-3">
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn-primary btn--large mt-3"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onProceed();
                                    }}
                                >
                                    UPDATE PAY DATES
                                </button>
                            </div>
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.hideUpdatePayDates();
                                    }}
                                >
                                    CLOSE
                                </button>
                            </div>
                        </div> 
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.showAddShiftPopup}
                    onHiding={this.hideAddShiftPopup}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title={"ADD SHIFTS TO INVOICE"}
                    resizeEnabled={false}
                    // width={"90%"}
                    // height={"90%"}
                    >
                    {/* <ScrollView width='100%' height='100%'> */}
                      <AddShiftContainerForBilling filters={this.state.filters}
                      isAddShiftClicked={this.state.showAddShiftPopup}
                      onAddToInvoice={this.onAddToInvoice}>
                      </AddShiftContainerForBilling>
                    {/* </ScrollView> */}
                </Popup>
            </div>
        );
    }
}

export default LockShiftComponent;
