import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload,faRotateExclamation } from "@fortawesome/pro-regular-svg-icons";
import ManageDocumentsGridService,{logicAppRegeneratePostRequest} from "../../../services/ManageDocumentsGridService";
import { AxiosResponse } from "axios";
import { Button, LoadPanel } from "devextreme-react";
import gridUtils, { currencyTypeToSymbolMatrix } from "../../grid/GridUtilities";
import sharedUtils from "../../grid/sharedUtilities";
import notify from 'devextreme/ui/notify';
import { alert } from "devextreme/ui/dialog";

interface InvoiceAmountCellProps {
    data: any;
    onRefresh?: (flag:boolean) => void;
}

interface InvoiceAmountCellState {
    filetype: string;
    loadPanelVisible: boolean;
}

class InvoiceAmountCell extends React.Component<InvoiceAmountCellProps> {
    state: InvoiceAmountCellState;
    service: ManageDocumentsGridService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    constructor(props: InvoiceAmountCellProps) {
        super(props);
        this.state = {
            filetype: this.props.data.data.formatType,
            loadPanelVisible: false,
        };
        this.service = new ManageDocumentsGridService();
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
    }

    onDownloadClick = (event: any) => {
        var id = "";
        if (this.props.data && this.props.data.data && this.props.data.data.documents) {
            this.props.data.data.documents.map((item: any) => {
                if (item.documentType.toLowerCase() == "selfbillinvoice") {
                    id = item.id;
                }
            });
        }
        this.setState({
            loadPanelVisible: true,
        });

        this.service.getDocumentById(id).then(this.handleDownloadSuccess).catch(this.handleDownloadError);
        event.event.stopPropagation();
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        var linkRaw = "";
        if (this.props.data && this.props.data.data && this.props.data.data.documents) {
            this.props.data.data.documents.map((item: any) => {
                if (item.documentType.toLowerCase() == "selfbillinvoice") {
                    linkRaw = item.link;
                }
            });
        }
        let link = linkRaw.split("/");
        let filename = link[link.length - 1];
        var FileSaver = require("file-saver");
        FileSaver.saveAs(response.data, filename);
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleDownloadError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "getDocumentById failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    onRegenerateClick = (event: any) => {
        event.event?.stopPropagation();
        let paymentId = this.props.data.data.paymentId  || '';
        this.setState({
            loadPanelVisible: true
        }, () => {

            let postObject: logicAppRegeneratePostRequest = {
                PaymentID: paymentId
            };

            this.service
            .triggerLogicAppForRegenerateDocument(postObject)
            .then(this.handleSuccess)
            .catch(this.handleError);
        });
    };

    handleSuccess = (response: AxiosResponse<any>) => {
            //setTimeout(()=>{
                this.setState({
                    loadPanelVisible: false
                },()=>{
                //notify({ message: "The request to re-create the document has been processed successfully. Please wait approximately 20 seconds and click the Refresh button to see the new document.", width: 300, shading: true, position: "center" }, 'success', 2000);
                alert("The request to re-create the document has been processed successfully. Please wait approximately 20 seconds and click the Refresh button to see the new document.", "Success");
                this.props.onRefresh ? this.props.onRefresh(true):null;
                });
            //},15000)
    };

    handleError = (error: any) => {
        this.setState({
            loadPanelVisible: false
        });
        var respMessage: string = "generateDocument failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        notify({ message: "The request to re-create the document has been unsuccessful. Please re-try", width: 300, shading: true,position: "center" }, 'error', 1000);
    };

    renderActionButtons = () => {
        let show = false;
        if (this.props.data && this.props.data.data && this.props.data.data.documents) {
            let filtered = this.props.data.data.documents.filter((item: any) => item.documentType.toLowerCase() == "selfbillinvoice")
            if (filtered && filtered.length > 0) {
                show = true;
            }
        }
        if (show) {
            return (
                <>
                    <Button className="btn icon-btn btn-file-downloader" onClick={this.onDownloadClick}>
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <Button className="btn icon-btn btn-file-downloader" onClick={(e) => this.onRegenerateClick(e)}>
                        <FontAwesomeIcon icon={faRotateExclamation} 
                        // title="re-generate document" 
                        />
                    </Button>
                </>
            );
        }
    }

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {this.sharedUtils.thousandsSeparator(this.props.data.data.amount)}
                </div>
                {this.renderActionButtons()}
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={this.state.loadPanelVisible}
                    style={{
                        position: "absolute",
                    }}
                />
            </div>
        );
    }
}

export default InvoiceAmountCell;
