import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import FooterFile from "../../Footer/FooterFile";
class Terms extends Component<RouteComponentProps> {
    constructor(props: RouteComponentProps) {
        super(props);
    }

    render() {
        return <FooterFile FileName="Terms" />;
    }
}

export default withRouter(Terms);
