import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Scrolling,
    Editing,
    Texts,
    StateStoring,
    SearchPanel,
    Selection,
    FilterPanel,
    GroupPanel,
} from "devextreme-react/data-grid";
import { LoadIndicator, Popup, ScrollView } from "devextreme-react";
import { faCircle as faCircleSolid } from "@fortawesome/pro-solid-svg-icons";
import SimpleGridService, {
    GridTypes,
    eventUkUserGridRowItem,
    PeriodGridTypeParams,
    filterTypes,
    eventUKUserGridDataSource,
} from "../../services/SimpleGridService";
import AllUserGridService from "../grid/AllUserGridService";
import CheckInOutCell from "./ManageViewShiftGridRowCell/CheckInOutCellComponent";
import CostCell from "./ManageViewShiftGridRowCell/CostCellComponent";
import AllUserGridShift from "./ManageViewShiftGridRowCell/AllUserGridShiftCellComponent";
import gridUtils from "./GridUtilities";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import sharedUtils from "./sharedUtilities";
import ManageShiftGlobalFilterService, { gridFilter } from "../../services/ManageShiftGlobalFilterService";
import { ServiceType } from "../../common/ServiceUtilities";
import GridToggleTab from "../BillingV2/GridToggleTab";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { Link } from "react-router-dom";
import SIALicenceDetails from "../SIA_License/SIALicenceDetails";
import ViewShiftMatrixCell from "./ViewShiftMatrixCell";
import ChangeShiftMatrixCell from "../GridCells/ChangeShiftMatrixCell";
import { DataGridOnSelectionChanged, onExportingEvent } from "../../types/DevExtremeTypes";
import GlobalService from "../../services/GlobalService";
import CostReportUtils from "../CostReports/CostReportUtils";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
import QueryActionCell from "../GridCells/QueryActionCell";
import DeleteEditShiftActionCellV2 from "../GridCells/DeleteEditShiftActionCellV2";
import ApproveActionCell from "../GridCells/ApproveActionCell";
import MaskedStatusForHoldPaymentHardApproval from "../GridCells/MaskedStatusForHoldPaymentHardApproval";
import AddEditPopUpService from "../../services/AddEditPopUpService";
import addEditPopupUtils, { AddEditFormData, itemTypes } from "./AddEditPopUpUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle, faClock, faMusic, faTimes, faFlagCheckered, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FadSquare } from "../GridCells/BaseActionCell";
import { on } from "devextreme/events";
import { PaymentRequestMatchedPopUpFormData } from "../PaymentRequests/PaymentRequestUtils";
import { paymentRequestStatus } from "../../services/PaymentService";
import CardApproveComponent from "./CardApproveComponent";
import CardQueryComponent from "./CardQueryComponent";
import CardViewComponent from "./CardViewComponent";
import { PaymentTypes } from "../../services/FindAnArtistService";

var rowIndex: string = "";
var rowKey: string = "";
let billableDataItem: AddEditFormData;
let utils = new addEditPopupUtils();
billableDataItem = utils.initializeBillableItem("");
//props
interface EventUkUserGridProps {
    setParentButtonStatesSignal: (isButtonStateChanged: boolean) => void;
    setBillableItemList: (billableItemList: string) => void;
    setBillableItemCount: (billableItemCount: string) => void;
    setSummary: (SummaryObject: any, noGridPresent?: boolean) => void;
    setIndicatorStates: (isGridLoadSuccess: boolean) => void;
    setCalculations: (CalculationsObject: any) => void;
    setBillableItemObj: (billableItemObj: AddEditFormData) => void;
    previewUpdate?: (isPreview: boolean, isError: boolean, error: []) => void;
    refreshSignal: boolean;
    gridType: string; //The GridType can take in three of the possible values, "EventUk", "Billing", or "Payments"
    id: number; // To fetch in the List of Billable items for a particular Invoice ID.,
    filterText: string;
    billableItemId: string;
    filterId?: string;
    clientId: any;
    venueId: any;
    shiftStart: any;
    shiftEnd: any;
    isSchedulePage: boolean;
    periodGridTypeParams?: PeriodGridTypeParams;
    signalForArrowClick?: string;
    serviceTypeValue?: string;
    dateFrom: string;
    paymentDate?: string;
    dateTo: string;
    view?: boolean;
    providerId?: string;
    venueName?: string;
    providerName?: string;
    fee?: string;
    setMatchButtonState: (buttonState: boolean, isLoaderVisible: boolean) => void;
    setRemoveButtonState: (buttonState: boolean) => void;
    paymentObject?: PaymentRequestMatchedPopUpFormData;
    isUnMatched?: boolean;
    isClickedCancel?: boolean;
    billingData?: any;
    isPreviewClicked?: boolean;
    gridData?: (data: eventUkUserGridRowItem[]) => void;
    addToInvoiceClicked?:boolean;
    selectedAddShiftResponse?:any[];
    setAddToInvoiceClicked?:(flag:boolean)=>void;
    // onRowSelectionChanged?:(flag:boolean)=>void;
    // selectionChanged?:boolean;
}

// State
interface EventUkUserGridState {
    EventUKGridDataSource: eventUkUserGridRowItem[];
    showProgressIndicator: boolean;
    CalculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    allowSelection: string;
    noDataDisplay: boolean;
    SummaryObject: {
        billableHours: string;
        billableCost: string;
        equipmentCost: string;
        total: string;
        service: string;
    };
    loadIndicatorOnCalendar: boolean;
    serviceTypeValue: string;
    globalFilterValues: gridFilter;
    primaryInvoiceDataSource: eventUkUserGridRowItem[];
    secondaryInvoiceDataSource: eventUkUserGridRowItem[];
    primaryInvoiceDataSourceCount: number;
    licencePopupVisible: boolean;
    billableItemId: string;
    commentsPopupVisible: boolean;
    internalCommentsPopupVisible: boolean;
    externalComments: string;
    internalComments: string;
    editViewDataItem: AddEditFormData;
    iconToShow: string;
    isMatchIconDisable: boolean;
    gridType: string;
    rowIndex: number;
    isUnMatchSuccessful: boolean;
    isSelectedYellowCard: boolean;
    isSelectedGreenCard: boolean;
    isSelectedOrangeCard: boolean;
    clickedCardId: string;
    errorMessage: [];
    //selectionChanged:boolean;
}

// Component - displays both the header and child grids
class EventUkUserGrid extends React.Component<EventUkUserGridProps> {
    state: EventUkUserGridState;
    buttonRef: React.RefObject<any>;
    eventukgridservice: SimpleGridService;
    gridUtils: gridUtils;
    EventUKGridDataSource: any;
    sharedUtils: sharedUtils;
    costReportUtils: CostReportUtils;
    editViewService: AddEditPopUpService;
    dataGridRef: any;
    utils: addEditPopupUtils;
    constructor(props: EventUkUserGridProps) {
        super(props);
        this.buttonRef = React.createRef();
        this.utils = new addEditPopupUtils();
        var convertedEditViewDataItem = this.utils.initializeBillableItem(
            ""
        );
        // Initialize state and services/utils
        this.state = {
            editViewDataItem: convertedEditViewDataItem,
            EventUKGridDataSource: [],
            showProgressIndicator: false,
            CalculationsObject: {
                subTotal: "",
                total: "",
                tax: "",
            },
            allowSelection: "multiple",
            noDataDisplay: true,
            SummaryObject: {
                billableHours: "",
                billableCost: "",
                equipmentCost: "",
                total: "",
                service: "",
            },
            loadIndicatorOnCalendar: true,
            serviceTypeValue: "",
            globalFilterValues: {
                serviceType: "",
                dateFrom: undefined,
                dateTo: undefined,
            },
            primaryInvoiceDataSource: [],
            secondaryInvoiceDataSource: [],
            primaryInvoiceDataSourceCount: 0,
            licencePopupVisible: false,
            billableItemId: "",
            commentsPopupVisible: false,
            internalCommentsPopupVisible: false,
            externalComments: "",
            internalComments: "",
            iconToShow: "",
            isMatchIconDisable: true,
            gridType: this.props.gridType,
            rowIndex: 0,
            isUnMatchSuccessful: false,
            isSelectedYellowCard: false,
            isSelectedGreenCard: false,
            isSelectedOrangeCard: false,
            clickedCardId: "",
            errorMessage: [],
            //selectionChanged:false
        };
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        this.costReportUtils = new CostReportUtils();
        // Functions
        this.eventukgridservice = new SimpleGridService();
        this.editViewService = new AddEditPopUpService();

        this.eventUKGridDataSource = this.eventUKGridDataSource.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleCalculations = this.handleCalculations.bind(this);
        this.onCustomGridLoad = this.onCustomGridLoad.bind(this);
        this.onCustomGridSave = this.onCustomGridSave.bind(this);
        this.dataGridRef = React.createRef();
    }

    // Initialize state from server
    componentDidMount() {
        if (
            this.props.filterId ||
            this.props.gridType == GridTypes.billing ||
            this.props.gridType == GridTypes.payments ||
            this.props.gridType == GridTypes.invoiceReport ||
            this.props.gridType == GridTypes.EventUk ||
            this.props.gridType == GridTypes.futureNegetiveShift ||
            this.props.gridType == GridTypes.paymentsRequest || 
            this.props.gridType == "BillingPreview"
        ) {
            this.eventUKGridDataSource();
        }
    }

    fetchFilterValues = () => {
        var filterValuesFromGlobalService: gridFilter;
        filterValuesFromGlobalService = ManageShiftGlobalFilterService.getFilterValues();
        this.setState({
            globalFilterValues: filterValuesFromGlobalService,
        });
        return filterValuesFromGlobalService;
    };

    serviceTypeValueSet = () => {
        var filterValues = this.fetchFilterValues();
        var serviceType = filterValues.serviceType;
        this.setState({
            serviceTypeValue: serviceType,
        });
    };

    eventUKGridDataSource() {
        this.setState({
            showProgressIndicator: true,
        });
        this.props.setIndicatorStates(true);
        //Sice the same Grid component makes 3 different API calls, this variable stores the type of datasource passed down as props from the respective page it is rendered upon.
        var typeOfGridDataSource = this.props.gridType;
        switch (typeOfGridDataSource) {
            case "BillingPreview":
                this.setUpBillingPreviewGrid();
                break;
            case GridTypes.billing:
                this.setUpBillingGrid();
                GlobalService.removeGridType();
                break;

            case GridTypes.invoiceReport:
                this.setUpInvoiceReportGrid();
                GlobalService.removeGridType();
                break;

            case GridTypes.payments:
                this.setUpPaymentsGrid();
                GlobalService.removeGridType();
                break;

            case GridTypes.EventUk:
                this.setUpShiftGrid();
                GlobalService.removeGridType();
                break;

            case GridTypes.futureNegetiveShift:
                this.setUpFututeNegetivePaymentGrid();
                GlobalService.removeGridType();
                break;

            case GridTypes.paymentsRequest:
                this.setUpPaymentRequestGrid();
                GlobalService.setGridType(GridTypes.paymentsRequest);
                break;
        }
    }

    filterDataSourceBy = (filterType: string) => {
        return this.state.EventUKGridDataSource.filter((item) => {
            return item.service.includes(filterType);
        });
    };

    setUpPaymentRequestGrid = () => {
        if (this.props.dateFrom && this.props.serviceTypeValue && this.props.venueId && this.props.providerId) {
            this.eventukgridservice.getFilteredShifts(this.props.dateFrom, this.props.serviceTypeValue ? this.props.serviceTypeValue : "", this.props.venueId, this.props.providerId ? this.props.providerId : "").then(this.handleSuccess).catch(this.handleError);
        }
    };

    summaryCalculations = () => {
        let billHours = 0.0;
        let billCost = 0.0;
        let equipCost = 0.0;
        let Total = 0.0;
        let dataForSecurity = this.filterDataSourceBy(filterTypes.security);
        let dataForCleaning = this.filterDataSourceBy(filterTypes.cleaning);
        let dataForEntertainment = this.filterDataSourceBy(filterTypes.entertainment);
        if (
            (dataForSecurity.length > 0 && dataForCleaning.length > 0 && dataForCleaning.length > 0) ||
            (dataForSecurity.length > 0 && dataForCleaning.length > 0) ||
            (dataForSecurity.length > 0 && dataForEntertainment.length > 0) ||
            (dataForCleaning.length > 0 && dataForEntertainment.length > 0)
        ) {
            let totalPay = this.state.EventUKGridDataSource.map((item) => item.provider_Pay_Amount_Local_Ccy);
            totalPay.forEach((rowElement) => {
                Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
            });
            this.setState(
                {
                    SummaryObject: {
                        ...this.state.SummaryObject,
                        billableHours: "",
                        billableCost: "",
                        equipmentCost: "",
                        total: Total.toString(),
                        service: "All",
                    },
                },
                () => this.props.setSummary(this.state.SummaryObject)
            );
        } else {
            if (dataForSecurity.length > 0) {
                let dataPersonel = dataForSecurity.filter((item) => {
                    return item.service.includes("Personnel");
                });
                let dataEquipment = dataForSecurity.filter((item) => {
                    return item.service.includes("Equipment");
                });
                let hours = dataForSecurity.map((item) => item.billable_Minutes);
                let cost = dataPersonel.map((item) => item.provider_Pay_Amount_Local_Ccy);
                let equipment = dataEquipment.map((item) => item.provider_Pay_Amount_Local_Ccy);
                let totalPay = dataForSecurity.map((item) => item.provider_Pay_Amount_Local_Ccy);

                hours.forEach((rowElement) => {
                    billHours = billHours + this.gridUtils.convertDecimalAsStringToFloat(rowElement);
                });
                billHours = billHours / 60;
                cost.forEach((rowElement) => {
                    billCost = billCost + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                equipment.forEach((rowElement) => {
                    equipCost = equipCost + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                totalPay.forEach((rowElement) => {
                    Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                this.setState(
                    {
                        SummaryObject: {
                            ...this.state.SummaryObject,
                            billableHours: billHours.toString(),
                            billableCost: billCost.toString(),
                            equipmentCost: equipCost.toString(),
                            total: Total.toString(),
                            service: "Security",
                        },
                    },
                    () => this.props.setSummary(this.state.SummaryObject)
                );
            } else if (dataForCleaning.length > 0) {
                let totalPay = dataForCleaning.map((item) => item.provider_Pay_Amount_Local_Ccy);
                totalPay.forEach((rowElement) => {
                    Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                this.setState(
                    {
                        SummaryObject: {
                            ...this.state.SummaryObject,
                            billableHours: "",
                            billableCost: "",
                            equipmentCost: "",
                            total: Total.toString(),
                            service: "Cleaning",
                        },
                    },
                    () => this.props.setSummary(this.state.SummaryObject)
                );
            } else if (dataForEntertainment.length > 0) {
                let totalPay = dataForEntertainment.map((item) => item.provider_Pay_Amount_Local_Ccy);
                totalPay.forEach((rowElement) => {
                    Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(rowElement);
                });
                this.setState(
                    {
                        SummaryObject: {
                            ...this.state.SummaryObject,
                            billableHours: "",
                            billableCost: "",
                            equipmentCost: "",
                            total: Total.toString(),
                            service: "Entertainment",
                        },
                    },
                    () => this.props.setSummary(this.state.SummaryObject)
                );
            }
        }
    };

    setUpShiftGrid() {
        var { billableItemId, filterId, filterText, dateFrom, dateTo } = this.props;
        var filterbyId: string = "";
        filterbyId = billableItemId ? billableItemId : filterId ? filterId : "";
        var outstandingFilter: string = "";
        if (filterText) {
            outstandingFilter = filterText;
        }
        if (this.props.isSchedulePage) {
            this.eventukgridservice
                .getManageShiftGrid(this.props.clientId, this.props.venueId, this.props.shiftStart, this.props.shiftEnd)
                .then(this.handleSuccess)
                .catch(this.handleError);
            this.setState({
                noDataDisplay: false,
            });
        } else if (this.props.periodGridTypeParams && this.props.periodGridTypeParams.serviceTypeId.length > 0) {
            if (this.props.periodGridTypeParams.periodWeek.length > 0) {
                this.eventukgridservice
                    .getManageShiftGridByPeriodNumberAndWeek(
                        this.props.clientId,
                        this.props.venueId,
                        this.props.periodGridTypeParams.serviceTypeId,
                        this.props.periodGridTypeParams.periodNumber,
                        this.props.periodGridTypeParams.periodWeek
                    )
                    .then(this.handleSuccess)
                    .catch(this.handleError);
            } else {
                this.eventukgridservice
                    .getManageShiftGridByPeriodNumber(
                        this.props.clientId,
                        this.props.venueId,
                        this.props.periodGridTypeParams.serviceTypeId,
                        this.props.periodGridTypeParams.periodNumber
                    )
                    .then(this.handleSuccess)
                    .catch(this.handleError);
            }
        } else if (!this.isBlank(filterbyId)) {
            this.eventukgridservice
                .getManageShiftGridByBillableId(filterbyId)
                .then(this.handleSuccess)
                .catch(this.handleError);
            this.setState({
                noDataDisplay: false,
            });
        } else if (outstandingFilter) {
            this.eventukgridservice
                .getSimpleGridRows(outstandingFilter)
                .then(this.handleSuccess)
                .catch(this.handleError);
            this.setState({
                noDataDisplay: false,
            });
        } else if (dateFrom || dateTo) {
            this.eventukgridservice
                .getSimpleGridRowsWithinDateRange(dateFrom, dateTo)
                .then(this.handleSuccess)
                .catch(this.handleError);
            this.setState({
                noDataDisplay: false,
            });
        } else {
            this.setState({
                showProgressIndicator: false,
            });
        }
    }

    setUpPaymentsGrid() {
        this.setState({
            allowSelection: "none",
        });
        this.eventukgridservice
            .getSimpleGridRowsbyPaymentCycleId(this.props.id)
            .then(this.handleSuccess)
            .catch(this.handleError);
    }

    setUpFututeNegetivePaymentGrid = () => {
        this.eventukgridservice.getFutureNegetiveShifts(this.props.id).then(this.handleSuccess).catch(this.handleError);
    };

    setUpInvoiceReportGrid() {
        this.setState({
            allowSelection: "none",
        });
        this.eventukgridservice
            .getSimpleGridReportbyInvoiceId(this.props.id)
            .then(this.handleSuccess)
            .catch(this.handleError);
    }

    setUpBillingGrid() {
        this.setState({
            allowSelection: "none",
        });
        if (this.props.id) {
            this.eventukgridservice
                .getSimpleGridRowsbyInvoiceId(this.props.id)
                .then(this.handleSuccess)
                .catch(this.handleError);
        } else {
            this.setState({
                showProgressIndicator: false,
            });
        }
    }

    setUpBillingPreviewGrid() {
        this.setState({
            allowSelection: "none",
        });
        this.eventukgridservice
            .getManageShiftGridForPreview(this.props.billingData.clientId, this.props.billingData.serviceTypeIds, this.props.billingData.dateRangeFrom, 
                this.props.billingData.dateRangeTo, this.props.billingData.includeAccruals, this.props.billingData.groupAccruals)
            .then(this.handleSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null,
                });
                this.handleError(err);
            })
            .finally(() => { });           
    }

    handleCalculations() {
        //var { EventUKGridDataSource } = this.state;
        let EventUKGridDataSource = this.filterToExcludeSelectedRows();
        let billableItemList = "";
        let Total = 0;
        let subtotal = 0;
        let Tax = 0;

        EventUKGridDataSource &&
            EventUKGridDataSource.forEach((rowElement: eventUkUserGridRowItem) => {
                // let modifiedTotal= this.sharedUtils.thousandsSeparator(rowElement.client_Billable_Total_Local_Ccy);
                // let modifiedSub = this.sharedUtils.thousandsSeparator(rowElement.client_Billable_Amount_Local_Ccy);
                // let modifiedTax = this.sharedUtils.thousandsSeparator(rowElement.client_Billable_Tax_Local_Ccy);
                let modifiedTotal= rowElement.client_Billable_Total_Local_Ccy;
                let modifiedSub = rowElement.client_Billable_Amount_Local_Ccy;
                let modifiedTax = rowElement.client_Billable_Tax_Local_Ccy;
                if(!modifiedTotal.includes("£")){
                    modifiedTotal = "£" + modifiedTotal;
                }
                if(!modifiedSub.includes("£")){
                    modifiedSub = "£" + modifiedSub;
                }
                if(!modifiedTax.includes("£")){
                    modifiedTax = "£" + modifiedTax;
                }

                Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(modifiedTotal);
                subtotal = subtotal + this.gridUtils.convertCurrencyAsStringToFloat(modifiedSub);
                Tax = Tax + this.gridUtils.convertCurrencyAsStringToFloat(modifiedTax);
                // Total = Total + this.gridUtils.convertCurrencyAsStringToFloat(rowElement.client_Billable_Total_Local_Ccy);
                // subtotal = subtotal + this.gridUtils.convertCurrencyAsStringToFloat(rowElement.client_Billable_Amount_Local_Ccy);
                // Tax = Tax + this.gridUtils.convertCurrencyAsStringToFloat(rowElement.client_Billable_Tax_Local_Ccy);
                billableItemList += rowElement.billableItemId + ",";
            });
        this.props.setBillableItemList(billableItemList);

        this.setState({
            CalculationsObject: {
                ...this.state.CalculationsObject,
                total: Total,
                subTotal: subtotal,
                tax: Tax,
            },
        },()=>{
            this.Calculations();
        });
    }

    filterToExcludeSelectedRows = ()=>{
        let dataSourceList = this.state.EventUKGridDataSource;
        var excludedBillingItemIds = GlobalService.getBillableItemIds();
        var ExcludedItemIds = excludedBillingItemIds.split(",") || [];
        let arr = dataSourceList.filter(function(item:any){
         return ExcludedItemIds.indexOf(item.billableItemId)===-1;
        });
        return arr;
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.props.previewUpdate ? this.props.previewUpdate(false, false, []) : null;
        if (this.props.gridType != GridTypes.billing && this.props.gridType != GridTypes.payments) {
            this.serviceTypeValueSet();
        }
        // when there will be no content in the response the code will be 204
        if (response.status == 204) {
            this.handleEmptyResponse(response);
        } else {
            var { gridType, filterText, dateFrom, dateTo } = this.props;
            var secondaryDataSource: eventUkUserGridRowItem[] = [];
            if (gridType == GridTypes.invoiceReport) {
                secondaryDataSource = this.gridUtils.orderManageShiftsGrid(
                    response.data.data.eventUkUserGridLookUp,
                    false
                );
            }
            let eventUKGridDataSource: eventUkUserGridRowItem[] = [];
            let dataSourceForPaymentReq: eventUKUserGridDataSource[] = [];
            if (gridType == GridTypes.EventUk) {
                eventUKGridDataSource = this.gridUtils.modifyManageShiftsGridRow(
                    response.data.data.eventUkUserGridLookUp,
                    filterText,
                    dateFrom,
                    dateTo
                )
            }
            else if (gridType == GridTypes.paymentsRequest) {
                dataSourceForPaymentReq.push({
                    cards: response.data.data.eventUkUserGridLookUp
                });

            }
            else {
                eventUKGridDataSource = response.data.data.eventUkUserGridLookUp;
            }
            this.setState({
                EventUKGridDataSource: dataSourceForPaymentReq && dataSourceForPaymentReq.length > 0 ? dataSourceForPaymentReq : eventUKGridDataSource,
                showProgressIndicator: false,
                secondaryInvoiceDataSource: secondaryDataSource ? secondaryDataSource : [],
                primaryInvoiceDataSource: response.data.data.eventUkUserGridLookUp,
                primaryInvoiceDataSourceCount: response.data.data.eventUkUserGridLookUp
                    ? response.data.data.eventUkUserGridLookUp.length
                    : 0,
            });
            if (this.state.serviceTypeValue && this.props.gridType != GridTypes.paymentsRequest) {
                let dataGridForDifferentService = this.state.EventUKGridDataSource.filter((item) => {
                    return item.serviceTypeId == this.state.serviceTypeValue;
                });
                this.setState({
                    EventUKGridDataSource: dataGridForDifferentService,
                    showProgressIndicator: false,
                });
                if (dataGridForDifferentService.length == 0) {
                    this.setState(
                        {
                            SummaryObject: {
                                ...this.state.SummaryObject,
                                billableHours: "",
                                billableCost: "",
                                equipmentCost: "",
                                total: "",
                                service: "",
                            },
                        },
                        () => this.props.setSummary(this.state.SummaryObject)
                    );
                }
            }

            if (this.props.gridType == GridTypes.EventUk) {
                this.summaryCalculations();
            }
            if (this.props.gridType == GridTypes.billing) {
                this.props.setSummary(this.state.SummaryObject, false);
            }
            if (this.props.gridType == GridTypes.billing || this.props.gridType == GridTypes.billingPreview || this.props.gridType == GridTypes.invoiceReport) {
                this.handleCalculations();
                //this.Calculations();
            }
        }
        this.props.setIndicatorStates(this.state.loadIndicatorOnCalendar);
        this.props.setBillableItemCount(response.data.data.eventUkUserGridLookUp.length);
        this.props.gridData ? this.props.gridData(this.state.EventUKGridDataSource) : null;
    }

    handleEmptyResponse(response: AxiosResponse<any>) {
        this.setState({
            EventUKGridDataSource: response.data,
            showProgressIndicator: false,
        });

        // reset the state of this object if there is no data in the grid
        this.setState(
            {
                SummaryObject: {
                    ...this.state.SummaryObject,
                    billableHours: "",
                    billableCost: "",
                    equipmentCost: "",
                    total: "",
                    service: "",
                },
            },
            () => this.props.setSummary(this.state.SummaryObject, true)
        );
        if (this.props.gridType == GridTypes.billing || this.props.gridType == GridTypes.billingPreview || this.props.gridType == GridTypes.invoiceReport) {
            this.handleCalculations();
            //this.Calculations();
        }
    }

    Calculations() {
        this.props.setCalculations(this.state.CalculationsObject);
    }

    handleError(error: AxiosResponse<any>) {
        if (this.props.signalForArrowClick == "YES") {
            this.setState({
                loadIndicatorOnCalendar: false,
            });
        }
        this.setState({
            showProgressIndicator: false,
            EventUKGridDataSource: null,
        });
        this.Calculations();
        // Log to AppInsights as a TRACE
        var respMessage: string = "Manage Shift Grid data load failed with response: " + JSON.stringify(error);

        if (!this.eventukgridservice.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.props.setIndicatorStates(this.state.loadIndicatorOnCalendar);
        this.props.previewUpdate ? this.props.previewUpdate(false, true, this.state.errorMessage) : null;
    }
    // Update state from server
    componentDidUpdate(prevProps: EventUkUserGridProps, prevState: EventUkUserGridState) {
        if (
            this.props.refreshSignal != prevProps.refreshSignal ||
            this.props.id != prevProps.id ||
            this.props.dateFrom != prevProps.dateFrom ||
            this.props.dateTo != prevProps.dateTo ||
            this.props.filterText != prevProps.filterText ||
            this.props.serviceTypeValue != prevProps.serviceTypeValue
            || this.props.venueId != prevProps.venueId || this.props.providerId != prevProps.providerId || this.props.isClickedCancel != prevProps.isClickedCancel 
            || this.props.isPreviewClicked !== prevProps.isPreviewClicked
        ) {
            this.eventUKGridDataSource();
        }
        if (this.props.isUnMatched != prevProps.isUnMatched) {
            this.setState({
                isUnMatchSuccessful: this.props.isUnMatched
            });
        }
        if(this.props.addToInvoiceClicked != prevProps.addToInvoiceClicked && this.props.addToInvoiceClicked){
            let response = this.props.selectedAddShiftResponse || [];
            let dataSource = [...this.state.EventUKGridDataSource,...response];
            this.setState({EventUKGridDataSource:dataSource},()=>{
                this.handleCalculations();
                //this.Calculations();
            });
            this.props.setAddToInvoiceClicked ? this.props.setAddToInvoiceClicked(false) : null;
        }
        // if(this.state.selectionChanged != prevState.selectionChanged && this.state.selectionChanged){
        //     this.handleCalculations();
        //     this.setState({selectionChanged:false});
        // }
        // if(this.props.selectionChanged != prevProps.selectionChanged && this.props.selectionChanged){
        //     this.handleCalculations();
        //     this.props.onRowSelectionChanged ? this.props.onRowSelectionChanged(false) : null;
        // }
    }

    setStateForserviceTypeValue = (serviceTypeValue: string | undefined) => {
        this.setState({
            serviceTypeValue: serviceTypeValue,
        });
    };

    venueCellDisplayContent = (cellInfo: any) => {
        var venueParts: string[] = this.gridUtils.convertStringToStringArray(cellInfo.data.venue);
        var isShowInColour: boolean = false;
        if (this.props.venueName && this.props.gridType == GridTypes.paymentsRequest) {
            if (venueParts.length > 0 && venueParts[1] && venueParts[1] == this.props.venueName) {
                isShowInColour = true;
            }
        }
        return (
            <div className="container-fluid">
                {venueParts.map((item: any, uniqueKey: number) => {
                    return (
                        <div key={uniqueKey} className="row">
                            {isShowInColour ? <span className="grid-info__text_color_green">{item}</span> : item}
                        </div>
                    );
                })}
            </div>
        );
    };

    providerCellDisplayContent = (cellInfo: any) => {
        var provider: string = cellInfo.data.provider;
        var isShowInColour: boolean = false;
        if (this.props.providerName && this.props.gridType == GridTypes.paymentsRequest) {
            if (provider == this.props.providerName) {
                isShowInColour = true;
            }
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{provider}</span> : provider}
            </div>
        );
    };

    onboardedCellDisplayContent = (cellInfo: any) => {
        let onboardedVal = cellInfo.data.data.venueDetails?.onboarded;
        let valToShow: string = "";
        if (onboardedVal) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {valToShow}
            </div>
        );

    }

    dateCellDisplayContent = (cellInfo: any) => {
        var date: string = cellInfo.data.date;
        var isShowInColour: boolean = false;
        if (this.props.dateFrom && this.props.gridType == GridTypes.paymentsRequest) {
            if (date == this.props.dateFrom) {
                isShowInColour = true;
            }
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{date}</span> : date}
            </div>
        );
    };

    feeCellDisplayContent = (cellInfo: any) => {
        var providerAmount: number = this.gridUtils.convertCurrencyAsStringToFloat(cellInfo.data.provider_Pay_Amount_Local_Ccy);
        var isShowInColour: boolean = false;
        var x = this.props.fee;
        if (this.props.fee && this.props.gridType == GridTypes.paymentsRequest) {
            if (providerAmount == parseFloat(this.props.fee) || providerAmount == parseInt(this.props.fee)) {
                isShowInColour = true;
            }
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{this.sharedUtils.thousandsSeparator(providerAmount.toString())}</span> : this.sharedUtils.thousandsSeparator(providerAmount.toString())}
            </div>
        );
    };

    commentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoComments(cellInfo.data.comments);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.comments
                        ? cellInfo.data.comments.length > 16
                            ? `${cellInfo.data.comments.substring(0, 16)}...`
                            : cellInfo.data.comments
                        : ""}
                </Link>
            </div>
        );
    };

    internalCommentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoInternalComments(cellInfo.data.internalComments);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.internalComments
                        ? cellInfo.data.internalComments.length > 16
                            ? `${cellInfo.data.internalComments.substring(0, 16)}...`
                            : cellInfo.data.internalComments
                        : ""}
                </Link>
            </div>
        );
    };

    isBlank(billableItemId: string) {
        return !billableItemId || /^\s*$/.test(billableItemId) || 0 === billableItemId.length;
    }

    onCustomGridLoad() {
        return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid);
    }

    onCustomGridSave(gridState: Object) {
        CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid, gridState);
    }

    //This function will change the contents of the Grid, depending on the Tab button that is clicked.
    swapGridDataSource = (isToggle: boolean) => {
        var { primaryInvoiceDataSource, secondaryInvoiceDataSource } = this.state;
        if (isToggle) {
            this.setState({
                EventUKGridDataSource: primaryInvoiceDataSource,
            });
        } else {
            this.setState({
                EventUKGridDataSource: secondaryInvoiceDataSource,
            });
        }
    };

    renderDelEditTitleV2Header = (data: any) => {
        return UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ? (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-1">DELETE</div> <span>|</span>
                    {"  "} <div className="ml-1">MASTER EDIT</div>
                </div>
            </>
        ) : UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ? (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-1">DELETE</div>
                </div>
            </>
        ) : null;
    };

    renderTitleV2Header = (data: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <>
                    <div className="mr-4">APPROVE</div>
                    <span>|</span> {"  "}
                    <div className="mx-3">QUERY</div> <span>|</span> {"  "}
                    <div className="mx-3">VIEW</div>
                    <span>|</span> {"  "}
                    <div className="mx-3">CHANGE</div>
                    {/* <span>|</span> {"  "}
                    <div className="mx-3">STATUS</div> */}
                </>
            </div>
        );
    };

    licenceCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                {cellInfo.data.unscheduled == "Yes" ? (
                    <Link
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.showInfoLicence(cellInfo.data.billableItemId);
                        }}
                        className="approval-query-column"
                        to="#"
                    >
                        {this.sharedUtils.licenseNumberFormat(cellInfo.data.identification_Number)}
                    </Link>
                ) : (
                    this.sharedUtils.licenseNumberFormat(cellInfo.data.identification_Number)
                )}
            </div>
        );
    };

    payReqCellDisplayContent = (cellInfo: any) => {
        let fasterPaymentVal: string = "";
        let paymentReqId: string = "";
        if (cellInfo && cellInfo.data && cellInfo.data.paymentRequest && cellInfo.data.paymentRequest.fasterPayment) {
            fasterPaymentVal = cellInfo.data.paymentRequest.fasterPayment;
            paymentReqId = cellInfo.data.paymentRequest.id;
        }
        return (
            <div>
                <Link
                    className="approval-query-column"
                    to={
                        {
                            pathname: "/MatchedPopUpPage",
                            search: `filterBy=${paymentReqId}`
                        }
                    }
                    target="_blank"
                >
                    {fasterPaymentVal}
                </Link>
            </div>
        );
    };

    showInfoLicence(billableItemId: string) {
        this.setState({
            billableItemId: billableItemId,
            licencePopupVisible: true,
        });
    }
    hideInfoLicence = () => {
        this.setState({
            licencePopupVisible: false,
        });
    };
    showInfoComments = (comments: string) => {
        this.setState({
            commentsPopupVisible: true,
            externalComments: comments,
        });
    };
    showInfoInternalComments = (internalComments: string) => {
        this.setState({
            internalCommentsPopupVisible: true,
            internalComments: internalComments,
        });
    };
    hideInfoComments = () => {
        this.setState({
            commentsPopupVisible: false,
        });
    };
    hideInfoInternalComments = () => {
        this.setState({
            internalCommentsPopupVisible: false,
        });
    };

    rateCellRender = (cellInfo: any) => {
        var billableExtraRateStr = "";
        var billableRateStr = "";
        var billableUnitClrClassValue: string = "errorstatus";
        var billedRate: string[] = this.gridUtils.billableRateDisplayContent(cellInfo.data ? cellInfo.data : "");
        if (billedRate[1] && billedRate[1].includes("(")) {
            billableExtraRateStr = billedRate[1].substring(
                billedRate[1].lastIndexOf("(") + 1,
                billedRate[1].lastIndexOf(")")
            );
            billableRateStr = billedRate[1].substr(0, billedRate[1].indexOf("("));
        }
        return (
            <div className="status-message-color">
                {billableExtraRateStr == "" ? (
                    <ul className="list-style-type">
                        <li> {billedRate[1]} </li>
                    </ul>
                ) : (
                    <ul className="list-style-type">
                        <li>
                            {billableRateStr}
                            <span className={billableUnitClrClassValue}>({billableExtraRateStr})</span>
                        </li>
                    </ul>
                )}
            </div>
        );
    };

    handleSelectionChange = (billableItemIdValue: number) => {
        if (billableItemIdValue) {
            var billableItemId = billableItemIdValue;
            this.editViewService.getEditViewData(billableItemId).then(
                this.handleSuccessGetById
            ).catch(this.handleError)
        }
        this.renderSelectionCellRender({}, true)
    }

    renderSelectionCellRender = (cellInfo: any, isShowFilledCircle: boolean) => {
        var iconToShow: string = "";
        if (cellInfo && cellInfo.data.paymentRequestID) {
            iconToShow = "disableIcon";
        }
        else if (isShowFilledCircle == true) {
            iconToShow = "filled"
        }
        else {
            iconToShow = "circle"
        }
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                        <button
                            className={"btn icon-btn"}
                            onClick={() => this.handleSelectionChange(cellInfo.data.billableItemId)}
                        >
                            {iconToShow == "circle" ? <FontAwesomeIcon icon={faCircle} /> : iconToShow == "filled" ? <FontAwesomeIcon icon={faCircleSolid} /> : <FadSquare
                                height={16}
                                width={16}
                            />}
                        </button>
                    </div>
                </div>
            </>
        );
    }

    headerOfExcludeCell = () => {
        return <div>EXCLUDE</div>;
    };

    headerOfSelectCell = () => {
        return <div>SELECT</div>
    }

    onClickGridCard = (billableItemId: number, navigation: string) => {
        this.setState({
            isMatchIconDisable: false
        }, () => { this.props.setMatchButtonState(this.state.isMatchIconDisable, true) });
        if (navigation == "yellow") {
            this.setState({
                isSelectedYellowCard: true,
                isSelectedGreenCard: false,
                isSelectedOrangeCard: false,
                clickedCardId: billableItemId
            });
        }
        else if (navigation == "green") {
            this.setState({
                isSelectedYellowCard: false,
                isSelectedGreenCard: true,
                isSelectedOrangeCard: false,
                clickedCardId: billableItemId
            });
        }
        else if (navigation == "orange") {
            this.setState({
                isSelectedYellowCard: false,
                isSelectedGreenCard: false,
                isSelectedOrangeCard: true,
                clickedCardId: billableItemId
            });
        }
        this.editViewService.getEditViewData(billableItemId).then(
            this.handleSuccessGetById
        ).catch(this.handleError)
    }

    onSelectionChanged = (value: DataGridOnSelectionChanged) => {
        GlobalService.removeBillableItemIds();
        if (value.selectedRowsData && value.selectedRowsData.length > 0) {
            value.selectedRowsData.forEach((items) => {
                GlobalService.setBillableItemIds(items.billableItemId);
            });
        }
        //this.setState({selectionChanged:true})
        //this.props.onRowSelectionChanged ? this.props.onRowSelectionChanged(true) : null;
        //this.handleCalculations();
    };

    handleSuccessGetById = (response: AxiosResponse<any>) => {
        var convertedFormData = this.utils.convertBillableItemResponeToFormData(response.data.data, false);
        this.setState({
            editViewDataItem: convertedFormData
        }, () => { this.props.setMatchButtonState(this.state.isMatchIconDisable, false) });
        this.props.setBillableItemObj(convertedFormData);
    }

    billableHrsCellDisplayContent = (cellInfo: any) => {
        if (
            cellInfo.data.billable_Hours_Units &&
            cellInfo.data.billable_Hours_Units.includes("(") &&
            (cellInfo.data.itemTypeValue == "AdjustmentClientDebit" ||
                cellInfo.data.itemTypeValue == "AdjustmentProviderDebit")
        ) {
            var billedTime: string[] = this.costReportUtils.billableTimeDisplayContent(
                cellInfo.data ? cellInfo.data : "",
                "shiftmatrix"
            );
            var billableExtraHrsStr = cellInfo.data.billable_Hours_Units.substring(
                cellInfo.data.billable_Hours_Units.lastIndexOf("(") + 1,
                cellInfo.data.billable_Hours_Units.lastIndexOf(")")
            );
            var billableHrsStr = cellInfo.data.billable_Hours_Units.substr(
                0,
                cellInfo.data.billable_Hours_Units.indexOf("(") - 1
            );
            return (
                <div>
                    <span className={billedTime[0]}>{billableHrsStr}</span>
                    <span> ({billableExtraHrsStr})</span>
                </div>
            );
        } else {
            billedTime = this.costReportUtils.billableTimeDisplayContent(
                cellInfo.data ? cellInfo.data : "",
                "shiftmatrix"
            );
            return (
                <div>
                    <span className={billedTime[0]}>{`${billedTime[1]}`}</span>
                </div>
            );
        }
    };

    billableRateCellDisplayContent = (cellInfo: any) => {
        var billableExtraRateStr = "";
        var billableRateStr = "";
        var billedRate: string[] = this.costReportUtils.billableRateDisplayContent(
            cellInfo.data ? cellInfo.data : "",
            "shiftmatrix"
        );
        if (cellInfo.data.rate_Decimal && cellInfo.data.rate_Decimal.includes("(")) {
            billableExtraRateStr = billedRate[1].substring(
                cellInfo.data.rate_Decimal.lastIndexOf("(") + 1,
                cellInfo.data.rate_Decimal.lastIndexOf(")")
            );
            billableExtraRateStr = "(" + " " + billableExtraRateStr + ")";
            billableRateStr = cellInfo.data.rate_Decimal.substr(0, billedRate[1].indexOf("("));
        }
        return (
            <div>
                {this.renderBillableRate(billableExtraRateStr, cellInfo.data.itemTypeId, billedRate, billableRateStr)}
            </div>
        );
    };

    // return rate based on itemType 
    renderBillableRate = (billableExtraRateStr: string, itemType: string, billedRate: string[], billableRateStr: string) => {
        if (billableExtraRateStr && itemType == itemTypes.Original) {
            return (
                <>
                    <span>{billableRateStr}</span> <span className={billedRate[0]}>{billableExtraRateStr}</span>
                </>
            );
        }
        else if (billableExtraRateStr && (itemType == itemTypes.AdjustmentClientDebit || itemType == itemTypes.AdjustmentProviderDebit || itemType == itemTypes.Adjustment)) {
            return (
                <>
                    <span className={billedRate[0]}>{billableRateStr}</span> <span>{billableExtraRateStr}</span>
                </>
            );
        }
        else {
            return (
                <ul className="list-style-type">
                    <li> {billedRate[1]} </li>
                </ul>
            );
        }
    }

    showStringToCurrencyFormat = (value: string) => {
        var valuewithoutPoundSymbol: number = 0;
        if (value && value.includes("£")) {
            valuewithoutPoundSymbol = parseFloat(value.replace("£", ""));
        }
        return valuewithoutPoundSymbol;
    };
    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            //topLeftCell: { row: 2, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell && gridCell.rowType === "data") {
                    if (gridCell.column && gridCell.column.dataField === "identification_Number") {
                        if (gridCell.value) {
                            excelCell.value = parseInt(gridCell.value);
                            excelCell.numFmt = "####-####-####-####";
                        }
                    }
                    if (
                        gridCell.column &&
                        (gridCell.column.dataField === "rate_Decimal" ||
                            gridCell.column.dataField === "provider_Pay_Amount_Local_Ccy" ||
                            gridCell.column.dataField === "provider_Pay_Tax_Local_Ccy" ||
                            gridCell.column.dataField === "provider_Pay_Total_Local_Ccy" ||
                            gridCell.column.dataField === "fee_Amount_Local_Ccy" ||
                            gridCell.column.dataField === "fee_Total_Local_Ccy" ||
                            gridCell.column.dataField === "fee_Tax_Local_Ccy" ||
                            gridCell.column.dataField === "client_Billable_Amount_Local_Ccy" ||
                            gridCell.column.dataField === "provider_Pay_Original_Amount_Local_Ccy" ||
                            gridCell.column.dataField === "client_Billable_Tax_Local_Ccy" ||
                            gridCell.column.dataField === "client_Billable_Total_Local_Ccy" ||
                            gridCell.column.dataField === "client_Billable_Original_Amount_Local_Ccy" ||
                            gridCell.column?.dataField === 'providerPayAmountAccrual' ||
                            gridCell.column?.dataField === 'grossProfitAmountAccrual' || 
                            gridCell.column?.dataField ==='clientBillableAmountAccrual')
                    ) {
                        excelCell.value = this.showStringToCurrencyFormat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.column && gridCell.column.dataField == "check_In_Out") {
                        let checkInOutArr: any[] = [];
                        let checkInClass: string = "";
                        let checkOutClass: string = "";
                        checkInOutArr = this.gridUtils.convertStringToStringArray(gridCell.value);
                        if (gridCell.data.checkin_Color == "Red" && gridCell.data.checkin_Font == "Bold") {
                            checkInClass = "FFFF0000";
                        } else if (gridCell.data.checkin_Color == "Green" && gridCell.data.checkin_Font == "Bold") {
                            checkInClass = "0000AEEF";
                        } else if (gridCell.data.checkin_Color == "Red" && !gridCell.data.checkin_Font) {
                            checkInClass = "FFFF0000";
                        } else if (gridCell.data.checkin_Color == "Green" && !gridCell.data.checkin_Font) {
                            checkInClass = "0000AEEF";
                        }

                        if (gridCell.data.checkOut_Color == "Red" && gridCell.data.checkOut_Font == "Bold") {
                            checkOutClass = "FFFF0000";
                        } else if (gridCell.data.checkOut_Color == "Green" && gridCell.data.checkOut_Font == "Bold") {
                            checkOutClass = "0000AEEF";
                        } else if (gridCell.data.checkOut_Color == "Red" && !gridCell.data.checkOut_Font) {
                            checkOutClass = "FFFF0000";
                        } else if (gridCell.data.checkOut_Color == "Green" && !gridCell.data.checkOut_Font) {
                            checkOutClass = "0000AEEF";
                        }

                        excelCell.value = {
                            richText: [
                                {
                                    font: {
                                        size: 12,
                                        color: {
                                            argb: checkInClass,
                                        },
                                        name: "Calibri",
                                        family: 2,
                                        scheme: "minor",
                                    },
                                    text: checkInOutArr.length > 0 ? checkInOutArr[0] : "",
                                },
                                {
                                    font: {
                                        size: 12,
                                        color: {
                                            argb: checkOutClass,
                                        },
                                        name: "Calibri",
                                        scheme: "minor",
                                    },
                                    text: checkInOutArr.length > 0 ? "-" + " " + checkInOutArr[1] : "",
                                },
                            ],
                        };
                    }
                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    };

    onRowPrepared = (e: any) => {
        if (e.rowType === "data" && this.state.isUnMatchSuccessful && this.props.paymentObject && (e.data.paymentRequestID != this.props.paymentObject.id || !e.data.paymentRequestID)) {
            e.rowElement.style.opacity = "";
        }
        else if (e.rowType === "data" && e.data.paymentRequestID
            && this.props.paymentObject && e.data.paymentRequestID == this.props.paymentObject.id && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched) {
            rowIndex = e.rowIndex;
            rowKey = e.data.billableItemId;
        }
        else if (e.rowType === "data" && this.props.paymentObject && (e.data.paymentRequestID != this.props.paymentObject.id || !e.data.paymentRequestID) && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched) {
            e.rowElement.style.opacity = "0.5"; // Appear 'disabled'
            on(e.rowElement, "dxclick", (event: MouseEvent) => event.stopPropagation()); // Prevent selection on click
            on(e.rowElement, "dxhold", (event: MouseEvent) => event.stopPropagation());
        }
        else if (e.rowType === "data" && e.data.paymentRequestID && this.props.paymentObject && this.props.paymentObject.paymentRequestStatusLookUp.id != paymentRequestStatus.Matched) {
            e.rowElement.style.opacity = 0.5; // Appear 'disabled'
            on(e.rowElement, "dxclick", (event: MouseEvent) => event.stopPropagation()); // Prevent selection on click
            on(e.rowElement, "dxhold", (event: MouseEvent) => event.stopPropagation()); // Prevent selection on hold
            rowIndex = "";
        }
        else if (e.rowType === "data" && !e.data.paymentRequestID && this.props.paymentObject && this.props.paymentObject.paymentRequestStatusLookUp.id != paymentRequestStatus.Matched) {
            rowIndex = "";
        }
    }

    onContentReady = (e: any) => {
        if (!e.component.getSelectedRowKeys().length) {
            e.component.selectRowsByIndexes(rowIndex);
        }
        var noData;
        setTimeout(() => {
            noData = document.querySelector(".dx-datagrid-nodata");
            if (noData) {
                //do anything
                noData.innerHTML = "<b className = noDataSection>No shifts found for this payment request</b>"
            }
        })
    }

    renderColouredBlock = (data: any, color: string, isSelectedState: boolean, isDisabled: boolean, isMatchedItem: boolean) => {
        let serviceSubType: string = "";
        serviceSubType = data.serviceSubTypeValue;
        let innerClassName: string = "";
        let outerClassName: string = "";
        let stateText: string = "";
        const isSelectedCard = isSelectedState && this.state.clickedCardId == data.billableItemId;
        if (isMatchedItem) {
            if (!this.state.editViewDataItem.billableItemId) {
                this.editViewService.getEditViewData(data.billableItemId).then(
                    this.handleSuccessGetById
                ).catch(() => { this.handleError });
                // could change this to --matched?                
                stateText = "Matched shift"
            }
            innerClassName = `grid-info__grid-card--${color}`;
        }
        else {
            if (isDisabled) {
                innerClassName = `grid-info__grid-card--${color} grid-info__grid-card--disabled`;
                stateText = "Unmatchable shift"
            }
            else {
                innerClassName = `grid-info__grid-card--${color}`;
                stateText = "Shift" // could update to partial match/ no match based on colour
                if (isSelectedCard) {
                    outerClassName = "grid-info__grid-card__container--selected"
                }
            }
        }
        // define anything that is possibly null or needs formatting
        const payAmountToMatch = parseFloat(this.props.fee ? this.props.fee : "");
        const dateToMatch = new Date(data.dateFrom.split("T")[0]);
        const dateToMatchString = dateToMatch.toLocaleDateString("en-GB");
        // could move these outside of the repeated function for perfomance improvement, but worried about readability
        //booking amount calcualtion 
        let bookingAmount: string = "";
        if (data.providerPayAmountLocal || data.providerFasterPayFee) {
            if (data.providerPayAmountLocal && data.providerFasterPayFee) {
                let totalBookingAmount = data.providerPayAmountLocal + parseFloat(data.providerFasterPayFee.toString());
                bookingAmount = totalBookingAmount.toString();
            }
            else if (data.providerPayAmountLocal) {
                bookingAmount = data.providerPayAmountLocal.toString();
            }
            else if (data.providerFasterPayFee) {
                bookingAmount = data.providerFasterPayFee.toString();
            }
            else {
                bookingAmount = "0.00";
            }
        }
        // separate the data out from the HTML
        // this allows it to exist in the most basic state possible
        // later, iterate over the data to produce the HTML
        const cardData = [
            {
                key: 1,
                value: data.venue,
                isMatch: data.venueName === this.props.venueName,
                importance: "danger"
            },
            {
                key: 2,
                value: dateToMatchString,
                isMatch: dateToMatchString === this.props.paymentDate,
                importance: "danger"
            },
            {
                key: 3,
                value: data.provider,
                isMatch: data.provider === this.props.providerName,
                importance: "warning"
            },
            {
                key: 4,
                value: `£${bookingAmount}`,
                isMatch: parseFloat(bookingAmount) === payAmountToMatch,
                importance: "warning"
            },
            {
                key: 5,
                value: data.shift_Start,
                icon: faClock,
                importance: "reference"
            },
            {
                key: 6,
                value: serviceSubType,
                icon: faMusic,
                importance: "reference"
            },
            {
                key: 7,
                value: data.originator?.value,
                icon: faUser,
                importance: "reference"
            },
            {
                key: 8,
                value: data.status,
                icon: faFlagCheckered,
                importance: "reference"
            },
        ];

        return (
            <article className="grid-info__card_position">
                <div className={`grid-info__grid-card__container ${outerClassName}`}>
                    <button className={`btn ${innerClassName}`}
                        disabled={isDisabled}
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.onClickGridCard(parseInt(data.billableItemId), color);
                        }}>
                        <span className="sr-only">{stateText}</span>
                        {
                            // iterate over the data
                            // this means the HMTL code can be written once rather than multiple times
                            cardData.map(item =>
                                <span key={item.key} className={`grid-info__grid-card__item--${item.importance}`}>
                                    {item.icon != null ?
                                        // if icon provied, use icon
                                        <span className="grid-info__grid-card__item__icon">
                                            <FontAwesomeIcon icon={item.icon} />
                                        </span>
                                        :
                                        // else use isMatch
                                        item.isMatch ?
                                            <span className="grid-info__grid-card__item__icon grid-info__grid-card__item__icon--matching">
                                                <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            :
                                            <span className="grid-info__grid-card__item__icon grid-info__grid-card__item__icon--not-matching">
                                                <FontAwesomeIcon icon={faTimes} />
                                            </span>
                                    }
                                    {item.value == "Cancelled" ?
                                        <span className="ml-2 grid-info__grid-card__item__red">
                                            {item.value}
                                        </span>
                                        : item.value == "Queried" ?
                                            <span className="ml-2 grid-info__grid-card__item__ember">
                                                {item.value}
                                            </span>
                                            : <span className="ml-2 grid-info__grid-card__item__value">
                                                {item.value}
                                            </span>

                                    }
                                </span>)

                        }
                    </button>
                    {isSelectedCard ?
                        <div className="grid-info__grid-card__controls">
                            <div className="grid-info__grid-card__control">
                                {/* the above class adds bloat, but normalises the structure of the coponents */}
                                <CardApproveComponent data={data} />
                            </div>
                            <div className="grid-info__grid-card__control">
                                <CardQueryComponent data={data} />
                            </div>
                            <div className="grid-info__grid-card__control">
                                <CardViewComponent data={data} />
                            </div>
                        </div>
                        : null}
                </div>
            </article>
        )
    }

    // these could be updated directly in the code below, but this maintains a layer of abstraction
    // and keeps the interface consistent
    renderGreyColouredBlock = (data: eventUkUserGridRowItem) => {
        return (
            this.renderColouredBlock(data, "grey", false, true, false)
        );
    }

    renderYellowColouredBlock = (data: eventUkUserGridRowItem) => {
        return (
            this.renderColouredBlock(data, "yellow", this.state.isSelectedYellowCard, false, false)
        )
    }

    renderGreenColouredBlock = (data: any) => {
        return (
            this.renderColouredBlock(data, "green", this.state.isSelectedGreenCard, false, false)
        )
    }

    renderOrangeColouredBlock = (data: any) => {
        return (
            this.renderColouredBlock(data, "orange", this.state.isSelectedOrangeCard, false, false)
        )
    }

    renderBlueColouredBlock = (data: any) => {
        return (
            this.renderColouredBlock(data, "blue", this.state.isSelectedOrangeCard, false, true)
        )
    }

    renderDatarow = (rowInfo: any) => {
        return (
            <>
                <tr>
                    <td rowSpan={5}>
                        {this.renderCards(rowInfo.data.cards, 3, "previous")}
                    </td>
                    <td>
                        {this.renderCards(rowInfo.data.cards, 2, "previous")}
                    </td>
                    <td>
                        {this.renderCards(rowInfo.data.cards, 1, "previous")}
                    </td>
                    <td>
                        {this.renderCards(rowInfo.data.cards, 0, "previous")}
                    </td>
                    <td>
                        {this.renderCards(rowInfo.data.cards, 1, "after")}
                    </td>
                    <td>
                        {this.renderCards(rowInfo.data.cards, 2, "after")}
                    </td>
                    <td>
                        {this.renderCards(rowInfo.data.cards, 3, "after")}
                    </td>

                </tr>
            </>
        )
    }

    renderCards = (cardsData: any[], colNo: number, navigation: string) => {
        let dateVal = this.sharedUtils.convertStringToDate(this.props.dateFrom);
        let paymentDateVal = this.props.paymentDate;
        if (dateVal) {
            if (navigation == "previous") {
                dateVal.setDate(dateVal.getDate() - colNo);
            }
            else if (navigation == "after") {
                dateVal.setDate(dateVal.getDate() + colNo);
            }
        }
        let convertedDate = this.sharedUtils.convertDateToString(dateVal);
        var items: eventUkUserGridRowItem[] = [];
        items = cardsData
            ? cardsData.filter((item) => item.dateTo == convertedDate)
            : [];
        let blockToReturn: any[] = [];
        if (items.length > 0) {
            items.forEach((data: eventUkUserGridRowItem, key: number) => {
                let date: string = "";
                let bookingAmount: string = "";
                if (data.providerPayAmountLocal || data.providerFasterPayFee) {
                    if (data.providerPayAmountLocal && data.providerFasterPayFee) {
                        let totalBookingAmount = data.providerPayAmountLocal + parseFloat(data.providerFasterPayFee.toString());
                        bookingAmount = totalBookingAmount.toString();
                    }
                    else if (data.providerPayAmountLocal) {
                        bookingAmount = data.providerPayAmountLocal.toString();
                    }
                    else if (data.providerFasterPayFee) {
                        bookingAmount = data.providerFasterPayFee.toString();
                    }
                    else {
                        bookingAmount = "0.00";
                    }
                }
                if (paymentDateVal) {
                    date = paymentDateVal;
                }
                //let providerAmount: number = this.gridUtils.convertCurrencyAsStringToFloat(data.provider_Pay_Amount_Local_Ccy);
                let provider: string = this.props.providerName ? this.props.providerName : "";
                let venueParts: string = data.venueName;
                if (
                    (data.provider == provider) &&
                    (data.dateTo == date) &&
                    (this.props.fee && (parseFloat(bookingAmount) == parseFloat(this.props.fee))) &&
                    (venueParts == this.props.venueName)
                ) {
                    if (this.props.paymentObject && this.props.paymentObject.paymentRequestType && this.props.paymentObject.paymentRequestType.id == PaymentTypes.PaymentRequest) {
                        if (data.paymentRequestID
                            && this.props.paymentObject && data.paymentRequestID == this.props.paymentObject.id && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched) {
                            blockToReturn.push(this.renderBlueColouredBlock(data));
                            // this.editViewService.getEditViewData(parseInt(data.billableItemId)).then(
                            //     this.handleSuccessGetById).catch(this.handleError);
                        }
                        else if (data.paymentRequestID && this.props.paymentObject && this.props.paymentObject.paymentRequestStatusLookUp.id != paymentRequestStatus.Matched
                            || this.props.paymentObject && (data.paymentRequestID != this.props.paymentObject.id || !data.paymentRequestID) && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched) {
                            blockToReturn.push(this.renderGreyColouredBlock(data));
                        }
                        else {
                            blockToReturn.push(this.renderGreenColouredBlock(data));
                        }
                    }
                    else if (this.props.paymentObject && this.props.paymentObject.paymentRequestType && this.props.paymentObject.paymentRequestType.id == PaymentTypes.AddJob) {
                        if (data.addJobRequestID
                            && this.props.paymentObject && data.addJobRequestID == this.props.paymentObject.id && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.AddJobCompleted) {
                            blockToReturn.push(this.renderBlueColouredBlock(data));
                        }
                        else if (data.addJobRequestID && this.props.paymentObject && this.props.paymentObject.paymentRequestStatusLookUp.id != paymentRequestStatus.AddJobCompleted
                            || this.props.paymentObject && (data.addJobRequestID != this.props.paymentObject.id || !data.addJobRequestID) && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.AddJobCompleted) {
                            blockToReturn.push(this.renderGreyColouredBlock(data));
                        }
                        else {
                            blockToReturn.push(this.renderGreenColouredBlock(data));
                        }
                    }
                }
                else if (data.dateTo == date && venueParts == this.props.venueName) {
                    if (this.props.paymentObject && this.props.paymentObject.paymentRequestType && this.props.paymentObject.paymentRequestType.id == PaymentTypes.PaymentRequest) {
                        if (data.paymentRequestID
                            && this.props.paymentObject && data.paymentRequestID == this.props.paymentObject.id && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched) {
                            blockToReturn.push(this.renderBlueColouredBlock(data));
                            // this.editViewService.getEditViewData(parseInt(data.billableItemId)).then(
                            //     this.handleSuccessGetById).catch(this.handleError);`
                        }
                        else if (data.paymentRequestID && this.props.paymentObject && this.props.paymentObject.paymentRequestStatusLookUp.id != paymentRequestStatus.Matched
                            || this.props.paymentObject && (data.paymentRequestID != this.props.paymentObject.id || !data.paymentRequestID) && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched) {
                            blockToReturn.push(this.renderGreyColouredBlock(data));
                        }
                        else {
                            blockToReturn.push(this.renderYellowColouredBlock(data));
                        }
                    }
                    else if (this.props.paymentObject && this.props.paymentObject.paymentRequestType && this.props.paymentObject.paymentRequestType.id == PaymentTypes.AddJob) {
                        if (data.addJobRequestID
                            && this.props.paymentObject && data.addJobRequestID == this.props.paymentObject.id && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.AddJobCompleted) {
                            blockToReturn.push(this.renderBlueColouredBlock(data));
                            // this.editViewService.getEditViewData(parseInt(data.billableItemId)).then(
                            //     this.handleSuccessGetById).catch(this.handleError);
                        }
                        else if (data.addJobRequestID && this.props.paymentObject && this.props.paymentObject.paymentRequestStatusLookUp.id != paymentRequestStatus.AddJobCompleted
                            || this.props.paymentObject && (data.addJobRequestID != this.props.paymentObject.id || !data.addJobRequestID) && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.AddJobCompleted) {
                            blockToReturn.push(this.renderGreyColouredBlock(data));
                        }
                        else {
                            blockToReturn.push(this.renderYellowColouredBlock(data));
                        }
                    }
                }
                else if ((venueParts == this.props.venueName) || data.provider == provider) {
                    if (this.props.paymentObject && this.props.paymentObject.paymentRequestType && this.props.paymentObject.paymentRequestType.id == PaymentTypes.PaymentRequest) {
                        if (data.paymentRequestID
                            && this.props.paymentObject && data.paymentRequestID == this.props.paymentObject.id && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched) {
                            blockToReturn.push(this.renderBlueColouredBlock(data));
                            // this.editViewService.getEditViewData(parseInt(data.billableItemId)).then(
                            //     this.handleSuccessGetById).catch(this.handleError);
                        }
                        else if (data.paymentRequestID && this.props.paymentObject && this.props.paymentObject.paymentRequestStatusLookUp.id != paymentRequestStatus.Matched
                            || this.props.paymentObject && (data.paymentRequestID != this.props.paymentObject.id || !data.paymentRequestID) && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.Matched) {
                            blockToReturn.push(this.renderGreyColouredBlock(data));
                        }
                        else {
                            blockToReturn.push(this.renderOrangeColouredBlock(data));
                        }
                    }
                    else if (this.props.paymentObject && this.props.paymentObject.paymentRequestType && this.props.paymentObject.paymentRequestType.id == PaymentTypes.AddJob) {
                        if (data.addJobRequestID
                            && this.props.paymentObject && data.addJobRequestID == this.props.paymentObject.id && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.AddJobCompleted) {
                            blockToReturn.push(this.renderBlueColouredBlock(data));
                        }
                        else if (data.addJobRequestID && this.props.paymentObject && this.props.paymentObject.paymentRequestStatusLookUp.id != paymentRequestStatus.AddJobCompleted
                            || this.props.paymentObject && (data.addJobRequestID != this.props.paymentObject.id || !data.addJobRequestID) && this.props.paymentObject.paymentRequestStatusLookUp.id == paymentRequestStatus.AddJobCompleted) {
                            blockToReturn.push(this.renderGreyColouredBlock(data));
                        }
                        else {
                            blockToReturn.push(this.renderOrangeColouredBlock(data));
                        }
                    }
                }
            });
        }
        return blockToReturn;
    }

    renderColouredBlockGrid = () => {
        return (
            <div className="alternate-style-grid__container">
                <DataGrid
                    dataSource={this.state.EventUKGridDataSource}
                    // columnAutoWidth={true}
                    columnMinWidth={215}
                    showBorders={false}
                    rowAlternationEnabled={true}
                    dataRowRender={this.renderDatarow}
                //hoverStateEnabled={true}
                >
                    <Column headerCellRender={() => this.renderColumnHeader(3, "previous")} />
                    <Column headerCellRender={() => this.renderColumnHeader(2, "previous")} />
                    <Column headerCellRender={() => this.renderColumnHeader(1, "previous")} />
                    <Column headerCellRender={() => this.renderColumnHeader(0, "previous")} />
                    <Column headerCellRender={() => this.renderColumnHeader(1, "after")} />
                    <Column headerCellRender={() => this.renderColumnHeader(2, "after")} />
                    <Column headerCellRender={() => this.renderColumnHeader(3, "after")} />

                </DataGrid>
            </div>
        )
    }


    renderColumnHeader = (columnNo: number, position: string) => {
        let dayText = "";
        let dateText = "";
        let matchingDateClass = "";
        if (this.props.dateFrom) {
            let dateVal = this.sharedUtils.convertStringToDate(this.props.dateFrom);
            if (dateVal) {
                if (position.toLowerCase() == "previous") {
                    dateVal.setDate(dateVal.getDate() - columnNo);
                }
                else if (position.toLowerCase() == "after") {
                    dateVal.setDate(dateVal.getDate() + columnNo);
                }
            }
            dateText = dateVal?.getDate().toString() || "";
            dayText = new Intl.DateTimeFormat("en-GB", { weekday: "long" }).format(dateVal)

            const dateToMatchString = dateVal?.toLocaleDateString("en-GB") || "";
            // if date is the same as the shift we are trying to match, add a class to highlight it
            // This is a string comparison, ideally we would be comparing date values
            // however, it is not trivial to convert this.props.dateFrom to a date
            if (dateToMatchString === this.props.paymentDate) {
                matchingDateClass = "alternate-style-grid__date-header--matching"
            }
        }
        return <div className={`alternate-style-grid__date-header ${matchingDateClass}`}>
            <span className="alternate-style-grid__date-header__date">{dateText}</span>
            <span className="alternate-style-grid__date-header__day">{dayText}</span>
        </div>
    }

    render() {
        const isEventUk =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        var { gridType } = this.props;
        var { primaryInvoiceDataSourceCount } = this.state;
        var modeOfSelection: string = "";
        var isStoring: boolean = true;
        if (this.props.gridType == GridTypes.billing) {
            modeOfSelection = "multiple";
            isStoring = false;
        }
        else if (this.props.gridType == GridTypes.paymentsRequest) {
            modeOfSelection = "single";
            isStoring = false;
        }
        return (
            <div>
                {gridType == GridTypes.invoiceReport && (
                    <GridToggleTab
                        primaryTabText={`By Date (${primaryInvoiceDataSourceCount.toString()})`}
                        secondaryTabText={`By Amount (${primaryInvoiceDataSourceCount.toString()})`}
                        className={"Teal"}
                        onToggle={this.swapGridDataSource}
                    />
                )}
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : gridType == GridTypes.paymentsRequest ? this.renderColouredBlockGrid() : (
                    <DataGrid
                        dataSource={this.state.EventUKGridDataSource}
                        keyExpr="billableItemId"
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        onSelectionChanged={this.onSelectionChanged}
                        onExporting={this.onExporting}
                        ref="dataGridRef"
                    >
                        <GroupPanel visible={true} />
                        <FilterPanel visible={true}></FilterPanel>
                        <SearchPanel visible={true} placeholder={"Search"} />
                        {isStoring ? (
                            <StateStoring
                                enabled={true}
                                type="custom"
                                customLoad={this.onCustomGridLoad}
                                customSave={this.onCustomGridSave}
                                savingTimeout='0'
                            />
                        ) : null}
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Scrolling useNative={true} showScrollbar={top} />
                        <Export enabled={true} allowExportSelectedData={true} />
                        {/* <Selection mode={this.state.allowSelection} /> */}

                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        {this.props.gridType.toLowerCase() == "billing" ? (
                            this.props.view ? (
                                <Column
                                    cssClass="disabled"
                                    headerCellRender={this.headerOfExcludeCell}
                                    type="selection"
                                />
                            ) : (
                                <Column headerCellRender={this.headerOfExcludeCell} type="selection" />
                            )
                        ) :
                            null}
                        <Column
                            width="120"
                            caption="APPROVE"
                            cellComponent={ApproveActionCell}
                            allowExporting={false}
                        />

                        <Column
                            width="120"
                            caption="QUERY"
                            cellComponent={QueryActionCell}
                            allowExporting={false}
                        />
                        <Column
                            width="120"
                            caption="CHANGE"
                            cellComponent={ChangeShiftMatrixCell}
                            allowExporting={false}
                        />
                        <Column width="120" caption="VIEW" cellComponent={ViewShiftMatrixCell} allowExporting={false} />

                        <Column dataField="status" caption="STATUS" width="300" />
                        <Column dataField="paymentRequest.fasterPayment" caption="PAY REQUEST" width={150} cellRender={this.payReqCellDisplayContent} />
                        <Column
                            dataField="date"
                            caption="DATE"
                            width="100"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                            cellRender={this.dateCellDisplayContent}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                            dataType="date"
                            format="dd/MM/yyyy"
                        />
                        <Column dataField="client" caption="CLIENT" width="120" />
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? (
                            <Column dataField="provider" caption="ARTIST NAME/SERVICE" width="160" />
                        ) : this.state.serviceTypeValue == ServiceType.Security ||
                            this.state.serviceTypeValue == ServiceType.ContractCleaning ? (
                            <Column dataField="provider" caption="PROVIDER" width="160" />
                        ) : (
                            <Column dataField="provider" cellRender={this.providerCellDisplayContent} caption="PROVIDER/ARTIST" width="160" />
                        )}
                        <Column
                            dataField="venueDetails.venueName"
                            caption="VENUE"
                            width="200"
                        />
                        <Column
                            dataField="venueDetails.houseNumber"
                            caption="VENUE NO"
                            width="200"
                        />
                        <Column 
                            dataField="venueDetails.onboarded" 
                            caption="ONBOARDED" 
                            cellComponent={this.onboardedCellDisplayContent}
                            width="200"
                        />
                        {/* {this.props.gridType == GridTypes.paymentsRequest ?
                            <Column
                                dataField="provider_Pay_Amount_Local_Ccy"
                                caption="PROVIDER NET"
                                width="245"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertCurrencyAsStringToFloat(
                                        rowData.provider_Pay_Amount_Local_Ccy
                                    );
                                }}
                                // calculateDisplayValue={(rowData: any) => {
                                //     return this.sharedUtils.thousandsSeparator(rowData.provider_Pay_Amount_Local_Ccy);
                                // }}
                                cellRender={this.feeCellDisplayContent}
                            /> : null} */}
                        <Column dataField="service" caption="SERVICE" width="200" cellComponent={AllUserGridService} />
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? (
                            <Column dataField="role" caption="TYPE" width="150" />
                        ) : this.state.serviceTypeValue == ServiceType.ContractCleaning ? (
                            <Column dataField="role" caption="DESCRIPTION" width="150" />
                        ) : (
                            <Column dataField="role" caption="ROLE/TYPE" width="150" />
                        )}
                        {this.state.serviceTypeValue == ServiceType.Entertainment ||
                            this.state.serviceTypeValue == ServiceType.ContractCleaning ? null : (
                            <Column dataField="name" caption="NAME" width="100" />
                        )}
                        {this.state.serviceTypeValue == ServiceType.Entertainment ||
                            this.state.serviceTypeValue == ServiceType.ContractCleaning ? null : (
                            <Column
                                dataField="check_In_Out"
                                caption="CHECK IN & OUT"
                                width="133"
                                cellComponent={CheckInOutCell}
                            />
                        )}
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? (
                            <Column
                                dataField="performanceInformationSetsDuration"
                                caption="PERFORMANCE TIME"
                                width="150"
                            />
                        ) : null}
                        {this.state.serviceTypeValue == ServiceType.ContractCleaning ||
                            this.state.serviceTypeValue == ServiceType.Entertainment ? null : (
                            <Column
                                dataField="shift"
                                caption="SCHEDULED SHIFT"
                                width="131"
                                cellComponent={AllUserGridShift}
                            />
                        )}
                        {this.state.serviceTypeValue == ServiceType.ContractCleaning ||
                            this.state.serviceTypeValue == ServiceType.Security ? null : (
                            <Column dataField="performanceInformation" caption="PERFORMANCE DETAILS" width="200" />
                        )}
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? null : (
                            <Column
                                dataField="billable_Hours_Units"
                                caption="TIME/QUANTITY"
                                width="173"
                                cellRender={this.billableHrsCellDisplayContent}
                            />
                        )}
                        <Column dataField="timeDecimal" caption="TIME(DECIMAL)" width="150" />
                        {this.state.serviceTypeValue == ServiceType.Entertainment ? (
                            <Column
                                dataField="rate_Decimal"
                                caption="FEE"
                                width="88"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate_Decimal);
                                }}
                                cellRender={this.billableRateCellDisplayContent}
                            />
                        ) : this.state.serviceTypeValue == ServiceType.Security ||
                            this.state.serviceTypeValue == ServiceType.ContractCleaning ? (
                            <Column
                                dataField="rate_Decimal"
                                caption="RATE"
                                width="88"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate_Decimal);
                                }}
                                cellRender={this.billableRateCellDisplayContent}
                            />
                        ) : (
                            <Column
                                dataField="rate_Decimal"
                                caption="RATE/FEE"
                                width="88"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate_Decimal);
                                }}
                                cellRender={this.billableRateCellDisplayContent}
                            />
                        )}
                        <Column dataField="cost" caption="COST" width="97" cellComponent={CostCell} />
                        <Column
                            dataField="comments"
                            caption="COMMENTS"
                            width="107"
                            cellRender={this.commentsCellDisplayContent}
                        />
                        {isEventUk && (
                            <Column
                                dataField="internalComments"
                                caption="INTERNAL COMMENTS"
                                width="165"
                                cellRender={this.internalCommentsCellDisplayContent}
                            />
                        )}
                        <Column dataField="itemTypeValue" caption="ITEM TYPE" width="99" />
                        <Column dataField="hardApproval" caption="HARD APPROVAL" width="150"/>
                        <Column dataField="holdPayment" caption="HOLD PAYMENT" width="150"/>
                        {this.props.gridType != GridTypes.paymentsRequest ?
                            <Column
                                dataField="provider_Pay_Amount_Local_Ccy"
                                caption="PROVIDER NET"
                                width="245"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertCurrencyAsStringToFloat(
                                        rowData.provider_Pay_Amount_Local_Ccy
                                    );
                                }}
                                // calculateDisplayValue={(rowData: any) => {
                                //     return this.sharedUtils.thousandsSeparator(rowData.provider_Pay_Amount_Local_Ccy);
                                // }}
                                cellRender={this.feeCellDisplayContent}
                            /> : null}
                        <Column
                            dataField="provider_Pay_Tax_Local_Ccy"
                            caption="PROVIDER VAT"
                            width="216"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.provider_Pay_Tax_Local_Ccy
                                );
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.provider_Pay_Tax_Local_Ccy);
                            }}
                        />
                        <Column
                            dataField="provider_Pay_Total_Local_Ccy"
                            caption="PROVIDER GROSS"
                            width="229"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.provider_Pay_Total_Local_Ccy
                                );
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.provider_Pay_Total_Local_Ccy);
                            }}
                        />
                        <Column
                            dataField="fee_Amount_Local_Ccy"
                            caption="EVENT FEE NET"
                            width="185"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(rowData.fee_Amount_Local_Ccy);
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.fee_Amount_Local_Ccy);
                            }}
                        />


                        <Column
                            dataField="providerPayAmountAccrual"
                            caption="PAY ACCRUAL"
                            width={150}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.providerPayAmountAccrual);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.grossProfitAmountLocal
                                );
                            }}
                        />


                        <Column
                            dataField="grossProfitAmountAccrual"
                            caption="FEE ACCRUAL"
                            width={150}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.grossProfitAmountAccrual);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.grossProfitAmountLocal
                                );
                            }}
                        />


                        <Column
                            dataField="clientBillableAmountAccrual"
                            caption="CLIENT ACCRUAL"
                            width={150}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.clientBillableAmountAccrual);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.grossProfitAmountLocal
                                );
                            }}
                        />

                        <Column
                            dataField="client_Billable_Amount_Local_Ccy"
                            caption="TOTAL NET"
                            width="257"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.client_Billable_Amount_Local_Ccy
                                );
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.client_Billable_Amount_Local_Ccy);
                            }}
                        />
                        {this.state.serviceTypeValue == ServiceType.ContractCleaning ||
                            this.state.serviceTypeValue == ServiceType.Entertainment ? null : (
                            <Column
                                dataField="identification_Number"
                                caption="IDENTIFICATION NO."
                                width="153"
                                cellRender={this.licenceCellDisplayContent}
                            />
                        )}
                        {this.state.serviceTypeValue == ServiceType.ContractCleaning ||
                            this.state.serviceTypeValue == ServiceType.Security ||
                            this.state.serviceTypeValue == ServiceType.Entertainment ? null : (
                            <Column dataField="cover" caption="COVER" width="81" />
                        )}
                        <Column dataField="billable_Start_Finish" caption="BILLABLE START/FINISH" width="170" />
                        <Column
                            dataField="dateTo"
                            caption="DATE TO"
                            width="89"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                            }}
                            cellRender={this.dateCellDisplayContent}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                            }}
                            dataType="date"
                            format="dd/MM/yyyy"
                        />
                        <Column
                            dataField="pay_Date"
                            caption="PAY DATE"
                            width="100"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.pay_Date);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.pay_Date);
                            }}
                            dataType="date"
                            format="dd/MM/yyyy"
                        />
                        <Column
                            dataField="bill_Date"
                            caption="BILL DATE"
                            width="100"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.bill_Date);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.bill_Date);
                            }}
                            dataType="date"
                            format="dd/MM/yyyy"
                        />
                        <Column dataField="provider_Tax_Applicable" caption="PROVIDER VAT APPLICABLE?" width="199" />
                        <Column dataField="client_Tax_Applicable" caption="CLIENT VAT APPLICABLE?" width="181" />
                        <Column
                            dataField="provider_Pay_Currency_Local_Ccy"
                            caption="PROVIDER PAY CURRENCY"
                            width="256"
                        />
                        <Column
                            dataField="provider_Pay_Original_Amount_Local_Ccy"
                            caption="PROVIDER PAY ORIGINAL AMOUNT"
                            width="301"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.provider_Pay_Original_Amount_Local_Ccy
                                );
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(
                                    rowData.provider_Pay_Original_Amount_Local_Ccy
                                );
                            }}
                        />
                        <Column dataField="fee_Currency_Local_Ccy" caption="FEE CURRENCY" width="196" />
                        <Column
                            dataField="fee_Tax_Local_Ccy"
                            caption="FEE VAT"
                            width="156"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(rowData.fee_Tax_Local_Ccy);
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.fee_Tax_Local_Ccy);
                            }}
                        />
                        <Column
                            dataField="fee_Total_Local_Ccy"
                            caption="FEE TOTAL"
                            width="169"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(rowData.fee_Total_Local_Ccy);
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.fee_Total_Local_Ccy);
                            }}
                        />
                        <Column dataField="fee_Percentage_Local_Ccy" caption="FEE %" width="159" />
                        <Column
                            dataField="client_Billable_Currency_Local_Ccy"
                            caption="CLIENT BILLABLE CURRENCY"
                            width="271"
                        />
                        <Column
                            dataField="client_Billable_Tax_Local_Ccy"
                            caption="CLIENT BILLABLE VAT"
                            width="228"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.client_Billable_Tax_Local_Ccy
                                );
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.client_Billable_Tax_Local_Ccy);
                            }}
                        />
                        <Column
                            dataField="client_Billable_Total_Local_Ccy"
                            caption="CLIENT BILLABLE TOTAL"
                            width="242"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.client_Billable_Total_Local_Ccy
                                );
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.client_Billable_Total_Local_Ccy);
                            }}
                        />
                        <Column
                            dataField="client_Billable_Original_Amount_Local_Ccy"
                            caption="CLIENT BILLABLE ORIGINAL AMOUNT"
                            width="313"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertCurrencyAsStringToFloat(
                                    rowData.client_Billable_Original_Amount_Local_Ccy
                                );
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(
                                    rowData.client_Billable_Original_Amount_Local_Ccy
                                );
                            }}
                        />
                        <Column
                            dataField="accept_Date"
                            caption="ACCEPT DATE"
                            width="118"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.accept_Date);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.accept_Date);
                            }}
                            dataType="date"
                            format="dd/MM/yyyy"
                        />
                        <Column
                            dataField="approval_Date"
                            caption="APPROVAL DATE"
                            width="133"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.approval_Date);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.approval_Date);
                            }}
                            dataType="date"
                            format="dd/MM/yyyy"
                        />
                        <Column
                        dataField="shiftCompleteAcknowledgmentDateTime"  
                        caption="SHIFT ACKNOWLEDGEMENT DATE"
                        format="dd/MM/yyyy"
                        dataType="date"/>
                        <Column dataField="non_Standard" caption="NON STANDARD" width="132" />
                        <Column dataField="batchId" caption="BATCH" width="132" />
                        <Column dataField="billableItemId" caption="ID" width="132" />
                        <Column
                            headerCellRender={this.renderDelEditTitleV2Header}
                            cellComponent={DeleteEditShiftActionCellV2}
                            width={
                                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
                                    ? "200"
                                    : UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
                                        ? "100"
                                        : null
                            }
                            allowExporting={false}
                        />

                        <Selection mode={modeOfSelection}></Selection>

                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}

                <Popup
                    visible={this.state.licencePopupVisible}
                    onHiding={this.hideInfoLicence}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Door Supervision - Unscheduled Shift"
                    resizeEnabled={true}
                    className="pop-up pop-up--tall pop-up--left-aligned"
                >
                    <ScrollView width="100%" height="100%">
                        <SIALicenceDetails billableItemId={this.state.billableItemId}></SIALicenceDetails>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.commentsPopupVisible}
                    onHiding={this.hideInfoComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.externalComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.internalCommentsPopupVisible}
                    onHiding={this.hideInfoInternalComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"INTERNAL COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.internalComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </div>
        );
    }
}

export default EventUkUserGrid;
